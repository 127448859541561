import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Dropdown,
  Form,
  Accordion,
  Icon,
  Message,
  Breadcrumb,
  Menu,
  Grid,
  GridColumn,
  Button,
  Responsive,
  Loader
} from "semantic-ui-react";
import Provinces from "../data/provinces";
import { Link } from "react-router-dom";
import ProductCards from "../components/ProductCards";
import ImageGallery from "../components/ImageGallery";
import { injectIntl, defineMessages } from "react-intl";
import feathersClient from "../feathersClient";

const messages = defineMessages({
  reVerifyHeader: {
    id: "app.views.reverify.header",
    defaultMessage: "Tu correo electronico no está verificado"
  },
  reVerifyContent: {
    id: "app.views.reverify.content",
    defaultMessage:
      "Revisa tu correo electronico, te enviamos un enlace para verificar tu cuenta"
  },
  reSendButton: {
    id: "app.views.reverify.button",
    defaultMessage: "Enviar de nuevo"
  },
  updatingMessage: {
    id: "app.views.reverify.updating-message",
    defaultMessage: "En cuanto verifiques tu cuenta vas a poder ingresar"
  },
  emailSent: {
    id: "app.views.reverify.emailSent",
    defaultMessage: "Te enviamos el correo de nuevo!"
  }
});
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";

class ReVerifyScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null
    };
    // this.listener = helperCreateSubscriptions(
    //   feathersClient.service("api/users"),
    //   this.onUserChange,
    //   ["updated", "patched", "removed"]
    // );
  }

  // onUserChange = event => user => {
  //   //console.log(event);
  //   if (event == "patched" || "updated") {
  //     //console.log(user);
  //     if (user.id == this.props.session.user.id) {
  //       this.props.session.authenticate();
  //       return;
  //     }
  //   } else {
  //     this.props.authenticate();
  //   }
  // };

  sendVerificationMail = async () => {
    this.setState({
      loading: true,
      error: null
    });
    try {
      await feathersClient.service("/api/authmanagement").create({
        action: "resendVerifySignup",
        value: { email: this.props.session.user.email }, // {email}, {token: verifyToken}
        notifierOptions: {} // options passed to options.notifier, e.g. {preferredComm: 'cellphone'}
      });
      this.setState({
        loading: false,
        error: null
      });
    } catch (error) {
      this.setState({
        error,
        loading: false
      });
    }
  };

  componentDidMount() {}

  componentWillUnmount() {
    // this.listener.remover();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.loading == true &&
      this.state.loading == false &&
      this.state.error == null
    ) {
      alert(this.props.intl.formatMessage(messages.emailSent));
    }
  }

  render() {
    const {
      intl: { formatMessage },
      match: { path },
      initialData: {
        company: { interface: uiConfiguration }
      }
    } = this.props;

    const { loading, error } = this.state;

    return (
      <Container style={{ paddingTop: 10 }}>
        <Segment basic padded="very">
          <Header textAlign="center">
            {formatMessage(messages.reVerifyHeader)}
            <Header.Subheader>
              {formatMessage(messages.reVerifyContent)}
              <br />
              {formatMessage(messages.updatingMessage)}
            </Header.Subheader>
          </Header>
          <Segment basic padded="very">
            <Loader active={true} />
          </Segment>
          <Segment>
            <Button
              fluid
              disabled={loading}
              color="green"
              size="huge"
              icon="mail"
              onClick={this.sendVerificationMail}
              content={formatMessage(messages.reSendButton)}
            />
          </Segment>
          <Segment basic padded="very">
            {!error ? null : (
              <Message
                error
                header={"Oops!"}
                content={!error ? "" : `${error.message} (${error.name})`}
              />
            )}
          </Segment>
        </Segment>
      </Container>
    );
  }
}

export default injectIntl(ReVerifyScreen);
