import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Breadcrumb,
  Menu,
  Grid
} from "semantic-ui-react";
import queryString from "query-string";
import { Link } from "react-router-dom";
import ProductCards from "../components/ProductCards";
import { injectIntl, defineMessages } from "react-intl";

const messages = defineMessages({
  sortBy: {
    id: "app.views.category.sortBy.header",
    defaultMessage: "Sort By"
  },
  priceSort: {
    id: "app.views.category.sortBy.price",
    defaultMessage: "Price"
  },
  subcategories: {
    id: "app.views.category.subcategories.header",
    defaultMessage: "Subcategories"
  },
  header: {
    id: "app.views.category.header",
    defaultMessage: "Search Results for "
  }
});
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";
const productList = [
  {
    id: 1,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 3,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 4,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 5,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 6,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  }
];
class SearchResultsScreen extends Component {
  render() {
    const {
      intl: { formatMessage },
      initialData: {
        company: { interface: uiConfiguration }
      }
    } = this.props;
    return (
      <Container style={{ paddingTop: 10 }}>
        <Grid stackable>
          <Grid.Column width="2" only="tablet computer">
            <Menu vertical text style={{ paddingTop: 100 }}>
              <Menu.Item header>{formatMessage(messages.sortBy)}</Menu.Item>
              <Menu.Item name={formatMessage(messages.priceSort)} />
              <Menu.Item header>
                {formatMessage(messages.subcategories)}
              </Menu.Item>
              <Menu.Item name="Categoria 1" />
              <Menu.Item name="Categoria 2" />
            </Menu>
          </Grid.Column>
          <Grid.Column width="14">
            <CategoryBreadcrumb uiConfiguration={uiConfiguration} />
            <ProductCards
              header={`${formatMessage(messages.header)} "${
                queryString.parse(this.props.location.search).q
              }"`}
              products={productList}
              shouldScroll={false}
              showName
              defaultColor={uiConfiguration.colors.primary}
            />
          </Grid.Column>
          <Grid.Column only="mobile">
            <Menu text stackable>
              <Menu.Item header>{formatMessage(messages.sortBy)}</Menu.Item>
              <Menu.Item name={formatMessage(messages.priceSort)} />
              <Menu.Item header>
                {formatMessage(messages.subcategories)}
              </Menu.Item>
              <Menu.Item name="Categoria 1" />
              <Menu.Item name="Categoria 2" />
            </Menu>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const CategoryBreadcrumb = ({ uiConfiguration }) => {
  return (
    <Breadcrumb style={{ paddingLeft: 10 }}>
      <Breadcrumb.Section
        link
        as={Link}
        to="/"
        style={{ color: uiConfiguration.colors.primary }}>
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section>Products</Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section active>Search</Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default injectIntl(SearchResultsScreen);
