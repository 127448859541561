import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Breadcrumb,
  Menu,
  Grid
} from "semantic-ui-react";
import ProductCards from "./ProductCards";
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";
const productList = [
  {
    id: 1,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 3,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 4,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 5,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 6,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 7,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 8,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 9,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  }
];

export default props => {
  return (
    <div>
      <Image
        src={
          props.images && props.images.length > 0
            ? props.images[props.activeImage]
            : placeholderProductImg
        }
        fluid
      />
      <ThumbnailList
        uiConfiguration={props.uiConfiguration}
        images={props.images || []}
        productId={props.productId}
      />
    </div>
  );
};

const ThumbnailList = props => {
  // console.log("LOG: props", props);

  const pseudoProducts = props.images.map((anImage, idx) => {
    let thumbnailVersion = anImage;
    thumbnailVersion = `${anImage}`.split(".");
    let ext = thumbnailVersion.pop();

    thumbnailVersion = anImage.replace(`.${ext}`, `-thumbnail-128-128.${ext}`);

    return {
      id: props.productId,
      thumbnail: !props.uiConfiguration.disableThumbnails
        ? thumbnailVersion
        : anImage,
      StockTransactions: { amount: 1 }
    };
  });
  console.log("pseudoproducts", pseudoProducts);
  return (
    <ProductCards
      id="thumbnailsProduct"
      products={pseudoProducts}
      thumbnailUrlsEnabled
      defaultColor={props.uiConfiguration.colors.primary}
    />
  );
};
