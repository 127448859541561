/**
 * Authentication function
 * @param  {Object} options  Options for authentication with feathersjs
 * @param  {Object} client  API Client to authenticate
 * @param  {function} dispatch Method to dispatch events
 * @return {promise}          Resolution of authentication promise
 */
const authenticate = async (options, client) => {
  //console.log("authenticate.options", options);
  try {
    let response = await client.authenticate(options);
    let payload = await client.passport.verifyJWT(response.accessToken);
    let user = await client.service("api/users").get(payload.userId);
    client.set("user", user);
    client.set("error", null);
    return user;
  } catch (error) {
    client.set("user", null);
    client.set("error", error);
    throw error;
  }
};

export default client => {
  return options => authenticate(options, client);
};

export const logOut = client => {
  return async () => {
    try {
      await client.logout();
      client.set("user", null);
      client.set("error", null);
      return true;
    } catch (error) {
      client.set("user", null);
      client.set("error", error);
      throw error;
    }
  };
};
