import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Form,
  Dropdown,
  Breadcrumb,
  List,
  Menu,
  Pagination,
  Grid,
  GridColumn,
  Button,
  Input,
  Message
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ProductCards from "../components/ProductCards";
import ImageGallery from "../components/ImageGallery";
import { injectIntl, defineMessages } from "react-intl";
import feathersClient from "../feathersClient";

const messages = defineMessages({
  header: {
    id: "app.views.profile.header",
    defaultMessage: "Perfil"
  }
});
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";
class ProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: {
        loading: false,
        error: null,
        message: null
      },
      email: "",
      fullName: "",
      phoneNumber: "",
      address: {
        street: "",
        streetContinuation: "",
        streetNumber: "",
        city: "",
        postalCode: "",
        province: "",
        country: ""
      },
      personalId: "",
      passwordForm: {
        currentPassword: "",
        newPassword: "",
        passwordAgain: ""
      }
    };
  }

  setInitialValues = () => {
    let user = this.props.session.user;
    let initialValues = {
      email: user.email,
      fullName: user.fullName,
      phoneNumber: user.phoneNumber,
      address: user.address,
      personalId: user.personalId
    };
    this.setState({
      email: "",
      fullName: "",
      phoneNumber: "",
      personalId: "",
      ...initialValues,
      address: {
        ...(!!initialValues.address
          ? initialValues.address
          : {
              street: "",
              streetContinuation: "",
              streetNumber: "",
              city: "",
              postalCode: "",
              province: "",
              country: ""
            })
      }
    });
  };

  handleProfileChange = (field, address = false) => (e, { value }) => {
    if (!address) {
      this.setState({
        [field]: value
      });
    } else {
      this.setState({
        ...this.state,
        address: {
          ...this.state.address,
          [field]: value
        }
      });
    }
  };

  handlePasswordChange = field => (e, { value }) => {
    this.setState({
      ...this.state,
      passwordForm: {
        ...this.state.passwordForm,
        [field]: value
      }
    });
  };

  updateProfile = async () => {
    const {
      intl: { formatMessage },
      session: { user }
    } = this.props;
    if (!user) {
      return;
    }
    this.setState({
      status: {
        loading: true,
        error: null,
        message: null
      }
    });
    let Fields = { ...this.state };
    delete Fields.passwordForm;
    delete Fields.status;
    try {
      let response = await feathersClient
        .service("/api/users")
        .patch(user.id, { ...Fields, username: Fields.email });
      //console.log("response", response);
      this.setState({
        status: {
          loading: false,
          error: null,
          message: "Success"
        }
      });
    } catch (error) {
      console.error(error);
      this.setState({
        status: {
          loading: false,
          error,
          message: null
        }
      });
    }
  };

  updatePassword = async () => {
    const {
      intl: { formatMessage },
      session: { user }
    } = this.props;
    let Fields = { ...this.state.passwordForm };
    if (!user || Fields.passwordAgain != Fields.newPassword) {
      return;
    }
    this.setState({
      status: {
        loading: true,
        error: null,
        message: null
      }
    });
    try {
      let response = await feathersClient.service("api/authmanagement").create({
        action: "passwordChange",
        value: {
          user: { username: user.username }, // identify user, e.g. {email: 'a@a.com'}. See options.identifyUserProps.
          oldPassword: Fields.currentPassword, // old password for verification
          password: Fields.newPassword // new password
        }
      });
      //console.log("response", response);
      this.setState({
        status: {
          loading: false,
          error: null,
          message: "Success"
        }
      });
    } catch (error) {
      console.error(error);
      this.setState({
        status: {
          loading: false,
          error,
          message: null
        }
      });
    }
  };

  componentDidMount() {
    this.setInitialValues();
  }
  render() {
    const {
      intl: { formatMessage },
      session: { user },
      initialData: {
        company: { interface: uiConfiguration }
      }
    } = this.props;

    const {
      fullName,
      personalId,
      phoneNumber,
      email,
      address,
      passwordForm,
      status
    } = this.state;
    return (
      <Container style={{ paddingTop: 10 }}>
        <CategoryBreadcrumb
          formatMessage={formatMessage}
          uiConfiguration={uiConfiguration}
        />
        <Header size="large">{formatMessage(messages.header)}</Header>
        {(!status.message && !status.error) || status.loading ? null : (
          <Message
            header={status.message && !status.error ? "Exito!" : "Oops"}
            content={
              !status.message && status.error
                ? JSON.stringify(status.error)
                : ""
            }
            error={!!status.error}
            positive={!status.error}
            negative={!!status.error}
          />
        )}
        <Segment loading={status.loading}>
          <Grid reversed="mobile" stackable divided>
            <Grid.Column width="8">
              <Segment basic>
                <Header size="small">Datos Personales</Header>
                <Form stackable>
                  <Form.Group stackable>
                    <Form.Input
                      label="Nombre Completo"
                      width="10"
                      onChange={this.handleProfileChange("fullName")}
                      value={fullName}
                    />
                    <Form.Input
                      label="DNI"
                      width="6"
                      value={personalId}
                      onChange={this.handleProfileChange("personalId")}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      label="Telefono"
                      width="6"
                      value={phoneNumber}
                      onChange={this.handleProfileChange("phoneNumber")}
                    />
                    <Form.Input
                      label="Correo Electronico"
                      width="12"
                      value={email}
                      onChange={this.handleProfileChange("email")}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      label="Calle"
                      width="9"
                      value={address.street}
                      onChange={this.handleProfileChange("street", true)}
                    />
                    <Form.Input
                      label="Numero"
                      width="7"
                      value={address.streetNumber}
                      onChange={this.handleProfileChange("streetNumber", true)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      label="Calle (detalles, e.g. Piso, Depto, Timbre, etc...)"
                      width="16"
                      value={address.streetContinuation}
                      onChange={this.handleProfileChange(
                        "streetContinuation",
                        true
                      )}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      label="Ciudad"
                      width="10"
                      value={address.city}
                      onChange={this.handleProfileChange("city", true)}
                    />
                    <Form.Input
                      label="CP"
                      width="6"
                      value={address.postalCode}
                      onChange={this.handleProfileChange("postalCode", true)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      label="Provincia"
                      width="8"
                      value={address.province}
                      onChange={this.handleProfileChange("province", true)}
                    />
                    <Form.Input
                      label="País"
                      width="8"
                      value={address.country}
                      onChange={this.handleProfileChange("country", true)}
                    />
                  </Form.Group>
                </Form>
                <Segment basic>
                  <Button
                    content="Actualizar Datos"
                    color="blue"
                    onClick={this.updateProfile}
                    style={{ backgroundColor: uiConfiguration.colors.primary }}
                  />
                </Segment>
              </Segment>
            </Grid.Column>
            <Grid.Column width="8">
              <Segment basic loading={status.loading}>
                <Header size="small">Cambiar Contraseña</Header>
                <Form stackable>
                  <Form.Group>
                    <Form.Input
                      label="Contraseña actual"
                      width="10"
                      type="password"
                      value={passwordForm.currentPassword}
                      onChange={this.handlePasswordChange("currentPassword")}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      label="Nueva contraseña"
                      width="10"
                      type="password"
                      value={passwordForm.newPassword}
                      onChange={this.handlePasswordChange("newPassword")}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      label="Nueva contraseña, de nuevo"
                      width="10"
                      type="password"
                      value={passwordForm.passwordAgain}
                      onChange={this.handlePasswordChange("passwordAgain")}
                    />
                  </Form.Group>
                </Form>
                <Segment basic>
                  <Button
                    content="Cambiar Contraseña"
                    disabled={
                      passwordForm.currentPassword.length < 3 ||
                      passwordForm.newPassword != passwordForm.passwordAgain ||
                      passwordForm.newPassword.length < 4
                    }
                    color="blue"
                    style={{ backgroundColor: uiConfiguration.colors.primary }}
                    onClick={this.updatePassword}
                  />
                </Segment>
              </Segment>
            </Grid.Column>
          </Grid>
        </Segment>
      </Container>
    );
  }
}

const CategoryBreadcrumb = props => {
  return (
    <Breadcrumb>
      <Breadcrumb.Section
        link
        as={Link}
        to="/"
        style={{ color: props.uiConfiguration.colors.primary }}>
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section>Mi Cuenta</Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section active>
        {props.formatMessage(messages.header)}
      </Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default injectIntl(ProfileScreen);
