import React, { Component, useState, useEffect, useCallback } from "react";
import {
  Button,
  Checkbox,
  Form,
  List,
  Select,
  Input,
  Segment,
  Message
} from "semantic-ui-react";
import feathersClient from "../../feathersClient";
import { load } from "@feathersjs/client/dist/feathers";
import moment from "moment";

function cleanObject(object) {
  let copy = { ...object };
  if (!!copy.deleted) {
    delete copy.deleted;
  }
  if (!!copy.modified) {
    delete copy.modified;
  }
  return { ...copy };
}

function DetailItem(props) {
  const {
    id,
    OrderId,
    ProductId,
    priceId: PriceId,
    externalId,
    createdAt,
    updatedAt,
    discount,
    record,
    observations,
    amount,
    canEdit,
    deleted
  } = props;
  const { updateItem, loading } = props;
  return (
    <List.Item key={id}>
      <List.Content floated={"right"}>
        <Input
          type="number"
          step="1"
          min="0"
          max="100"
          value={discount}
          label="Descuento"
          disabled={!canEdit}
          onChange={(e, { value }) =>
            Number(value) &&
            Number.isInteger(Number(value)) &&
            value >= 0 &&
            value <= 100 &&
            updateItem({ discount: value })
          }
        />
        <Input
          type="number"
          step="1"
          min="0"
          disabled={!canEdit}
          value={amount}
          label="Cantidad"
          onChange={(e, { value }) =>
            Number(value) &&
            Number.isInteger(Number(value)) &&
            value >= 0 &&
            updateItem({ amount: value })
          }
        />
        <Button
          negative
          icon="trash"
          disabled={!canEdit}
          onClick={() => updateItem({ deleted: true })}
        />
      </List.Content>
      <List.Content>
        <List.Header
          style={{ textDecoration: !deleted ? undefined : "line-through" }}
        >
          {record.product && record.product.name}
        </List.Header>
        <List.Description
          style={{ textDecoration: !deleted ? undefined : "line-through" }}
        >
          #{id}/{OrderId}/{ProductId}/{PriceId}{" "}
          {!record.product ? null : record.product.code}
        </List.Description>
        <List.Description>
          {moment(updatedAt).format("DD/MM/YY hh:mm")}
        </List.Description>
      </List.Content>
    </List.Item>
  );
}

function DetailForm(props) {
  const {
    OrderId,
    Order,
    initialData: { company }
  } = props;

  const isCompanyAllowedToEditDetails =
    company.settings.canEdit &&
    company.settings.canEdit.indexOf("order-details") != -1;

  const canEdit = Order.status == "confirming" && isCompanyAllowedToEditDetails;

  const [orderDetails, setOrderDetails] = useState([]);
  const [originalDetails, setOriginalDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(
    () => {
      if (loading) {
        return;
      }
      setLoading(true);
      feathersClient
        .service("api/order-details")
        .find({
          query: {
            OrderId
          }
        })
        .then(details => {
          setOrderDetails(details.data);
          setOriginalDetails(details.data);

          setLoading(false);
          setError(null);
        })
        .catch(error => {
          setLoading(false);
          setOrderDetails([]);
          setOriginalDetails([]);
          setError(error);
        });
    },
    [OrderId]
  );

  const updateDetails = async () => {
    console.log("updateDetails");
    if (!canEdit) {
      return;
    }
    setLoading(true);
    setError(null);
    console.log("updating");
    try {
      setLoading(true);
      let promises = await Promise.all(
        orderDetails
          .filter(a => !!a.modified)
          .map(a =>
            !!a.deleted
              ? feathersClient.service("api/order-details").remove(a.id)
              : feathersClient
                  .service("api/order-details")
                  .patch(a.id, cleanObject(a))
          )
      );
      feathersClient
        .service("api/order-details")
        .find({ query: { OrderId } })
        .then(details => {
          setOrderDetails(details.data);
          setOriginalDetails(details.data);

          setLoading(false);
          setError(null);
        })
        .catch(error => {
          setLoading(false);
          setOrderDetails([]);
          setOriginalDetails([]);
          console.log(error);
          setError(error);
        });
    } catch (error) {
      console.log(error);
      setError(error);
      setLoading(false);
    }
  };

  // const updateEntry = useCallback(
  //   async () => {
  //     if (loading || !currentStockEntry || !ProductId) {
  //       return;
  //     }
  //     setLoading(true);
  //     try {
  //       if (!currentStockEntry.id) {
  //         let anEntry = await feathersClient
  //           .service("api/stock-transactions")
  //           .create(currentStockEntry);
  //         setCurrentStockEntry(anEntry);
  //         setLatestStock(anEntry.amount);
  //       } else {
  //         let anEntry = await feathersClient
  //           .service("api/stock-transactions")
  //           .patch(currentStockEntry.id, currentStockEntry);
  //         // console.log(anEntry);
  //         setCurrentStockEntry(anEntry);
  //         setLatestStock(anEntry.amount);
  //       }
  //       setLoading(false);
  //       setError(null);
  //     } catch (error) {
  //       setLatestStock(null);
  //       setLoading(false);
  //       setError(error);
  //       // console.log(error);
  //     }
  //   },
  //   [currentStockEntry, loading, ProductId]
  // );
  if (
    Order.status == "cart" ||
    Order.status == "anonymousCart" ||
    Order.status == "pendingPayment"
  )
    return (
      <Message error>
        No se puede ver los detalles de un pedido en esta etapa
      </Message>
    );
  return (
    <Segment basic loading={loading}>
      {Order.status == "confirming" && isCompanyAllowedToEditDetails ? (
        <Message info>
          Recuerde actualizar los montos del pago en "Pedido" y hacer lo mismo
          en el procesador de pago correspondiente.
        </Message>
      ) : (
        <Message info>
          No se puede editar un pedido en esta{" "}
          {isCompanyAllowedToEditDetails ? "etapa" : "empresa"}
        </Message>
      )}
      <List>
        {orderDetails.map(aDetail => (
          <DetailItem
            {...aDetail}
            canEdit={canEdit}
            key={aDetail.id}
            updateItem={values => {
              setOrderDetails(someDetails =>
                someDetails.map(a =>
                  a.id == aDetail.id ? { modified: true, ...a, ...values } : a
                )
              );
            }}
          />
        ))}
      </List>
      <Button
        negative
        onClick={() => setOrderDetails(originalDetails)}
        disabled={!canEdit || orderDetails.some(a => a.modified != true)}
      >
        Descartar Cambios
      </Button>
      <Button
        positive
        onClick={updateDetails}
        disabled={!canEdit || orderDetails.some(a => a.modified != true)}
      >
        Guardar Cambios
      </Button>
    </Segment>
  );
}

export default DetailForm;
