import React, { Component } from "react";
import PropTypes from "prop-types";
import feathersClient from "../feathersClient";
import { Slide } from "react-slideshow-image";

import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Responsive
} from "semantic-ui-react";
import ProductCards from "../components/ProductCards";
import PromoCards from "../components/PromoCards";
import { injectIntl, defineMessages } from "react-intl";
import CategoryCards from "../components/CategoryCards";

const messages = defineMessages({});
//MOCKING
const someBanner =
  "https://http2.mlstatic.com/optimize/resources/exhibitors/MLA-black-friday/a741cb20-ecdb-11e8-b6d1-43b7d13987aa-home-slider_desktop.jpg";
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";

const productList = [
  {
    id: 1,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 3,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 4,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 5,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 6,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 7,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 8,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  },
  {
    id: 9,
    name: "Producto 1",
    thumbnail: placeholderProductImg
  }
];

const INITIAL_STATE = {
  products: {
    loading: false,
    error: null,
    data: []
  },
  promos: {
    loading: false,
    error: null,
    data: []
  },
  categories: {
    loading: false,
    error: null,
    data: []
  }
};
class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }

  // FETCHING - STATIC

  static FetchProducts = productIds => {
    return feathersClient.service("api/products").find({
      query: {
        $limit: 1000,
        id: { $in: productIds }
      }
    });
  };

  static FetchPromos = promosIds => {
    return feathersClient.service("api/promos").find({
      query: {
        $limit: 1000,
        id: { $in: promosIds }
      }
    });
  };

  static FetchCategories = categoriesIds => {
    console.log("categoryIds", categoriesIds);
    return feathersClient.service("api/categories").find({
      query: {
        $limit: 1000,
        id: { $in: categoriesIds }
      }
    });
  };

  // LOADING - DYNAMIC

  loadProducts = async () => {
    this.setState({
      products: {
        loading: true,
        error: null,
        data: this.state.products.data
      }
    });
    try {
      let response = await HomeScreen.FetchProducts(
        this.props.initialData.lists.data.reduce((prev, curr) => {
          if (curr.section == "Home" && curr.table == "Product") {
            return [...prev, ...curr.items];
          }
          return prev;
        }, [])
      );
      //console.log("loadProducts.response", response);
      this.setState({
        products: {
          loading: false,
          error: null,
          data: response.data
        }
      });
    } catch (error) {
      console.error(error);
      this.setState({
        products: {
          loading: false,
          error,
          data: []
        }
      });
    }
  };

  loadPromos = async () => {
    this.setState({
      promos: {
        loading: true,
        error: null,
        data: this.state.promos.data
      }
    });
    try {
      let response = await HomeScreen.FetchPromos(
        this.props.initialData.lists.data.reduce((prev, curr) => {
          if (
            (curr.section == "Home" || curr.section == "HomeBanner") &&
            curr.table == "Promo"
          ) {
            return [...prev, ...curr.items];
          }
          return prev;
        }, [])
      );
      //console.log("loadPromos.response", response);
      this.setState({
        promos: {
          loading: false,
          error: null,
          data: response.data
        }
      });
    } catch (error) {
      console.error(error);
      this.setState({
        promos: {
          loading: false,
          error,
          data: []
        }
      });
    }
  };

  loadCategories = async () => {
    this.setState({
      categories: {
        loading: true,
        error: null,
        data: this.state.categories.data
      }
    });
    try {
      let response = await HomeScreen.FetchCategories(
        this.props.initialData.lists.data.reduce((prev, curr) => {
          if (curr.section == "Home" && curr.table == "Category") {
            return [...prev, ...curr.items];
          }
          return prev;
        }, [])
      );
      //console.log("loadCategories.response", response);
      console.log(
        "lists",
        this.props.initialData.lists.data,
        "data",
        response.data
      );
      this.setState({
        categories: {
          loading: false,
          error: null,
          data: response.data
        }
      });
    } catch (error) {
      console.error(error);
      this.setState({
        categories: {
          loading: false,
          error,
          data: []
        }
      });
    }
  };

  // EVENT LISTENERS - DYNAMIC

  componentDidMount() {
    this.loadProducts();
    this.loadPromos();
    this.loadCategories();
  }

  render() {
    const {
      intl: { formatMessage },
      initialData: {
        company: { interface: uIConfiguration },
        lists: { data: lists }
      }
    } = this.props;

    const { promos, products, categories } = this.state;

    const slideProperties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: false,
      indicators: true,
      arrows: true
    };

    const homeLists = lists
      .filter(aList => aList.section == "Home")
      .sort((a, b) => (a.order > b.order ? 1 : -1));
    const homeBannerLists = lists.filter(
      aList => aList.section == "HomeBanner" && aList.table == "Promo"
    );
    console.log("LOG: render -> homeBannerLists", homeBannerLists);
    const bannerPromosItems = homeBannerLists.reduce((p, c) => {
      return [...p, ...c.items];
    }, []);
    console.log("LOG: render -> bannerPromosItems", bannerPromosItems);
    const bannerPromos = bannerPromosItems
      .map(aPromoId => promos.data.find(aPromo => aPromo.id == aPromoId))
      .filter(a => !!a && a.id);
    console.log("LOG: render -> promos", promos);
    console.log("bannerPromos", bannerPromos);
    return (
      <Container fluid>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          {!!promos.loading || promos.data.length <= 0 ? null : (
            <Slide {...slideProperties}>
              {bannerPromos.map(aPromo => {
                return (
                  <div className="each-slide" key={`${aPromo.id}promoBanner`}>
                    <div style={{ height: "auto" }}>
                      {/* <span>Slide 1</span> */}
                      <Image
                        fluid
                        src={aPromo.imageSrc}
                        href={aPromo.link || undefined}
                      />
                    </div>
                  </div>
                );
              })}
            </Slide>
          )}
        </Responsive>
        <Container style={{ paddingTop: 5 }}>
          {homeLists.map(aSection => {
            if (aSection.table == "Product") {
              return (
                <ProductCards
                  header={aSection.name}
                  shouldScroll={aSection.shouldScroll}
                  itemsPerRow={aSection.itemsPerRow || 4}
                  showName
                  id={`${aSection.id}section`}
                  key={`${aSection.id}section`}
                  products={aSection.items
                    .map(aItem =>
                      products.data.find(aProduct => aProduct.id == aItem)
                    )
                    .filter(a => a != undefined)
                    .map(aProduct => {
                      let anImage =
                        aProduct.images.length > 0
                          ? aProduct.images[0]
                          : placeholderProductImg;
                      let thumbnailVersion = anImage;
                      if (anImage != placeholderProductImg) {
                        thumbnailVersion = `${anImage}`.split(".");
                        let ext = thumbnailVersion.pop();

                        thumbnailVersion = anImage.replace(
                          `.${ext}`,
                          `-thumbnail-512-512.${ext}`
                        );
                      }
                      return {
                        ...aProduct,
                        thumbnail: thumbnailVersion
                      };
                    })}
                  loading={products.loading}
                  defaultColor={
                    uIConfiguration.colors
                      ? uIConfiguration.colors.primary
                      : null
                  }
                />
              );
            }
            if (aSection.table == "Promo") {
              return (
                <PromoCards
                  header={aSection.name}
                  showName={aSection.showName}
                  itemsPerRow={aSection.itemsPerRow || 2}
                  shouldScroll={aSection.shouldScroll}
                  id={`${aSection.id}sectionPromo`}
                  key={`${aSection.id}sectionPromo`}
                  promos={aSection.items
                    .map(aItem =>
                      promos.data.find(aPromo => aPromo.id == aItem)
                    )
                    .filter(a => a != undefined)}
                  loading={promos.loading}
                />
              );
            }

            if (aSection.table == "Category") {
              return (
                <CategoryCards
                  header={aSection.name}
                  showName={aSection.showName}
                  itemsPerRow={aSection.itemsPerRow || 6}
                  id={`${aSection.id}sectionCategory`}
                  key={`${aSection.id}sectionCategory`}
                  categories={aSection.items
                    .map(aItem =>
                      categories.data.find(aCategory => aCategory.id == aItem)
                    )
                    .filter(a => a != undefined)}
                  shouldScroll={aSection.shouldScroll}
                  loading={categories.loading}
                />
              );
            }
          })}
        </Container>
      </Container>
    );
  }
}

HomeScreen.propTypes = {
  companyName: PropTypes.string.isRequired
};

export default injectIntl(HomeScreen);
