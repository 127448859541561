import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./semantic/dist/semantic.min.css";
let node = document.getElementById("root");
const initialData = node.dataset.react ? JSON.parse(node.dataset.react) : {};
ReactDOM.render(<App initialData={initialData} />, node);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
