import React, { Component } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Responsive,
  Button
} from "semantic-ui-react";

const buttonStyles = {
  position: "absolute",
  top: "40%",
  zIndex: 10
};

// class HorizontalScrollAware extends Component {
//   state = {
//     scrollLeft: 0
//   };

//   setScroll = (scrollLeft = 0) => {
//     this.setState({
//       scrollLeft
//     });
//   };

//   render() {
//     let Child = React.Children.only(this.props.children);

//     window.onload = () => {
//       window.setTimeout(() => {
//         (document.getElementById(`trackId`) || {}).onscroll = function(ev) {
//           this.setScroll(
//             Math.abs(document.getElementById(`trackId`).scrollLeft)
//           );
//         };
//       }, 1000);
//     };

//     return (
//       <Child trackId={`trackId`} scroll={{ left: this.state.scrollLeft }} />
//     );
//   }
// }

class ProductCards extends Component {
  state = {
    scrollLeft: 0,
    page: 4,
    endOfScroll: false
  };

  setScroll = (scrollLeft = 0, endOfScroll = false) => {
    this.setState({
      scrollLeft,
      endOfScroll
    });
  };

  componentDidMount() {
    let element = document.getElementById(`scrollComponent${this.props.id}`);
    if (element) {
      this.setScroll(
        element.scrollLeft,
        element.offsetWidth + element.scrollLeft === element.scrollWidth
      );
      let updateFunc = () => {
        let otherElement = document.getElementById(
          `scrollComponent${this.props.id}`
        );
        // console.dir(otherElement);
        if (otherElement) {
          this.setScroll(
            otherElement.scrollLeft,
            element.offsetWidth + element.scrollLeft === element.scrollWidth
          );
        }
      };
      window.setInterval(updateFunc, 300);
      element.onscroll = debounce(updateFunc, 300, { leading: true });
    }
  }

  scroll = (reverse = false) => () => {
    let modifier = reverse ? -1 : 1;
    let selectString = `[id="scrollComponent${this.props.id}"]`;
    let selection = window.document.querySelector(selectString);
    let rect = selection.getBoundingClientRect();
    let width = rect.width;
    //console.log(selectString);
    selection.scrollBy({
      left: modifier * width,
      behavior: "smooth"
    });
  };

  debouncedScroll = this.scroll;

  render() {
    let {
      header,
      products: productsUnfiltered,
      itemsPerRow,
      shouldScroll,
      showName,
      thumbnailUrlsEnabled,
      loading,
      showWarning
    } = this.props;
    // console.log(
    //   // "LOG: ProductCards -> render -> productsUnfiltered",
    //   productsUnfiltered
    // );
    let { scrollLeft, endOfScroll } = this.state;
    let products = productsUnfiltered.filter(
      a => !!a.StockTransactions && a.StockTransactions.amount > 0
    );
    // let products = productsUnfiltered;

    return (
      <Responsive as={Segment} basic loading={loading}>
        <Header>{header}</Header>
        <div style={{ position: "relative" }} className="sectionProducts">
          {scrollLeft <= 0 ? null : (
            <Button
              icon="arrow left"
              circular
              size="massive"
              className="sectionArrow"
              onClick={this.scroll(true)}
              style={{ ...buttonStyles, left: -40 }}
            />
          )}
          {products.length <= 0 && !loading && !!showWarning ? (
            <Card
              fluid
              header={
                <Header>No hay productos disponibles en esta categoria!</Header>
              }
            />
          ) : null}
          <Responsive
            as={Card.Group}
            doubling
            itemsPerRow={itemsPerRow || 4}
            id={`scrollComponent${this.props.id}`}
            className="scrolling-wrapper scrolling-no-bars"
            style={
              shouldScroll === false
                ? undefined
                : { display: "flex", flexWrap: "nowrap", overflowX: "auto" }
            }
          >
            {products.map((aProduct, i) => {
              // console.log(aProduct);
              return (
                <Card
                  as={Link}
                  id={`scrollComponentCard${this.props.id}Position${i}`}
                  to={`/product/${aProduct.id}${
                    thumbnailUrlsEnabled ? `#${i}` : ""
                  }`}
                  style={{ flex: "0 0 auto" }}
                  color={aProduct.color || undefined}
                  image={aProduct.thumbnail}
                  style={
                    this.props.defaultColor && !aProduct.color
                      ? {
                          flex: "0 0 auto",
                          "box-shadow": `0 0 0 1px #d4d4d5,0 2px 0 0 ${
                            this.props.defaultColor
                          },0 1px 3px 0 #d4d4d5`
                        }
                      : { flex: "0 0 auto" }
                  }
                  header={
                    showName ? (
                      <Header>
                        <Header.Subheader>{aProduct.name}</Header.Subheader>
                        <Header.Subheader style={{ fontWeight: "bold" }}>
                          {aProduct.prices && aProduct.prices.length > 0
                            ? `$${parseFloat(aProduct.prices[0].value)
                                .toFixed(2)
                                .replace(".", ",")}`
                            : ""}
                        </Header.Subheader>
                      </Header>
                    ) : null
                  }
                  key={`${aProduct.id}${header}${i}`}
                />
              );
            })}
          </Responsive>
          {endOfScroll ? null : (
            <Button
              icon="arrow right"
              circular
              size="massive"
              className="sectionArrow"
              onClick={this.scroll()}
              style={{ ...buttonStyles, right: -40 }}
            />
          )}
        </div>
      </Responsive>
    );
  }
}

export default props => <ProductCards {...props} />;
