import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Image,
  Segment,
  Modal,
  Button,
  Header,
  Card,
  List,
  Breadcrumb,
  Menu,
  Grid
} from "semantic-ui-react";
import { Link, Route } from "react-router-dom";
import ProductCards from "../components/ProductCards";
import { injectIntl, defineMessages } from "react-intl";
import PurchasesListScreen from "./PurchasesListScreen";
import PurchaseDetailScreen from "./PurchaseDetailScreen";
import ProfileScreen from "./ProfileScreen";
import CRUDProductsScreen from "./administrative/CRUDProductsScreen";
import CRUDCategoriesScreen from "./administrative/CRUDCategoriesScreen";
import CRUDPriceListsScreen from "./administrative/CRUDPriceListsScreen";
import CRUDPromosScreen from "./administrative/CRUDPromosScreen";
import CRUDSectionsListsScreen from "./administrative/CRUDSectionsListsScreen";
import CRUDUsersScreen from "./administrative/CRUDUsersScreen";
import CRUDShippingScreen from "./administrative/CRUDShippingScreen";
import CRUDOrdersScreen from "./administrative/CRUDOrdersScreen";
const messages = defineMessages({
  header: {
    id: "app.views.account.header",
    defaultMessage: "Mi Cuenta"
  }
});

class AccountScreen extends Component {
  render() {
    const {
      intl: { formatMessage },
      initialData,
      session,
      match
    } = this.props;
    console.log(session);
    const accountSummary =
      !!session &&
      !!session.user &&
      !!session.user.client &&
      !!session.user.client.accountSummary
        ? session.user.client.accountSummary
        : null;
    //console.log("match", match);
    let menuItems = [
      <Menu.Item
        name={"Compras"}
        key="purchases"
        as={Link}
        to="/account/dashboard/purchases"
      />,
      <Menu.Item
        name={"Perfil"}
        key="profile"
        as={Link}
        to="/account/dashboard/profile"
      />
    ];

    if (!!accountSummary) {
      menuItems = [
        ...menuItems,
        <Modal
          trigger={
            <Menu.Item name={"Estado De Cuenta"} key="Estado de Cuenta" />
          }
        >
          <Modal.Header>Estado de Cuenta</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Header as={"h2"}>
                Balance: ${Number(accountSummary.balance).toFixed(2)}
              </Header>
              <Header as={"h4"}>Facturas Sin Cancelar</Header>
              <List divided verticalAlign="middle">
                {(accountSummary.transactions || []).map(
                  (aTransaction, idx) => {
                    return (
                      <List.Item key={idx}>
                        <List.Content floated="right">
                          ${Number(aTransaction.value).toFixed(2)}
                        </List.Content>
                        <List.Content floated="left">
                          {aTransaction.date}
                        </List.Content>
                        <List.Content>{aTransaction.summary}</List.Content>
                      </List.Item>
                    );
                  }
                )}
              </List>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      ];
    }

    const adminItems = [
      <Menu.Item
        name={"Usuarios"}
        key="adminUsers"
        as={Link}
        to="/account/admin/users"
      />,
      <Menu.Item
        name={"Productos"}
        key="adminProducts"
        as={Link}
        to="/account/admin/products"
      />, // <Menu.Item
      //   name={"Pedidos"}
      //   key="adminOrders"
      //   as={Link}
      //   to="/account/admin/orders"
      // />,
      <Menu.Item
        name={"Categorias"}
        key="adminCategories"
        as={Link}
        to="/account/admin/categories"
      />,
      <Menu.Item
        name={"Listas de Precios"}
        key="adminPriceLists"
        as={Link}
        to="/account/admin/pricelists"
      />,
      <Menu.Item
        name={"Secciones"}
        key="adminSections"
        as={Link}
        to="/account/admin/sections"
      />,
      <Menu.Item
        name={"Envios"}
        key="adminShipping"
        as={Link}
        to="/account/admin/shipping"
      />,
      <Menu.Item
        name={"Pedidos"}
        key="adminOrders"
        as={Link}
        to="/account/admin/orders"
      />
    ];

    const sysadminItems = [
      // <Menu.Item
      //   name={"Empresas"}
      //   key="adminCompanies"
      //   as={Link}
      //   to="/account/admin/companies"
      // />
    ];

    const routes = [
      <Route
        exact
        path="/account/dashboard/purchases"
        id="routePurchases"
        key="routePurchases"
        render={props => (
          <PurchasesListScreen
            {...props}
            initialData={initialData}
            {...props}
            {...this.props}
          />
        )}
      />,
      <Route
        exact
        id="routeDefault"
        key="routeDefault"
        path="/account/dashboard"
        render={props => (
          <PurchasesListScreen
            {...props}
            initialData={initialData}
            {...props}
            {...this.props}
          />
        )}
      />,
      <Route
        exact
        id="routeProfile"
        key="routeProfile"
        path="/account/dashboard/profile"
        render={props => <ProfileScreen {...props} {...this.props} />}
      />,
      <Route
        exact
        id="routeProductsAdmin"
        key="routeProductsAdmin"
        path="/account/admin/products"
        render={props => <CRUDProductsScreen {...props} {...this.props} />}
      />,
      <Route
        exact
        id="routeCategoriesAdmin"
        key="routeCategoriesAdmin"
        path="/account/admin/categories"
        render={props => <CRUDCategoriesScreen {...props} {...this.props} />}
      />,
      <Route
        exact
        id="routePriceListsAdmin"
        key="routePriceListsAdmin"
        path="/account/admin/pricelists"
        render={props => <CRUDPriceListsScreen {...props} {...this.props} />}
      />,
      <Route
        exact
        id="routeSectionsAdmin"
        key="routeSectionsAdmin"
        path="/account/admin/sections"
        render={props => <CRUDSectionsListsScreen {...props} {...this.props} />}
      />,
      <Route
        exact
        id="routeUsersAdmin"
        key="routeUsersAdmin"
        path="/account/admin/users"
        render={props => <CRUDUsersScreen {...props} {...this.props} />}
      />,
      <Route
        exact
        id="routeShippingAdmin"
        key="routeShippingAdmin"
        path="/account/admin/shipping"
        render={props => <CRUDShippingScreen {...props} {...this.props} />}
      />,
      <Route
        exact
        id="routeOrdersAdmin"
        key="routeOrdersAdmin"
        path="/account/admin/orders"
        render={props => <CRUDOrdersScreen {...props} {...this.props} />}
      />
    ];

    const phoneRoutes = [
      <Route
        exact
        id="purchaseDetailRoute"
        key="purchaseDetailRoutePhone"
        path="/account/dashboard/purchases/:id"
        render={props => (
          <PurchaseDetailScreen
            {...this.props}
            {...props}
            isPhone={true}
            initialData={initialData}
          />
        )}
      />,
      ...routes
    ];

    const largeScreenRoutes = [
      <Route
        exact
        id="purchaseDetailRoute"
        key="purchaseDetailRouteLarge"
        path="/account/dashboard/purchases/:id"
        render={props => (
          <PurchaseDetailScreen
            {...props}
            {...this.props}
            initialData={initialData}
          />
        )}
      />,
      ...routes
    ];

    return (
      <Container fluid>
        <Grid stackable padded>
          <Grid.Column
            width="3"
            only="computer tablet"
            style={{
              height: "calc(100vh - 109px)",
              backgroundColor: "rgb(27, 28, 29)"
            }}
          >
            <Menu inverted attached vertical fluid>
              <Menu.Item header>Secciones</Menu.Item>
              {menuItems}

              {!session.user.permissions.some(
                a => a == "sysadmin" || a == "admin"
              ) ? null : (
                <Menu.Item header>Administración</Menu.Item>
              )}
              {!session.user.permissions.some(
                a => a == "admin" || a == "sysadmin"
              )
                ? null
                : adminItems}
              {!session.user.permissions.some(a => a == "sysadmin")
                ? null
                : sysadminItems}
            </Menu>
          </Grid.Column>
          <Grid.Column width="3" only="mobile">
            <Menu vertical fluid inverted>
              <Menu.Item header>Secciones</Menu.Item>
              {menuItems}
              {!session.user.permissions.some(
                a => a == "sysadmin" || a == "admin"
              ) ? null : (
                <Menu.Item header>Administración</Menu.Item>
              )}
              {!session.user.permissions.some(
                a => a == "admin" || a == "sysadmin"
              )
                ? null
                : adminItems}
              {!session.user.permissions.some(a => a == "sysadmin")
                ? null
                : sysadminItems}
            </Menu>
          </Grid.Column>
          <Grid.Column
            width="13"
            only="computer tablet"
            style={{ height: "calc(100vh - 109px)", overflowY: "auto" }}
          >
            <Segment basic>{largeScreenRoutes}</Segment>
          </Grid.Column>
          <Grid.Column width="16" only="mobile">
            <Segment basic>{phoneRoutes}</Segment>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const CategoryBreadcrumb = () => {
  return (
    <Breadcrumb>
      <Breadcrumb.Section link as={Link} to="/">
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section link as={Link} to="/category/2">
        Category 1
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section active>A Category</Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default injectIntl(AccountScreen);
