import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import NavigationMenu from "./containers/menus/NavigationMenu";
import HomeScreen from "./containers/HomeScreen";
import ReVerifyScreen from "./containers/ReVerifyScreen";
import CategoryScreen from "./containers/CategoryScreen";
import ProductScreen from "./containers/ProductScreen";
import ShoppingCartScreen from "./containers/ShoppingCartScreen";
import AdvancedSearchScreen from "./containers/AdvancedSearchScreen";
import SessionScreen from "./containers/SessionScreen";
import AccountScreen from "./containers/AccountScreen";
import CheckoutScreen from "./containers/CheckoutScreen";
import CategoriesScreen from "./containers/CategoriesScreen";
import SearchResultsScreen from "./containers/SearchResultsScreen";
import FooterMenu from "./containers/menus/FooterMenu";
import VerifyScreen from "./containers/VerifyScreen";
import { InitialDataContext } from "./App";
import { SessionDataContext } from "./App";
const AppRouter = () => (
  <Router>
    <InitialDataContext.Consumer>
      {initialData => (
        <SessionDataContext.Consumer>
          {session => (
            <div>
              <NavigationMenu
                company={initialData.company}
                categories={initialData.categories}
                brands={initialData.brands}
                groups={initialData.groups}
                session={session}
              />
              <div>
                <Route
                  path="/"
                  exact
                  render={props => (
                    <HomeScreen
                      {...props}
                      initialData={initialData}
                      session={session}
                    />
                  )}
                />
                <Route
                  path="/payment/:response"
                  exact
                  render={props => (
                    <Redirect to="/account/dashboard/purchases" />
                  )}
                />
                <Route
                  path="/categories"
                  exact
                  render={props =>
                    initialData.company.settings.secureProducts &&
                    (!session.authenticated ||
                      !!session.loading ||
                      !session.user) ? (
                      <SessionScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : initialData.company.settings.secureProducts &&
                      !session.user.isVerified ? (
                      <ReVerifyScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : (
                      <CategoriesScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    )
                  }
                />
                <Route
                  path="/search/:id?"
                  exact
                  render={props =>
                    initialData.company.settings.secureProducts &&
                    (!session.authenticated ||
                      !!session.loading ||
                      !session.user) ? (
                      <SessionScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : initialData.company.settings.secureProducts &&
                      !session.user.isVerified ? (
                      <ReVerifyScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : (
                      <CategoryScreen
                        {...props}
                        initialData={initialData}
                        searchView
                        session={session}
                      />
                    )
                  }
                />
                <Route
                  path="/category/:id"
                  exact
                  render={props =>
                    initialData.company.settings.secureProducts &&
                    (!session.authenticated ||
                      !!session.loading ||
                      !session.user) ? (
                      <SessionScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : initialData.company.settings.secureProducts &&
                      !session.user.isVerified ? (
                      <ReVerifyScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : (
                      <CategoryScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    )
                  }
                />
                <Route
                  path="/product/:id"
                  exact
                  render={props =>
                    initialData.company.settings.secureProducts &&
                    (!session.authenticated ||
                      !!session.loading ||
                      !session.user) ? (
                      <SessionScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : initialData.company.settings.secureProducts &&
                      !session.user.isVerified ? (
                      <ReVerifyScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : (
                      <ProductScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    )
                  }
                />
                <Route
                  path="/cart/"
                  exact
                  render={props =>
                    initialData.company.settings.secureProducts &&
                    (!session.authenticated ||
                      !!session.loading ||
                      !session.user) ? (
                      <SessionScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : initialData.company.settings.secureProducts &&
                      !session.user.isVerified ? (
                      <ReVerifyScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : (
                      <ShoppingCartScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    )
                  }
                />
                <Route
                  path="/advanced/cart"
                  exact
                  render={props =>
                    initialData.company.settings.secureProducts &&
                    (!session.authenticated ||
                      !!session.loading ||
                      !session.user) ? (
                      <SessionScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : initialData.company.settings.secureProducts &&
                      !session.user.isVerified ? (
                      <ReVerifyScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : (
                      <AdvancedSearchScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    )
                  }
                />
                <Route
                  path="/sign-in/"
                  exact
                  render={props => (
                    <SessionScreen
                      {...props}
                      initialData={initialData}
                      session={session}
                    />
                  )}
                />
                <Route
                  path="/sign-up/"
                  exact
                  render={props => (
                    <SessionScreen
                      {...props}
                      initialData={initialData}
                      session={session}
                    />
                  )}
                />
                <Route
                  path="/session/verify/:token"
                  exact
                  render={props => (
                    <VerifyScreen
                      {...props}
                      initialData={initialData}
                      session={session}
                    />
                  )}
                />
                <Route
                  path="/account"
                  render={props =>
                    !session.authenticated ||
                    !!session.loading ||
                    !session.user ? (
                      <SessionScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : !session.user.isVerified ? (
                      <ReVerifyScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : (
                      <AccountScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    )
                  }
                />

                <Route
                  path="/checkout/"
                  exact
                  render={props =>
                    !session.authenticated ||
                    !!session.loading ||
                    !session.user ? (
                      <SessionScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : !session.user.isVerified ? (
                      <ReVerifyScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : (
                      <CheckoutScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    )
                  }
                />
                <Route
                  path="/checkout/confirm"
                  exact
                  render={props =>
                    !session.authenticated ||
                    !!session.loading ||
                    !session.user ? (
                      <SessionScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : !session.user.isVerified ? (
                      <ReVerifyScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    ) : (
                      <CheckoutScreen
                        {...props}
                        initialData={initialData}
                        session={session}
                      />
                    )
                  }
                />
              </div>
              <FooterMenu initialData={initialData} />
            </div>
          )}
        </SessionDataContext.Consumer>
      )}
    </InitialDataContext.Consumer>
  </Router>
);

export default AppRouter;
