import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Dropdown,
  Form,
  Accordion,
  Icon,
  Message,
  Breadcrumb,
  Menu,
  Grid,
  GridColumn,
  Button,
  Responsive,
  Loader
} from "semantic-ui-react";
import Provinces from "../data/provinces";
import { Link } from "react-router-dom";
import ProductCards from "../components/ProductCards";
import ImageGallery from "../components/ImageGallery";
import { injectIntl, defineMessages } from "react-intl";
import feathersClient from "../feathersClient";

const messages = defineMessages({
  verifyHeader: {
    id: "app.views.verify.header",
    defaultMessage: "Verificando tu cuenta"
  },
  verifyContent: {
    id: "app.views.verify.content",
    defaultMessage: "Espera un momento por favor..."
  }
});
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";
class VerifyScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null
    };
  }

  verifyAccount = async () => {
    let { token } = this.props.match.params;
    this.setState({
      loading: true,
      error: null
    });
    try {
      await feathersClient.service("/api/authmanagement").create({
        action: "verifySignupLong",
        value: token
      });
      this.setState({
        loading: false,
        error: null
      });
      if (this.props.session.authenticated) {
        this.props.session.authenticate();
      }
      this.props.history.push(`/account/dashboard`);
    } catch (error) {
      this.setState({
        error,
        loading: false
      });
    }
  };

  componentDidMount() {
    this.verifyAccount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.token != prevProps.match.params.token) {
      this.verifyAccount();
    }
  }

  render() {
    const {
      intl: { formatMessage },
      match: { path },
      initialData: {
        company: { interface: uiConfiguration }
      }
    } = this.props;

    const { loading, error } = this.state;

    return (
      <Container style={{ paddingTop: 10 }}>
        <Segment basic padded="very">
          <Header textAlign="center">
            {formatMessage(messages.verifyHeader)}
            <Header.Subheader>
              {formatMessage(messages.verifyContent)}
              <br />
            </Header.Subheader>
          </Header>
          <Segment basic padded="very">
            {!error ? null : (
              <Message
                error
                header={"Oops!"}
                content={!error ? "" : `${error.message} (${error.name})`}
              />
            )}
            <Loader active={loading} />
          </Segment>
        </Segment>
      </Container>
    );
  }
}

export default injectIntl(VerifyScreen);
