import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Menu, Input, Container, Grid, Segment } from "semantic-ui-react";
import { injectIntl, defineMessages } from "react-intl";
const messages = defineMessages({
  searchPlaceholder: {
    id: "app.navigation.search.placeholder",
    defaultMessage: "Buscar productos..."
  },
  logIn: {
    id: "app.navigation.session.log-in",
    defaultMessage: "Iniciar Sesión"
  },
  categories: {
    id: "app.navigation.categories.button",
    defaultMessage: "Categorias"
  }
});

class CategoriesMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCategory: null,
      activeFilter: null // props.categories.total > 0 ? props.categories.data[0].id : null
    };
  }

  onSelectTopCategory = categoryId => () => {
    this.setState({
      activeCategory: categoryId
    });
  };

  onSelectTopFilter = (filter, categoryId) => () => {
    this.setState({
      activeFilter: filter,
      activeCategory: categoryId
    });
  };

  render() {
    const {
      items,
      categories,
      uiConfiguration,
      onClick,
      baseId,
      filter,
      groups,
      intl: { formatMessage }
    } = this.props;
    const { activeCategory, activeFilter } = this.state;

    let categoriesData = categories.data;
    let mainCategories = categoriesData.filter(
      aCategory => aCategory.CategoryId == (baseId || null)
    );
    let passFilter = [];
    let passGroups = groups;
    const categoryInstance = !baseId
      ? null
      : categoriesData.find(b => b.id == baseId);

    let menuItems = mainCategories.map(aCategory => (
      <Menu.Item
        as={Link}
        active={activeCategory == aCategory.id}
        to={`/category/${aCategory.id}`}
        name={aCategory.name}
        key={aCategory.id}
        onClick={onClick}
        onMouseOver={this.onSelectTopCategory(aCategory.id)}
      />
    ));

    if (menuItems.length <= 0 && categoryInstance) {
      let validFilters = [
        "brand",
        "extraField1",
        "extraField2",
        "extraField3",
        "extraField4",
        "extraField5",
        "extraField6",
        "arrayField1"
      ].filter(aField => categoryInstance[aField] != null);
      if (filter && filter.length > 0) {
        validFilters = validFilters.slice(filter.length);
      }
      if (validFilters.length <= 0) {
        return null;
      }
      let currentFilter = validFilters[0];
      let options = [
        ...new Set(
          groups
            .filter(a => a.CategoryId == baseId)
            .map(a => a[currentFilter])
            .filter(a => a != null)
        )
      ];
      passGroups =
        activeFilter != null
          ? groups.filter(
              a =>
                a[currentFilter] == activeFilter &&
                a.CategoryId == categoryInstance.id
            )
          : [];
      passFilter =
        activeFilter != null ? [...(filter || []), activeFilter] : filter || [];
      console.log(passFilter);
      menuItems = options.map(aGroup => (
        <Menu.Item
          as={Link}
          active={activeFilter == aGroup}
          to={`/category/${categoryInstance.id}?filter=${JSON.stringify([
            ...filter,
            aGroup
          ])}`}
          name={aGroup}
          key={aGroup}
          onClick={onClick}
          onMouseOver={this.onSelectTopFilter(aGroup, categoryInstance.id)}
        />
      ));
      menuItems =
        menuItems.length <= 0
          ? menuItems
          : [
              <Menu.Item
                header
                name={categoryInstance[currentFilter]}
                key={currentFilter}
              />,
              ...menuItems
            ];
    }

    if (menuItems.length <= 0) {
      return null;
    }

    return (
      <div style={{ marginTop: this.props.asColumn ? 0 : -8, display: "flex" }}>
        <div
          style={{
            backgroundColor: "white",
            overflow: "auto",
            display: "flex"
          }}
        >
          <div
            style={{
              backgroundColor: this.props.asColumn ? "white" : "black",
              float: "left",
              padding: 10
            }}
          >
            <Menu vertical secondary compact inverted={!this.props.asColumn}>
              {menuItems}
            </Menu>
          </div>
          {activeCategory == null ? null : (
            <CategoriesMenu
              {...this.props}
              baseId={activeCategory}
              asColumn
              filter={passFilter}
              groups={passGroups}
            />
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(CategoriesMenu);
