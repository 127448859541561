export default [
  {
    id: 6,
    nombre: "Buenos Aires",
    ciudades: [
      { id: "429", nombre: "Abasto" },
      { id: "553", nombre: "Abbott" },
      { id: "754", nombre: "Acassuso" },
      { id: "625", nombre: "Acevedo" },
      { id: "13", nombre: "Adolfo Gonzales Chaves (Est. Chaves)" },
      { id: "25", nombre: "Adrogue" },
      { id: "250", nombre: "Aeropuerto Internacional Ezeiza" },
      { id: "397", nombre: "Aguas Verdes" },
      { id: "850", nombre: "Agustin Mosconi" },
      { id: "388", nombre: "Agustin Roca" },
      { id: "389", nombre: "Agustina" },
      { id: "465", nombre: "Alberdi Viejo" },
      { id: "17", nombre: "Alberti (Est. Andres Vaccarezza)" },
      { id: "406", nombre: "Aldo Bonzi" },
      { id: "782", nombre: "Alejandro Korn" },
      { id: "133", nombre: "Alejandro Petion" },
      { id: "580", nombre: "Alfredo Demarchi (Est. Facundo Quiroga)" },
      { id: "24", nombre: "Almirante Brown" },
      { id: "120", nombre: "Altamirano" },
      { id: "129", nombre: "Alto Los Cardales" },
      { id: "724", nombre: "Álvarez De Toledo" },
      { id: "677", nombre: "Alvarez Jonte" },
      { id: "698", nombre: "America" },
      { id: "212", nombre: "Andant" },
      { id: "430", nombre: "Angel Etcheverry" },
      { id: "487", nombre: "Antonio Carboni" },
      { id: "173", nombre: "Aparicio" },
      { id: "431", nombre: "Arana" },
      { id: "213", nombre: "Arboledas" },
      { id: "37", nombre: "Área Cinturon Ecologico" },
      { id: "520", nombre: "Area De Promocion El Triangulo" },
      { id: "481", nombre: "Arenas Verdes" },
      { id: "471", nombre: "Arenaza" },
      { id: "872", nombre: "Argerich" },
      { id: "50", nombre: "Ariel" },
      { id: "72", nombre: "Arrecifes" },
      { id: "274", nombre: "Arribeños" },
      { id: "717", nombre: "Arroyo Corto" },
      { id: "241", nombre: "Arroyo De La Cruz" },
      { id: "730", nombre: "Arroyo Dulce" },
      { id: "372", nombre: "Arroyo Venado" },
      { id: "432", nombre: "Arturo Segui" },
      { id: "275", nombre: "Ascension" },
      { id: "511", nombre: "Atalaya" },
      { id: "535", nombre: "Atlantida" },
      { id: "38", nombre: "Avellaneda" },
      { id: "46", nombre: "Ayacucho" },
      { id: "738", nombre: "Azcuenaga" },
      { id: "666", nombre: "Azopardo" },
      { id: "51", nombre: "Azul" },
      { id: "55", nombre: "Bahia Blanca" },
      { id: "606", nombre: "Bahia San Blas" },
      { id: "355", nombre: "Baigorrita" },
      { id: "168", nombre: "Bajo Hondo" },
      { id: "62", nombre: "Balcarce" },
      { id: "390", nombre: "Balneario Laguna De Gomez" },
      { id: "174", nombre: "Balneario Marisol" },
      { id: "820", nombre: "Balneario Orense" },
      { id: "169", nombre: "Balneario Pehuen Co" },
      { id: "748", nombre: "Balneario San Cayetano" },
      { id: "556", nombre: "Balneario Sauce Grande" },
      { id: "360", nombre: "Banderalo" },
      { id: "492", nombre: "Banfield" },
      { id: "68", nombre: "Baradero" },
      { id: "75", nombre: "Barker" },
      { id: "664", nombre: "Barrio America Unida" },
      { id: "90", nombre: "Barrio Banco Provincia" },
      { id: "137", nombre: "Barrio Belgrano" },
      { id: "310", nombre: "Barrio Colinas Verdes" },
      { id: "311", nombre: "Barrio El Boqueron" },
      { id: "91", nombre: "Barrio El Carmen (Este)" },
      { id: "433", nombre: "Barrio El Carmen (Oeste)" },
      { id: "312", nombre: "Barrio El Casal" },
      { id: "313", nombre: "Barrio El Coyunco" },
      { id: "134", nombre: "Barrio El Taladro" },
      { id: "434", nombre: "Barrio Gambier" },
      { id: "285", nombre: "Barrio Kennedy" },
      { id: "314", nombre: "Barrio La Gloria" },
      { id: "499", nombre: "Barrio Las Casuarinas" },
      { id: "121", nombre: "Barrio Las Golondrinas" },
      { id: "435", nombre: "Barrio Las Malvinas" },
      { id: "436", nombre: "Barrio Las Quintas" },
      { id: "122", nombre: "Barrio Los Bosquecitos" },
      { id: "130", nombre: "Barrio Los Pioneros" },
      {
        id: "641",
        nombre: "Barrio Parque Almirante Irizar (Ap. Kilometro 61)"
      },
      { id: "328", nombre: "Barrio Parque General San Martin" },
      { id: "123", nombre: "Barrio Parque Las Acacias" },
      { id: "301", nombre: "Barrio Rio Salado" },
      { id: "325", nombre: "Barrio Ruta 24 Kilometro 10" },
      { id: "881", nombre: "Barrio Saavedra" },
      { id: "315", nombre: "Barrio Santa Paula" },
      { id: "542", nombre: "Barrio Santa Rosa" },
      { id: "92", nombre: "Barrio Universitario" },
      { id: "543", nombre: "Barrios Lisandro De La Torre Y Santa Marta" },
      { id: "316", nombre: "Batan" },
      { id: "472", nombre: "Bayauca" },
      { id: "755", nombre: "Beccar" },
      { id: "226", nombre: "Belen De Escobar" },
      { id: "760", nombre: "Bella Vista" },
      { id: "144", nombre: "Bellocq" },
      { id: "794", nombre: "Benavidez" },
      { id: "76", nombre: "Benito Juarez (Est. Juarez)" },
      { id: "80", nombre: "Berazategui" },
      { id: "81", nombre: "Berazategui Oeste" },
      { id: "731", nombre: "Berdier" },
      { id: "93", nombre: "Berisso" },
      { id: "473", nombre: "Bermudez" },
      { id: "682", nombre: "Bernal" },
      { id: "683", nombre: "Bernal Oeste" },
      { id: "814", nombre: "Berutti" },
      { id: "329", nombre: "Billinghurst" },
      { id: "592", nombre: "Blancagrande" },
      { id: "266", nombre: "Blaquier" },
      { id: "725", nombre: "Blaquier" },
      { id: "622", nombre: "Bocayuva" },
      { id: "667", nombre: "Bordenave" },
      { id: "256", nombre: "Bosques" },
      { id: "756", nombre: "Boulogne Sur Mer" },
      { id: "111", nombre: "Bragado" },
      { id: "26", nombre: "Burzaco" },
      { id: "60", nombre: "Cabildo" },
      { id: "52", nombre: "Cachari" },
      { id: "145", nombre: "Cadret" },
      { id: "320", nombre: "Camet" },
      { id: "536", nombre: "Camet Norte" },
      { id: "131", nombre: "Campana" },
      { id: "761", nombre: "Campo De Mayo" },
      { id: "772", nombre: "Campos Salles" },
      { id: "251", nombre: "Canning" },
      { id: "235", nombre: "Canning" },
      { id: "361", nombre: "Cañada Seca" },
      { id: "135", nombre: "Cañuelas" },
      { id: "242", nombre: "Capilla Del Señor (Est. Capilla)" },
      { id: "613", nombre: "Capitan Castro" },
      { id: "142", nombre: "Capitan Sarmiento" },
      { id: "860", nombre: "Carapachay" },
      { id: "607", nombre: "Cardenal Cagliero" },
      { id: "2", nombre: "Carhue" },
      { id: "659", nombre: "Carilo" },
      { id: "715", nombre: "Carlos Beguerie" },
      { id: "146", nombre: "Carlos Casares" },
      { id: "500", nombre: "Carlos Keen" },
      { id: "581", nombre: "Carlos Maria Naon" },
      { id: "474", nombre: "Carlos Salas" },
      { id: "252", nombre: "Carlos Spegazzini" },
      { id: "154", nombre: "Carlos Tejedor" },
      { id: "82", nombre: "Carlos Tomas Sourigues" },
      { id: "159", nombre: "Carmen De Areco" },
      { id: "608", nombre: "Carmen De Patagones" },
      { id: "639", nombre: "Casalins" },
      { id: "373", nombre: "Casbas" },
      { id: "186", nombre: "Cascadas" },
      { id: "833", nombre: "Caseros" },
      { id: "564", nombre: "Castelar" },
      { id: "162", nombre: "Castelli" },
      { id: "196", nombre: "Castilla" },
      { id: "726", nombre: "Cazon" },
      { id: "163", nombre: "Centro Guerrero" },
      { id: "197", nombre: "Chacabuco" },
      { id: "298", nombre: "Chacras De San Clemente" },
      { id: "317", nombre: "Chapadmalal" },
      { id: "201", nombre: "Chascomus" },
      { id: "202", nombre: "Chascomus Country Club" },
      { id: "806", nombre: "Chasico" },
      { id: "614", nombre: "Chiclana" },
      { id: "53", nombre: "Chillar" },
      { id: "205", nombre: "Chivilcoy" },
      { id: "834", nombre: "Churruca" },
      { id: "437", nombre: "City Bell" },
      { id: "407", nombre: "Ciudad Evita" },
      { id: "331", nombre: "Ciudad Jardin El Libertador" },
      { id: "835", nombre: "Ciudad Jardin Lomas Del Palomar" },
      { id: "836", nombre: "Ciudadela" },
      { id: "573", nombre: "Claraz" },
      { id: "821", nombre: "Claromeco" },
      { id: "27", nombre: "Claypole" },
      { id: "642", nombre: "Club De Campo Larena - Los Quinchos" },
      { id: "164", nombre: "Colon" },
      { id: "593", nombre: "Colonia Hinojo" },
      { id: "147", nombre: "Colonia Mauricio" },
      { id: "594", nombre: "Colonia Nievas" },
      { id: "873", nombre: "Colonia San Adolfo" },
      { id: "718", nombre: "Colonia San Martin" },
      { id: "595", nombre: "Colonia San Miguel" },
      { id: "3", nombre: "Colonia San Miguel Arcangel" },
      { id: "305", nombre: "Colonia San Ricardo (Est. Iriarte)" },
      { id: "155", nombre: "Colonia Sere" },
      { id: "269", nombre: "Comandante Nicanor Otamendi" },
      { id: "112", nombre: "Comodoro Py" },
      { id: "764", nombre: "Conesa" },
      { id: "823", nombre: "Copetonas" },
      { id: "461", nombre: "Coronel Boerr" },
      { id: "124", nombre: "Coronel Brandsen" },
      { id: "362", nombre: "Coronel Charlone" },
      { id: "175", nombre: "Coronel Dorrego" },
      { id: "475", nombre: "Coronel Martinez De Hoz (Ap. Kilometro 322)" },
      { id: "181", nombre: "Coronel Pringles (Est. Pringles)" },
      { id: "18", nombre: "Coronel Segui" },
      { id: "187", nombre: "Coronel Suarez" },
      { id: "528", nombre: "Coronel Vidal" },
      { id: "501", nombre: "Cortines" },
      { id: "574", nombre: "Costa Bonita" },
      { id: "326", nombre: "Country Club Bosque Real - Barrio Morabo" },
      { id: "882", nombre: "Country Club El Casco" },
      { id: "643", nombre: "Country Club El JagÜEL" },
      { id: "427", nombre: "Country Club El Rodeo" },
      { id: "508", nombre: "Country Club Las Praderas" },
      { id: "877", nombre: "Country Los Medanos" },
      { id: "791", nombre: "Crotto" },
      { id: "39", nombre: "Crucesita" },
      { id: "558", nombre: "Cuartel V" },
      { id: "739", nombre: "Cucullu" },
      { id: "188", nombre: "Cura Malal" },
      { id: "156", nombre: "Curaru" },
      { id: "214", nombre: "Daireaux" },
      { id: "668", nombre: "Darregueira" },
      { id: "623", nombre: "De Bary" },
      { id: "786", nombre: "De La Canal" },
      { id: "14", nombre: "De La Garma" },
      { id: "727", nombre: "Del Carril" },
      { id: "851", nombre: "Del Valle" },
      { id: "644", nombre: "Del Viso" },
      { id: "385", nombre: "Del Viso" },
      { id: "4", nombre: "Delfin Huergo" },
      { id: "787", nombre: "Desvio Aguirre" },
      { id: "243", nombre: "Diego Gaynor" },
      { id: "795", nombre: "Dique Lujan" },
      { id: "220", nombre: "Dique N 1" },
      { id: "40", nombre: "Dock Sud" },
      { id: "218", nombre: "Dolores" },
      { id: "781", nombre: "Domselaar" },
      { id: "684", nombre: "Don Bosco" },
      { id: "28", nombre: "Don Orione" },
      { id: "796", nombre: "Don Torcuato Este" },
      { id: "797", nombre: "Don Torcuato Oeste" },
      { id: "189", nombre: "D'Orbigny" },
      { id: "583", nombre: "Dudignac" },
      { id: "719", nombre: "Dufaur" },
      { id: "745", nombre: "Duggan" },
      { id: "822", nombre: "Dunamar" },
      { id: "165", nombre: "El Arbolito" },
      { id: "227", nombre: "El Cazador" },
      { id: "182", nombre: "El Divisorio" },
      { id: "466", nombre: "El Dorado" },
      { id: "236", nombre: "El JagÜEL" },
      { id: "837", nombre: "El Libertador" },
      { id: "318", nombre: "El Marquesado" },
      { id: "565", nombre: "El Palomar" },
      { id: "691", nombre: "El Paraiso" },
      { id: "83", nombre: "El Pato" },
      { id: "183", nombre: "El Pensamiento" },
      { id: "176", nombre: "El Perdido (Est. Jose A. Guisasola)" },
      { id: "247", nombre: "El Remanso" },
      { id: "438", nombre: "El Retiro" },
      { id: "798", nombre: "El Talar" },
      { id: "462", nombre: "El Trigo" },
      { id: "476", nombre: "El Triunfo" },
      { id: "488", nombre: "Elvira" },
      { id: "206", nombre: "Emilio Ayarza" },
      { id: "363", nombre: "Emilio V. Bunge" },
      { id: "221", nombre: "Ensenada" },
      { id: "765", nombre: "Erezcano" },
      { id: "852", nombre: "Ernestina" },
      { id: "883", nombre: "Escalada" },
      { id: "225", nombre: "Escobar" },
      { id: "5", nombre: "Espartillar" },
      { id: "720", nombre: "Espartillar" },
      { id: "596", nombre: "Espigas" },
      { id: "276", nombre: "Estacion Arenales" },
      { id: "321", nombre: "Estacion Camet" },
      { id: "319", nombre: "Estacion Chapadmalal" },
      { id: "257", nombre: "Estanislao Severo Zeballos" },
      { id: "6", nombre: "Esteban Agustin Gascon" },
      { id: "234", nombre: "Esteban Echeverria" },
      { id: "670", nombre: "Estela" },
      { id: "685", nombre: "Ezpeleta" },
      { id: "686", nombre: "Ezpeleta Oeste" },
      { id: "177", nombre: "Faro" },
      { id: "645", nombre: "Fatima" },
      { id: "671", nombre: "Felipe Sola" },
      { id: "277", nombre: "Ferre" },
      { id: "258", nombre: "Florencio Varela" },
      { id: "267", nombre: "Florentino Ameghino" },
      { id: "861", nombre: "Florida" },
      { id: "862", nombre: "Florida Oeste" },
      { id: "626", nombre: "Fontezuela" },
      { id: "467", nombre: "Fortin Acha" },
      { id: "699", nombre: "Fortin Olavarria" },
      { id: "391", nombre: "Fortin Tiburcio" },
      { id: "559", nombre: "Francisco Alvarez" },
      { id: "615", nombre: "Francisco Madero" },
      { id: "740", nombre: "Franklin" },
      { id: "537", nombre: "Frente Mar" },
      { id: "732", nombre: "Gahan" },
      { id: "788", nombre: "Gardey" },
      { id: "228", nombre: "Garin" },
      { id: "374", nombre: "Garre" },
      { id: "273", nombre: "General Alvear" },
      { id: "278", nombre: "General Arenales" },
      { id: "281", nombre: "General Belgrano" },
      { id: "804", nombre: "General Conesa" },
      { id: "61", nombre: "General Daniel Cerri (Est. General Cerri)" },
      { id: "283", nombre: "General Guido" },
      { id: "292", nombre: "General Hornos" },
      { id: "286", nombre: "General Juan Madariaga" },
      { id: "287", nombre: "General La Madrid" },
      { id: "293", nombre: "General Las Heras (Est. Las Heras)" },
      { id: "299", nombre: "General Lavalle" },
      { id: "512", nombre: "General Mansilla (Est. Bartolome Bavio)" },
      { id: "113", nombre: "General O'Brien" },
      { id: "799", nombre: "General Pacheco" },
      { id: "306", nombre: "General Pinto" },
      { id: "529", nombre: "General Piran" },
      { id: "784", nombre: "General Rivas" },
      { id: "327", nombre: "General Rodriguez" },
      { id: "766", nombre: "General Rojo" },
      { id: "330", nombre: "General San Martin" },
      { id: "364", nombre: "General Villegas (Est. Villegas)" },
      { id: "41", nombre: "Gerli" },
      { id: "421", nombre: "Gerli" },
      { id: "307", nombre: "Germania (Est. Mayor Jose Orellano)" },
      { id: "815", nombre: "Girodias" },
      { id: "29", nombre: "Glew" },
      { id: "775", nombre: "Gobernador Castro" },
      { id: "259", nombre: "Gobernador Julio A. Costa" },
      { id: "136", nombre: "Gobernador Udaondo" },
      { id: "853", nombre: "Gobernador Ugarte" },
      { id: "125", nombre: "Gomez" },
      { id: "408", nombre: "Gonzalez Catan" },
      { id: "700", nombre: "Gonzalez Moreno" },
      { id: "282", nombre: "Gorchs" },
      { id: "207", nombre: "Gorostiaga" },
      { id: "545", nombre: "Gowland" },
      { id: "721", nombre: "Goyena" },
      { id: "521", nombre: "Grand Bourg" },
      { id: "409", nombre: "Gregorio De Laferrere" },
      { id: "56", nombre: "GrÜNBEIN" },
      { id: "375", nombre: "Guamini" },
      { id: "665", nombre: "Guernica" },
      { id: "627", nombre: "Guerrico" },
      { id: "84", nombre: "Guillermo Enrique Hudson" },
      { id: "566", nombre: "Haedo" },
      { id: "103", nombre: "Hale" },
      { id: "377", nombre: "Henderson" },
      { id: "378", nombre: "Herrera Vegas" },
      { id: "874", nombre: "Hilario Ascasubi" },
      { id: "597", nombre: "Hinojo" },
      { id: "148", nombre: "Hortensia" },
      { id: "190", nombre: "Huanguelen" },
      { id: "379", nombre: "Hurlingham" },
      { id: "428", nombre: "Ignacio Correas" },
      { id: "184", nombre: "Indio Rico" },
      { id: "733", nombre: "Ines Indart" },
      { id: "522", nombre: "Ingeniero Adolfo Sourdeaux" },
      { id: "260", nombre: "Ingeniero Juan Allan" },
      { id: "229", nombre: "Ingeniero Maschwitz" },
      { id: "523", nombre: "Ingeniero Pablo Nogues" },
      { id: "848", nombre: "Ingeniero Thompson" },
      { id: "57", nombre: "Ingeniero White" },
      { id: "114", nombre: "Irala" },
      { id: "178", nombre: "Irene" },
      { id: "69", nombre: "Irineo Portela" },
      { id: "410", nombre: "Isidro Casanova" },
      { id: "222", nombre: "Isla Santiago (Oeste)" },
      { id: "382", nombre: "Ituzaingo Centro" },
      { id: "383", nombre: "Ituzaingo Sur" },
      { id: "126", nombre: "Jeppener" },
      { id: "439", nombre: "Joaquin Gorina" },
      { id: "609", nombre: "Jose B. Casas" },
      { id: "386", nombre: "Jose C. Paz" },
      { id: "440", nombre: "Jose Hernandez" },
      { id: "838", nombre: "Jose Ingenieros" },
      { id: "569", nombre: "Jose Juan Almeyra" },
      { id: "253", nombre: "Jose Maria Ezeiza" },
      { id: "502", nombre: "Jose Maria Jauregui (Est. Jauregui)" },
      { id: "30", nombre: "Jose Marmol" },
      { id: "441", nombre: "Jose Melchor Romero" },
      { id: "628", nombre: "Juan A. De La Peña" },
      { id: "610", nombre: "Juan A. Pradere" },
      { id: "629", nombre: "Juan Anchorena (Est. Urquiza)" },
      { id: "468", nombre: "Juan Bautista Alberdi (Est. Alberdi)" },
      { id: "875", nombre: "Juan Couste (Est. Algarrobo)" },
      { id: "15", nombre: "Juan E. Barra" },
      { id: "104", nombre: "Juan F. Ibarra" },
      { id: "616", nombre: "Juan Jose Paso" },
      { id: "85", nombre: "Juan Maria Gutierrez" },
      { id: "575", nombre: "Juan N. Fernandez" },
      { id: "392", nombre: "Junin" },
      { id: "279", nombre: "La Angelita" },
      { id: "530", nombre: "La Armonia" },
      { id: "584", nombre: "La Aurora (Est. La Niña)" },
      { id: "532", nombre: "La Baliza" },
      { id: "706", nombre: "La Beba" },
      { id: "533", nombre: "La Caleta" },
      { id: "265", nombre: "La Capilla" },
      { id: "816", nombre: "La Carreta" },
      { id: "294", nombre: "La Choza" },
      { id: "288", nombre: "La Colina" },
      { id: "47", nombre: "La Constancia" },
      { id: "442", nombre: "La Cumbre" },
      { id: "356", nombre: "La Delfina" },
      { id: "767", nombre: "La Emilia" },
      { id: "734", nombre: "La Invencible" },
      { id: "215", nombre: "La Larga" },
      { id: "115", nombre: "La Limpia" },
      { id: "646", nombre: "La Lonja" },
      { id: "863", nombre: "La Lucila" },
      { id: "143", nombre: "La Luisa" },
      { id: "216", nombre: "La Manuela" },
      { id: "405", nombre: "La Matanza" },
      { id: "7", nombre: "La Pala" },
      { id: "443", nombre: "La Plata" },
      { id: "560", nombre: "La Reja" },
      { id: "208", nombre: "La Rica" },
      { id: "149", nombre: "La Sofia" },
      { id: "411", nombre: "La Tablada" },
      { id: "280", nombre: "La Trinidad" },
      { id: "254", nombre: "La Union" },
      { id: "630", nombre: "La Violeta" },
      { id: "284", nombre: "Labarden" },
      { id: "376", nombre: "Laguna Alsina (Est. Bonifacio)" },
      { id: "489", nombre: "Laguna De Lobos" },
      { id: "422", nombre: "Lanus Este" },
      { id: "423", nombre: "Lanus Oeste" },
      { id: "393", nombre: "Laplacette" },
      { id: "458", nombre: "Laprida" },
      { id: "185", nombre: "Lartigau" },
      { id: "517", nombre: "Las Armas" },
      { id: "692", nombre: "Las Bahamas" },
      { id: "707", nombre: "Las Carabelas" },
      { id: "463", nombre: "Las Flores" },
      { id: "570", nombre: "Las Marianas" },
      { id: "289", nombre: "Las Martinetas" },
      { id: "678", nombre: "Las Tahonas" },
      { id: "396", nombre: "Las Toninas" },
      { id: "477", nombre: "Las Toscas" },
      { id: "469", nombre: "Leandro N. Alem" },
      { id: "505", nombre: "Lezica Y Torrezuri" },
      { id: "290", nombre: "Libano" },
      { id: "548", nombre: "Libertad" },
      { id: "482", nombre: "Licenciado Matienzo" },
      { id: "884", nombre: "Lima" },
      { id: "824", nombre: "Lin Calel" },
      { id: "478", nombre: "Lincoln" },
      { id: "444", nombre: "Lisandro Olmos" },
      { id: "493", nombre: "Llavallol" },
      { id: "483", nombre: "Loberia" },
      { id: "490", nombre: "Lobos" },
      { id: "839", nombre: "Loma Hermosa" },
      { id: "302", nombre: "Loma Verde" },
      { id: "230", nombre: "Loma Verde" },
      { id: "456", nombre: "Lomas De Copello" },
      { id: "494", nombre: "Lomas De Zamora" },
      { id: "412", nombre: "Lomas Del Mirador" },
      { id: "132", nombre: "Lomas Del Rio Lujan (Est. Rio Lujan)" },
      { id: "31", nombre: "Longchamps" },
      { id: "77", nombre: "Lopez" },
      { id: "672", nombre: "Lopez Lecube" },
      { id: "198", nombre: "Los Angeles" },
      { id: "647", nombre: "Los Cachorros" },
      { id: "244", nombre: "Los Cardales" },
      { id: "445", nombre: "Los Hornos" },
      { id: "708", nombre: "Los Indios" },
      { id: "513", nombre: "Los Naranjos" },
      { id: "63", nombre: "Los Pinos" },
      { id: "524", nombre: "Los Polvorines" },
      { id: "94", nombre: "Los Talas" },
      { id: "357", nombre: "Los Toldos" },
      { id: "800", nombre: "Los Troncos Del Talar" },
      { id: "295", nombre: "Lozano" },
      { id: "854", nombre: "Lucas Monteverde" },
      { id: "398", nombre: "Lucila Del Mar" },
      { id: "237", nombre: "Luis Guillon" },
      { id: "506", nombre: "Lujan" },
      { id: "617", nombre: "Magdala" },
      { id: "514", nombre: "Magdalena" },
      { id: "518", nombre: "Maipu" },
      { id: "32", nombre: "Malvinas Argentinas" },
      { id: "525", nombre: "Malvinas Argentinas" },
      { id: "446", nombre: "Manuel B. Gonnet" },
      { id: "585", nombre: "Manuel B. Gonnet (Est. French)" },
      { id: "203", nombre: "Manuel J. Cobo (Est. Lezama)" },
      { id: "631", nombre: "Manuel Ocampo" },
      { id: "648", nombre: "Manzanares" },
      { id: "649", nombre: "Manzone" },
      { id: "232", nombre: "Maquinista F. Savio Este" },
      { id: "650", nombre: "Maquinista F. Savio Oeste" },
      { id: "869", nombre: "Mar Azul" },
      { id: "531", nombre: "Mar Chiquita" },
      { id: "399", nombre: "Mar De Ajo" },
      { id: "400", nombre: "Mar De Ajo Norte" },
      { id: "534", nombre: "Mar De Cobo" },
      { id: "870", nombre: "Mar De Las Pampas" },
      { id: "322", nombre: "Mar Del Plata" },
      { id: "270", nombre: "Mar Del Sur" },
      { id: "403", nombre: "Mar Del Tuyu" },
      { id: "586", nombre: "Marcelino Ugarte (Est. Dennehy)" },
      { id: "544", nombre: "Marcos Paz" },
      { id: "789", nombre: "Maria Ignacia (Est. Vela)" },
      { id: "549", nombre: "Mariano Acosta" },
      { id: "632", nombre: "Mariano Benitez" },
      { id: "633", nombre: "Mariano H. Alfonzo (Est. San Patricio)" },
      { id: "105", nombre: "Mariano Unzue" },
      { id: "840", nombre: "Martin Coronado" },
      { id: "757", nombre: "Martinez" },
      { id: "365", nombre: "Massey (Est. Elordi)" },
      { id: "231", nombre: "Matheu" },
      { id: "150", nombre: "Mauricio Hirsch" },
      { id: "116", nombre: "Maximo Fernandez (Est. Juan F. Salaberry)" },
      { id: "138", nombre: "Maximo Paz" },
      { id: "876", nombre: "Mayor Buratovich" },
      { id: "8", nombre: "Maza" },
      { id: "19", nombre: "Mechita" },
      { id: "117", nombre: "Mechita (Est. Mecha)" },
      { id: "271", nombre: "Mechongue" },
      { id: "878", nombre: "Medanos" },
      { id: "546", nombre: "Mercedes" },
      { id: "550", nombre: "Merlo" },
      { id: "825", nombre: "Micaela Cascallares (Est. Cascallares)" },
      { id: "33", nombre: "Ministro Rivadavia" },
      { id: "701", nombre: "Mira Pampa" },
      { id: "272", nombre: "Miramar" },
      { id: "151", nombre: "Moctezuma" },
      { id: "618", nombre: "Mones Cazon" },
      { id: "424", nombre: "Monte Chingolo" },
      { id: "238", nombre: "Monte Grande" },
      { id: "557", nombre: "Monte Hermoso" },
      { id: "209", nombre: "Moquehua" },
      { id: "587", nombre: "Morea" },
      { id: "561", nombre: "Moreno" },
      { id: "567", nombre: "Moron" },
      { id: "394", nombre: "Morse" },
      { id: "864", nombre: "Munro" },
      { id: "762", nombre: "Muñiz" },
      { id: "64", nombre: "Napaleofu" },
      { id: "571", nombre: "Navarro" },
      { id: "576", nombre: "Necochea" },
      { id: "578", nombre: "Nicanor Olivera (Est. La Dulce)" },
      { id: "855", nombre: "Norberto De La Riestra" },
      { id: "588", nombre: "Norumbega" },
      { id: "619", nombre: "Nueva Plata" },
      { id: "776", nombre: "Obligado" },
      { id: "749", nombre: "Ochandio" },
      { id: "199", nombre: "O'Higgins" },
      { id: "118", nombre: "Olascoaga" },
      { id: "598", nombre: "Olavarria" },
      { id: "127", nombre: "Oliden" },
      { id: "507", nombre: "Olivera" },
      { id: "865", nombre: "Olivos" },
      { id: "509", nombre: "Open Door" },
      { id: "152", nombre: "Ordoqui" },
      { id: "826", nombre: "Orense" },
      { id: "179", nombre: "Oriente" },
      { id: "660", nombre: "Ostende" },
      { id: "842", nombre: "Pablo Podesta" },
      { id: "245", nombre: "Parada Orlando" },
      { id: "248", nombre: "Parada Robles" },
      { id: "246", nombre: "Parada Robles - Pavon" },
      { id: "464", nombre: "Pardo" },
      { id: "191", nombre: "Pasman" },
      { id: "562", nombre: "Paso Del Rey" },
      { id: "479", nombre: "Pasteur" },
      { id: "590", nombre: "Patricios" },
      { id: "106", nombre: "Paula" },
      { id: "249", nombre: "Pavon" },
      { id: "300", nombre: "Pavon" },
      { id: "166", nombre: "Pearson" },
      { id: "856", nombre: "Pedernales" },
      { id: "879", nombre: "Pedro Luro" },
      { id: "620", nombre: "Pehuajo" },
      { id: "624", nombre: "Pellegrini" },
      { id: "86", nombre: "Pereyra" },
      { id: "693", nombre: "Perez Millan" },
      { id: "634", nombre: "Pergamino" },
      { id: "366", nombre: "Pichincha" },
      { id: "367", nombre: "Piedritas" },
      { id: "484", nombre: "Pieres" },
      { id: "722", nombre: "PigÜE" },
      { id: "640", nombre: "Pila" },
      { id: "651", nombre: "Pilar" },
      { id: "661", nombre: "Pinamar" },
      { id: "635", nombre: "Pinzon" },
      { id: "42", nombre: "Piñeyro" },
      { id: "679", nombre: "Pipinas" },
      { id: "107", nombre: "Pirovano" },
      { id: "20", nombre: "Pla" },
      { id: "87", nombre: "Platanos" },
      { id: "538", nombre: "Playa Dorada" },
      { id: "296", nombre: "Plomer" },
      { id: "728", nombre: "Polvaredas" },
      { id: "291", nombre: "Pontaut" },
      { id: "551", nombre: "Pontevedra" },
      { id: "268", nombre: "Porvenir" },
      { id: "652", nombre: "Presidente Derqui" },
      { id: "663", nombre: "Presidente Peron" },
      { id: "673", nombre: "Puan" },
      { id: "777", nombre: "Pueblo Doyle" },
      { id: "160", nombre: "Pueblo Gouin" },
      { id: "459", nombre: "Pueblo Nuevo" },
      { id: "460", nombre: "Pueblo San Jorge" },
      { id: "233", nombre: "Puerto Parana" },
      { id: "170", nombre: "Punta Alta (Est. Almirante Solier)" },
      { id: "680", nombre: "Punta Indio" },
      { id: "223", nombre: "Punta Lara" },
      { id: "323", nombre: "Punta Mogotes" },
      { id: "736", nombre: "Quenuma" },
      { id: "577", nombre: "Quequen" },
      { id: "687", nombre: "Quilmes" },
      { id: "688", nombre: "Quilmes Oeste" },
      { id: "34", nombre: "Rafael Calzada" },
      { id: "413", nombre: "Rafael Castillo" },
      { id: "709", nombre: "Rafael Obligado" },
      { id: "694", nombre: "Ramallo" },
      { id: "210", nombre: "Ramon Biaus" },
      { id: "579", nombre: "Ramon Santamarina" },
      { id: "414", nombre: "Ramos Mejia" },
      { id: "65", nombre: "Ramos Otero" },
      { id: "636", nombre: "Rancagua" },
      { id: "303", nombre: "Ranchos" },
      { id: "88", nombre: "Ranelagh" },
      { id: "697", nombre: "Rauch" },
      { id: "200", nombre: "Rawson" },
      { id: "599", nombre: "Recalde" },
      { id: "843", nombre: "Remedios De Escalada" },
      { id: "425", nombre: "Remedios Escalada De San Martin" },
      { id: "827", nombre: "Reta" },
      { id: "801", nombre: "Ricardo Rojas" },
      { id: "802", nombre: "Rincon De Milberg" },
      { id: "447", nombre: "Ringuelet" },
      { id: "778", nombre: "Rio Tala" },
      { id: "9", nombre: "Rivera" },
      { id: "710", nombre: "Roberto Cano" },
      { id: "653", nombre: "Roberto De Vicenzo" },
      { id: "515", nombre: "Roberto J. Payro" },
      { id: "480", nombre: "Roberts" },
      { id: "711", nombre: "Rojas" },
      { id: "702", nombre: "Roosevelt" },
      { id: "716", nombre: "Roque Perez" },
      { id: "448", nombre: "Rufino De Elizalde" },
      { id: "457", nombre: "Ruta Sol" },
      { id: "723", nombre: "Saavedra" },
      { id: "844", nombre: "Saenz Peña" },
      { id: "395", nombre: "Saforcada" },
      { id: "729", nombre: "Saladillo" },
      { id: "217", nombre: "Salazar" },
      { id: "807", nombre: "Saldungaray" },
      { id: "737", nombre: "Salliquelo" },
      { id: "735", nombre: "Salto" },
      { id: "491", nombre: "Salvador Maria" },
      { id: "128", nombre: "Samborombon" },
      { id: "66", nombre: "San Agustin" },
      { id: "741", nombre: "San Andres De Giles" },
      { id: "746", nombre: "San Antonio De Areco" },
      { id: "552", nombre: "San Antonio De Padua" },
      { id: "401", nombre: "San Bernardo" },
      { id: "621", nombre: "San Bernardo (Est. Guanaco)" },
      { id: "108", nombre: "San Carlos De Bolivar (Est. Bolivar)" },
      { id: "750", nombre: "San Cayetano" },
      { id: "402", nombre: "San Clemente Del Tuyu" },
      { id: "358", nombre: "San Emilio" },
      { id: "857", nombre: "San Enrique" },
      { id: "751", nombre: "San Fernando" },
      { id: "828", nombre: "San Francisco De Bellocq" },
      { id: "35", nombre: "San Francisco Solano" },
      { id: "689", nombre: "San Francisco Solano" },
      { id: "674", nombre: "San German" },
      { id: "758", nombre: "San Isidro" },
      { id: "192", nombre: "San Jose" },
      { id: "36", nombre: "San Jose" },
      { id: "415", nombre: "San Justo" },
      { id: "485", nombre: "San Manuel" },
      { id: "703", nombre: "San Mauricio" },
      { id: "829", nombre: "San Mayol" },
      { id: "763", nombre: "San Miguel" },
      { id: "554", nombre: "San Miguel Del Monte (Est. Monte)" },
      { id: "773", nombre: "San Nicolas De Los Arroyos" },
      { id: "779", nombre: "San Pedro" },
      { id: "180", nombre: "San Roman" },
      { id: "211", nombre: "San Sebastian" },
      { id: "783", nombre: "San Vicente" },
      { id: "704", nombre: "Sansinena" },
      { id: "539", nombre: "Santa Clara Del Mar" },
      { id: "70", nombre: "Santa Coloma" },
      { id: "540", nombre: "Santa Elena" },
      { id: "368", nombre: "Santa Eleodora" },
      { id: "780", nombre: "Santa Lucia" },
      { id: "600", nombre: "Santa Luisa" },
      { id: "193", nombre: "Santa Maria" },
      { id: "369", nombre: "Santa Regina" },
      { id: "139", nombre: "Santa Rosa" },
      { id: "654", nombre: "Santa Teresa" },
      { id: "404", nombre: "Santa Teresita" },
      { id: "194", nombre: "Santa Trinidad" },
      { id: "519", nombre: "Santo Domingo" },
      { id: "845", nombre: "Santos Lugares" },
      { id: "43", nombre: "Sarandi" },
      { id: "167", nombre: "Sarasa" },
      { id: "219", nombre: "Sevigne" },
      { id: "601", nombre: "Sierra Chica" },
      { id: "808", nombre: "Sierra De La Ventana" },
      { id: "324", nombre: "Sierra De Los Padres" },
      { id: "602", nombre: "Sierras Bayas" },
      { id: "153", nombre: "Smith" },
      { id: "712", nombre: "Sol De Mayo" },
      { id: "48", nombre: "Solanet" },
      { id: "742", nombre: "Solis" },
      { id: "611", nombre: "Stroeder" },
      { id: "785", nombre: "Suipacha" },
      { id: "705", nombre: "Sundblad" },
      { id: "486", nombre: "Tamangueyu" },
      { id: "790", nombre: "Tandil" },
      { id: "792", nombre: "Tapalque" },
      { id: "416", nombre: "Tapiales" },
      { id: "78", nombre: "Tedin Uriburu" },
      { id: "495", nombre: "Temperley" },
      { id: "880", nombre: "Teniente Origone" },
      { id: "10", nombre: "Thames" },
      { id: "803", nombre: "Tigre" },
      { id: "157", nombre: "Timote" },
      { id: "73", nombre: "Todd" },
      { id: "449", nombre: "Tolosa" },
      { id: "547", nombre: "Tomas Jofre" },
      { id: "809", nombre: "Tornquist" },
      { id: "510", nombre: "Torres" },
      { id: "526", nombre: "Tortuguitas" },
      { id: "655", nombre: "Tortuguitas" },
      { id: "387", nombre: "Tortuguitas" },
      { id: "450", nombre: "Transradio" },
      { id: "818", nombre: "Trenque Lauquen" },
      { id: "158", nombre: "Tres Algarrobos (Est. Cuenca)" },
      { id: "830", nombre: "Tres Arroyos" },
      { id: "832", nombre: "Tres De Febrero" },
      { id: "849", nombre: "Tres Lomas" },
      { id: "810", nombre: "Tres Picos" },
      { id: "161", nombre: "Tres Sargentos" },
      { id: "255", nombre: "Tristan Suarez" },
      { id: "819", nombre: "Tronge" },
      { id: "563", nombre: "Trujui" },
      { id: "496", nombre: "Turdera" },
      { id: "49", nombre: "Udaquiola" },
      { id: "109", nombre: "Urdampilleta" },
      { id: "140", nombre: "Uribelarrea" },
      { id: "858", nombre: "Valdes" },
      { id: "426", nombre: "Valentin Alsina" },
      { id: "662", nombre: "Valeria Del Mar" },
      { id: "16", nombre: "Vasquez" },
      { id: "470", nombre: "Vedia" },
      { id: "793", nombre: "Velloso" },
      { id: "681", nombre: "Veronica" },
      { id: "141", nombre: "Vicente Casares" },
      { id: "866", nombre: "Vicente Lopez" },
      { id: "752", nombre: "Victoria" },
      { id: "516", nombre: "Vieytes" },
      { id: "418", nombre: "Villa  Eduardo Madero" },
      { id: "867", nombre: "Villa Adelina" },
      { id: "759", nombre: "Villa Adelina" },
      { id: "604", nombre: "Villa Alfredo Fortabat" },
      { id: "71", nombre: "Villa Alsina (Est. Alsina)" },
      { id: "637", nombre: "Villa Angelica (Est. El Socorro)" },
      { id: "95", nombre: "Villa ArgÜELLO" },
      { id: "603", nombre: "Villa Arrieta" },
      { id: "656", nombre: "Villa Astolfi" },
      { id: "332", nombre: "Villa Ayacucho" },
      { id: "333", nombre: "Villa Ballester" },
      { id: "334", nombre: "Villa Bernardo Monteagudo" },
      { id: "58", nombre: "Villa Bordeau" },
      { id: "846", nombre: "Villa Bosch (Est. Juan Maria Bosch)" },
      { id: "261", nombre: "Villa Brown" },
      { id: "79", nombre: "Villa Cacique (Est. Alfredo Fortabat)" },
      { id: "768", nombre: "Villa Campi" },
      { id: "769", nombre: "Villa Canto" },
      { id: "675", nombre: "Villa Castelar (Est. Erize)" },
      { id: "224", nombre: "Villa Catela" },
      { id: "497", nombre: "Villa Centenario" },
      { id: "335", nombre: "Villa Chacabuco" },
      { id: "336", nombre: "Villa Coronel Jose M. Zapiola" },
      { id: "527", nombre: "Villa De Mayo" },
      { id: "171", nombre: "Villa Del Mar" },
      { id: "96", nombre: "Villa Dolores" },
      { id: "44", nombre: "Villa Dominico" },
      { id: "451", nombre: "Villa Elisa" },
      { id: "452", nombre: "Villa Elvira" },
      { id: "89", nombre: "Villa España" },
      { id: "774", nombre: "Villa Esperanza" },
      { id: "743", nombre: "Villa Espil" },
      { id: "59", nombre: "Villa Espora" },
      { id: "498", nombre: "Villa Fiorito" },
      { id: "503", nombre: "Villa Flandria Norte" },
      { id: "504", nombre: "Villa Flandria Sur" },
      { id: "308", nombre: "Villa Francia (Est. Coronel Granada)" },
      { id: "453", nombre: "Villa Garibaldi" },
      { id: "337", nombre: "Villa General Antonio J. De Sucre" },
      { id: "172", nombre: "Villa General Arias (Est. Kilometro 638)" },
      { id: "338", nombre: "Villa General Eugenio Necochea" },
      { id: "591", nombre: "Villa General Fournier (Est. 9 De Julio Sud)" },
      { id: "339", nombre: "Villa General Jose Tomas Guido" },
      { id: "340", nombre: "Villa General Juan G. Las Heras" },
      { id: "695", nombre: "Villa General Savio (Est. Sanchez)" },
      { id: "871", nombre: "Villa Gesell" },
      { id: "384", nombre: "Villa Gobernador Udaondo" },
      { id: "341", nombre: "Villa Godoy Cruz" },
      { id: "342", nombre: "Villa Granaderos De San Martin" },
      { id: "343", nombre: "Villa Gregoria Matorras" },
      { id: "21", nombre: "Villa Grisolia (Est. Achupallas)" },
      { id: "771", nombre: "Villa Hermosa" },
      { id: "97", nombre: "Villa Independencia" },
      { id: "676", nombre: "Villa Iris" },
      { id: "344", nombre: "Villa Jose Leon Suarez" },
      { id: "345", nombre: "Villa Juan Martin De Pueyrredon" },
      { id: "195", nombre: "Villa La Arcadia" },
      { id: "690", nombre: "Villa La Florida" },
      { id: "605", nombre: "Villa La Serrania" },
      { id: "67", nombre: "Villa Laguna La Brava" },
      { id: "811", nombre: "Villa Las Encadenadas" },
      { id: "747", nombre: "Villa Lia" },
      { id: "346", nombre: "Villa Libertad" },
      { id: "419", nombre: "Villa Luzuriaga" },
      { id: "347", nombre: "Villa Lynch" },
      { id: "110", nombre: "Villa Lynch Pueyrredon" },
      { id: "348", nombre: "Villa Maipu" },
      { id: "713", nombre: "Villa Manuel Pomar" },
      { id: "11", nombre: "Villa Margarita" },
      { id: "22", nombre: "Villa Maria" },
      { id: "349", nombre: "Villa Maria Irene De Los Remedios De Escalada" },
      { id: "350", nombre: "Villa Marques Alejandro Maria De Aguado" },
      { id: "868", nombre: "Villa Martelli" },
      { id: "572", nombre: "Villa Moll (Est. Moll)" },
      { id: "454", nombre: "Villa Montoro" },
      { id: "98", nombre: "Villa Nueva" },
      { id: "23", nombre: "Villa Ortiz (Est. Coronel Mom)" },
      { id: "714", nombre: "Villa Parque Cecir" },
      { id: "204", nombre: "Villa Parque Girado" },
      { id: "351", nombre: "Villa Parque Presidente Figueroa Alcorta" },
      { id: "352", nombre: "Villa Parque San Lorenzo" },
      { id: "455", nombre: "Villa Parque Sicardi" },
      { id: "99", nombre: "Villa Porteña" },
      { id: "100", nombre: "Villa Progreso" },
      { id: "847", nombre: "Villa Raffo" },
      { id: "696", nombre: "Villa Ramallo" },
      { id: "770", nombre: "Villa Riccio" },
      { id: "805", nombre: "Villa Roch" },
      { id: "831", nombre: "Villa Rodriguez (Est. Barrow)" },
      { id: "657", nombre: "Villa Rosa" },
      { id: "309", nombre: "Villa Roth (Est. Ingeniero Balbin)" },
      { id: "744", nombre: "Villa Ruiz" },
      { id: "370", nombre: "Villa Saboya" },
      { id: "353", nombre: "Villa San Andres" },
      { id: "101", nombre: "Villa San Carlos" },
      { id: "638", nombre: "Villa San Jose" },
      { id: "262", nombre: "Villa San Luis" },
      { id: "263", nombre: "Villa Santa Rosa" },
      { id: "380", nombre: "Villa Santos Tesei" },
      { id: "568", nombre: "Villa Sarmiento" },
      { id: "371", nombre: "Villa Sauze" },
      { id: "812", nombre: "Villa Serrana La Gruta" },
      { id: "264", nombre: "Villa Vatteone" },
      { id: "813", nombre: "Villa Ventana" },
      { id: "354", nombre: "Villa Yapeyu" },
      { id: "102", nombre: "Villa Zula" },
      { id: "612", nombre: "Villalonga" },
      { id: "304", nombre: "Villanueva  (Ap. Rio Salado)" },
      { id: "297", nombre: "Villars" },
      { id: "74", nombre: "Viña" },
      { id: "420", nombre: "Virrey Del Pino" },
      { id: "753", nombre: "Virreyes" },
      { id: "541", nombre: "Vivorata" },
      { id: "119", nombre: "Warnes" },
      { id: "45", nombre: "Wilde" },
      { id: "381", nombre: "William C. Morris" },
      { id: "12", nombre: "Yutuyaco" },
      { id: "885", nombre: "Zarate" },
      { id: "359", nombre: "Zavalia" },
      { id: "658", nombre: "Zelaya" },
      { id: "555", nombre: "Zenon Videla Dorna" },
      { id: "240", nombre: "Zona Aeropuerto Internacional Ezeiza" },
      { id: "841", nombre: "11 De Septiembre" },
      { id: "582", nombre: "12 De Octubre" },
      { id: "54", nombre: "16 De Julio" },
      { id: "669", nombre: "17 De Agosto" },
      { id: "417", nombre: "20 De Junio" },
      { id: "859", nombre: "25 De Mayo" },
      { id: "817", nombre: "30 De Agosto" },
      { id: "239", nombre: "9 De Abril" },
      { id: "589", nombre: "9 De Julio" }
    ]
  },
  {
    id: 10,
    nombre: "Catamarca",
    ciudades: [
      { id: "909", nombre: "Aconquija" },
      { id: "934", nombre: "Adolfo E. Carranza" },
      { id: "1030", nombre: "Alijilan" },
      { id: "910", nombre: "Alto De Las Juntas" },
      { id: "983", nombre: "Amadores" },
      { id: "902", nombre: "Amanao" },
      { id: "898", nombre: "Ancasti" },
      { id: "903", nombre: "Andalgala" },
      { id: "1005", nombre: "Andalhuala" },
      { id: "1037", nombre: "Anillaco" },
      { id: "899", nombre: "Anquincila" },
      { id: "1038", nombre: "Antinaco" },
      { id: "915", nombre: "Antofagasta De La Sierra" },
      { id: "916", nombre: "Antofalla" },
      { id: "994", nombre: "Apoyaco" },
      { id: "935", nombre: "Balde De La Punta" },
      { id: "1039", nombre: "Banda De Lucero" },
      { id: "1031", nombre: "Bañado De Ovanta" },
      { id: "919", nombre: "Barranca Larga" },
      { id: "949", nombre: "Barrio Bancario" },
      { id: "920", nombre: "Belen" },
      { id: "907", nombre: "Buena Vista" },
      { id: "936", nombre: "Capayan" },
      { id: "968", nombre: "Casa De Piedra" },
      { id: "1023", nombre: "Casa De Piedra" },
      { id: "1006", nombre: "Caspichango" },
      { id: "1040", nombre: "Cerro Negro" },
      { id: "1007", nombre: "Chañar Punco" },
      { id: "904", nombre: "Chaquiago" },
      { id: "905", nombre: "Choya" },
      { id: "886", nombre: "Chuchucaruana" },
      { id: "937", nombre: "Chumbicha" },
      { id: "995", nombre: "Colana" },
      { id: "958", nombre: "Collagasta" },
      { id: "938", nombre: "Colonia Del Valle" },
      { id: "939", nombre: "Colonia Nueva Coneta" },
      { id: "996", nombre: "Colpes" },
      { id: "887", nombre: "Colpes" },
      { id: "940", nombre: "Concepcion" },
      { id: "921", nombre: "Condor Huasi" },
      { id: "941", nombre: "Coneta" },
      { id: "1041", nombre: "Copacabana" },
      { id: "1043", nombre: "Cordobita" },
      { id: "922", nombre: "Corral Quemado" },
      { id: "1044", nombre: "Costa De Reyes" },
      { id: "906", nombre: "El Alamito" },
      { id: "908", nombre: "El Alamito" },
      { id: "952", nombre: "El Alto" },
      { id: "969", nombre: "El Aybal" },
      { id: "1061", nombre: "El Bañado" },
      { id: "970", nombre: "El Bañado" },
      { id: "942", nombre: "El Bañado" },
      { id: "888", nombre: "El Bolson" },
      { id: "1010", nombre: "El Cajon" },
      { id: "1018", nombre: "El Cerrito" },
      { id: "1011", nombre: "El Desmonte" },
      { id: "971", nombre: "El Divisadero" },
      { id: "923", nombre: "El Durazno" },
      { id: "961", nombre: "El Hueco" },
      { id: "911", nombre: "El Lindero" },
      { id: "997", nombre: "El Pajonal (Est. Poman)" },
      { id: "950", nombre: "El Pantanillo" },
      { id: "917", nombre: "El Peñon" },
      { id: "1058", nombre: "El Portezuelo" },
      { id: "913", nombre: "El Potrero" },
      { id: "1045", nombre: "El Pueblito" },
      { id: "1012", nombre: "El Puesto" },
      { id: "1046", nombre: "El Puesto" },
      { id: "972", nombre: "El Quimilo" },
      { id: "889", nombre: "El Rodeo" },
      { id: "984", nombre: "El Rosario" },
      { id: "1047", nombre: "El Salado" },
      { id: "973", nombre: "Esquiu" },
      { id: "1013", nombre: "Famatanca" },
      { id: "924", nombre: "Farallon Negro" },
      { id: "1048", nombre: "Fiambala" },
      { id: "1015", nombre: "Fuerte Quemado" },
      { id: "953", nombre: "Guayamba" },
      { id: "925", nombre: "Hualfin" },
      { id: "1059", nombre: "Huaycama" },
      { id: "890", nombre: "Huaycama" },
      { id: "943", nombre: "Huillapima" },
      { id: "974", nombre: "Icaño" },
      { id: "954", nombre: "Infanzon" },
      { id: "926", nombre: "Jacipunco" },
      { id: "998", nombre: "Joyango" },
      { id: "914", nombre: "La Aguada" },
      { id: "985", nombre: "La Bajada" },
      { id: "900", nombre: "La Candelaria" },
      { id: "962", nombre: "La Carrera" },
      { id: "975", nombre: "La Dorada" },
      { id: "963", nombre: "La Falda De San Antonio" },
      { id: "976", nombre: "La Guardia" },
      { id: "986", nombre: "La Higuera" },
      { id: "1016", nombre: "La Hoyada" },
      { id: "1017", nombre: "La Loma" },
      { id: "901", nombre: "La Majada" },
      { id: "987", nombre: "La Merced" },
      { id: "912", nombre: "La Mesada" },
      { id: "891", nombre: "La Puerta" },
      { id: "927", nombre: "La Puntilla" },
      { id: "1024", nombre: "La Puntilla" },
      { id: "1042", nombre: "La Puntilla" },
      { id: "1049", nombre: "La Ramadita" },
      { id: "964", nombre: "La Tercena" },
      { id: "988", nombre: "La Viña" },
      { id: "1008", nombre: "Lampacito" },
      { id: "1032", nombre: "Las Cañas" },
      { id: "892", nombre: "Las Chacritas" },
      { id: "977", nombre: "Las Esquinas" },
      { id: "893", nombre: "Las Juntas" },
      { id: "928", nombre: "Las Juntas" },
      { id: "989", nombre: "Las Lajas" },
      { id: "1019", nombre: "Las Mojarras" },
      { id: "978", nombre: "Las Palmitas" },
      { id: "1060", nombre: "Las Tejas" },
      { id: "1033", nombre: "Lavalle" },
      { id: "929", nombre: "Londres" },
      { id: "1020", nombre: "Loro Huasi" },
      { id: "1034", nombre: "Los Altos" },
      { id: "944", nombre: "Los Angeles" },
      { id: "1051", nombre: "Los Balverdis" },
      { id: "894", nombre: "Los Castillos" },
      { id: "955", nombre: "Los Corrales" },
      { id: "918", nombre: "Los Nacimientos" },
      { id: "930", nombre: "Los Nacimientos" },
      { id: "895", nombre: "Los Talas" },
      { id: "896", nombre: "Los Varela" },
      { id: "1035", nombre: "Manantiales" },
      { id: "1052", nombre: "Medanitos" },
      { id: "1009", nombre: "Medanitos" },
      { id: "945", nombre: "Miraflores" },
      { id: "990", nombre: "Monte Potrero" },
      { id: "999", nombre: "Mutquin" },
      { id: "1053", nombre: "Palo Blanco" },
      { id: "991", nombre: "Palo Labrado" },
      { id: "1025", nombre: "Palo Seco" },
      { id: "1050", nombre: "Pampa Blanca" },
      { id: "1062", nombre: "Polcos" },
      { id: "1000", nombre: "Poman" },
      { id: "959", nombre: "Pomancillo Este" },
      { id: "960", nombre: "Pomancillo Oeste" },
      { id: "1063", nombre: "Pozo Del Mistol" },
      { id: "931", nombre: "Puerta De Corral Quemado" },
      { id: "932", nombre: "Puerta De San Jose" },
      { id: "1021", nombre: "Punta De Balasto" },
      { id: "1054", nombre: "Punta Del Agua" },
      { id: "979", nombre: "Quiros" },
      { id: "980", nombre: "Ramblones" },
      { id: "981", nombre: "Recreo" },
      { id: "1001", nombre: "Rincon" },
      { id: "951", nombre: "S. F. Del V. De Catamarca" },
      { id: "965", nombre: "San Antonio" },
      { id: "992", nombre: "San Antonio" },
      { id: "982", nombre: "San Antonio" },
      { id: "1064", nombre: "San Isidro" },
      { id: "966", nombre: "San Jose" },
      { id: "1022", nombre: "San Jose" },
      { id: "1014", nombre: "San Jose Banda" },
      { id: "1026", nombre: "San Jose Norte" },
      { id: "1027", nombre: "San Jose Villa" },
      { id: "946", nombre: "San Martin" },
      { id: "1002", nombre: "San Miguel" },
      { id: "947", nombre: "San Pablo" },
      { id: "1036", nombre: "San Pedro" },
      { id: "948", nombre: "San Pedro" },
      { id: "1068", nombre: "Santa Cruz" },
      { id: "1028", nombre: "Santa Maria" },
      { id: "1065", nombre: "Santa Rosa" },
      { id: "1003", nombre: "Saujil" },
      { id: "1055", nombre: "Saujil" },
      { id: "1004", nombre: "Sijan" },
      { id: "897", nombre: "Singuil" },
      { id: "1066", nombre: "Sumalao" },
      { id: "956", nombre: "Tapso" },
      { id: "1056", nombre: "Taton" },
      { id: "1057", nombre: "Tinogasta" },
      { id: "957", nombre: "Vilisman" },
      { id: "993", nombre: "Villa De Balcozna" },
      { id: "1067", nombre: "Villa Dolores" },
      { id: "967", nombre: "Villa Las Pirquitas" },
      { id: "933", nombre: "Villa Vil" },
      { id: "1029", nombre: "Yapes" }
    ]
  },
  {
    id: 22,
    nombre: "Chaco",
    ciudades: [
      { id: "1728", nombre: "Avia Terai" },
      { id: "1758", nombre: "Barranqueras" },
      { id: "1753", nombre: "Barrio De Los Pescadores" },
      { id: "1759", nombre: "Basail" },
      { id: "1729", nombre: "Campo Largo" },
      { id: "1766", nombre: "Capitan Solari" },
      { id: "1771", nombre: "Charadai" },
      { id: "1700", nombre: "Charata" },
      { id: "1708", nombre: "Chorotis" },
      { id: "1736", nombre: "Ciervo Petiso" },
      { id: "1776", nombre: "Colonia Aborigen" },
      { id: "1760", nombre: "Colonia Baranda" },
      { id: "1754", nombre: "Colonia Benitez" },
      { id: "1767", nombre: "Colonia Elisa" },
      { id: "1732", nombre: "Colonia Popular" },
      { id: "1768", nombre: "Colonias Unidas" },
      { id: "1687", nombre: "Concepcion Del Bermejo" },
      { id: "1745", nombre: "Coronel Du Graty" },
      { id: "1711", nombre: "Corzuela" },
      { id: "1772", nombre: "Cote Lai" },
      { id: "1716", nombre: "El Espinillo" },
      { id: "1717", nombre: "El Sauzal" },
      { id: "1718", nombre: "El Sauzalito" },
      { id: "1746", nombre: "Enrique Urien" },
      { id: "1733", nombre: "Estacion Estacion General Obligado" },
      { id: "1761", nombre: "Fontana" },
      { id: "1730", nombre: "Fortin Las Chuñas" },
      { id: "1719", nombre: "Fortin Lavalle" },
      { id: "1720", nombre: "Fuerte Esperanza" },
      { id: "1701", nombre: "Gancedo" },
      { id: "1702", nombre: "General Capdevila" },
      { id: "1737", nombre: "General Jose De San Martin" },
      { id: "1703", nombre: "General Pinedo" },
      { id: "1692", nombre: "General Vedia" },
      { id: "1773", nombre: "Haumonia" },
      { id: "1706", nombre: "Hermoso Campo" },
      { id: "1774", nombre: "Horquilla" },
      { id: "1769", nombre: "Ingeniero Barbet" },
      { id: "1693", nombre: "Isla Del Cerrito" },
      { id: "1707", nombre: "Itin" },
      { id: "1721", nombre: "Juan Jose Castelli" },
      { id: "1749", nombre: "La Clotilde" },
      { id: "1738", nombre: "La Eduvigis" },
      { id: "1712", nombre: "La Escondida" },
      { id: "1694", nombre: "La Leonesa" },
      { id: "1775", nombre: "La Sabana" },
      { id: "1750", nombre: "La Tigra" },
      { id: "1713", nombre: "La Verde" },
      { id: "1734", nombre: "Laguna Blanca" },
      { id: "1739", nombre: "Laguna Limpia" },
      { id: "1714", nombre: "Lapachito" },
      { id: "1748", nombre: "Las Breñas" },
      { id: "1770", nombre: "Las Garcitas" },
      { id: "1695", nombre: "Las Palmas" },
      { id: "1688", nombre: "Los Frentones" },
      { id: "1777", nombre: "Machagai" },
      { id: "1715", nombre: "Makalle" },
      { id: "1755", nombre: "Margarita Belen" },
      { id: "1704", nombre: "Meson De Fierro" },
      { id: "1722", nombre: "Miraflores" },
      { id: "1778", nombre: "Napalpi" },
      { id: "1731", nombre: "Napenay" },
      { id: "1723", nombre: "Nueva Pompeya" },
      { id: "1740", nombre: "Pampa Almiron" },
      { id: "1741", nombre: "Pampa Del Indio" },
      { id: "1689", nombre: "Pampa Del Infierno" },
      { id: "1705", nombre: "Pampa Landriel" },
      { id: "1752", nombre: "Presidencia De La Plaza" },
      { id: "1742", nombre: "Presidencia Roca" },
      { id: "1699", nombre: "Presidencia Roque Saenz Peña" },
      { id: "1696", nombre: "Puerto Bermejo Nuevo" },
      { id: "1697", nombre: "Puerto Bermejo Viejo" },
      { id: "1698", nombre: "Puerto Eva Peron" },
      { id: "1724", nombre: "Puerto Lavalle" },
      { id: "1735", nombre: "Puerto Tirol" },
      { id: "1762", nombre: "Puerto Vilelas" },
      { id: "1756", nombre: "Quitilipi" },
      { id: "1763", nombre: "Resistencia" },
      { id: "1690", nombre: "Rio Muerto" },
      { id: "1764", nombre: "Samuhu" },
      { id: "1751", nombre: "San Bernardo" },
      { id: "1709", nombre: "Santa Sylvina" },
      { id: "1743", nombre: "Selvas Del Rio De Oro" },
      { id: "1691", nombre: "Taco Pozo" },
      { id: "1744", nombre: "Tres Isletas" },
      { id: "1710", nombre: "Venados Grandes" },
      { id: "1747", nombre: "Villa Angela" },
      { id: "1765", nombre: "Villa Berthet" },
      { id: "1757", nombre: "Villa El Palmar" },
      { id: "1725", nombre: "Villa Rio Bermejito" },
      { id: "1726", nombre: "Wichi" },
      { id: "1727", nombre: "Zaparinqui" }
    ]
  },
  {
    id: 26,
    nombre: "Chubut",
    ciudades: [
      { id: "1791", nombre: "Acceso Norte" },
      { id: "1848", nombre: "Aldea Apeleg" },
      { id: "1849", nombre: "Aldea Beleiro" },
      { id: "1833", nombre: "Aldea Epulef" },
      { id: "1817", nombre: "Aldea Escolar" },
      { id: "1850", nombre: "Alto Rio Senguer" },
      { id: "1863", nombre: "Arroyo Verde" },
      { id: "1789", nombre: "Astra" },
      { id: "1790", nombre: "Bahia Bustamante" },
      { id: "1792", nombre: "Barrio Caleta Cordova" },
      { id: "1793", nombre: "Barrio Caleta Olivares" },
      { id: "1794", nombre: "Barrio Castelli" },
      { id: "1795", nombre: "Barrio Ciudadela" },
      { id: "1796", nombre: "Barrio Gasoducto" },
      { id: "1797", nombre: "Barrio GÜEMES" },
      { id: "1798", nombre: "Barrio Laprida" },
      { id: "1799", nombre: "Barrio Manantial Rosales" },
      { id: "1800", nombre: "Barrio Militar - Aeropuerto Militar" },
      { id: "1801", nombre: "Barrio Prospero Palazzo" },
      { id: "1802", nombre: "Barrio Restinga Ali" },
      { id: "1803", nombre: "Barrio Rodriguez Peña" },
      { id: "1804", nombre: "Barrio Saavedra" },
      { id: "1805", nombre: "Barrio Sarmiento" },
      { id: "1807", nombre: "Barrio Villa S.U.P.E." },
      { id: "1806", nombre: "Barrio 25 De Mayo" },
      { id: "1828", nombre: "Blancuntre" },
      { id: "1855", nombre: "Buen Pasto" },
      { id: "1779", nombre: "Buenos Aires Chico" },
      { id: "1815", nombre: "Camarones" },
      { id: "1834", nombre: "Carrenleufu" },
      { id: "1840", nombre: "Cerro Condor" },
      { id: "1780", nombre: "Cholila" },
      { id: "1835", nombre: "Colan Conhue" },
      { id: "1808", nombre: "Comodoro Rivadavia" },
      { id: "1818", nombre: "Corcovado" },
      { id: "1781", nombre: "Cushamen Centro" },
      { id: "1813", nombre: "Diadema Argentina" },
      { id: "1824", nombre: "Dique Florentino Ameghino" },
      { id: "1851", nombre: "Doctor Ricardo Rojas" },
      { id: "1825", nombre: "Dolavon" },
      { id: "1829", nombre: "El Escorial" },
      { id: "1782", nombre: "El Hoyo" },
      { id: "1783", nombre: "El Maiten" },
      { id: "1838", nombre: "El Mirasol" },
      { id: "1784", nombre: "Epuyen" },
      { id: "1819", nombre: "Esquel" },
      { id: "1852", nombre: "Facundo" },
      { id: "1857", nombre: "Frontera De Rio Pico" },
      { id: "1826", nombre: "Gaiman" },
      { id: "1861", nombre: "Gan Gan" },
      { id: "1816", nombre: "Garayalde" },
      { id: "1830", nombre: "Gastre" },
      { id: "1858", nombre: "Gobernador Costa" },
      { id: "1785", nombre: "Gualjaina" },
      { id: "1859", nombre: "Jose De San Martin" },
      { id: "1811", nombre: "Km. 11 - Cuarteles" },
      { id: "1812", nombre: "Km. 3 - General Mosconi" },
      { id: "1809", nombre: "Km. 5 - Presidente Ortiz" },
      { id: "1810", nombre: "Km. 8 - Don Bosco" },
      { id: "1853", nombre: "Lago Blanco" },
      { id: "1786", nombre: "Lago Epuyen" },
      { id: "1787", nombre: "Lago Puelo" },
      { id: "1820", nombre: "Lago Rosario" },
      { id: "1831", nombre: "Lagunita Salada" },
      { id: "1839", nombre: "Las Plumas" },
      { id: "1788", nombre: "Leleque" },
      { id: "1841", nombre: "Los Altares" },
      { id: "1821", nombre: "Los Cipreses" },
      { id: "1842", nombre: "Paso De Indios" },
      { id: "1836", nombre: "Paso Del Sapo" },
      { id: "1843", nombre: "Playa Magagna" },
      { id: "1844", nombre: "Playa Union" },
      { id: "1864", nombre: "Puerto Madryn" },
      { id: "1865", nombre: "Puerto Piramide" },
      { id: "1845", nombre: "Puerto Rawson" },
      { id: "1866", nombre: "Quinta El Mirador" },
      { id: "1814", nombre: "Rada Tilly" },
      { id: "1846", nombre: "Rawson" },
      { id: "1854", nombre: "Rio Mayo" },
      { id: "1860", nombre: "Rio Pico" },
      { id: "1856", nombre: "Sarmiento" },
      { id: "1837", nombre: "Tecka" },
      { id: "1862", nombre: "Telsen" },
      { id: "1847", nombre: "Trelew" },
      { id: "1822", nombre: "Trevelin" },
      { id: "1823", nombre: "Villa Futalaufquen" },
      { id: "1832", nombre: "Yala Laubat" },
      { id: "1827", nombre: "28 De Julio" }
    ]
  },
  {
    id: 2,
    nombre: "Ciudad Autonoma De Bs As",
    ciudades: [
      { id: "3961", nombre: "Agronomia" },
      { id: "3962", nombre: "Almagro" },
      { id: "3963", nombre: "Balvanera" },
      { id: "3964", nombre: "Barracas" },
      { id: "3965", nombre: "Belgrano" },
      { id: "3966", nombre: "Boedo" },
      { id: "3967", nombre: "Caballito" },
      { id: "3968", nombre: "Chacarita" },
      { id: "3969", nombre: "Coghlan" },
      { id: "3970", nombre: "Colegiales" },
      { id: "3971", nombre: "Constitucion" },
      { id: "3972", nombre: "Flores" },
      { id: "3973", nombre: "Floresta" },
      { id: "3974", nombre: "La Boca" },
      { id: "3975", nombre: "Liniers" },
      { id: "3976", nombre: "Mataderos" },
      { id: "3977", nombre: "Monserrat" },
      { id: "3978", nombre: "Monte Castro" },
      { id: "3979", nombre: "Nueva Pompeya" },
      { id: "3980", nombre: "Nuñez" },
      { id: "3981", nombre: "Palermo" },
      { id: "3982", nombre: "Parque Avellaneda" },
      { id: "3983", nombre: "Parque Chacabuco" },
      { id: "3984", nombre: "Parque Chas" },
      { id: "3985", nombre: "Parque Patricios" },
      { id: "3986", nombre: "Paternal" },
      { id: "3987", nombre: "Puerto Madero" },
      { id: "3988", nombre: "Recoleta" },
      { id: "1", nombre: "Retiro" },
      { id: "3989", nombre: "Saavedra" },
      { id: "3990", nombre: "San Cristobal" },
      { id: "3991", nombre: "San Nicolas" },
      { id: "3992", nombre: "San Telmo" },
      { id: "3994", nombre: "Velez Sarsfield" },
      { id: "3993", nombre: "Versalles" },
      { id: "3996", nombre: "Villa Crespo" },
      { id: "3997", nombre: "Villa Del Parque" },
      { id: "3998", nombre: "Villa Devoto" },
      { id: "3995", nombre: "Villa Gral Mitre" },
      { id: "3999", nombre: "Villa Lugano" },
      { id: "4000", nombre: "Villa Luro" },
      { id: "4001", nombre: "Villa Ortuzar" },
      { id: "4002", nombre: "Villa Pueyrredon" },
      { id: "4003", nombre: "Villa Real" },
      { id: "4004", nombre: "Villa Riachuelo" },
      { id: "4005", nombre: "Villa Santa Rita" },
      { id: "4006", nombre: "Villa Soldati" },
      { id: "4007", nombre: "Villa Urquiza" }
    ]
  },
  {
    id: 14,
    nombre: "Cordoba",
    ciudades: [
      { id: "1310", nombre: "Achiras" },
      { id: "1311", nombre: "Adelia Maria" },
      { id: "1109", nombre: "Agua De Oro" },
      { id: "1312", nombre: "Alcira (Est. Gigena)" },
      { id: "1584", nombre: "Aldea Santa Maria" },
      { id: "1212", nombre: "Alejandro Roca (Est. Alejandro)" },
      { id: "1229", nombre: "Alejo Ledesma" },
      { id: "1452", nombre: "Alicia" },
      { id: "1544", nombre: "Almafuerte" },
      { id: "1313", nombre: "Alpa Corral" },
      { id: "1496", nombre: "Alta Gracia" },
      { id: "1585", nombre: "Alto Alegre" },
      { id: "1146", nombre: "Alto De Los Quebrachos" },
      { id: "1445", nombre: "Alto Resbaloso - El Barrial" },
      { id: "1453", nombre: "Altos De Chipion" },
      { id: "1069", nombre: "Amboy" },
      { id: "1403", nombre: "Ambul" },
      { id: "1586", nombre: "Ana Zumaran" },
      { id: "1497", nombre: "Anisacate" },
      { id: "1230", nombre: "Arias" },
      { id: "1454", nombre: "Arroyito" },
      { id: "1180", nombre: "Arroyo Algodon" },
      { id: "1181", nombre: "Arroyo Cabral" },
      { id: "1404", nombre: "Arroyo Los Patos" },
      { id: "1070", nombre: "Arroyo San Antonio" },
      { id: "1110", nombre: "Ascochinga" },
      { id: "1213", nombre: "Assunta" },
      { id: "1342", nombre: "Atahona" },
      { id: "1182", nombre: "Ausonia" },
      { id: "1199", nombre: "Avellaneda" },
      { id: "1587", nombre: "Ballesteros" },
      { id: "1588", nombre: "Ballesteros Sur" },
      { id: "1455", nombre: "Balnearia" },
      { id: "1147", nombre: "Bañado De Soto" },
      { id: "1498", nombre: "Barrio Gilbert" },
      { id: "1514", nombre: "Barrio Las Quintas" },
      { id: "1278", nombre: "Barrio Santa Isabel" },
      { id: "1499", nombre: "Barrio Villa Del Parque" },
      { id: "1589", nombre: "Bell Ville" },
      { id: "1214", nombre: "Bengolea" },
      { id: "1590", nombre: "Benjamin Gould" },
      { id: "1314", nombre: "Berrotaran" },
      { id: "1279", nombre: "Bialet Masse" },
      { id: "1500", nombre: "Bouwer" },
      { id: "1456", nombre: "Brinkmann" },
      { id: "1315", nombre: "Bulnes" },
      { id: "1280", nombre: "Cabalango" },
      { id: "1382", nombre: "Calchin" },
      { id: "1383", nombre: "Calchin Oeste" },
      { id: "1231", nombre: "Camilo Aldao" },
      { id: "1539", nombre: "Caminiaga" },
      { id: "1591", nombre: "Canals" },
      { id: "1561", nombre: "Candelaria Sur" },
      { id: "1111", nombre: "Canteras El Sauce" },
      { id: "1148", nombre: "Canteras Quilpo" },
      { id: "1562", nombre: "Cañada De Luque" },
      { id: "1343", nombre: "Cañada De Machado" },
      { id: "1200", nombre: "Cañada De Rio Pinto" },
      { id: "1071", nombre: "Cañada Del Sauce" },
      { id: "1344", nombre: "Capilla De Los Remedios" },
      { id: "1563", nombre: "Capilla De Siton" },
      { id: "1384", nombre: "Capilla Del Carmen" },
      { id: "1281", nombre: "Capilla Del Monte" },
      { id: "1072", nombre: "Capilla Vieja" },
      { id: "1232", nombre: "Capitan General Bernardo O Higgins" },
      { id: "1215", nombre: "Carnerillo" },
      { id: "1385", nombre: "Carrilobo" },
      { id: "1112", nombre: "Casa Bamba" },
      { id: "1282", nombre: "Casa Grande" },
      { id: "1501", nombre: "Caseros Centro" },
      { id: "1233", nombre: "Cavanagh" },
      { id: "1573", nombre: "Cerro Colorado" },
      { id: "1369", nombre: "Cerro Colorado" },
      { id: "1540", nombre: "Cerro Colorado" },
      { id: "1316", nombre: "Chajan" },
      { id: "1345", nombre: "Chalacea" },
      { id: "1260", nombre: "Chancani" },
      { id: "1370", nombre: "Chañar Viejo" },
      { id: "1283", nombre: "Charbonier" },
      { id: "1216", nombre: "Charras" },
      { id: "1183", nombre: "Chazon" },
      { id: "1592", nombre: "Chilibroste" },
      { id: "1317", nombre: "Chucul" },
      { id: "1201", nombre: "Chuña" },
      { id: "1541", nombre: "Chuña Huasi" },
      { id: "1574", nombre: "Churqui Cañada" },
      { id: "1252", nombre: "Cienaga Del Coro" },
      { id: "1593", nombre: "Cintra" },
      { id: "1386", nombre: "Colazo" },
      { id: "1545", nombre: "Colonia Almada" },
      { id: "1457", nombre: "Colonia Anita" },
      { id: "1234", nombre: "Colonia Barge" },
      { id: "1594", nombre: "Colonia Bismarck" },
      { id: "1595", nombre: "Colonia Bremen" },
      { id: "1113", nombre: "Colonia Caroya" },
      { id: "1235", nombre: "Colonia Italiana" },
      { id: "1346", nombre: "Colonia Las Cuatro Esquinas" },
      { id: "1459", nombre: "Colonia Las Pichanas" },
      { id: "1460", nombre: "Colonia Marina" },
      { id: "1461", nombre: "Colonia Prosperidad" },
      { id: "1462", nombre: "Colonia San Bartolome" },
      { id: "1463", nombre: "Colonia San Pedro" },
      { id: "1464", nombre: "Colonia Santa Maria" },
      { id: "1114", nombre: "Colonia Tirolesa" },
      { id: "1465", nombre: "Colonia Valtelina" },
      { id: "1236", nombre: "Colonia Veinticinco" },
      { id: "1115", nombre: "Colonia Vicente AgÜERO" },
      { id: "1387", nombre: "Colonia Videla" },
      { id: "1466", nombre: "Colonia Vignaud" },
      { id: "1458", nombre: "Colonia 10 De Julio" },
      { id: "1424", nombre: "Conlara" },
      { id: "1202", nombre: "Copacabana" },
      { id: "1106", nombre: "Cordoba" },
      { id: "1318", nombre: "Coronel Baigorria" },
      { id: "1319", nombre: "Coronel Moldes" },
      { id: "1237", nombre: "Corral De Bustos" },
      { id: "1116", nombre: "Corral Quemado" },
      { id: "1546", nombre: "Corralito" },
      { id: "1425", nombre: "Corralito" },
      { id: "1284", nombre: "Cosquin" },
      { id: "1502", nombre: "Costa Azul" },
      { id: "1388", nombre: "Costasacate" },
      { id: "1117", nombre: "Country Chacras De La Villa" },
      { id: "1118", nombre: "Country San Isidro" },
      { id: "1238", nombre: "Cruz Alta" },
      { id: "1426", nombre: "Cruz Caña" },
      { id: "1149", nombre: "Cruz De Caña" },
      { id: "1150", nombre: "Cruz Del Eje" },
      { id: "1285", nombre: "Cuesta Blanca" },
      { id: "1547", nombre: "Dalmacio Velez" },
      { id: "1203", nombre: "Dean Funes" },
      { id: "1166", nombre: "Del Campillo" },
      { id: "1503", nombre: "Despeñaderos (Est. Dr. Lucas A. De Olmos)" },
      { id: "1467", nombre: "Devoto" },
      { id: "1347", nombre: "Diego De Rojas" },
      { id: "1504", nombre: "Dique Chico" },
      { id: "1427", nombre: "Dos Arroyos" },
      { id: "1124", nombre: "Dumesnil" },
      { id: "1348", nombre: "El Alcalde (Est. Tala Norte)" },
      { id: "1468", nombre: "El Arañado" },
      { id: "1151", nombre: "El Brete" },
      { id: "1253", nombre: "El Chacho" },
      { id: "1073", nombre: "El Corcovado - El Torreon" },
      { id: "1349", nombre: "El Crispin" },
      { id: "1119", nombre: "El Diquecito" },
      { id: "1469", nombre: "El Fortin" },
      { id: "1470", nombre: "El Fuertecito" },
      { id: "1405", nombre: "El Huayco" },
      { id: "1120", nombre: "El Manzano" },
      { id: "1428", nombre: "El Pantanillo" },
      { id: "1446", nombre: "El Pueblito" },
      { id: "1135", nombre: "El Pueblito" },
      { id: "1217", nombre: "El Rastreador" },
      { id: "1152", nombre: "El Rincon" },
      { id: "1575", nombre: "El Rodeo" },
      { id: "1471", nombre: "El Tio" },
      { id: "1576", nombre: "El Tuscal" },
      { id: "1447", nombre: "El Valle" },
      { id: "1320", nombre: "Elena" },
      { id: "1074", nombre: "Embalse" },
      { id: "1350", nombre: "Esquina" },
      { id: "1204", nombre: "Esquina Del Alambre" },
      { id: "1121", nombre: "Estacion Colonia Tirolesa" },
      { id: "1167", nombre: "Estacion Lecueder" },
      { id: "1472", nombre: "Estacion Luxardo" },
      { id: "1254", nombre: "Estancia De Guadalupe" },
      { id: "1286", nombre: "Estancia Vieja" },
      { id: "1184", nombre: "Etruria" },
      { id: "1371", nombre: "Eufrasio Loza" },
      { id: "1505", nombre: "Falda Del Cañete" },
      { id: "1506", nombre: "Falda Del Carmen" },
      { id: "1473", nombre: "Freyre" },
      { id: "1239", nombre: "General Baldissera" },
      { id: "1218", nombre: "General Cabrera" },
      { id: "1219", nombre: "General Deheza" },
      { id: "1548", nombre: "General Fotheringham" },
      { id: "1268", nombre: "General Levalle" },
      { id: "1122", nombre: "General Paz" },
      { id: "1240", nombre: "General Roca" },
      { id: "1153", nombre: "Guanaco Muerto" },
      { id: "1255", nombre: "Guasapampa" },
      { id: "1241", nombre: "Guatimozin" },
      { id: "1372", nombre: "GÜTEMBERG" },
      { id: "1549", nombre: "Hernando" },
      { id: "1168", nombre: "Hipolito Bouchard" },
      { id: "1220", nombre: "Huanchillas" },
      { id: "1287", nombre: "Huerta Grande" },
      { id: "1169", nombre: "Huinca Renanco" },
      { id: "1596", nombre: "Idiazabal" },
      { id: "1389", nombre: "Impira" },
      { id: "1242", nombre: "Inriville" },
      { id: "1243", nombre: "Isla Verde" },
      { id: "1170", nombre: "Italo" },
      { id: "1550", nombre: "James Craik" },
      { id: "1107", nombre: "Jardin Arenales" },
      { id: "1123", nombre: "Jesus Maria" },
      { id: "1535", nombre: "Jose De La Quintana" },
      { id: "1144", nombre: "Juarez Celman" },
      { id: "1597", nombre: "Justiniano Posse" },
      { id: "1351", nombre: "Kilometro 658" },
      { id: "1154", nombre: "La Banda" },
      { id: "1155", nombre: "La Batea" },
      { id: "1507", nombre: "La Boca Del Rio" },
      { id: "1125", nombre: "La Calera" },
      { id: "1508", nombre: "La Carbonada" },
      { id: "1221", nombre: "La Carlota" },
      { id: "1321", nombre: "La Carolina" },
      { id: "1322", nombre: "La Cautiva" },
      { id: "1269", nombre: "La Cesira" },
      { id: "1406", nombre: "La Cortadera" },
      { id: "1075", nombre: "La Cruz" },
      { id: "1288", nombre: "La Cumbre" },
      { id: "1509", nombre: "La Cumbrecita" },
      { id: "1076", nombre: "La Cumbrecita" },
      { id: "1289", nombre: "La Falda" },
      { id: "1108", nombre: "La Floresta" },
      { id: "1474", nombre: "La Francia" },
      { id: "1323", nombre: "La Gilda" },
      { id: "1126", nombre: "La Granja" },
      { id: "1156", nombre: "La Higuera" },
      { id: "1185", nombre: "La Laguna" },
      { id: "1510", nombre: "La Paisanita" },
      { id: "1186", nombre: "La Palestina" },
      { id: "1564", nombre: "La Pampa" },
      { id: "1475", nombre: "La Paquita" },
      { id: "1352", nombre: "La Para" },
      { id: "1429", nombre: "La Paz" },
      { id: "1565", nombre: "La Paz" },
      { id: "1511", nombre: "La Perla" },
      { id: "1256", nombre: "La Playa" },
      { id: "1187", nombre: "La Playosa" },
      { id: "1433", nombre: "La Poblacion" },
      { id: "1353", nombre: "La Posta" },
      { id: "1354", nombre: "La Puerta" },
      { id: "1127", nombre: "La Puerta" },
      { id: "1355", nombre: "La Quinta" },
      { id: "1434", nombre: "La Ramada" },
      { id: "1512", nombre: "La Rancherita" },
      { id: "1373", nombre: "La Rinconada" },
      { id: "1513", nombre: "La Serranita" },
      { id: "1476", nombre: "La Tordilla" },
      { id: "1435", nombre: "La Travesia" },
      { id: "1598", nombre: "Laborde" },
      { id: "1270", nombre: "Laboulaye" },
      { id: "1390", nombre: "Laguna Larga" },
      { id: "1324", nombre: "Las Acequias" },
      { id: "1325", nombre: "Las Albahacas" },
      { id: "1577", nombre: "Las Arrias" },
      { id: "1077", nombre: "Las Bajadas" },
      { id: "1078", nombre: "Las Caleras" },
      { id: "1407", nombre: "Las Calles" },
      { id: "1157", nombre: "Las Cañadas" },
      { id: "1430", nombre: "Las Chacras" },
      { id: "1448", nombre: "Las Chacras" },
      { id: "1356", nombre: "Las Gramillas" },
      { id: "1326", nombre: "Las Higueras" },
      { id: "1551", nombre: "Las Isletillas" },
      { id: "1290", nombre: "Las Jarillas" },
      { id: "1391", nombre: "Las Junturas" },
      { id: "1188", nombre: "Las Mojarras" },
      { id: "1408", nombre: "Las Oscuras" },
      { id: "1261", nombre: "Las Palmas" },
      { id: "1566", nombre: "Las Peñas" },
      { id: "1327", nombre: "Las Peñas" },
      { id: "1552", nombre: "Las Perdices" },
      { id: "1158", nombre: "Las Playas" },
      { id: "1409", nombre: "Las Rabonas" },
      { id: "1357", nombre: "Las Saladas" },
      { id: "1436", nombre: "Las Tapias" },
      { id: "1477", nombre: "Las Varas" },
      { id: "1478", nombre: "Las Varillas" },
      { id: "1328", nombre: "Las Vertientes" },
      { id: "1271", nombre: "Leguizamon" },
      { id: "1244", nombre: "Leones" },
      { id: "1431", nombre: "Loma Bola" },
      { id: "1410", nombre: "Los Callejones" },
      { id: "1515", nombre: "Los Cedros" },
      { id: "1437", nombre: "Los Cerrillos" },
      { id: "1392", nombre: "Los Chañaritos" },
      { id: "1205", nombre: "Los Chañaritos" },
      { id: "1159", nombre: "Los Chañaritos" },
      { id: "1222", nombre: "Los Cisnes" },
      { id: "1291", nombre: "Los Cocos" },
      { id: "1079", nombre: "Los Condores" },
      { id: "1438", nombre: "Los Hornillos" },
      { id: "1374", nombre: "Los Hoyos" },
      { id: "1567", nombre: "Los Mistoles" },
      { id: "1080", nombre: "Los Molinos" },
      { id: "1439", nombre: "Los Molles" },
      { id: "1128", nombre: "Los Molles" },
      { id: "1206", nombre: "Los Pozos" },
      { id: "1081", nombre: "Los Reartes" },
      { id: "1440", nombre: "Los Romeros" },
      { id: "1245", nombre: "Los Surgentes" },
      { id: "1262", nombre: "Los Talares" },
      { id: "1553", nombre: "Los Zorros" },
      { id: "1516", nombre: "Lozada" },
      { id: "1189", nombre: "Luca" },
      { id: "1578", nombre: "Lucio V. Mansilla" },
      { id: "1393", nombre: "Luque" },
      { id: "1082", nombre: "Lutti" },
      { id: "1441", nombre: "Luyaba" },
      { id: "1517", nombre: "Malagueño" },
      { id: "1329", nombre: "Malena" },
      { id: "1292", nombre: "Mallin" },
      { id: "1129", nombre: "Malvinas Argentinas" },
      { id: "1394", nombre: "Manfredi" },
      { id: "1358", nombre: "Maquinista Gallini" },
      { id: "1246", nombre: "Marcos Juarez" },
      { id: "1479", nombre: "Marull" },
      { id: "1395", nombre: "Matorrales" },
      { id: "1171", nombre: "Mattaldi" },
      { id: "1293", nombre: "Mayu Sumaj" },
      { id: "1160", nombre: "Media Naranja" },
      { id: "1272", nombre: "Melo" },
      { id: "1130", nombre: "Mendiolaza" },
      { id: "1131", nombre: "Mi Granja" },
      { id: "1533", nombre: "Mi Valle" },
      { id: "1411", nombre: "Mina Clavero" },
      { id: "1480", nombre: "Miramar" },
      { id: "1247", nombre: "Monte Buey" },
      { id: "1330", nombre: "Monte De Los Gauchos" },
      { id: "1359", nombre: "Monte Del Rosario" },
      { id: "1599", nombre: "Monte Leña" },
      { id: "1600", nombre: "Monte Maiz" },
      { id: "1519", nombre: "Monte Ralo" },
      { id: "1083", nombre: "Monte Ralo" },
      { id: "1360", nombre: "Montecristo" },
      { id: "1601", nombre: "Morrison" },
      { id: "1481", nombre: "Morteros" },
      { id: "1412", nombre: "Mussi" },
      { id: "1172", nombre: "Nicolas Bruzzone" },
      { id: "1602", nombre: "Noetinger" },
      { id: "1248", nombre: "Noetinger" },
      { id: "1413", nombre: "Nono" },
      { id: "1361", nombre: "Obispo Trejo" },
      { id: "1223", nombre: "Olaeta" },
      { id: "1554", nombre: "Oliva" },
      { id: "1207", nombre: "Olivares De San Nicolas" },
      { id: "1173", nombre: "Onagoity" },
      { id: "1396", nombre: "Oncativo" },
      { id: "1603", nombre: "Ordoñez" },
      { id: "1224", nombre: "Pacheco De Melo" },
      { id: "1132", nombre: "Pajas Blancas" },
      { id: "1555", nombre: "Pampayasta Norte" },
      { id: "1556", nombre: "Pampayasta Sur" },
      { id: "1414", nombre: "Panaholma" },
      { id: "1084", nombre: "Parque Calmayo" },
      { id: "1142", nombre: "Parque Norte" },
      { id: "1604", nombre: "Pascanas" },
      { id: "1190", nombre: "Pasco" },
      { id: "1331", nombre: "Paso Del Durazno" },
      { id: "1225", nombre: "Paso Del Durazno" },
      { id: "1161", nombre: "Paso Viejo" },
      { id: "1397", nombre: "Pilar" },
      { id: "1174", nombre: "Pincen" },
      { id: "1362", nombre: "Piquillin" },
      { id: "1363", nombre: "Plaza De Mercedes" },
      { id: "1482", nombre: "Plaza Luxardo" },
      { id: "1483", nombre: "Plaza San Francisco" },
      { id: "1484", nombre: "Porteña" },
      { id: "1520", nombre: "Potrero De Garay" },
      { id: "1398", nombre: "Pozo Del Molle" },
      { id: "1542", nombre: "Pozo Nuevo" },
      { id: "1364", nombre: "Pueblo Comechingones" },
      { id: "1605", nombre: "Pueblo Italiano" },
      { id: "1375", nombre: "Puesto De Castro" },
      { id: "1557", nombre: "Punta Del Agua" },
      { id: "1485", nombre: "Quebracho Herrado" },
      { id: "1432", nombre: "Quebracho Ladeado" },
      { id: "1442", nombre: "Quebrada De Los Pozos" },
      { id: "1294", nombre: "Quebrada De Luna" },
      { id: "1208", nombre: "Quilino" },
      { id: "1521", nombre: "Rafael Garcia" },
      { id: "1606", nombre: "Ramon J. Carcano" },
      { id: "1175", nombre: "Ranqueles" },
      { id: "1376", nombre: "Rayo Cortado" },
      { id: "1399", nombre: "Rincon" },
      { id: "1273", nombre: "Rio Bamba" },
      { id: "1133", nombre: "Rio Ceballos" },
      { id: "1332", nombre: "Rio Cuarto" },
      { id: "1085", nombre: "Rio De Los Sauces" },
      { id: "1365", nombre: "Rio Primero" },
      { id: "1400", nombre: "Rio Segundo" },
      { id: "1558", nombre: "Rio Tercero" },
      { id: "1274", nombre: "Rosales" },
      { id: "1579", nombre: "Rosario Del Saladillo" },
      { id: "1486", nombre: "Sacanta" },
      { id: "1366", nombre: "Sagrada Familia" },
      { id: "1249", nombre: "Saira" },
      { id: "1250", nombre: "Saladillo" },
      { id: "1134", nombre: "Saldan" },
      { id: "1263", nombre: "Salsacate" },
      { id: "1136", nombre: "Salsipuedes" },
      { id: "1333", nombre: "Sampacho" },
      { id: "1086", nombre: "San Agustin" },
      { id: "1295", nombre: "San Antonio De Arredondo" },
      { id: "1607", nombre: "San Antonio De Litin" },
      { id: "1334", nombre: "San Basilio" },
      { id: "1257", nombre: "San Carlos Minas" },
      { id: "1522", nombre: "San Clemente" },
      { id: "1296", nombre: "San Esteban" },
      { id: "1487", nombre: "San Francisco" },
      { id: "1543", nombre: "San Francisco Del Chañar" },
      { id: "1264", nombre: "San Geronimo" },
      { id: "1415", nombre: "San Huberto" },
      { id: "1087", nombre: "San Ignacio (Loteo San Javier)" },
      { id: "1088", nombre: "San Ignacio (Loteo Velez Crespo)" },
      { id: "1443", nombre: "San Javier Y Yacanto" },
      { id: "1275", nombre: "San Joaquin" },
      { id: "1444", nombre: "San Jose" },
      { id: "1580", nombre: "San Jose De La Dormida" },
      { id: "1581", nombre: "San Jose De Las Salinas" },
      { id: "1416", nombre: "San Lorenzo" },
      { id: "1608", nombre: "San Marcos" },
      { id: "1162", nombre: "San Marcos Sierra" },
      { id: "1417", nombre: "San Martin" },
      { id: "1523", nombre: "San Nicolas" },
      { id: "1418", nombre: "San Pedro" },
      { id: "1377", nombre: "San Pedro De GÜTEMBERG" },
      { id: "1209", nombre: "San Pedro De Toyos" },
      { id: "1582", nombre: "San Pedro Norte" },
      { id: "1297", nombre: "San Roque" },
      { id: "1609", nombre: "San Severo" },
      { id: "1419", nombre: "San Vicente" },
      { id: "1191", nombre: "Sanabria" },
      { id: "1568", nombre: "Santa Catalina" },
      { id: "1335", nombre: "Santa Catalina (Est. Holmberg)" },
      { id: "1378", nombre: "Santa Elena" },
      { id: "1137", nombre: "Santa Elena" },
      { id: "1226", nombre: "Santa Eufemia" },
      { id: "1176", nombre: "Santa Magdalena (Est. Jovita)" },
      { id: "1298", nombre: "Santa Maria De Punilla" },
      { id: "1089", nombre: "Santa Monica" },
      { id: "1090", nombre: "Santa Rosa De Calamuchita" },
      {
        id: "1367",
        nombre: "Santa Rosa De Rio Primero (Est. Villa Santa Rosa)"
      },
      { id: "1401", nombre: "Santiago Temple" },
      { id: "1569", nombre: "Sarmiento" },
      { id: "1488", nombre: "Saturnino Maria Laspiur" },
      { id: "1420", nombre: "Sauce Arriba" },
      { id: "1379", nombre: "Sebastian Elcano" },
      { id: "1489", nombre: "Seeber" },
      { id: "1091", nombre: "Segunda Usina" },
      { id: "1276", nombre: "Serrano" },
      { id: "1163", nombre: "Serrezuela" },
      { id: "1192", nombre: "Silvio Pellico" },
      { id: "1570", nombre: "Simbolar" },
      { id: "1571", nombre: "Sinsacate" },
      { id: "1524", nombre: "Socavones" },
      { id: "1092", nombre: "Solar De Los Molinos" },
      { id: "1336", nombre: "Suco" },
      { id: "1265", nombre: "Tala Cañada" },
      { id: "1299", nombre: "Tala Huasi" },
      { id: "1258", nombre: "Talaini" },
      { id: "1559", nombre: "Tancacha" },
      { id: "1266", nombre: "Taninga" },
      { id: "1300", nombre: "Tanti" },
      { id: "1421", nombre: "Tasna" },
      { id: "1193", nombre: "Ticino" },
      { id: "1138", nombre: "Tinoco" },
      { id: "1194", nombre: "Tio Pujio" },
      { id: "1525", nombre: "Toledo" },
      { id: "1490", nombre: "Toro Pujio" },
      { id: "1259", nombre: "Tosno" },
      { id: "1337", nombre: "Tosquitas" },
      { id: "1491", nombre: "Transito" },
      { id: "1492", nombre: "Trinchera" },
      { id: "1164", nombre: "Tuclame" },
      { id: "1227", nombre: "Ucacha" },
      { id: "1139", nombre: "Unquillo" },
      { id: "1526", nombre: "Valle Alegre" },
      { id: "1527", nombre: "Valle De Anisacate" },
      { id: "1301", nombre: "Valle Hermoso" },
      { id: "1610", nombre: "Viamonte" },
      { id: "1338", nombre: "Vicuña Mackenna" },
      { id: "1531", nombre: "Villa  La Bolsa" },
      { id: "1532", nombre: "Villa  Los Aromos" },
      { id: "1195", nombre: "Villa Albertina" },
      { id: "1140", nombre: "Villa Allende" },
      { id: "1093", nombre: "Villa Alpina" },
      { id: "1094", nombre: "Villa Amancay" },
      { id: "1560", nombre: "Villa Ascasubi" },
      { id: "1095", nombre: "Villa Berna" },
      { id: "1380", nombre: "Villa Candelaria Norte" },
      { id: "1302", nombre: "Villa Carlos Paz" },
      { id: "1141", nombre: "Villa Cerro Azul" },
      { id: "1528", nombre: "Villa Ciudad De America (Loteo Diego De Rojas)" },
      { id: "1096", nombre: "Villa Ciudad Parque Los Reartes (1A. Seccion)" },
      { id: "1097", nombre: "Villa Ciudad Parque Los Reartes (3A. Seccion)" },
      { id: "1493", nombre: "Villa Concepcion Del Tio" },
      { id: "1422", nombre: "Villa Cura Brochero" },
      { id: "1449", nombre: "Villa De Las Rosas" },
      { id: "1381", nombre: "Villa De Maria" },
      { id: "1267", nombre: "Villa De Pocho" },
      { id: "1165", nombre: "Villa De Soto" },
      { id: "1098", nombre: "Villa Del Dique" },
      { id: "1529", nombre: "Villa Del Prado" },
      { id: "1402", nombre: "Villa Del Rosario" },
      { id: "1572", nombre: "Villa Del Totoral" },
      { id: "1494", nombre: "Villa Del Transito" },
      { id: "1450", nombre: "Villa Dolores" },
      { id: "1339", nombre: "Villa El Chacay" },
      { id: "1143", nombre: "Villa El Fachinal" },
      { id: "1099", nombre: "Villa El Tala" },
      { id: "1251", nombre: "Villa Elisa" },
      { id: "1303", nombre: "Villa Flor Serrana" },
      { id: "1368", nombre: "Villa Fontana" },
      { id: "1100", nombre: "Villa General Belgrano" },
      { id: "1304", nombre: "Villa Giardino" },
      { id: "1210", nombre: "Villa Gutierrez" },
      { id: "1177", nombre: "Villa Huidobro (Est. Cañada Verde)" },
      { id: "1530", nombre: "Villa La Donosa" },
      { id: "1101", nombre: "Villa La Rivera" },
      { id: "1451", nombre: "Villa La Viña" },
      { id: "1305", nombre: "Villa Lago Azul" },
      { id: "1145", nombre: "Villa Los Llanos" },
      { id: "1611", nombre: "Villa Los Patos" },
      { id: "1196", nombre: "Villa Maria" },
      { id: "1197", nombre: "Villa Nueva" },
      { id: "1198", nombre: "Villa Oeste" },
      { id: "1534", nombre: "Villa Parque Santa Ana" },
      { id: "1306", nombre: "Villa Parque Siquiman" },
      { id: "1211", nombre: "Villa Quilino" },
      { id: "1102", nombre: "Villa Quillinzo" },
      { id: "1228", nombre: "Villa Reduccion" },
      { id: "1307", nombre: "Villa Rio Icho Cruz" },
      { id: "1277", nombre: "Villa Rossi" },
      { id: "1103", nombre: "Villa Rumipal" },
      { id: "1495", nombre: "Villa San Esteban" },
      { id: "1536", nombre: "Villa San Isidro" },
      { id: "1308", nombre: "Villa San Jose" },
      { id: "1104", nombre: "Villa San Miguel" },
      { id: "1309", nombre: "Villa Santa Cruz Del Lago" },
      { id: "1340", nombre: "Villa Santa Eugenia" },
      { id: "1423", nombre: "Villa Sarmiento" },
      { id: "1178", nombre: "Villa Sarmiento" },
      { id: "1537", nombre: "Villa Sierras De Oro" },
      { id: "1583", nombre: "Villa Tulumba" },
      { id: "1179", nombre: "Villa Valeria" },
      { id: "1105", nombre: "Villa Yacanto" },
      { id: "1341", nombre: "Washington" },
      { id: "1612", nombre: "Wenceslao Escalante" },
      { id: "1538", nombre: "Yocsina" }
    ]
  },
  {
    id: 18,
    nombre: "Corrientes",
    ciudades: [
      { id: "1630", nombre: "Alvear" },
      { id: "1616", nombre: "Barrio Esperanza" },
      { id: "1613", nombre: "Bella Vista" },
      { id: "1614", nombre: "Beron De Astrada" },
      { id: "1660", nombre: "Bonpland" },
      { id: "1624", nombre: "Cazadores Correntinos" },
      { id: "1677", nombre: "Chavarria" },
      { id: "1671", nombre: "Colonia Carlos Pellegrini" },
      { id: "1636", nombre: "Colonia Carolina" },
      { id: "1654", nombre: "Colonia Libertad" },
      { id: "1640", nombre: "Colonia Liebig'S" },
      { id: "1678", nombre: "Colonia Pando" },
      { id: "1621", nombre: "Concepcion" },
      { id: "1617", nombre: "Corrientes" },
      { id: "1645", nombre: "Cruz De Los Milagros" },
      { id: "1625", nombre: "Curuzu Cuatia" },
      { id: "1627", nombre: "El Sombrero" },
      { id: "1628", nombre: "Empedrado" },
      { id: "1629", nombre: "Esquina" },
      { id: "1655", nombre: "Estacion Libertad" },
      { id: "1631", nombre: "Estacion Torrent" },
      { id: "1651", nombre: "Felipe Yofre" },
      { id: "1683", nombre: "Garruchos" },
      { id: "1684", nombre: "Gobernador Igr. Valentin Virasoro" },
      { id: "1646", nombre: "Gobernador Juan E. Martinez" },
      { id: "1637", nombre: "Goya" },
      { id: "1672", nombre: "Guaviravi" },
      { id: "1666", nombre: "Ingenio Primer Correntino" },
      { id: "1632", nombre: "Ita Ibate" },
      { id: "1638", nombre: "Itati" },
      { id: "1641", nombre: "Ituzaingo" },
      { id: "1682", nombre: "Jose Rafael Gomez (Garabi)" },
      { id: "1656", nombre: "Juan Pujol" },
      { id: "1673", nombre: "La Cruz" },
      { id: "1618", nombre: "Laguna Brava" },
      { id: "1647", nombre: "Lavalle" },
      { id: "1633", nombre: "Lomas De Vallejos" },
      { id: "1675", nombre: "Loreto" },
      { id: "1652", nombre: "Mariano I. Loza (Est. Justino Solari)" },
      { id: "1650", nombre: "Mburucuya" },
      { id: "1653", nombre: "Mercedes" },
      { id: "1657", nombre: "Mocoreta" },
      { id: "1658", nombre: "Monte Caseros" },
      { id: "1634", nombre: "Nuestra Señora Del Rosario De Caa Cati" },
      { id: "1635", nombre: "Palmar Grande" },
      { id: "1659", nombre: "Parada Acuña" },
      { id: "1661", nombre: "Parada Pucheta" },
      { id: "1667", nombre: "Paso De La Patria" },
      { id: "1662", nombre: "Paso De Los Libres" },
      { id: "1680", nombre: "Pedro R. Fernandez (Est. Manuel F. Mantilla)" },
      { id: "1626", nombre: "Perugorria" },
      { id: "1639", nombre: "Ramada Paso" },
      { id: "1619", nombre: "Riachuelo" },
      { id: "1664", nombre: "Saladas" },
      { id: "1642", nombre: "San Antonio" },
      { id: "1643", nombre: "San Carlos" },
      { id: "1620", nombre: "San Cayetano" },
      { id: "1668", nombre: "San Cosme" },
      { id: "1665", nombre: "San Lorenzo" },
      { id: "1670", nombre: "San Luis Del Palmar" },
      { id: "1676", nombre: "San Miguel" },
      { id: "1681", nombre: "San Roque" },
      { id: "1669", nombre: "Santa Ana" },
      { id: "1648", nombre: "Santa Lucia" },
      { id: "1622", nombre: "Santa Rosa" },
      { id: "1685", nombre: "Santo Tome" },
      { id: "1686", nombre: "Sauce" },
      { id: "1623", nombre: "Tabay" },
      { id: "1663", nombre: "Tapebicua" },
      { id: "1649", nombre: "Villa Cordoba" },
      { id: "1644", nombre: "Villa Olivari" },
      { id: "1615", nombre: "Yahape" },
      { id: "1674", nombre: "Yapeyu" },
      { id: "1679", nombre: "9 De Julio (Est. Pueblo 9 De Julio)" }
    ]
  },
  {
    id: 30,
    nombre: "Entre Rios",
    ciudades: [
      { id: "1934", nombre: "Aldea Asuncion" },
      { id: "1898", nombre: "Aldea Brasilera" },
      { id: "1973", nombre: "Aldea Maria Luisa" },
      { id: "1899", nombre: "Aldea Protestante" },
      { id: "1900", nombre: "Aldea Salto" },
      { id: "1939", nombre: "Aldea San Antonio" },
      { id: "1901", nombre: "Aldea San Francisco" },
      { id: "1940", nombre: "Aldea San Juan" },
      { id: "1974", nombre: "Aldea San Rafael" },
      { id: "1975", nombre: "Aldea Santa Maria" },
      { id: "1976", nombre: "Aldea Santa Rosa" },
      { id: "1902", nombre: "Aldea Spatzenkutter" },
      { id: "1903", nombre: "Aldea Valle Maria" },
      { id: "2006", nombre: "Altamirano Sur" },
      { id: "2031", nombre: "Antelo" },
      { id: "1965", nombre: "Aranguren" },
      { id: "1867", nombre: "Arroyo Baru" },
      { id: "1950", nombre: "Arroyo Martinez" },
      { id: "2016", nombre: "Basavilbaso" },
      { id: "1885", nombre: "Benito Legeren" },
      { id: "1966", nombre: "Betbeder" },
      { id: "1956", nombre: "Bovril" },
      { id: "1880", nombre: "Calabacilla" },
      { id: "2017", nombre: "Caseros" },
      { id: "1951", nombre: "Ceibas" },
      { id: "1977", nombre: "Cerrito" },
      { id: "1915", nombre: "Chajari" },
      { id: "2032", nombre: "CharigÜE" },
      { id: "1881", nombre: "Clodomiro Ledesma" },
      { id: "1868", nombre: "Colon" },
      { id: "1916", nombre: "Colonia Alemana" },
      { id: "1979", nombre: "Colonia Avellaneda" },
      { id: "1957", nombre: "Colonia Avigdor" },
      { id: "1882", nombre: "Colonia Ayui" },
      { id: "2018", nombre: "Colonia Elia" },
      { id: "1917", nombre: "Colonia Ensanche Sauce" },
      { id: "1904", nombre: "Colonia Ensayo" },
      { id: "1883", nombre: "Colonia General Roca" },
      { id: "1869", nombre: "Colonia Hugues" },
      { id: "1918", nombre: "Colonia La Argentina" },
      { id: "1919", nombre: "Colonia Peña" },
      { id: "1920", nombre: "Colonia Santa Maria" },
      { id: "1884", nombre: "Colonia Yerua" },
      { id: "2019", nombre: "Concepcion Del Uruguay" },
      { id: "1886", nombre: "Concordia" },
      { id: "1927", nombre: "Conscripto Bernardi" },
      { id: "1980", nombre: "Crespo" },
      { id: "1905", nombre: "Diamante" },
      { id: "1967", nombre: "Don Cristobal" },
      { id: "2007", nombre: "Durazno" },
      { id: "1875", nombre: "El Brillante" },
      { id: "1928", nombre: "El Cimarron" },
      { id: "1876", nombre: "El Colorado" },
      { id: "1981", nombre: "El Palenque" },
      { id: "1982", nombre: "El Pingo" },
      { id: "1890", nombre: "El Redomon" },
      { id: "1958", nombre: "El Solar" },
      { id: "1941", nombre: "Enrique Carbo" },
      { id: "2008", nombre: "Estacion Arroyo Cle" },
      { id: "1907", nombre: "Estacion Camps" },
      { id: "1935", nombre: "Estacion Lazo" },
      { id: "1913", nombre: "Estacion Puiggari" },
      { id: "2037", nombre: "Estacion Raices" },
      { id: "1891", nombre: "Estacion Yerua" },
      { id: "2020", nombre: "Estancia San Pedro" },
      { id: "1942", nombre: "Faustino M. Parera" },
      { id: "1968", nombre: "Febre" },
      { id: "1921", nombre: "Federacion" },
      { id: "1929", nombre: "Federal" },
      { id: "1943", nombre: "General Almada" },
      { id: "1908", nombre: "General Alvear" },
      { id: "2004", nombre: "General Campos" },
      { id: "1936", nombre: "General Galarza" },
      { id: "1909", nombre: "General Racedo" },
      { id: "1910", nombre: "General Ramirez" },
      { id: "1944", nombre: "Gilbert" },
      { id: "2009", nombre: "Gobernador EchagÜE" },
      { id: "2010", nombre: "Gobernador Mansilla" },
      { id: "2011", nombre: "Gobernador Sola" },
      { id: "1937", nombre: "Gualeguay" },
      { id: "1945", nombre: "Gualeguaychu" },
      { id: "2012", nombre: "Guardamonte" },
      { id: "1870", nombre: "Hambis" },
      { id: "1983", nombre: "Hasenkamp" },
      { id: "1984", nombre: "Hernandarias" },
      { id: "1969", nombre: "Hernandez" },
      { id: "2021", nombre: "Herrera" },
      { id: "1871", nombre: "Hocker" },
      { id: "1952", nombre: "Ibicuy" },
      { id: "2038", nombre: "Ingeniero Miguel Sajaroff" },
      { id: "1946", nombre: "Irazusta" },
      { id: "2039", nombre: "Jubileo" },
      { id: "1872", nombre: "La Clarita" },
      { id: "1892", nombre: "La Criolla" },
      { id: "1959", nombre: "La Paz" },
      { id: "1985", nombre: "La Picada" },
      { id: "2033", nombre: "Laguna Del Pescado" },
      { id: "1947", nombre: "Larroque" },
      { id: "2013", nombre: "Las Guachas" },
      { id: "2022", nombre: "Las Moscas" },
      { id: "1887", nombre: "Las Tejas" },
      { id: "1986", nombre: "Las Tunas" },
      { id: "2023", nombre: "Libaros" },
      { id: "1893", nombre: "Los Charruas" },
      { id: "1922", nombre: "Los Conquistadores" },
      { id: "1970", nombre: "Lucas Gonzalez" },
      { id: "2014", nombre: "Macia" },
      { id: "1987", nombre: "Maria Grande" },
      { id: "1953", nombre: "Medanos" },
      { id: "2040", nombre: "Mojones Norte" },
      { id: "2034", nombre: "Molino Doll" },
      { id: "1971", nombre: "Nogoya" },
      { id: "1894", nombre: "Nueva Escocia" },
      { id: "1930", nombre: "Nueva Vizcaya" },
      { id: "1954", nombre: "ñANCAY" },
      { id: "1988", nombre: "Oro Verde" },
      { id: "1895", nombre: "Osvaldo Magnasco" },
      { id: "1911", nombre: "Paraje La Virgen" },
      { id: "1989", nombre: "Parana" },
      { id: "2041", nombre: "Paso De La Laguna" },
      { id: "1896", nombre: "Pedernal" },
      { id: "1960", nombre: "Piedras Blancas" },
      { id: "2025", nombre: "Pronunciamiento" },
      { id: "1961", nombre: "Pueblo Arrua" },
      { id: "1990", nombre: "Pueblo Bellocq (Est. Las Garzas)" },
      { id: "1991", nombre: "Pueblo Brugo" },
      { id: "1873", nombre: "Pueblo Cazes" },
      { id: "1948", nombre: "Pueblo General Belgrano" },
      { id: "1992", nombre: "Pueblo General San Martin" },
      { id: "1874", nombre: "Pueblo Liebig'S" },
      { id: "1978", nombre: "Pueblo Moreno" },
      { id: "1912", nombre: "Puerto Las Cuevas" },
      { id: "1938", nombre: "Puerto Ruiz" },
      { id: "1897", nombre: "Puerto Yerua" },
      { id: "2035", nombre: "Rincon De Nogoya" },
      { id: "2026", nombre: "Rocamora" },
      { id: "2015", nombre: "Rosario Del Tala" },
      { id: "1993", nombre: "San Benito" },
      { id: "1962", nombre: "San Gustavo" },
      { id: "1923", nombre: "San Jaime De La Frontera" },
      { id: "1877", nombre: "San Jose" },
      { id: "1932", nombre: "San Jose De Feliciano" },
      { id: "1924", nombre: "San Pedro" },
      { id: "2005", nombre: "San Salvador" },
      { id: "1933", nombre: "San Victor" },
      { id: "1925", nombre: "Santa Ana" },
      { id: "2027", nombre: "Santa Anita" },
      { id: "1963", nombre: "Santa Elena" },
      { id: "1931", nombre: "Sauce De Luna" },
      { id: "1994", nombre: "Sauce Montrull" },
      { id: "1995", nombre: "Sauce Pinto" },
      { id: "1996", nombre: "Segui" },
      { id: "1964", nombre: "Sir Leonard" },
      { id: "1997", nombre: "Sosa" },
      { id: "1906", nombre: "Strobel" },
      { id: "1998", nombre: "Tabossi" },
      { id: "1999", nombre: "Tezanos Pinto" },
      { id: "1878", nombre: "Ubajay" },
      { id: "1949", nombre: "Urdinarrain" },
      { id: "2000", nombre: "Viale" },
      { id: "2036", nombre: "Victoria" },
      { id: "1879", nombre: "Villa  Elisa" },
      { id: "1888", nombre: "Villa Adela" },
      { id: "2042", nombre: "Villa Clara" },
      { id: "1926", nombre: "Villa Del Rosario" },
      { id: "2043", nombre: "Villa Dominguez" },
      { id: "2001", nombre: "Villa Fontana" },
      { id: "2002", nombre: "Villa Gdor. Luis F. Etchevehere" },
      { id: "1914", nombre: "Villa Libertador San Martin" },
      { id: "2028", nombre: "Villa Mantero" },
      { id: "1955", nombre: "Villa Paranacito" },
      { id: "2029", nombre: "Villa San Justo" },
      { id: "2030", nombre: "Villa San Marcial (Est. Gobernador Urquiza)" },
      { id: "2003", nombre: "Villa Urquiza" },
      { id: "1889", nombre: "Villa Zorraquin" },
      { id: "2044", nombre: "Villaguay" },
      { id: "1972", nombre: "Xx De Setiembre" },
      { id: "2024", nombre: "1º De Mayo" }
    ]
  },
  {
    id: 34,
    nombre: "Formosa",
    ciudades: [
      { id: "2057", nombre: "Banco Payagua" },
      { id: "2064", nombre: "Bartolome De Las Casas" },
      { id: "2081", nombre: "Buena Vista" },
      { id: "2087", nombre: "Clorinda" },
      { id: "2095", nombre: "Colonia Campo Villafañe" },
      { id: "2049", nombre: "Colonia Pastoril" },
      { id: "2065", nombre: "Colonia Sarmiento" },
      { id: "2066", nombre: "Comandante Fontana" },
      { id: "2096", nombre: "El Colorado" },
      { id: "2082", nombre: "El Espinillo" },
      { id: "2100", nombre: "El Potrillo" },
      { id: "2067", nombre: "El Recreo" },
      { id: "2068", nombre: "Estanislao Del Campo" },
      { id: "2050", nombre: "Formosa" },
      { id: "2069", nombre: "Fortin Cabo 1º Lugones" },
      { id: "2070", nombre: "Fortin Sargento 1º Leyes" },
      { id: "2058", nombre: "General Lucio V. Mansilla" },
      { id: "2101", nombre: "General Mosconi" },
      { id: "2051", nombre: "Gran Guardia" },
      { id: "2059", nombre: "Herradura" },
      { id: "2071", nombre: "Ibarreta" },
      { id: "2063", nombre: "Ingeniero Guillermo N. Juarez" },
      { id: "2072", nombre: "Juan G. Bazan" },
      { id: "2088", nombre: "Laguna Blanca" },
      { id: "2083", nombre: "Laguna Gallo" },
      { id: "2089", nombre: "Laguna Naick-Neck" },
      { id: "2045", nombre: "Laguna Yema" },
      { id: "2073", nombre: "Las Lomitas" },
      { id: "2046", nombre: "Los Chiriguanos" },
      { id: "2052", nombre: "Mariano Boedo" },
      { id: "2084", nombre: "Mision Tacaagle" },
      { id: "2053", nombre: "Mojon De Fierro" },
      { id: "2090", nombre: "Palma Sola" },
      { id: "2097", nombre: "Palo Santo" },
      { id: "2098", nombre: "Pirane" },
      { id: "2085", nombre: "Porton Negro" },
      { id: "2074", nombre: "Posta Cambio Zalazar" },
      { id: "2047", nombre: "Pozo De Maza" },
      { id: "2048", nombre: "Pozo Del Mortero" },
      { id: "2075", nombre: "Pozo Del Tigre" },
      { id: "2091", nombre: "Puerto Pilcomayo" },
      { id: "2092", nombre: "Riacho He-He" },
      { id: "2093", nombre: "Riacho Negro" },
      { id: "2060", nombre: "San Francisco De Laishi" },
      { id: "2054", nombre: "San Hilario" },
      { id: "2076", nombre: "San Martin I" },
      { id: "2077", nombre: "San Martin Ii" },
      { id: "2094", nombre: "Siete Palmas" },
      { id: "2078", nombre: "Subteniente Perin" },
      { id: "2061", nombre: "Tatane" },
      { id: "2086", nombre: "Tres Lagunas" },
      { id: "2055", nombre: "Villa Del Carmen" },
      { id: "2062", nombre: "Villa Escolar" },
      { id: "2079", nombre: "Villa General GÜEMES" },
      { id: "2080", nombre: "Villa General Manuel Belgrano" },
      { id: "2099", nombre: "Villa Kilometro 213" },
      { id: "2056", nombre: "Villa Trinidad" }
    ]
  },
  {
    id: 38,
    nombre: "Jujuy",
    ciudades: [
      { id: "2102", nombre: "Abdon Castro Tolay" },
      { id: "2103", nombre: "Abra Pampa" },
      { id: "2104", nombre: "Abralaite" },
      { id: "2112", nombre: "Aguas Calientes" },
      { id: "2165", nombre: "Arrayanal" },
      { id: "2166", nombre: "Arroyo Colorado" },
      { id: "2142", nombre: "Bananal" },
      { id: "2218", nombre: "Barcena" },
      { id: "2115", nombre: "Barrio El Milagro" },
      { id: "2116", nombre: "Barrio La Union" },
      { id: "2120", nombre: "Barrio Tabacaleros" },
      { id: "2229", nombre: "Barrios" },
      { id: "2143", nombre: "Bermejito" },
      { id: "2144", nombre: "Caimancito" },
      { id: "2145", nombre: "Calilegua" },
      { id: "2230", nombre: "Cangrejillos" },
      { id: "2153", nombre: "Carahunco" },
      { id: "2105", nombre: "Casabindo" },
      { id: "2191", nombre: "Casira" },
      { id: "2223", nombre: "Caspala" },
      { id: "2202", nombre: "Catua" },
      { id: "2154", nombre: "Centro Forestal" },
      { id: "2146", nombre: "Chalican" },
      { id: "2192", nombre: "Cienega" },
      { id: "2193", nombre: "Cieneguillas" },
      { id: "2106", nombre: "Cochinoca" },
      { id: "2136", nombre: "Coctaca" },
      { id: "2212", nombre: "Colonia San Jose" },
      { id: "2203", nombre: "Coranzuli" },
      { id: "2194", nombre: "Cusi Cusi" },
      { id: "2167", nombre: "Don Emilio" },
      { id: "2168", nombre: "El Acheral" },
      { id: "2137", nombre: "El Aguilar" },
      { id: "2117", nombre: "El Carmen" },
      { id: "2162", nombre: "El Ceibal" },
      { id: "2231", nombre: "El Condor" },
      { id: "2184", nombre: "El Fuerte" },
      { id: "2219", nombre: "El Moreno" },
      { id: "2185", nombre: "El Piquete" },
      { id: "2169", nombre: "El Puesto" },
      { id: "2170", nombre: "El Quemado" },
      { id: "2186", nombre: "El Talar" },
      { id: "2204", nombre: "El Toro" },
      { id: "2113", nombre: "Fleming" },
      { id: "2147", nombre: "Fraile Pintado" },
      { id: "2127", nombre: "Guerrero" },
      { id: "2138", nombre: "Hipolito Yrigoyen (Est. Iturbe)" },
      { id: "2214", nombre: "Huacalera" },
      { id: "2205", nombre: "Huancar" },
      { id: "2139", nombre: "Humahuaca" },
      { id: "2215", nombre: "Juella" },
      { id: "2128", nombre: "La Almona" },
      { id: "2195", nombre: "La Cienega" },
      { id: "2171", nombre: "La Esperanza" },
      { id: "2232", nombre: "La Intermedia" },
      { id: "2172", nombre: "La Manga" },
      { id: "2173", nombre: "La Mendieta" },
      { id: "2233", nombre: "La Quiaca" },
      { id: "2158", nombre: "Lagunillas De Farallon" },
      { id: "2129", nombre: "Leon" },
      { id: "2148", nombre: "Libertad" },
      { id: "2149", nombre: "Libertador Grl. San Martin (Est. Ledesma)" },
      { id: "2159", nombre: "Liviara" },
      { id: "2234", nombre: "Llulluchayoc" },
      { id: "2118", nombre: "Los Lapachos (Est. Maquinista Veron)" },
      { id: "2133", nombre: "Los Nogales" },
      { id: "2163", nombre: "Loteo Navea" },
      { id: "2130", nombre: "Lozano" },
      { id: "2216", nombre: "Maimara" },
      { id: "2150", nombre: "Maiz Negro" },
      { id: "2119", nombre: "Manantiales" },
      { id: "2206", nombre: "Mina Providencia" },
      { id: "2155", nombre: "Mina 9 De Octubre" },
      { id: "2174", nombre: "Miraflores" },
      { id: "2196", nombre: "Misarrumi" },
      { id: "2121", nombre: "Monterrico" },
      { id: "2160", nombre: "Nuevo Pirquitas" },
      { id: "2131", nombre: "Ocloyas" },
      { id: "2207", nombre: "Olaroz Chico" },
      { id: "2197", nombre: "Oratorio" },
      { id: "2198", nombre: "Paicone" },
      { id: "2187", nombre: "Palma Sola" },
      { id: "2175", nombre: "Palos Blancos" },
      { id: "2156", nombre: "Palpala" },
      { id: "2122", nombre: "Pampa Blanca" },
      { id: "2224", nombre: "Pampichuela" },
      { id: "2176", nombre: "Parapeti" },
      { id: "2208", nombre: "Pastos Chicos" },
      { id: "2151", nombre: "Paulina" },
      { id: "2123", nombre: "Perico" },
      { id: "2177", nombre: "Piedritas" },
      { id: "2114", nombre: "Pila Pardo" },
      { id: "2188", nombre: "Puente Lavayen" },
      { id: "2107", nombre: "Puesto Del Marquez" },
      { id: "2209", nombre: "Puesto Sey" },
      { id: "2124", nombre: "Puesto Viejo" },
      { id: "2235", nombre: "Pumahuasi" },
      { id: "2220", nombre: "Purmamarca" },
      { id: "2161", nombre: "Rinconada" },
      { id: "2108", nombre: "Rinconadillas" },
      { id: "2157", nombre: "Rio Blanco" },
      { id: "2178", nombre: "Rodeito" },
      { id: "2179", nombre: "Rosario De Rio Grande" },
      { id: "2164", nombre: "San Antonio" },
      { id: "2180", nombre: "San Antonio" },
      { id: "2225", nombre: "San Francisco" },
      { id: "2109", nombre: "San Francisco De Alfarcito" },
      { id: "2125", nombre: "San Isidro" },
      { id: "2236", nombre: "San Jose" },
      { id: "2199", nombre: "San Juan De Oros" },
      { id: "2210", nombre: "San Juan De Quillaques" },
      { id: "2126", nombre: "San Juancito" },
      { id: "2181", nombre: "San Lucas" },
      { id: "2134", nombre: "San Pablo De Reyes" },
      { id: "2182", nombre: "San Pedro (Est. San Pedro De Jujuy)" },
      { id: "2132", nombre: "San Salvador De Jujuy (Est. Jujuy)" },
      { id: "2226", nombre: "Santa Ana" },
      { id: "2200", nombre: "Santa Catalina" },
      { id: "2189", nombre: "Santa Clara" },
      { id: "2110", nombre: "Santuario De Tres Pozos" },
      { id: "2183", nombre: "Sauzal" },
      { id: "2211", nombre: "Susques" },
      { id: "2217", nombre: "Tilcara" },
      { id: "2140", nombre: "Tres Cruces" },
      { id: "2221", nombre: "Tumbaya" },
      { id: "2111", nombre: "Tusaquillas" },
      { id: "2141", nombre: "Uquia (Est. Senador Perez)" },
      { id: "2227", nombre: "Valle Colorado" },
      { id: "2228", nombre: "Valle Grande" },
      { id: "2190", nombre: "Vinalito" },
      { id: "2222", nombre: "Volcan" },
      { id: "2213", nombre: "Yacoraite" },
      { id: "2135", nombre: "Yala" },
      { id: "2237", nombre: "Yavi" },
      { id: "2238", nombre: "Yavi Chico" },
      { id: "2201", nombre: "Yoscaba" },
      { id: "2152", nombre: "Yuto" }
    ]
  },
  {
    id: 42,
    nombre: "La Pampa",
    ciudades: [
      { id: "2274", nombre: "Abramo" },
      { id: "2307", nombre: "Adolfo Van Praet" },
      { id: "2287", nombre: "Agustoni" },
      { id: "2267", nombre: "Algarrobo Del Aguila" },
      { id: "2269", nombre: "Alpachiri" },
      { id: "2308", nombre: "Alta Italia" },
      { id: "2246", nombre: "Anguil" },
      { id: "2244", nombre: "Anzoategui" },
      { id: "2317", nombre: "Arata" },
      { id: "2320", nombre: "Ataliva Roca" },
      { id: "2261", nombre: "Bernardo Larroude" },
      { id: "2275", nombre: "Bernasconi" },
      { id: "2300", nombre: "Caleufu" },
      { id: "2282", nombre: "Carro Quemado" },
      { id: "2248", nombre: "Catrilo" },
      { id: "2262", nombre: "Ceballos" },
      { id: "2321", nombre: "Chacharramendi" },
      { id: "2294", nombre: "Colonia Baron" },
      { id: "2295", nombre: "Colonia San Jose" },
      { id: "2322", nombre: "Colonia Santa Maria" },
      { id: "2252", nombre: "Conhelo" },
      { id: "2263", nombre: "Coronel Hilario Lagos  (Est. Aguas Buenas)" },
      { id: "2279", nombre: "Cuchillo Co" },
      { id: "2309", nombre: "Damian Maisonave (Est. Simson)" },
      { id: "2239", nombre: "Doblas" },
      { id: "2288", nombre: "Dorila" },
      { id: "2253", nombre: "Eduardo Castex" },
      { id: "2310", nombre: "Embajador Martini" },
      { id: "2311", nombre: "Falucho" },
      { id: "2323", nombre: "General Acha" },
      { id: "2270", nombre: "General Manuel J. Campos" },
      { id: "2289", nombre: "General Pico" },
      { id: "2276", nombre: "General San Martin (Est. Villa Alba)" },
      { id: "2258", nombre: "Gobernador Duval" },
      { id: "2271", nombre: "Guatrache" },
      { id: "2277", nombre: "Hucal" },
      { id: "2301", nombre: "Ingeniero Foster" },
      { id: "2312", nombre: "Ingeniero Luiggi" },
      { id: "2264", nombre: "Intendente Alvear" },
      { id: "2278", nombre: "Jacinto Arauz" },
      { id: "2245", nombre: "La Adela" },
      { id: "2249", nombre: "La Gloria" },
      { id: "2268", nombre: "La Humada" },
      { id: "2302", nombre: "La Maruja" },
      { id: "2280", nombre: "La Reforma" },
      { id: "2281", nombre: "Limay Mahuida" },
      { id: "2250", nombre: "Lonquimay" },
      { id: "2283", nombre: "Loventue" },
      { id: "2284", nombre: "Luan Toro" },
      { id: "2240", nombre: "Macachin" },
      { id: "2254", nombre: "Mauricio Mayer" },
      { id: "2318", nombre: "Metileo" },
      { id: "2296", nombre: "Miguel Cane" },
      { id: "2241", nombre: "Miguel Riglos" },
      { id: "2255", nombre: "Monte Nievas" },
      { id: "2315", nombre: "Naico" },
      { id: "2313", nombre: "Ojeda" },
      { id: "2303", nombre: "Parera" },
      { id: "2272", nombre: "Peru" },
      { id: "2304", nombre: "Pichi Huinca" },
      { id: "2259", nombre: "Puelches" },
      { id: "2292", nombre: "Puelen" },
      { id: "2324", nombre: "Quehue" },
      { id: "2297", nombre: "Quemu Quemu" },
      { id: "2305", nombre: "Quetrequen" },
      { id: "2306", nombre: "Rancul" },
      { id: "2314", nombre: "Realico" },
      { id: "2298", nombre: "Relmo" },
      { id: "2242", nombre: "Rolon" },
      { id: "2256", nombre: "Rucanelo" },
      { id: "2260", nombre: "Santa Isabel" },
      { id: "2247", nombre: "Santa Rosa" },
      { id: "2273", nombre: "Santa Teresa" },
      { id: "2265", nombre: "Sarah" },
      { id: "2290", nombre: "Speluzzi" },
      { id: "2285", nombre: "Telen" },
      { id: "2316", nombre: "Toay" },
      { id: "2243", nombre: "Tomas M. Anchorena" },
      { id: "2291", nombre: "Trebolares" },
      { id: "2319", nombre: "Trenel" },
      { id: "2325", nombre: "Unanue" },
      { id: "2251", nombre: "Uriburu" },
      { id: "2266", nombre: "Vertiz" },
      { id: "2286", nombre: "Victorica" },
      { id: "2299", nombre: "Villa Mirasol" },
      { id: "2257", nombre: "Winifreda" },
      { id: "2293", nombre: "25 De Mayo" }
    ]
  },
  {
    id: 46,
    nombre: "La Rioja",
    ciudades: [
      { id: "2342", nombre: "Aicuña" },
      { id: "2326", nombre: "Aimogasta" },
      { id: "2406", nombre: "Alpasinche" },
      { id: "2369", nombre: "Alto Carrizal" },
      { id: "2399", nombre: "Alto JagÜE" },
      { id: "2402", nombre: "Amana" },
      { id: "2392", nombre: "Ambil" },
      { id: "2334", nombre: "Aminga" },
      { id: "2407", nombre: "Amuschina" },
      { id: "2408", nombre: "Andolucas" },
      { id: "2351", nombre: "Anguinan" },
      { id: "2370", nombre: "Angulos" },
      { id: "2335", nombre: "Anillaco" },
      { id: "2336", nombre: "Anjullon" },
      { id: "2371", nombre: "Antinaco" },
      { id: "2329", nombre: "Arauco" },
      { id: "2372", nombre: "Bajo Carrizal" },
      { id: "2400", nombre: "Bajo JagÜE" },
      { id: "2347", nombre: "Banda Florida" },
      { id: "2330", nombre: "Bañado De Los Pantanos" },
      { id: "2373", nombre: "Campanas" },
      { id: "2383", nombre: "Castro Barros" },
      { id: "2349", nombre: "Chamical" },
      { id: "2384", nombre: "Chañar" },
      { id: "2374", nombre: "Chañarmuyo" },
      { id: "2409", nombre: "Chaupihuasi" },
      { id: "2404", nombre: "Chepes" },
      { id: "2352", nombre: "Chilecito" },
      { id: "2337", nombre: "Chuquis" },
      { id: "2356", nombre: "Colonia Anguinan" },
      { id: "2357", nombre: "Colonia Catinzaco" },
      { id: "2358", nombre: "Colonia Malligasta" },
      { id: "2393", nombre: "Colonia Ortiz De Ocampo" },
      { id: "2359", nombre: "Colonia Vichigasta" },
      { id: "2410", nombre: "Cuipan" },
      { id: "2405", nombre: "Desiderio Tello" },
      { id: "2331", nombre: "Estacion Mazan" },
      { id: "2375", nombre: "Famatina" },
      { id: "2360", nombre: "Guanchin" },
      { id: "2343", nombre: "Guandacol" },
      { id: "2398", nombre: "JagÜE" },
      { id: "2376", nombre: "La Cuadra" },
      { id: "2353", nombre: "La Puntilla" },
      { id: "2333", nombre: "La Rioja" },
      { id: "2411", nombre: "Las Talas" },
      { id: "2385", nombre: "Loma Blanca" },
      { id: "2338", nombre: "Los Molinos" },
      { id: "2345", nombre: "Los Palacios" },
      { id: "2412", nombre: "Los Robles" },
      { id: "2354", nombre: "Los Sarmientos" },
      { id: "2327", nombre: "Machigasta" },
      { id: "2387", nombre: "Malanzan" },
      { id: "2361", nombre: "Malligasta" },
      { id: "2394", nombre: "Milagro" },
      { id: "2362", nombre: "Miranda" },
      { id: "2388", nombre: "Nacate" },
      { id: "2363", nombre: "Nonogasta" },
      { id: "2395", nombre: "Olpas" },
      { id: "2386", nombre: "Olta" },
      { id: "2346", nombre: "Pagancillo" },
      { id: "2403", nombre: "Patquia" },
      { id: "2339", nombre: "Pinchas" },
      { id: "2377", nombre: "Pituil" },
      { id: "2378", nombre: "Plaza Vieja" },
      { id: "2350", nombre: "Polco" },
      { id: "2389", nombre: "Portezuelo" },
      { id: "2381", nombre: "Punta De Los Llanos" },
      { id: "2413", nombre: "Salicas" },
      { id: "2328", nombre: "San Antonio" },
      { id: "2390", nombre: "San Antonio" },
      { id: "2414", nombre: "San Blas" },
      { id: "2355", nombre: "San Miguel" },
      { id: "2364", nombre: "San Nicolas" },
      { id: "2340", nombre: "San Pedro" },
      { id: "2344", nombre: "Santa Clara" },
      { id: "2379", nombre: "Santa Cruz" },
      { id: "2365", nombre: "Santa Florentina" },
      { id: "2396", nombre: "Santa Rita De Catuna" },
      { id: "2341", nombre: "Santa Vera Cruz" },
      { id: "2380", nombre: "Santo Domingo" },
      { id: "2366", nombre: "Sañogasta" },
      { id: "2415", nombre: "Shaqui" },
      { id: "2416", nombre: "Suriyaco" },
      { id: "2382", nombre: "Tama" },
      { id: "2367", nombre: "Tilimuqui" },
      { id: "2417", nombre: "Tuyubil" },
      { id: "2397", nombre: "Ulapes" },
      { id: "2368", nombre: "Vichigasta" },
      { id: "2391", nombre: "Villa Castelli" },
      { id: "2332", nombre: "Villa Mazan" },
      { id: "2401", nombre: "Villa San Jose De Vinchina" },
      { id: "2418", nombre: "Villa Sanagasta" },
      { id: "2348", nombre: "Villa Union" }
    ]
  },
  {
    id: 50,
    nombre: "Mendoza",
    ciudades: [
      { id: "2496", nombre: "Agrelo" },
      { id: "2534", nombre: "Agua Escondida" },
      { id: "2560", nombre: "Alto Salvador" },
      { id: "2561", nombre: "Alto Verde" },
      { id: "2538", nombre: "Andrade" },
      { id: "2535", nombre: "Bardas Blancas" },
      { id: "2521", nombre: "Barrancas" },
      { id: "2516", nombre: "Barrio Adina I Y Ii" },
      { id: "2479", nombre: "Barrio Alto Del Olvido" },
      { id: "2614", nombre: "Barrio Belgrano Norte" },
      { id: "2572", nombre: "Barrio Campos El Toledano" },
      { id: "2562", nombre: "Barrio Chivilcoy" },
      { id: "2539", nombre: "Barrio Cooperativa Los Campamentos" },
      { id: "2578", nombre: "Barrio Echeverria" },
      { id: "2554", nombre: "Barrio El Cepillo" },
      { id: "2573", nombre: "Barrio El Nevado" },
      { id: "2563", nombre: "Barrio Emanuel" },
      { id: "2574", nombre: "Barrio Empleados De Comercio" },
      { id: "2575", nombre: "Barrio Intendencia" },
      { id: "2522", nombre: "Barrio Jesus De Nazaret" },
      { id: "2480", nombre: "Barrio Jocoli Ii" },
      { id: "2489", nombre: "Barrio La Esperanza" },
      { id: "2481", nombre: "Barrio La Palmera" },
      { id: "2482", nombre: "Barrio La Pega" },
      { id: "2483", nombre: "Barrio Lagunas De Bartoluzzi" },
      { id: "2579", nombre: "Barrio Las Rosas" },
      { id: "2564", nombre: "Barrio Los Charabones" },
      { id: "2484", nombre: "Barrio Los Jarilleros" },
      { id: "2485", nombre: "Barrio Los Olivos" },
      { id: "2602", nombre: "Barrio Maria Auxiliadora" },
      { id: "2603", nombre: "Barrio Molina Cabrera" },
      { id: "2497", nombre: "Barrio Perdriel Iv" },
      { id: "2580", nombre: "Barrio Primavera" },
      { id: "2540", nombre: "Barrio Rivadavia" },
      { id: "2607", nombre: "Barrio San Cayetano" },
      { id: "2601", nombre: "Barrio 12 De Octubre" },
      { id: "2431", nombre: "Bermejo" },
      { id: "2458", nombre: "Blanco Encalada" },
      { id: "2420", nombre: "Bowen" },
      { id: "2432", nombre: "Buena Nueva" },
      { id: "2498", nombre: "Cacheuta" },
      { id: "2608", nombre: "Campo Los Andes" },
      { id: "2462", nombre: "Capdevila" },
      { id: "2459", nombre: "Capdeville" },
      { id: "2433", nombre: "Capilla Del Rosario" },
      { id: "2576", nombre: "Capitan Montoya" },
      { id: "2421", nombre: "Carmensa" },
      { id: "2510", nombre: "Carrodilla" },
      { id: "2511", nombre: "Chacras De Coria" },
      { id: "2565", nombre: "Chapanay" },
      { id: "2555", nombre: "Chilecito" },
      { id: "2566", nombre: "Chivilcoy" },
      { id: "2570", nombre: "Ciudad De San Martin" },
      { id: "2597", nombre: "Ciudad De San Rafael" },
      { id: "2609", nombre: "Colonia Las Rosas" },
      { id: "2429", nombre: "Colonia Segovia (Est. Amigorena)" },
      { id: "2526", nombre: "Coquimbito" },
      { id: "2615", nombre: "Cordon Del Plata" },
      { id: "2486", nombre: "Costa De Araujo" },
      { id: "2499", nombre: "Costa Flores" },
      { id: "2523", nombre: "Cruz De Piedra" },
      { id: "2577", nombre: "Cuadro Benegas" },
      { id: "2595", nombre: "Cuadro Nacional" },
      { id: "2544", nombre: "Cuadro Ortega" },
      { id: "2455", nombre: "Desaguadero" },
      { id: "2434", nombre: "Dorrego" },
      { id: "2464", nombre: "El Algarrobal" },
      { id: "2465", nombre: "El Borbollon" },
      { id: "2501", nombre: "El Carmelo" },
      { id: "2500", nombre: "El Carrizal" },
      { id: "2466", nombre: "El Challao" },
      { id: "2610", nombre: "El Manzano" },
      { id: "2541", nombre: "El Mirador" },
      { id: "2581", nombre: "El Nihuil" },
      { id: "2487", nombre: "El Paramillo" },
      { id: "2467", nombre: "El Pastal" },
      { id: "2524", nombre: "El Pedregal" },
      { id: "2616", nombre: "El Peral" },
      { id: "2468", nombre: "El Plumerillo" },
      { id: "2469", nombre: "El Resguardo" },
      { id: "2502", nombre: "El Salto" },
      { id: "2435", nombre: "El Sauce" },
      { id: "2582", nombre: "El Sosneado" },
      { id: "2583", nombre: "El Tropezon" },
      { id: "2488", nombre: "El Vergel" },
      { id: "2470", nombre: "El Zapallar" },
      { id: "2556", nombre: "Eugenio Bustos" },
      { id: "2525", nombre: "Fray Luis Beltran" },
      { id: "2422", nombre: "General Alvear (Est.Colonia Alvear Norte)" },
      { id: "2436", nombre: "General Belgrano" },
      { id: "2527", nombre: "General Gutierrez" },
      { id: "2425", nombre: "Gobernador Benegas" },
      { id: "2424", nombre: "Godoy Cruz" },
      { id: "2584", nombre: "Goudge" },
      { id: "2430", nombre: "Guaymallen" },
      { id: "2448", nombre: "Ingeniero Giagnoni" },
      { id: "2490", nombre: "Ingeniero Gustavo Andre" },
      { id: "2585", nombre: "Jaime Prats" },
      { id: "2437", nombre: "Jesus Nazareno" },
      { id: "2460", nombre: "Jocoli" },
      { id: "2491", nombre: "Jocoli" },
      { id: "2492", nombre: "Jocoli Viejo" },
      { id: "2449", nombre: "Junin" },
      { id: "2617", nombre: "La Arboleda" },
      { id: "2542", nombre: "La Central" },
      { id: "2471", nombre: "La Cieneguita" },
      { id: "2450", nombre: "La Colonia" },
      { id: "2557", nombre: "La Consulta" },
      { id: "2604", nombre: "La Dormida" },
      { id: "2543", nombre: "La Esperanza" },
      { id: "2545", nombre: "La Florida" },
      { id: "2546", nombre: "La Libertad" },
      { id: "2586", nombre: "La Llave Nueva" },
      { id: "2456", nombre: "La Paz" },
      { id: "2445", nombre: "La Primavera" },
      { id: "2513", nombre: "La Puntilla" },
      { id: "2438", nombre: "Las Cañas" },
      { id: "2503", nombre: "Las Carditas" },
      { id: "2605", nombre: "Las Catitas (Est. J. N. Lencinas)" },
      { id: "2505", nombre: "Las Compuertas" },
      { id: "2461", nombre: "Las Cuevas" },
      { id: "2463", nombre: "Las Heras" },
      { id: "2536", nombre: "Las Leñas" },
      { id: "2587", nombre: "Las Malvinas" },
      { id: "2596", nombre: "Las Paredes" },
      { id: "2426", nombre: "Las Tortugas" },
      { id: "2506", nombre: "Las Vegas" },
      { id: "2493", nombre: "Las Violetas" },
      { id: "2547", nombre: "Los Arboles" },
      { id: "2451", nombre: "Los Barriales" },
      { id: "2548", nombre: "Los Campamentos" },
      { id: "2423", nombre: "Los Compartos" },
      { id: "2446", nombre: "Los Corralitos" },
      { id: "2504", nombre: "Los Manantiales" },
      { id: "2473", nombre: "Los Penitentes" },
      { id: "2588", nombre: "Los Reyunos" },
      { id: "2611", nombre: "Los Sauces" },
      { id: "2512", nombre: "Lujan De Cuyo" },
      { id: "2528", nombre: "Luzuriaga" },
      { id: "2529", nombre: "Maipu" },
      { id: "2537", nombre: "MalargÜE" },
      { id: "2514", nombre: "Mayor Drummond" },
      { id: "2549", nombre: "Medrano" },
      { id: "2452", nombre: "Medrano" },
      { id: "2419", nombre: "Mendoza" },
      { id: "2589", nombre: "Monte Coman" },
      { id: "2567", nombre: "Montecaseros" },
      { id: "2550", nombre: "Mundo Nuevo" },
      { id: "2568", nombre: "Nueva California (Est. Moluches)" },
      { id: "2439", nombre: "Nueva Ciudad" },
      { id: "2569", nombre: "Palmira" },
      { id: "2472", nombre: "Panquehua" },
      { id: "2474", nombre: "Papagayos" },
      { id: "2558", nombre: "Pareditas" },
      { id: "2440", nombre: "Pedro Molina" },
      { id: "2517", nombre: "Perdriel" },
      { id: "2453", nombre: "Phillips" },
      { id: "2507", nombre: "Piedras Blancas" },
      { id: "2590", nombre: "Pobre Diablo" },
      { id: "2475", nombre: "Polvaredas" },
      { id: "2519", nombre: "Potrerillos" },
      { id: "2427", nombre: "Presidente Sarmiento" },
      { id: "2447", nombre: "Puente De Hierro" },
      { id: "2476", nombre: "Puente Del Inca" },
      { id: "2477", nombre: "Punta De Vacas" },
      { id: "2591", nombre: "Punta Del Agua" },
      { id: "2592", nombre: "Rama Caida" },
      { id: "2593", nombre: "Real Del Padre" },
      { id: "2551", nombre: "Reduccion De Abajo" },
      { id: "2552", nombre: "Rivadavia" },
      { id: "2441", nombre: "Rodeo De La Cruz" },
      { id: "2530", nombre: "Rodeo Del Medio" },
      { id: "2454", nombre: "Rodriguez Peña" },
      { id: "2531", nombre: "Russell" },
      { id: "2594", nombre: "Salto De Las Rosas" },
      { id: "2559", nombre: "San Carlos" },
      { id: "2442", nombre: "San Francisco Del Monte" },
      { id: "2428", nombre: "San Francisco Del Monte" },
      { id: "2618", nombre: "San Jose" },
      { id: "2443", nombre: "San Jose" },
      { id: "2532", nombre: "San Roque" },
      { id: "2553", nombre: "Santa Maria De Oro" },
      { id: "2606", nombre: "Santa Rosa" },
      { id: "2518", nombre: "Tres Esquinas" },
      { id: "2571", nombre: "Tres Porteñas" },
      { id: "2612", nombre: "Tunuyan" },
      { id: "2619", nombre: "Tupungato" },
      { id: "2520", nombre: "Ugarteche" },
      { id: "2478", nombre: "Uspallata" },
      { id: "2508", nombre: "Valle Del Sol" },
      { id: "2457", nombre: "Villa Antigua" },
      { id: "2599", nombre: "Villa Atuel" },
      { id: "2600", nombre: "Villa Atuel Norte" },
      { id: "2620", nombre: "Villa Bastias" },
      { id: "2509", nombre: "Villa El Refugio" },
      { id: "2444", nombre: "Villa Nueva" },
      { id: "2533", nombre: "Villa Teresa" },
      { id: "2495", nombre: "Villa Tulumaya" },
      { id: "2613", nombre: "Vista Flores" },
      { id: "2515", nombre: "Vistalba" },
      { id: "2598", nombre: "25 De Mayo" },
      { id: "2494", nombre: "3 De Mayo" }
    ]
  },
  {
    id: 54,
    nombre: "Misiones",
    ciudades: [
      { id: "2733", nombre: "Alba Posse" },
      { id: "2700", nombre: "Alberdi" },
      { id: "2673", nombre: "Almafuerte" },
      { id: "2661", nombre: "Almirante Brown" },
      { id: "2621", nombre: "Apostoles" },
      { id: "2628", nombre: "Aristobulo Del Valle" },
      { id: "2674", nombre: "Arroyo Del Medio" },
      { id: "2622", nombre: "Azara" },
      { id: "2647", nombre: "Barra Concepcion" },
      { id: "2662", nombre: "Bernardo De Irigoyen" },
      { id: "2636", nombre: "Bonpland" },
      { id: "2675", nombre: "Caa - Yari" },
      { id: "2629", nombre: "Campo Grande" },
      { id: "2701", nombre: "Campo Ramon" },
      { id: "2702", nombre: "Campo Viera" },
      { id: "2637", nombre: "Candelaria" },
      { id: "2682", nombre: "Capiovi" },
      { id: "2693", nombre: "Caraguatay" },
      { id: "2676", nombre: "Cerro Azul" },
      { id: "2638", nombre: "Cerro Cora" },
      { id: "2734", nombre: "Colonia Alicia" },
      { id: "2735", nombre: "Colonia Aurora" },
      { id: "2712", nombre: "Colonia Polana" },
      { id: "2651", nombre: "Colonia Victoria" },
      { id: "2648", nombre: "Concepcion De La Sierra" },
      { id: "2713", nombre: "Corpus" },
      { id: "2728", nombre: "Cruce Caballero" },
      { id: "2714", nombre: "Domingo Savio" },
      { id: "2677", nombre: "Dos Arroyos" },
      { id: "2630", nombre: "Dos De Mayo Nucleo I" },
      { id: "2631", nombre: "Dos De Mayo Nucleo Ii" },
      { id: "2632", nombre: "Dos De Mayo Nucleo Iii" },
      { id: "2663", nombre: "Dos Hermanas" },
      { id: "2683", nombre: "El Alcazar" },
      { id: "2703", nombre: "El Salto" },
      { id: "2666", nombre: "El Soberbio" },
      { id: "2652", nombre: "Eldorado" },
      { id: "2669", nombre: "Esperanza" },
      { id: "2623", nombre: "Estacion Apostoles" },
      { id: "2724", nombre: "Florentino Ameghino" },
      { id: "2667", nombre: "Fracran" },
      { id: "2684", nombre: "Garuhape" },
      { id: "2644", nombre: "Garupa" },
      { id: "2704", nombre: "General Alvear" },
      { id: "2715", nombre: "General Urquiza" },
      { id: "2678", nombre: "Gobernador Lopez" },
      { id: "2716", nombre: "Gobernador Roca" },
      { id: "2705", nombre: "Guarani" },
      { id: "2717", nombre: "Helvecia" },
      { id: "2718", nombre: "Hipolito Yrigoyen" },
      { id: "2664", nombre: "Integracion" },
      { id: "2725", nombre: "Itacaruare" },
      { id: "2719", nombre: "Jardin America" },
      { id: "2649", nombre: "La Corita" },
      { id: "2694", nombre: "Laharrague" },
      { id: "2679", nombre: "Leandro N. Alem" },
      { id: "2670", nombre: "Libertad" },
      { id: "2639", nombre: "Loreto" },
      { id: "2706", nombre: "Los Helechos" },
      { id: "2653", nombre: "Maria Magdalena" },
      { id: "2640", nombre: "Martires" },
      { id: "2685", nombre: "Mbopicua" },
      { id: "2726", nombre: "Mojon Grande" },
      { id: "2695", nombre: "Montecarlo" },
      { id: "2645", nombre: "Nemesio Parma" },
      { id: "2720", nombre: "Oasis" },
      { id: "2707", nombre: "Obera" },
      { id: "2680", nombre: "Olegario V. Andrade" },
      { id: "2708", nombre: "Panambi" },
      { id: "2709", nombre: "Panambi Kilometro 8" },
      { id: "2729", nombre: "Paraiso" },
      { id: "2624", nombre: "Pindapoy" },
      { id: "2730", nombre: "Piñalito Sur" },
      { id: "2696", nombre: "Piray Kilometro 18" },
      { id: "2646", nombre: "Posadas" },
      { id: "2641", nombre: "Profundidad" },
      { id: "2634", nombre: "Pueblo Illia" },
      { id: "2671", nombre: "Puerto Iguazu" },
      { id: "2686", nombre: "Puerto Leoni" },
      { id: "2656", nombre: "Puerto Mado" },
      { id: "2657", nombre: "Puerto Pinares" },
      { id: "2697", nombre: "Puerto Piray" },
      { id: "2687", nombre: "Puerto Rico" },
      { id: "2642", nombre: "Puerto Santa Ana" },
      { id: "2625", nombre: "Rincon De Azara" },
      { id: "2721", nombre: "Roca Chica" },
      { id: "2688", nombre: "Ruiz De Montoya" },
      { id: "2635", nombre: "Salto Encantado" },
      { id: "2689", nombre: "San Alberto" },
      { id: "2665", nombre: "San Antonio" },
      { id: "2736", nombre: "San Francisco De Asis" },
      { id: "2690", nombre: "San Gotardo" },
      { id: "2722", nombre: "San Ignacio" },
      { id: "2727", nombre: "San Javier" },
      { id: "2626", nombre: "San Jose" },
      { id: "2710", nombre: "San Martin" },
      { id: "2691", nombre: "San Miguel" },
      { id: "2731", nombre: "San Pedro" },
      { id: "2668", nombre: "San Vicente" },
      { id: "2643", nombre: "Santa Ana" },
      { id: "2650", nombre: "Santa Maria" },
      { id: "2737", nombre: "Santa Rita" },
      { id: "2658", nombre: "Santiago De Liniers" },
      { id: "2723", nombre: "Santo Pipo" },
      { id: "2698", nombre: "Taruma" },
      { id: "2732", nombre: "Tobuna" },
      { id: "2627", nombre: "Tres Capones" },
      { id: "2659", nombre: "Valle Hermoso" },
      { id: "2692", nombre: "Villa Akerman" },
      { id: "2711", nombre: "Villa Bonita" },
      { id: "2681", nombre: "Villa Libertad" },
      { id: "2699", nombre: "Villa Parodi" },
      { id: "2660", nombre: "Villa Roulet" },
      { id: "2672", nombre: "Wanda" },
      { id: "2633", nombre: "1º De Mayo" },
      { id: "2738", nombre: "25 De Mayo" },
      { id: "2654", nombre: "9 De Julio" },
      { id: "2655", nombre: "9 De Julio Kilometro 20" }
    ]
  },
  {
    id: 58,
    nombre: "Neuquen",
    ciudades: [
      { id: "2739", nombre: "Alumine" },
      { id: "2769", nombre: "Andacollo" },
      { id: "2741", nombre: "Añelo" },
      { id: "2746", nombre: "Arroyito" },
      { id: "2786", nombre: "Bajada Del Agrio" },
      { id: "2780", nombre: "Barrancas" },
      { id: "2747", nombre: "Barrio Ruca Luhe" },
      { id: "2781", nombre: "Buta Ranquil" },
      { id: "2775", nombre: "Caviahue" },
      { id: "2748", nombre: "Centenario" },
      { id: "2765", nombre: "Chorriaca" },
      { id: "2760", nombre: "Chos Malal" },
      { id: "2776", nombre: "Copahue" },
      { id: "2791", nombre: "Covunco Centro" },
      { id: "2749", nombre: "Cutral Co" },
      { id: "2750", nombre: "El Chocon" },
      { id: "2777", nombre: "El Cholar" },
      { id: "2778", nombre: "El Huecu" },
      { id: "2770", nombre: "Huinganco" },
      { id: "2763", nombre: "Junin De Los Andes" },
      { id: "2787", nombre: "La Buitrera" },
      { id: "2743", nombre: "Las Coloradas" },
      { id: "2788", nombre: "Las Lajas" },
      { id: "2771", nombre: "Las Ovejas" },
      { id: "2766", nombre: "Loncopue" },
      { id: "2790", nombre: "Los Catutos" },
      { id: "2772", nombre: "Los Miches" },
      { id: "2773", nombre: "Manzano Amargo" },
      { id: "2751", nombre: "Mari Menuco" },
      { id: "2792", nombre: "Mariano Moreno" },
      { id: "2752", nombre: "Neuquen" },
      { id: "2782", nombre: "Octavio Pico" },
      { id: "2784", nombre: "Paso Aguerre" },
      { id: "2785", nombre: "Picun Leufu" },
      { id: "2744", nombre: "Piedra Del Aguila" },
      { id: "2754", nombre: "Plaza Huincul" },
      { id: "2755", nombre: "Plottier" },
      { id: "2789", nombre: "Quili Malal" },
      { id: "2793", nombre: "Ramon M. Castro" },
      { id: "2783", nombre: "Rincon De Los Sauces" },
      { id: "2764", nombre: "San Martin De Los Andes" },
      { id: "2742", nombre: "San Patricio Del Chañar" },
      { id: "2745", nombre: "Santo Tomas" },
      { id: "2756", nombre: "Senillosa" },
      { id: "2779", nombre: "Taquimilan" },
      { id: "2761", nombre: "Tricao Malal" },
      { id: "2774", nombre: "Varvarco" },
      { id: "2762", nombre: "Villa Del Curi Leuvu" },
      { id: "2757", nombre: "Villa El Chocon" },
      { id: "2767", nombre: "Villa La Angostura" },
      { id: "2740", nombre: "Villa Pehuenia" },
      { id: "2768", nombre: "Villa Traful" },
      { id: "2758", nombre: "Vista Alegre Norte" },
      { id: "2759", nombre: "Vista Alegre Sur" },
      { id: "2794", nombre: "Zapala" },
      { id: "2753", nombre: "11 De Octubre" }
    ]
  },
  {
    id: 62,
    nombre: "Rio Negro",
    ciudades: [
      { id: "2921", nombre: "Aguada Cecilio" },
      { id: "2927", nombre: "Aguada De Guerra" },
      { id: "2825", nombre: "Aguada Guzman" },
      { id: "2833", nombre: "Allen" },
      { id: "2922", nombre: "Arroyo Los Berros" },
      { id: "2923", nombre: "Arroyo Ventana" },
      { id: "2795", nombre: "Bahia Creek" },
      { id: "2834", nombre: "Bajo San Cayetano" },
      { id: "2835", nombre: "Barda Del Medio" },
      { id: "2836", nombre: "Barrio Blanco" },
      { id: "2837", nombre: "Barrio Calle Ciega Nº 10" },
      { id: "2838", nombre: "Barrio Calle Ciega Nº 6" },
      { id: "2839", nombre: "Barrio Canale" },
      { id: "2840", nombre: "Barrio Chacra Monte" },
      { id: "2823", nombre: "Barrio Colonia Conesa" },
      { id: "2841", nombre: "Barrio Costa Este" },
      { id: "2842", nombre: "Barrio Costa Linda" },
      { id: "2843", nombre: "Barrio Costa Oeste" },
      { id: "2844", nombre: "Barrio El Labrador" },
      { id: "2845", nombre: "Barrio El Maruchito" },
      { id: "2846", nombre: "Barrio El Petroleo" },
      { id: "2813", nombre: "Barrio El Pilar" },
      { id: "2863", nombre: "Barrio El Treinta" },
      { id: "2847", nombre: "Barrio Frontera" },
      { id: "2864", nombre: "Barrio Goretti" },
      { id: "2848", nombre: "Barrio Guerrico" },
      { id: "2849", nombre: "Barrio Isla 10" },
      { id: "2850", nombre: "Barrio La Barda" },
      { id: "2851", nombre: "Barrio La Costa" },
      { id: "2852", nombre: "Barrio La Defensa" },
      { id: "2853", nombre: "Barrio La Lor" },
      { id: "2854", nombre: "Barrio La Ribera - Barrio Apycar" },
      { id: "2855", nombre: "Barrio Las Angustias" },
      { id: "2856", nombre: "Barrio Mar Del Plata" },
      { id: "2857", nombre: "Barrio Maria Elvira" },
      { id: "2858", nombre: "Barrio Mosconi" },
      { id: "2859", nombre: "Barrio Norte" },
      { id: "2860", nombre: "Barrio Pino Azul" },
      { id: "2861", nombre: "Barrio Porvenir" },
      { id: "2862", nombre: "Barrio Presidente Peron" },
      { id: "2866", nombre: "Barrio Puente De Madera" },
      { id: "2865", nombre: "Barrio Puente 83" },
      { id: "2868", nombre: "Barrio Santa Rita" },
      { id: "2867", nombre: "Barrio Tres Luces" },
      { id: "2804", nombre: "Barrio Union" },
      { id: "2869", nombre: "Barrio Union" },
      { id: "2870", nombre: "Barrio Virgen De Lujan" },
      { id: "2871", nombre: "Catriel" },
      { id: "2826", nombre: "Cerro Policia" },
      { id: "2872", nombre: "Cervantes" },
      { id: "2805", nombre: "Chelforo" },
      { id: "2873", nombre: "Chichinales" },
      { id: "2806", nombre: "Chimpay" },
      { id: "2807", nombre: "Choele Choel" },
      { id: "2874", nombre: "Cinco Saltos" },
      { id: "2875", nombre: "Cipolletti" },
      { id: "2928", nombre: "Clemente Onelli" },
      { id: "2929", nombre: "Colan Conhue" },
      { id: "2905", nombre: "Colonia Julia Y Echarren" },
      { id: "2814", nombre: "Colonia Suiza" },
      { id: "2907", nombre: "Comallo" },
      { id: "2893", nombre: "Comico" },
      { id: "2894", nombre: "Cona Niyeu" },
      { id: "2876", nombre: "Contralmirante Cordero" },
      { id: "2808", nombre: "Coronel Belisle" },
      { id: "2809", nombre: "Darwin" },
      { id: "2908", nombre: "Dina Huapi" },
      { id: "2815", nombre: "El Bolson" },
      { id: "2930", nombre: "El Cain" },
      { id: "2796", nombre: "El Condor" },
      { id: "2827", nombre: "El Cuy" },
      { id: "2816", nombre: "El Foyel" },
      { id: "2797", nombre: "El Juncal" },
      { id: "2877", nombre: "Ferri" },
      { id: "2824", nombre: "General Conesa" },
      { id: "2878", nombre: "General Enrique Godoy" },
      { id: "2879", nombre: "General Fernandez Oro" },
      { id: "2880", nombre: "General Roca" },
      { id: "2798", nombre: "Guardia Mitre" },
      { id: "2931", nombre: "Ingeniero Jacobacci" },
      { id: "2881", nombre: "Ingeniero Luis A. Huergo" },
      { id: "2882", nombre: "Ingeniero Otto Krause" },
      { id: "2799", nombre: "La Loberia" },
      { id: "2909", nombre: "Laguna Blanca" },
      { id: "2810", nombre: "Lamarque" },
      { id: "2900", nombre: "Las Bayas" },
      { id: "2915", nombre: "Las Grutas" },
      { id: "2828", nombre: "Las Perlas" },
      { id: "2932", nombre: "Los Menucos" },
      { id: "2800", nombre: "Loteo Costa De Rio" },
      { id: "2811", nombre: "Luis Beltran" },
      { id: "2883", nombre: "Mainque" },
      { id: "2901", nombre: "Mamuel Choique" },
      { id: "2933", nombre: "Maquinchao" },
      { id: "2829", nombre: "Mencue" },
      { id: "2934", nombre: "Mina Santa Teresita" },
      { id: "2895", nombre: "Ministro Ramos Mexia" },
      { id: "2924", nombre: "Nahuel Niyeu" },
      { id: "2830", nombre: "Naupa Huen" },
      { id: "2910", nombre: "ñIRIHUAU" },
      { id: "2902", nombre: "ñORQUINCO" },
      { id: "2903", nombre: "Ojos De Agua" },
      { id: "2831", nombre: "Paso Cordova" },
      { id: "2884", nombre: "Paso Cordova" },
      { id: "2911", nombre: "Paso Flores" },
      { id: "2885", nombre: "Peninsula Ruca Co" },
      { id: "2886", nombre: "Peñas Blancas" },
      { id: "2912", nombre: "Pilcaniyeu" },
      { id: "2935", nombre: "Pilquiniyeu" },
      { id: "2913", nombre: "Pilquiniyeu Del Limay" },
      { id: "2916", nombre: "Playas Doradas" },
      { id: "2812", nombre: "Pomona" },
      { id: "2801", nombre: "Pozo Salado" },
      { id: "2896", nombre: "Prahuaniyeu" },
      { id: "2917", nombre: "Puerto San Antonio Este" },
      { id: "2918", nombre: "Punta Colorada" },
      { id: "2904", nombre: "Rio Chico (Est. Cerro Mesa)" },
      { id: "2906", nombre: "Rio Colorado" },
      { id: "2817", nombre: "Rio Villegas" },
      { id: "2919", nombre: "San Antonio Oeste" },
      { id: "2818", nombre: "San Carlos De Bariloche" },
      { id: "2802", nombre: "San Javier" },
      { id: "2887", nombre: "Sargento Vidal" },
      { id: "2897", nombre: "Sierra Colorada" },
      { id: "2920", nombre: "Sierra Grande" },
      { id: "2925", nombre: "Sierra Paileman" },
      { id: "2898", nombre: "Treneta" },
      { id: "2926", nombre: "Valcheta" },
      { id: "2832", nombre: "Valle Azul" },
      { id: "2803", nombre: "Viedma" },
      { id: "2888", nombre: "Villa Alberdi" },
      { id: "2819", nombre: "Villa Campanario" },
      { id: "2820", nombre: "Villa Catedral" },
      { id: "2889", nombre: "Villa Del Parque" },
      { id: "2914", nombre: "Villa Llanquin" },
      { id: "2821", nombre: "Villa Llao Llao" },
      { id: "2822", nombre: "Villa Los Coihues" },
      { id: "2890", nombre: "Villa Manzano" },
      { id: "2891", nombre: "Villa Regina" },
      { id: "2892", nombre: "Villa San Isidro" },
      { id: "2899", nombre: "Yaminue" }
    ]
  },
  {
    id: 66,
    nombre: "Salta",
    ciudades: [
      { id: "3069", nombre: "Acoyte" },
      { id: "2974", nombre: "Aguaray" },
      { id: "3038", nombre: "Aguas Blancas" },
      { id: "3046", nombre: "Alto De La Sierra" },
      { id: "3018", nombre: "Ampascachi" },
      { id: "3065", nombre: "Angastaco" },
      { id: "3066", nombre: "Animana" },
      { id: "3056", nombre: "Antilla" },
      { id: "2936", nombre: "Apolinario Saravia" },
      { id: "2956", nombre: "Atocha" },
      { id: "2988", nombre: "Barrio El Jardin De San Martin" },
      { id: "2987", nombre: "Barrio El Milagro" },
      { id: "2959", nombre: "Barrio San Rafael" },
      { id: "3019", nombre: "Cabra Corral" },
      { id: "2952", nombre: "Cachi" },
      { id: "2954", nombre: "Cafayate" },
      { id: "2975", nombre: "Campamento Vespucio" },
      { id: "2976", nombre: "Campichuelo" },
      { id: "3003", nombre: "Campo Blanco" },
      { id: "2977", nombre: "Campo Duran" },
      { id: "3070", nombre: "Campo La Cruz" },
      { id: "3062", nombre: "Campo Quijano" },
      { id: "2970", nombre: "Campo Santo" },
      { id: "2978", nombre: "Capiazuti" },
      { id: "3047", nombre: "Capitan Juan Page" },
      { id: "2979", nombre: "Carboncito" },
      { id: "2937", nombre: "Ceibalito" },
      { id: "2938", nombre: "Centro 25 De Junio" },
      { id: "2964", nombre: "Cerrillos" },
      { id: "2968", nombre: "Chicoana" },
      { id: "2971", nombre: "Cobos" },
      { id: "3016", nombre: "Cobres" },
      { id: "3039", nombre: "Colonia Santa Rosa" },
      { id: "3057", nombre: "Copo Quile" },
      { id: "2980", nombre: "Coronel Cornejo" },
      { id: "3048", nombre: "Coronel Juan Sola (Est. Morillo)" },
      { id: "3020", nombre: "Coronel Moldes" },
      { id: "2939", nombre: "Coronel Mollinedo" },
      { id: "2940", nombre: "Coronel Olleros" },
      { id: "2957", nombre: "Country Club El Tipal" },
      { id: "2958", nombre: "Country Club La Almudena" },
      { id: "2981", nombre: "Dragones" },
      { id: "3067", nombre: "El Barrial" },
      { id: "2972", nombre: "El Bordo" },
      { id: "2969", nombre: "El Carril" },
      { id: "3027", nombre: "El Galpon (Est. Foguista J. F. Juarez)" },
      { id: "3013", nombre: "El Jardin" },
      { id: "3058", nombre: "El Naranjo" },
      { id: "3059", nombre: "El Potrero (Apeadero Cochabamba)" },
      { id: "2941", nombre: "El Quebrachal" },
      { id: "3040", nombre: "El Tabacal" },
      { id: "3014", nombre: "El Tala (Est. Ruiz De Los Llanos)" },
      { id: "3028", nombre: "El Tunal" },
      { id: "2982", nombre: "Embarcacion" },
      { id: "2942", nombre: "Gaona" },
      { id: "2983", nombre: "General Ballivian" },
      { id: "2973", nombre: "General GÜEMES (Est. GÜEMES)" },
      { id: "2984", nombre: "General Mosconi (Est. Vespucio)" },
      { id: "2943", nombre: "General Pizarro" },
      { id: "3007", nombre: "Guachipas" },
      { id: "2985", nombre: "Hickman" },
      { id: "3041", nombre: "Hipolito Yrigoyen (Est. Tabacal)" },
      { id: "3008", nombre: "Iruya" },
      { id: "3009", nombre: "Isla De Cañas" },
      { id: "2944", nombre: "Joaquin V. Gonzalez" },
      { id: "3011", nombre: "La Caldera" },
      { id: "3015", nombre: "La Candelaria" },
      { id: "2960", nombre: "La Cienaga" },
      { id: "2965", nombre: "La Merced" },
      { id: "3042", nombre: "La Mision" },
      { id: "3017", nombre: "La Poma" },
      { id: "3035", nombre: "La Puerta" },
      { id: "3063", nombre: "La Silleta" },
      { id: "3049", nombre: "La Union" },
      { id: "3021", nombre: "La Viña" },
      { id: "2961", nombre: "Las Costas" },
      { id: "2945", nombre: "Las Lajitas" },
      { id: "3050", nombre: "Los Blancos" },
      { id: "3071", nombre: "Los Toldos" },
      { id: "2946", nombre: "Luis Burela" },
      { id: "3029", nombre: "Lumbreras" },
      { id: "2947", nombre: "Macapillo" },
      { id: "3031", nombre: "Metan Viejo" },
      { id: "2986", nombre: "Mision Chaqueña" },
      { id: "2997", nombre: "Mision Curva El Talar" },
      { id: "2989", nombre: "Mision El Cruce" },
      { id: "2998", nombre: "Mision El Siwok" },
      { id: "2990", nombre: "Mision Kilometro 6" },
      { id: "2999", nombre: "Mision La Mora" },
      { id: "3000", nombre: "Mision Lapacho I" },
      { id: "3001", nombre: "Mision Lapacho Ii" },
      { id: "2991", nombre: "Mision Tierras Fiscales" },
      { id: "3036", nombre: "Molinos" },
      { id: "3072", nombre: "Nazareno" },
      { id: "2948", nombre: "Nuestra Señora De Talavera" },
      { id: "3023", nombre: "Olacapato" },
      { id: "2992", nombre: "Pacara" },
      { id: "2993", nombre: "Padre Lozano" },
      { id: "2953", nombre: "Payogasta" },
      { id: "3043", nombre: "Pichanal" },
      { id: "2949", nombre: "Piquete Cabado" },
      { id: "2994", nombre: "Piquirenda" },
      { id: "3051", nombre: "Pluma De Pato" },
      { id: "3073", nombre: "Poscaya" },
      { id: "3032", nombre: "Presa El Tunal" },
      { id: "2995", nombre: "Profesor Salvador Mazza (Est. Pocitos)" },
      { id: "3010", nombre: "Pueblo Viejo" },
      { id: "2996", nombre: "Recaredo" },
      { id: "2950", nombre: "Rio Del Valle" },
      { id: "3033", nombre: "Rio Piedras" },
      { id: "3052", nombre: "Rivadavia" },
      { id: "3060", nombre: "Rosario De La Frontera" },
      { id: "3064", nombre: "Rosario De Lerma" },
      { id: "2962", nombre: "Salta" },
      { id: "2966", nombre: "San Agustin" },
      { id: "3024", nombre: "San Antonio De Los Cobres" },
      { id: "3068", nombre: "San Carlos" },
      { id: "3061", nombre: "San Felipe" },
      { id: "3030", nombre: "San Jose De Metan (Est. Metan)" },
      { id: "3034", nombre: "San Jose De Orquera" },
      { id: "3074", nombre: "San Marcos" },
      { id: "3044", nombre: "San Ramon De La Nueva Oran (Est. Oran)" },
      { id: "3053", nombre: "Santa Maria" },
      { id: "3054", nombre: "Santa Rosa" },
      { id: "3025", nombre: "Santa Rosa De Los Pastos Grandes" },
      { id: "3075", nombre: "Santa Victoria" },
      { id: "3055", nombre: "Santa Victoria Este" },
      { id: "3037", nombre: "Seclantas" },
      { id: "3022", nombre: "Talapampa" },
      { id: "3002", nombre: "Tartagal" },
      { id: "3004", nombre: "Tobantirenda" },
      { id: "3026", nombre: "Tolar Grande" },
      { id: "2951", nombre: "Tolloche" },
      { id: "2955", nombre: "Tolombon" },
      { id: "3005", nombre: "Tranquitas" },
      { id: "3045", nombre: "Urundel" },
      { id: "3012", nombre: "Vaqueros" },
      { id: "2967", nombre: "Villa Los Alamos" },
      { id: "2963", nombre: "Villa San Lorenzo" },
      { id: "3006", nombre: "Yacuy" }
    ]
  },
  {
    id: 70,
    nombre: "San Juan",
    ciudades: [
      { id: "3146", nombre: "Alto De Sierra" },
      { id: "3121", nombre: "Alto De Sierra" },
      { id: "3101", nombre: "Angualasto" },
      { id: "3160", nombre: "Astica" },
      { id: "3161", nombre: "Balde Del Rosario" },
      { id: "3084", nombre: "Barreal" },
      { id: "3125", nombre: "Barrio Ruta 40" },
      { id: "3138", nombre: "Barrio Sadop" },
      { id: "3102", nombre: "Bella Vista" },
      { id: "3089", nombre: "Bermejo" },
      { id: "3086", nombre: "Calingasta" },
      { id: "3077", nombre: "Campo Afuera" },
      { id: "3149", nombre: "Cañada Honda" },
      { id: "3126", nombre: "Carpinteria" },
      { id: "3090", nombre: "Caucete" },
      { id: "3098", nombre: "Chimbas" },
      { id: "3162", nombre: "Chucuma" },
      { id: "3150", nombre: "Cienaguita" },
      { id: "3122", nombre: "Colonia Fiorito" },
      { id: "3151", nombre: "Colonia Fiscal" },
      { id: "3145", nombre: "Colonia Gutierrez" },
      { id: "3147", nombre: "Colonia Gutierrez" },
      { id: "3152", nombre: "Divisadero" },
      { id: "3139", nombre: "Dos Acequias (Est. Los Angacos)" },
      { id: "3166", nombre: "El Encon" },
      { id: "3113", nombre: "El Fiscal" },
      { id: "3133", nombre: "El Medanito" },
      { id: "3108", nombre: "El Medano" },
      { id: "3099", nombre: "El Mogote" },
      { id: "3076", nombre: "El Rincon" },
      { id: "3091", nombre: "El Rincon" },
      { id: "3109", nombre: "Gran China" },
      { id: "3153", nombre: "Guanacache" },
      { id: "3110", nombre: "Huaco" },
      { id: "3103", nombre: "Iglesia" },
      { id: "3078", nombre: "La Cañada" },
      { id: "3168", nombre: "La Chimbera" },
      { id: "3114", nombre: "La Falda" },
      { id: "3128", nombre: "La Rinconada" },
      { id: "3123", nombre: "Las Chacritas" },
      { id: "3104", nombre: "Las Flores" },
      { id: "3154", nombre: "Las Lagunas" },
      { id: "3092", nombre: "Las Talas" },
      { id: "3081", nombre: "Las Tapias" },
      { id: "3163", nombre: "Los Baldecitos" },
      { id: "3155", nombre: "Los Berros" },
      { id: "3093", nombre: "Los Medanos" },
      { id: "3094", nombre: "Marayes" },
      { id: "3111", nombre: "Mogna" },
      { id: "3112", nombre: "Niquivil" },
      { id: "3115", nombre: "Pampa Vieja" },
      { id: "3156", nombre: "Pedernal" },
      { id: "3095", nombre: "Pie De Palo" },
      { id: "3105", nombre: "Pismanta" },
      { id: "3157", nombre: "Punta Del Medano" },
      { id: "3127", nombre: "Quinto Cuartel" },
      { id: "3134", nombre: "Rawson" },
      { id: "3137", nombre: "Rivadavia" },
      { id: "3106", nombre: "Rodeo" },
      { id: "3116", nombre: "San Isidro" },
      { id: "3140", nombre: "San Isidro (Est. Los Angacos)" },
      { id: "3117", nombre: "San Jose De Jachal" },
      { id: "3088", nombre: "San Juan" },
      { id: "3148", nombre: "Santa Lucia" },
      { id: "3087", nombre: "Tamberias" },
      { id: "3118", nombre: "Tamberias" },
      { id: "3107", nombre: "Tudcum" },
      { id: "3167", nombre: "Tupeli" },
      { id: "3164", nombre: "Usno" },
      { id: "3096", nombre: "Vallecito" },
      { id: "3129", nombre: "Villa Aberastain" },
      { id: "3079", nombre: "Villa Ampacama" },
      { id: "3130", nombre: "Villa Barboza" },
      { id: "3172", nombre: "Villa Basilio Nievas" },
      { id: "3136", nombre: "Villa Bolaños" },
      { id: "3169", nombre: "Villa Borjas" },
      { id: "3132", nombre: "Villa Centenario" },
      { id: "3142", nombre: "Villa Dominguito (Est. Puntilla Blanca)" },
      { id: "3143", nombre: "Villa Don Bosco (Est. Angaco Sud)" },
      { id: "3082", nombre: "Villa El Salvador" },
      { id: "3141", nombre: "Villa El Salvador" },
      { id: "3170", nombre: "Villa El Tango" },
      { id: "3080", nombre: "Villa General San Martin" },
      { id: "3159", nombre: "Villa Ibañez" },
      { id: "3097", nombre: "Villa Independencia" },
      { id: "3135", nombre: "Villa Krause" },
      { id: "3119", nombre: "Villa Malvinas Argentinas" },
      { id: "3158", nombre: "Villa Media Agua" },
      { id: "3120", nombre: "Villa Mercedes" },
      { id: "3131", nombre: "Villa Nacusi" },
      { id: "3100", nombre: "Villa Paula Albarracin" },
      { id: "3085", nombre: "Villa Pituil" },
      { id: "3165", nombre: "Villa San Agustin" },
      { id: "3144", nombre: "Villa San Martin" },
      { id: "3171", nombre: "Villa Santa Rosa" },
      { id: "3083", nombre: "Villa Sefair Talacasto" },
      { id: "3173", nombre: "Villa Tacu" },
      { id: "3124", nombre: "9 De Julio" }
    ]
  },
  {
    id: 74,
    nombre: "San Luis",
    ciudades: [
      { id: "3234", nombre: "Alto Pelado" },
      { id: "3235", nombre: "Alto Pencoso" },
      { id: "3212", nombre: "Anchorena" },
      { id: "3213", nombre: "Arizona" },
      { id: "3214", nombre: "Bagual" },
      { id: "3236", nombre: "Balde" },
      { id: "3215", nombre: "Batavia" },
      { id: "3237", nombre: "Beazley" },
      { id: "3216", nombre: "Buena Esperanza" },
      { id: "3174", nombre: "Candelaria" },
      { id: "3185", nombre: "Carolina" },
      { id: "3226", nombre: "Carpinteria" },
      { id: "3238", nombre: "Cazador" },
      { id: "3244", nombre: "Cerro Colorado" },
      { id: "3227", nombre: "Cerro De Oro" },
      { id: "3239", nombre: "Chosmes" },
      { id: "3194", nombre: "Concaran" },
      { id: "3195", nombre: "Cortaderas" },
      { id: "3245", nombre: "Cruz De Piedra" },
      { id: "3240", nombre: "Desaguadero" },
      { id: "3246", nombre: "El Chorrillo" },
      { id: "3186", nombre: "El Trapiche" },
      { id: "3241", nombre: "El Volcan" },
      { id: "3217", nombre: "Fortin El Patria" },
      { id: "3218", nombre: "Fortuna" },
      { id: "3187", nombre: "Fraga" },
      { id: "3242", nombre: "Jarilla" },
      { id: "3203", nombre: "Juan Jorba" },
      { id: "3204", nombre: "Juan Llerena" },
      { id: "3243", nombre: "Juana Koslay" },
      { id: "3205", nombre: "Justo Daract" },
      { id: "3188", nombre: "La Bajada" },
      { id: "3181", nombre: "La Calera" },
      { id: "3189", nombre: "La Florida" },
      { id: "3175", nombre: "La Majada" },
      { id: "3219", nombre: "La Maroma" },
      { id: "3206", nombre: "La Punilla" },
      { id: "3249", nombre: "La Punta" },
      { id: "3190", nombre: "La Toma" },
      { id: "3256", nombre: "La Vertiente" },
      { id: "3228", nombre: "Lafinur" },
      { id: "3257", nombre: "Las Aguadas" },
      { id: "3247", nombre: "Las Chacras" },
      { id: "3258", nombre: "Las Chacras" },
      { id: "3259", nombre: "Las Lagunas" },
      { id: "3207", nombre: "Lavaisse" },
      { id: "3176", nombre: "Leandro N. Alem" },
      { id: "3229", nombre: "Los Cajones" },
      { id: "3230", nombre: "Los Molles" },
      { id: "3220", nombre: "Los Overos" },
      { id: "3177", nombre: "Lujan" },
      { id: "3221", nombre: "Martin De Loyola" },
      { id: "3231", nombre: "Merlo" },
      { id: "3250", nombre: "Mosmota" },
      { id: "3222", nombre: "Nahuel Mapa" },
      { id: "3196", nombre: "Naschel" },
      { id: "3223", nombre: "Navia" },
      { id: "3182", nombre: "Nogoli" },
      { id: "3224", nombre: "Nueva Galia" },
      { id: "3197", nombre: "Papagayos" },
      { id: "3260", nombre: "Paso Grande" },
      { id: "3261", nombre: "Potrerillo" },
      { id: "3251", nombre: "Potrero De Los Funes" },
      { id: "3178", nombre: "Quines" },
      { id: "3198", nombre: "Renca" },
      { id: "3192", nombre: "Rio Grande" },
      { id: "3179", nombre: "Rio Juan Gomez" },
      { id: "3191", nombre: "Riocito" },
      { id: "3193", nombre: "Saladillo" },
      { id: "3252", nombre: "Salinas Del Bebedero" },
      { id: "3180", nombre: "San Francisco Del Monte De Oro" },
      { id: "3253", nombre: "San Jeronimo" },
      { id: "3208", nombre: "San Jose Del Morro" },
      { id: "3254", nombre: "San Luis" },
      { id: "3262", nombre: "San Martin" },
      { id: "3199", nombre: "San Pablo" },
      { id: "3248", nombre: "San Roque" },
      { id: "3232", nombre: "Santa Rosa Del Conlara" },
      { id: "3233", nombre: "Talita" },
      { id: "3200", nombre: "Tilisarao" },
      { id: "3225", nombre: "Union" },
      { id: "3183", nombre: "Villa De La Quebrada" },
      { id: "3263", nombre: "Villa De Praga" },
      { id: "3201", nombre: "Villa Del Carmen" },
      { id: "3184", nombre: "Villa General Roca" },
      { id: "3202", nombre: "Villa Larca" },
      { id: "3209", nombre: "Villa Mercedes" },
      { id: "3210", nombre: "Villa Reynolds" },
      { id: "3211", nombre: "Villa Salles" },
      { id: "3255", nombre: "Zanjitas" }
    ]
  },
  {
    id: 78,
    nombre: "Santa Cruz",
    ciudades: [
      { id: "3288", nombre: "Bajo Caracoles" },
      { id: "3266", nombre: "Caleta Olivia" },
      { id: "3267", nombre: "Cañadon Seco" },
      { id: "3264", nombre: "Comandante Luis Piedrabuena" },
      { id: "3282", nombre: "El Calafate" },
      { id: "3283", nombre: "El Chalten" },
      { id: "3275", nombre: "El Turbio (Est. Gobernador Mayer)" },
      { id: "3268", nombre: "Fitz Roy" },
      { id: "3289", nombre: "Gobernador Gregores" },
      { id: "3290", nombre: "Hipolito Yrigoyen" },
      { id: "3269", nombre: "Jaramillo" },
      { id: "3276", nombre: "Julia Dufour" },
      { id: "3270", nombre: "Koluel Kaike" },
      { id: "3271", nombre: "Las Heras" },
      { id: "3285", nombre: "Los Antiguos" },
      { id: "3277", nombre: "Mina 3" },
      { id: "3286", nombre: "Perito Moreno" },
      { id: "3272", nombre: "Pico Truncado" },
      { id: "3273", nombre: "Puerto Deseado" },
      { id: "3287", nombre: "Puerto San Julian" },
      { id: "3265", nombre: "Puerto Santa Cruz" },
      { id: "3278", nombre: "Rio Gallegos" },
      { id: "3279", nombre: "Rospentek" },
      { id: "3274", nombre: "Tellier" },
      { id: "3284", nombre: "Tres Lagos" },
      { id: "3281", nombre: "Yacimientos Rio Turbio" },
      { id: "3280", nombre: "28 De Noviembre" }
    ]
  },
  {
    id: 82,
    nombre: "Santa Fe",
    ciudades: [
      { id: "3383", nombre: "Aaron Castellanos (Est. Castellanos)" },
      { id: "3516", nombre: "Acebal" },
      { id: "3548", nombre: "Aguara Grande" },
      { id: "3517", nombre: "Albarellos" },
      { id: "3355", nombre: "Alcorta" },
      { id: "3624", nombre: "Aldao" },
      { id: "3311", nombre: "Aldao (Est. Casablanca)" },
      { id: "3576", nombre: "Alejandra" },
      { id: "3518", nombre: "Álvarez" },
      { id: "3519", nombre: "Alvear" },
      { id: "3549", nombre: "Ambrosetti" },
      { id: "3384", nombre: "Amenabar" },
      { id: "3454", nombre: "Angel Gallardo" },
      { id: "3312", nombre: "Angelica" },
      { id: "3607", nombre: "Angeloni" },
      { id: "3520", nombre: "Arbilla" },
      { id: "3297", nombre: "Arequito" },
      { id: "3521", nombre: "Arminda" },
      { id: "3291", nombre: "Armstrong" },
      { id: "3582", nombre: "Arocena" },
      { id: "3455", nombre: "Arroyo Aguiar" },
      { id: "3414", nombre: "Arroyo Ceibal" },
      { id: "3456", nombre: "Arroyo Leyes" },
      { id: "3522", nombre: "Arroyo Seco" },
      { id: "3550", nombre: "Arrufo" },
      { id: "3298", nombre: "Arteaga" },
      { id: "3313", nombre: "Ataliva" },
      { id: "3314", nombre: "Aurelia" },
      { id: "3415", nombre: "Avellaneda (Est. Ewald)" },
      { id: "3551", nombre: "Balneario La Verde" },
      { id: "3583", nombre: "Balneario Monje" },
      { id: "3584", nombre: "Barrancas" },
      { id: "3356", nombre: "Barrio Arroyo Del Medio" },
      { id: "3585", nombre: "Barrio Caima" },
      { id: "3437", nombre: "Barrio Cicarelli" },
      { id: "3586", nombre: "Barrio El Pacaa - Barrio Comipini" },
      { id: "3357", nombre: "Barrio Mitre" },
      { id: "3315", nombre: "Barrios Acapulco Y Veracruz" },
      { id: "3316", nombre: "Bauer Y Sigel" },
      { id: "3317", nombre: "Bella Italia" },
      { id: "3299", nombre: "Beravebu" },
      { id: "3416", nombre: "Berna" },
      { id: "3587", nombre: "Bernardo De Irigoyen (Est. Irigoyen)" },
      { id: "3300", nombre: "Bigand" },
      { id: "3358", nombre: "Bombal" },
      { id: "3292", nombre: "Bouquet" },
      { id: "3438", nombre: "Bustinza" },
      { id: "3458", nombre: "Cabal" },
      { id: "3577", nombre: "Cacique Ariacaiquin" },
      { id: "3385", nombre: "Cafferata" },
      { id: "3658", nombre: "Calchaqui" },
      { id: "3459", nombre: "Campo Andino" },
      { id: "3460", nombre: "Candioti" },
      { id: "3439", nombre: "Cañada De Gomez" },
      { id: "3386", nombre: "Cañada Del Ucle" },
      { id: "3659", nombre: "Cañada Ombu" },
      { id: "3359", nombre: "Cañada Rica" },
      { id: "3640", nombre: "Cañada Rosquin" },
      { id: "3625", nombre: "Capitan Bermudez" },
      { id: "3552", nombre: "Capivara" },
      { id: "3626", nombre: "Carcaraña" },
      { id: "3641", nombre: "Carlos Pellegrini" },
      { id: "3387", nombre: "Carmen" },
      { id: "3523", nombre: "Carmen Del Sauce" },
      { id: "3388", nombre: "Carreras" },
      { id: "3440", nombre: "Carrizales (Est. Clarke)" },
      { id: "3588", nombre: "Casalegno" },
      { id: "3642", nombre: "Casas" },
      { id: "3301", nombre: "Casilda" },
      { id: "3643", nombre: "Castelar" },
      { id: "3318", nombre: "Castellanos" },
      { id: "3476", nombre: "Cavour" },
      { id: "3378", nombre: "Cayasta" },
      { id: "3608", nombre: "Cayastacito" },
      { id: "3589", nombre: "Centeno" },
      { id: "3360", nombre: "Cepeda" },
      { id: "3553", nombre: "Ceres" },
      { id: "3302", nombre: "Chabas" },
      { id: "3303", nombre: "Chañar Ladeado" },
      { id: "3389", nombre: "Chapuy" },
      { id: "3390", nombre: "Chovet" },
      { id: "3391", nombre: "Christophersen" },
      { id: "3441", nombre: "Classon" },
      { id: "3660", nombre: "Colmena" },
      { id: "3554", nombre: "Colonia Ana" },
      { id: "3644", nombre: "Colonia Belgrano" },
      { id: "3319", nombre: "Colonia Bicha" },
      { id: "3555", nombre: "Colonia Bossi" },
      { id: "3320", nombre: "Colonia Cello" },
      { id: "3609", nombre: "Colonia Dolores" },
      { id: "3578", nombre: "Colonia Duran" },
      { id: "3321", nombre: "Colonia Margarita" },
      { id: "3442", nombre: "Colonia Medici" },
      { id: "3322", nombre: "Colonia Raquel" },
      { id: "3556", nombre: "Colonia Rosa" },
      { id: "3557", nombre: "Constanza" },
      { id: "3590", nombre: "Coronda" },
      { id: "3627", nombre: "Coronel Arnold" },
      { id: "3524", nombre: "Coronel Bogado" },
      { id: "3323", nombre: "Coronel Fraga" },
      { id: "3525", nombre: "Coronel Rodolfo S. Dominguez" },
      { id: "3443", nombre: "Correa" },
      { id: "3645", nombre: "Crispi" },
      { id: "3526", nombre: "Cuatro Esquinas" },
      { id: "3477", nombre: "Cululu" },
      { id: "3558", nombre: "Curupayti" },
      { id: "3591", nombre: "Desvio Arijon" },
      { id: "3592", nombre: "Diaz" },
      { id: "3392", nombre: "Diego De Alvear" },
      { id: "3324", nombre: "Egusquiza" },
      { id: "3417", nombre: "El Araza" },
      { id: "3527", nombre: "El Caramelo" },
      { id: "3418", nombre: "El Rabon" },
      { id: "3646", nombre: "El Trebol" },
      { id: "3478", nombre: "Elisa" },
      { id: "3393", nombre: "Elortondo" },
      { id: "3461", nombre: "Emilia" },
      { id: "3479", nombre: "Empalme San Carlos" },
      { id: "3361", nombre: "Empalme Villa Constitucion" },
      { id: "3325", nombre: "Esmeralda" },
      { id: "3480", nombre: "Esperanza" },
      { id: "3326", nombre: "Estacion Clucellas" },
      { id: "3339", nombre: "Estacion Presidente Roca" },
      { id: "3327", nombre: "Estacion Saguier" },
      { id: "3507", nombre: "Esteban Rams" },
      { id: "3610", nombre: "Esther" },
      { id: "3328", nombre: "Eusebia Y Carolina" },
      { id: "3329", nombre: "Eustolia" },
      { id: "3481", nombre: "Felicia" },
      { id: "3528", nombre: "Fighiera" },
      { id: "3362", nombre: "Firmat" },
      { id: "3394", nombre: "Firmat" },
      { id: "3419", nombre: "Florencia" },
      { id: "3661", nombre: "Fortin Olmos" },
      { id: "3482", nombre: "Franck" },
      { id: "3628", nombre: "Fray Luis Beltran" },
      { id: "3330", nombre: "Frontera" },
      { id: "3629", nombre: "Fuentes" },
      { id: "3529", nombre: "Funes" },
      { id: "3593", nombre: "Gaboto" },
      { id: "3594", nombre: "Galvez" },
      { id: "3662", nombre: "Garabato" },
      { id: "3331", nombre: "Garibaldi" },
      { id: "3508", nombre: "Gato Colorado" },
      { id: "3363", nombre: "General Gelly" },
      { id: "3530", nombre: "General Lagos" },
      { id: "3595", nombre: "Gessler" },
      { id: "3611", nombre: "Gobernador Crespo" },
      { id: "3304", nombre: "GÖDEKEN" },
      { id: "3364", nombre: "Godoy" },
      { id: "3663", nombre: "Golondrina" },
      { id: "3531", nombre: "Granadero Baigorria" },
      { id: "3509", nombre: "Gregoria Perez De Denis  (Est. El Nochero)" },
      { id: "3483", nombre: "Grutly" },
      { id: "3420", nombre: "Guadalupe Norte" },
      { id: "3379", nombre: "Helvecia" },
      { id: "3559", nombre: "Hersilia" },
      { id: "3484", nombre: "Hipatia" },
      { id: "3560", nombre: "Huanqueros" },
      { id: "3395", nombre: "Hughes" },
      { id: "3332", nombre: "Humberto Primo" },
      { id: "3485", nombre: "Humboldt" },
      { id: "3532", nombre: "Ibarlucea" },
      { id: "3421", nombre: "Ingeniero Chanourdie" },
      { id: "3664", nombre: "Intiyaco" },
      { id: "3596", nombre: "Irigoyen" },
      { id: "3486", nombre: "Jacinto L. Arauz" },
      { id: "3333", nombre: "Josefina" },
      { id: "3365", nombre: "Juan B. Molina" },
      { id: "3366", nombre: "Juncal" },
      { id: "3533", nombre: "Kilometro 101" },
      { id: "3665", nombre: "Kilometro 115" },
      { id: "3579", nombre: "La Brava" },
      { id: "3561", nombre: "La Cabral" },
      { id: "3396", nombre: "La Chispa" },
      { id: "3612", nombre: "La Criolla (Est. Cañadita)" },
      { id: "3666", nombre: "La Gallareta" },
      { id: "3422", nombre: "La Isleta" },
      { id: "3487", nombre: "La Pelada" },
      { id: "3613", nombre: "La Penca Y Caraguata" },
      { id: "3562", nombre: "La Rubia" },
      { id: "3423", nombre: "La Sarita" },
      { id: "3367", nombre: "La Vanguardia" },
      { id: "3397", nombre: "Labordeboy" },
      { id: "3462", nombre: "Laguna Paiva" },
      { id: "3647", nombre: "Landeta" },
      { id: "3424", nombre: "Lanteri" },
      { id: "3444", nombre: "Larguia" },
      { id: "3597", nombre: "Larrechea" },
      { id: "3563", nombre: "Las Avispas" },
      { id: "3648", nombre: "Las Bandurrias" },
      { id: "3425", nombre: "Las Garzas" },
      { id: "3564", nombre: "Las Palmeras" },
      { id: "3293", nombre: "Las Parejas" },
      { id: "3649", nombre: "Las Petacas" },
      { id: "3294", nombre: "Las Rosas" },
      { id: "3426", nombre: "Las Toscas" },
      { id: "3488", nombre: "Las Tunas" },
      { id: "3398", nombre: "Lazzarino" },
      { id: "3334", nombre: "Lehmann" },
      { id: "3463", nombre: "Llambi Campbell" },
      { id: "3510", nombre: "Logroño" },
      { id: "3598", nombre: "Loma Alta" },
      { id: "3599", nombre: "Lopez (Est. San Martin De Tours)" },
      { id: "3667", nombre: "Los Amores" },
      { id: "3650", nombre: "Los Cardos" },
      { id: "3427", nombre: "Los Laureles" },
      { id: "3305", nombre: "Los Molinos" },
      { id: "3534", nombre: "Los Muchachos - La Alborada" },
      { id: "3306", nombre: "Los Nogales" },
      { id: "3307", nombre: "Los Quirquinchos" },
      { id: "3380", nombre: "Los Zapallos" },
      { id: "3445", nombre: "Lucio V. Lopez" },
      { id: "3630", nombre: "Luis Palacios (Est. La Salada)" },
      { id: "3600", nombre: "Maciel" },
      { id: "3399", nombre: "Maggiolo" },
      { id: "3428", nombre: "Malabrigo" },
      { id: "3614", nombre: "Marcelino Escalada" },
      { id: "3668", nombre: "Margarita" },
      { id: "3335", nombre: "Maria Juana" },
      { id: "3489", nombre: "Maria Luisa" },
      { id: "3651", nombre: "Maria Susana" },
      { id: "3400", nombre: "Maria Teresa" },
      { id: "3490", nombre: "Matilde" },
      { id: "3368", nombre: "Maximo Paz (Est. Paz)" },
      { id: "3401", nombre: "Melincue  (Est. San Urbano)" },
      { id: "3402", nombre: "Miguel Torres" },
      { id: "3565", nombre: "Moises Ville" },
      { id: "3566", nombre: "Monigotes" },
      { id: "3601", nombre: "Monje" },
      { id: "3535", nombre: "Monte Flores" },
      { id: "3464", nombre: "Monte Vera" },
      { id: "3511", nombre: "Montefiore" },
      { id: "3295", nombre: "Montes De Oca" },
      { id: "3403", nombre: "Murphy" },
      { id: "3615", nombre: "Nare" },
      { id: "3465", nombre: "Nelson" },
      { id: "3336", nombre: "Nueva Lehmann" },
      { id: "3491", nombre: "Nuevo Torino" },
      { id: "3567", nombre: "ñANDUCITA" },
      { id: "3446", nombre: "Oliveros" },
      { id: "3568", nombre: "Palacios" },
      { id: "3466", nombre: "Paraje Chaco Chico" },
      { id: "3467", nombre: "Paraje La Costa" },
      { id: "3429", nombre: "Paraje San Manuel" },
      { id: "3669", nombre: "Paraje 29" },
      { id: "3369", nombre: "Pavon" },
      { id: "3370", nombre: "Pavon Arriba" },
      { id: "3616", nombre: "Pedro Gomez Cello" },
      { id: "3536", nombre: "Perez" },
      { id: "3371", nombre: "Peyrano" },
      { id: "3652", nombre: "Piamonte" },
      { id: "3492", nombre: "Pilar" },
      { id: "3537", nombre: "Piñero (Est. Erasto)" },
      { id: "3337", nombre: "Plaza Clucellas" },
      { id: "3493", nombre: "Plaza Matilde" },
      { id: "3338", nombre: "Plaza Saguier" },
      { id: "3512", nombre: "Pozo Borrado" },
      { id: "3670", nombre: "Pozo De Los Indios" },
      { id: "3340", nombre: "Presidente Roca" },
      { id: "3494", nombre: "Progreso" },
      { id: "3495", nombre: "Providencia" },
      { id: "3447", nombre: "Pueblo Andino" },
      { id: "3538", nombre: "Pueblo Esther" },
      { id: "3341", nombre: "Pueblo Marini" },
      { id: "3539", nombre: "Pueblo Muñoz (Est. Bernard)" },
      { id: "3671", nombre: "Pueblo Santa Lucia" },
      { id: "3540", nombre: "Pueblo Uranga" },
      { id: "3602", nombre: "Puerto Aragon" },
      { id: "3541", nombre: "Puerto Arroyo Seco" },
      { id: "3631", nombre: "Puerto General San Martin" },
      { id: "3430", nombre: "Puerto Reconquista" },
      { id: "3632", nombre: "Pujato" },
      { id: "3342", nombre: "Rafaela" },
      { id: "3617", nombre: "Ramayon" },
      { id: "3343", nombre: "Ramona" },
      { id: "3431", nombre: "Reconquista" },
      { id: "3468", nombre: "Recreo" },
      { id: "3633", nombre: "Ricardone" },
      { id: "3457", nombre: "Rincon Norte" },
      { id: "3469", nombre: "Rincon Potrero" },
      { id: "3634", nombre: "Roldan" },
      { id: "3580", nombre: "Romang" },
      { id: "3542", nombre: "Rosario" },
      { id: "3372", nombre: "Rueda" },
      { id: "3404", nombre: "Rufino" },
      { id: "3496", nombre: "Sa Pereyra" },
      { id: "3381", nombre: "Saladero Mariano Cabal" },
      { id: "3448", nombre: "Salto Grande" },
      { id: "3497", nombre: "San Agustin" },
      { id: "3344", nombre: "San Antonio" },
      { id: "3432", nombre: "San Antonio De Obligado" },
      { id: "3618", nombre: "San Bernardo" },
      { id: "3498", nombre: "San Carlos Centro" },
      { id: "3499", nombre: "San Carlos Norte" },
      { id: "3500", nombre: "San Carlos Sud" },
      { id: "3569", nombre: "San Cristobal" },
      { id: "3405", nombre: "San Eduardo" },
      { id: "3603", nombre: "San Eugenio" },
      { id: "3604", nombre: "San Fabian" },
      { id: "3406", nombre: "San Francisco De Santa Fe" },
      { id: "3605", nombre: "San Genaro" },
      { id: "3606", nombre: "San Genaro Norte" },
      { id: "3407", nombre: "San Gregorio" },
      { id: "3570", nombre: "San Guillermo" },
      { id: "3581", nombre: "San Javier" },
      { id: "3501", nombre: "San Jeronimo Del Sauce" },
      { id: "3502", nombre: "San Jeronimo Norte" },
      { id: "3635", nombre: "San Jeronimo Sud" },
      { id: "3653", nombre: "San Jorge" },
      { id: "3308", nombre: "San Jose De La Esquina" },
      { id: "3470", nombre: "San Jose Del Rincon" },
      { id: "3619", nombre: "San Justo" },
      { id: "3636", nombre: "San Lorenzo" },
      { id: "3503", nombre: "San Mariano" },
      { id: "3654", nombre: "San Martin De Las Escobas" },
      { id: "3620", nombre: "San Martin Norte" },
      { id: "3345", nombre: "San Vicente" },
      { id: "3408", nombre: "Sancti Spiritu" },
      { id: "3309", nombre: "Sanford" },
      { id: "3504", nombre: "Santa Clara De Buena Vista" },
      { id: "3346", nombre: "Santa Clara De Saguier" },
      { id: "3471", nombre: "Santa Fe" },
      { id: "3409", nombre: "Santa Isabel" },
      { id: "3513", nombre: "Santa Margarita" },
      { id: "3382", nombre: "Santa Rosa De Calchines" },
      { id: "3373", nombre: "Santa Teresa" },
      { id: "3505", nombre: "Santo Domingo" },
      { id: "3472", nombre: "Santo Tome" },
      { id: "3571", nombre: "Santurce" },
      { id: "3374", nombre: "Sargento Cabral" },
      { id: "3506", nombre: "Sarmiento" },
      { id: "3655", nombre: "Sastre" },
      { id: "3473", nombre: "Sauce Viejo" },
      { id: "3449", nombre: "Serodino" },
      { id: "3621", nombre: "Silva (Est. Abipones)" },
      { id: "3543", nombre: "Soldini" },
      { id: "3572", nombre: "Soledad" },
      { id: "3375", nombre: "Stephenson" },
      { id: "3573", nombre: "Suardi" },
      { id: "3347", nombre: "Sunchales" },
      { id: "3348", nombre: "Susana" },
      { id: "3433", nombre: "Tacuarendi (Emb. Kilometro 421)" },
      { id: "3349", nombre: "Tacural" },
      { id: "3672", nombre: "Tartagal (Est. El Tajamar)" },
      { id: "3410", nombre: "Teodelina" },
      { id: "3376", nombre: "Theobald" },
      { id: "3637", nombre: "Timbues" },
      { id: "3673", nombre: "Toba" },
      { id: "3296", nombre: "Tortugas" },
      { id: "3514", nombre: "Tostado" },
      { id: "3450", nombre: "Totoras" },
      { id: "3656", nombre: "Traill" },
      { id: "3411", nombre: "Venado Tuerto" },
      { id: "3674", nombre: "Vera (Est. Gobernador Vera)" },
      { id: "3622", nombre: "Vera Y Pintado (Est. Guaranies)" },
      { id: "3623", nombre: "Videla" },
      { id: "3350", nombre: "Vila" },
      { id: "3474", nombre: "Villa Adelina" },
      { id: "3544", nombre: "Villa Amelia" },
      { id: "3434", nombre: "Villa Ana" },
      { id: "3412", nombre: "Villa Cañas" },
      { id: "3377", nombre: "Villa Constitucion" },
      { id: "3545", nombre: "Villa Del Plata" },
      { id: "3451", nombre: "Villa Eloisa" },
      { id: "3638", nombre: "Villa Elvira" },
      { id: "3546", nombre: "Villa Gobernador Galvez" },
      { id: "3435", nombre: "Villa Guillermina" },
      { id: "3351", nombre: "Villa Josefina" },
      { id: "3452", nombre: "Villa La Rivera (Comuna Oliveros)" },
      { id: "3453", nombre: "Villa La Rivera (Comuna Pueblo Andino)" },
      { id: "3475", nombre: "Villa Laura (Est. Constituyentes)" },
      { id: "3515", nombre: "Villa Minetti" },
      { id: "3639", nombre: "Villa Mugueta" },
      { id: "3436", nombre: "Villa Ocampo" },
      { id: "3352", nombre: "Villa San Jose" },
      { id: "3574", nombre: "Villa Saralegui" },
      { id: "3575", nombre: "Villa Trinidad" },
      { id: "3310", nombre: "Villada" },
      { id: "3353", nombre: "Virginia" },
      { id: "3413", nombre: "Wheelwright" },
      { id: "3657", nombre: "Wildermuth (Est. Granadero B. Bustos)" },
      { id: "3547", nombre: "Zavalla" },
      { id: "3354", nombre: "Zenon Pereyra" }
    ]
  },
  {
    id: 86,
    nombre: "Santiago Del Estero",
    ciudades: [
      { id: "3693", nombre: "Abra Grande" },
      { id: "3777", nombre: "Aerolito" },
      { id: "3778", nombre: "Alhuampa" },
      { id: "3734", nombre: "Ancajan" },
      { id: "3694", nombre: "Antaje" },
      { id: "3753", nombre: "Añatuya" },
      { id: "3695", nombre: "Ardiles" },
      { id: "3675", nombre: "Argentina" },
      { id: "3835", nombre: "Árraga" },
      { id: "3754", nombre: "Averias" },
      { id: "3708", nombre: "Bandera" },
      { id: "3743", nombre: "Bandera Bajada" },
      { id: "3822", nombre: "Beltran" },
      { id: "3830", nombre: "Brea Pozo" },
      { id: "3679", nombre: "Campo Gallo" },
      { id: "3696", nombre: "Cañada Escobar" },
      { id: "3676", nombre: "Casares" },
      { id: "3744", nombre: "Caspi Corral" },
      { id: "3802", nombre: "Chañar Pozo De Abajo" },
      { id: "3803", nombre: "Chauchillas" },
      { id: "3697", nombre: "Chaupi Pozo" },
      { id: "3827", nombre: "Chilca Juliana" },
      { id: "3735", nombre: "Choya" },
      { id: "3698", nombre: "Clodomira" },
      { id: "3819", nombre: "Colonia Alpina" },
      { id: "3687", nombre: "Colonia Dora" },
      { id: "3823", nombre: "Colonia El Simbolar" },
      { id: "3745", nombre: "Colonia San Juan" },
      { id: "3804", nombre: "Colonia Tinco" },
      { id: "3680", nombre: "Coronel Manuel L. Rico" },
      { id: "3709", nombre: "Cuatro Bocas" },
      { id: "3681", nombre: "Donadeu" },
      { id: "3761", nombre: "El Bobadal" },
      { id: "3725", nombre: "El Cabure" },
      { id: "3805", nombre: "El Charco" },
      { id: "3762", nombre: "El Charco" },
      { id: "3769", nombre: "El Colorado" },
      { id: "3746", nombre: "El Crucero" },
      { id: "3770", nombre: "El Cuadrado" },
      { id: "3712", nombre: "El Dean" },
      { id: "3713", nombre: "El Mojon" },
      { id: "3793", nombre: "El Mojon" },
      { id: "3763", nombre: "El Rincon" },
      { id: "3714", nombre: "El Zanjon" },
      { id: "3790", nombre: "El 49" },
      { id: "3684", nombre: "Estacion Atamisqui" },
      { id: "3736", nombre: "Estacion La Punta" },
      { id: "3831", nombre: "Estacion Robles" },
      { id: "3832", nombre: "Estacion Taboada" },
      { id: "3755", nombre: "Estacion Tacañitas" },
      { id: "3824", nombre: "Fernandez" },
      { id: "3710", nombre: "Fortin Inca" },
      { id: "3737", nombre: "Frias" },
      { id: "3834", nombre: "Garza" },
      { id: "3764", nombre: "Gramilla" },
      { id: "3806", nombre: "Gramilla" },
      { id: "3711", nombre: "Guardia Escolta" },
      { id: "3779", nombre: "Hasse" },
      { id: "3780", nombre: "Hernan Mejia Miraval" },
      { id: "3688", nombre: "Herrera" },
      { id: "3699", nombre: "Huyamampa" },
      { id: "3689", nombre: "Icaño" },
      { id: "3825", nombre: "Ingeniero Forres (Est. Chaguar Punco)" },
      { id: "3765", nombre: "Isca Yacu" },
      { id: "3766", nombre: "Isca Yacu Semaul" },
      { id: "3747", nombre: "Kilometro 30" },
      { id: "3700", nombre: "La Aurora" },
      { id: "3701", nombre: "La Banda" },
      { id: "3748", nombre: "La Cañada" },
      { id: "3702", nombre: "La Darsena" },
      { id: "3726", nombre: "La Firmeza" },
      { id: "3749", nombre: "La Invernada" },
      { id: "3756", nombre: "La Nena" },
      { id: "3807", nombre: "La Nueva Donosa" },
      { id: "3738", nombre: "Laprida" },
      { id: "3794", nombre: "Las Delicias" },
      { id: "3739", nombre: "Las Palmitas" },
      { id: "3781", nombre: "Las Tinajas" },
      { id: "3759", nombre: "Lavalle" },
      { id: "3782", nombre: "Libertad" },
      { id: "3783", nombre: "Lilo Viejo" },
      { id: "3715", nombre: "Los Cardozos" },
      { id: "3757", nombre: "Los Juries" },
      { id: "3808", nombre: "Los Miranda" },
      { id: "3809", nombre: "Los Nuñez" },
      { id: "3727", nombre: "Los Pirpintos" },
      { id: "3703", nombre: "Los Quiroga" },
      { id: "3704", nombre: "Los Soria" },
      { id: "3828", nombre: "Los Telares" },
      { id: "3728", nombre: "Los Tigres" },
      { id: "3690", nombre: "Lugones" },
      { id: "3716", nombre: "Maco" },
      { id: "3677", nombre: "Malbran" },
      { id: "3810", nombre: "Mansupa" },
      { id: "3717", nombre: "Maquito" },
      { id: "3771", nombre: "Matara" },
      { id: "3685", nombre: "Medellin" },
      { id: "3750", nombre: "Minerva" },
      { id: "3729", nombre: "Monte Quemado" },
      { id: "3718", nombre: "Morales" },
      { id: "3730", nombre: "Nueva Esperanza" },
      { id: "3795", nombre: "Nueva Esperanza" },
      { id: "3836", nombre: "Nueva Francia" },
      { id: "3820", nombre: "Palo Negro" },
      { id: "3731", nombre: "Pampa De Los Guanacos" },
      { id: "3784", nombre: "Patay" },
      { id: "3796", nombre: "Pozo Betbeder" },
      { id: "3767", nombre: "Pozo Hondo" },
      { id: "3811", nombre: "Pozuelos" },
      { id: "3785", nombre: "Pueblo Pablo Torelo (Est. Otumpa)" },
      { id: "3719", nombre: "Puesto De San Antonio" },
      { id: "3786", nombre: "Quimili" },
      { id: "3799", nombre: "Ramirez De Velazco" },
      { id: "3797", nombre: "Rapelli" },
      { id: "3691", nombre: "Real Sayana" },
      { id: "3812", nombre: "Rodeo De Valdez" },
      { id: "3787", nombre: "Roversi" },
      { id: "3682", nombre: "Sachayoj" },
      { id: "3732", nombre: "San Jose Del Boqueron" },
      { id: "3740", nombre: "San Pedro" },
      { id: "3768", nombre: "San Pedro" },
      { id: "3720", nombre: "San Pedro" },
      { id: "3760", nombre: "San Pedro" },
      { id: "3721", nombre: "Santa Maria" },
      { id: "3722", nombre: "Santiago Del Estero" },
      { id: "3798", nombre: "Santo Domingo" },
      { id: "3683", nombre: "Santos Lugares" },
      { id: "3813", nombre: "Sauzal" },
      { id: "3821", nombre: "Selva" },
      { id: "3837", nombre: "Simbol" },
      { id: "3705", nombre: "Simbolar" },
      { id: "3791", nombre: "Sol De Julio" },
      { id: "3838", nombre: "Sumamao" },
      { id: "3800", nombre: "Sumampa" },
      { id: "3801", nombre: "Sumampa Viejo" },
      { id: "3772", nombre: "Suncho Corral" },
      { id: "3741", nombre: "Tapso" },
      { id: "3814", nombre: "Termas De Rio Hondo" },
      { id: "3788", nombre: "Tintina" },
      { id: "3758", nombre: "Tomas Young" },
      { id: "3706", nombre: "Tramo 16" },
      { id: "3707", nombre: "Tramo 20" },
      { id: "3733", nombre: "Urutau" },
      { id: "3751", nombre: "Vaca Huañuna" },
      { id: "3773", nombre: "Vilelas" },
      { id: "3686", nombre: "Villa Atamisqui" },
      { id: "3752", nombre: "Villa Figueroa" },
      { id: "3678", nombre: "Villa General Mitre (Est. Pinto)" },
      { id: "3815", nombre: "Villa Gimenez" },
      { id: "3742", nombre: "Villa La Punta" },
      { id: "3692", nombre: "Villa Mailin" },
      { id: "3833", nombre: "Villa Nueva" },
      { id: "3792", nombre: "Villa Ojo De Agua" },
      { id: "3816", nombre: "Villa Rio Hondo" },
      { id: "3829", nombre: "Villa Salavina" },
      { id: "3775", nombre: "Villa San Martin (Est. Loreto)" },
      { id: "3839", nombre: "Villa Silipica" },
      { id: "3817", nombre: "Villa Turistica Del Embalse" },
      { id: "3776", nombre: "Villa Union" },
      { id: "3826", nombre: "Vilmer" },
      { id: "3818", nombre: "Vinara" },
      { id: "3723", nombre: "Vuelta De La Barranca" },
      { id: "3789", nombre: "Weisburd" },
      { id: "3724", nombre: "Yanda" },
      { id: "3774", nombre: "Yuchan" }
    ]
  },
  {
    id: 94,
    nombre: "Tierra Del Fuego",
    ciudades: [
      { id: "3958", nombre: "Laguna Escondida" },
      { id: "3960", nombre: "Puerto Argentino" },
      { id: "3956", nombre: "Rio Grande" },
      { id: "3957", nombre: "Tolhuin" },
      { id: "3959", nombre: "Ushuaia" }
    ]
  },
  {
    id: 90,
    nombre: "Tucuman",
    ciudades: [
      { id: "3909", nombre: "Acheral" },
      { id: "3919", nombre: "Aguilares" },
      { id: "3851", nombre: "Alderetes" },
      { id: "3874", nombre: "Alpachiri" },
      { id: "3875", nombre: "Alto Verde" },
      { id: "3932", nombre: "Amaicha Del Valle" },
      { id: "3876", nombre: "Arcadia" },
      { id: "3926", nombre: "Atahona" },
      { id: "3854", nombre: "Banda Del Rio Sali" },
      { id: "3856", nombre: "Barrio Aeropuerto" },
      { id: "3904", nombre: "Barrio Araujo" },
      { id: "3882", nombre: "Barrio Casa Rosada" },
      { id: "3936", nombre: "Barrio El Cruce" },
      { id: "3937", nombre: "Barrio Lomas De Tafi" },
      { id: "3938", nombre: "Barrio Mutual San Martin" },
      { id: "3939", nombre: "Barrio Parada 14" },
      { id: "3903", nombre: "Barrio San Felipe" },
      { id: "3840", nombre: "Barrio San Jorge" },
      { id: "3952", nombre: "Barrio San Jose Iii" },
      { id: "3877", nombre: "Barrio San Roque" },
      { id: "3940", nombre: "Barrio U.T.A. Ii" },
      { id: "3894", nombre: "Bella Vista" },
      { id: "3883", nombre: "Campo De Herrera" },
      { id: "3910", nombre: "Capitan Caceres" },
      { id: "3949", nombre: "Choromoro" },
      { id: "3933", nombre: "Colalao Del Valle" },
      { id: "3857", nombre: "Colombres" },
      { id: "3858", nombre: "Colonia Mayo - Barrio La Milagrosa" },
      { id: "3878", nombre: "Concepcion" },
      { id: "3859", nombre: "Delfin Gallo" },
      { id: "3941", nombre: "Diagonal Norte" },
      { id: "3863", nombre: "El Bracho" },
      { id: "3945", nombre: "El Cadillal" },
      { id: "3841", nombre: "El Chañar" },
      { id: "3852", nombre: "El Corte" },
      { id: "3905", nombre: "El Manantial" },
      { id: "3934", nombre: "El Mollar" },
      { id: "3842", nombre: "El Naranjo" },
      { id: "3860", nombre: "El Paraiso" },
      { id: "3895", nombre: "Estacion Araoz" },
      { id: "3861", nombre: "Ex Ingenio Esperanza" },
      { id: "3868", nombre: "Ex Ingenio Los Ralos" },
      { id: "3862", nombre: "Ex Ingenio Lujan" },
      { id: "3884", nombre: "Ex Ingenio Nueva Baviera" },
      { id: "3954", nombre: "Ex Ingenio San Jose" },
      { id: "3885", nombre: "Famailla" },
      { id: "3843", nombre: "Garmendia" },
      { id: "3887", nombre: "Graneros" },
      { id: "3879", nombre: "Iltico" },
      { id: "3886", nombre: "Ingenio Fronterita" },
      { id: "3864", nombre: "Ingenio La Florida" },
      { id: "3906", nombre: "Ingenio San Pablo" },
      { id: "3920", nombre: "Ingenio Santa Barbara" },
      { id: "3890", nombre: "Juan Bautista Alberdi" },
      { id: "3892", nombre: "La Cocha" },
      { id: "3865", nombre: "La Florida" },
      { id: "3844", nombre: "La Ramada" },
      { id: "3907", nombre: "La Reduccion" },
      { id: "3880", nombre: "La Trinidad" },
      { id: "3888", nombre: "Lamadrid" },
      { id: "3866", nombre: "Las Cejas" },
      { id: "3855", nombre: "Lastenia" },
      { id: "3853", nombre: "Los Gutierrez" },
      { id: "3943", nombre: "Los Pocitos" },
      { id: "3896", nombre: "Los Puestos" },
      { id: "3867", nombre: "Los Ralos" },
      { id: "3921", nombre: "Los Sarmientos" },
      { id: "3908", nombre: "Lules" },
      { id: "3942", nombre: "Luz Y Fuerza" },
      { id: "3845", nombre: "Macomitas" },
      { id: "3897", nombre: "Manuel Garcia Fernandez" },
      { id: "3881", nombre: "Medina" },
      { id: "3927", nombre: "Monteagudo" },
      { id: "3911", nombre: "Monteros" },
      { id: "3928", nombre: "Nueva Trinidad" },
      { id: "3871", nombre: "Pacara" },
      { id: "3898", nombre: "Pala Pala" },
      { id: "3846", nombre: "Piedrabuena" },
      { id: "3912", nombre: "Pueblo Independencia" },
      { id: "3872", nombre: "Ranchillos" },
      { id: "3922", nombre: "Rio Chico" },
      { id: "3899", nombre: "Rio Colorado" },
      { id: "3913", nombre: "Rio Seco" },
      { id: "3873", nombre: "San Andres" },
      { id: "3893", nombre: "San Jose De La Cocha" },
      { id: "3925", nombre: "San Miguel De Tucuman (Est. Tucuman)" },
      { id: "3950", nombre: "San Pedro De Colalao" },
      { id: "3923", nombre: "Santa Ana" },
      { id: "3929", nombre: "Santa Cruz" },
      { id: "3914", nombre: "Santa Lucia" },
      { id: "3900", nombre: "Santa Rosa De Leales" },
      { id: "3915", nombre: "Sargento Moya" },
      { id: "3930", nombre: "Simoca" },
      { id: "3916", nombre: "Soldado Maldonado" },
      { id: "3889", nombre: "Taco Ralo" },
      { id: "3935", nombre: "Tafi Del Valle" },
      { id: "3946", nombre: "Tafi Viejo" },
      { id: "3917", nombre: "Teniente Berdina" },
      { id: "3850", nombre: "Villa  Padre Monti" },
      { id: "3891", nombre: "Villa Belgrano" },
      { id: "3848", nombre: "Villa Benjamin Araoz" },
      { id: "3849", nombre: "Villa Burruyacu" },
      { id: "3953", nombre: "Villa Carmela" },
      { id: "3931", nombre: "Villa Chicligasta" },
      { id: "3924", nombre: "Villa Clodomiro Hileret" },
      { id: "3951", nombre: "Villa De Trancas" },
      { id: "3901", nombre: "Villa Fiad - Ingenio Leales" },
      { id: "3947", nombre: "Villa Las Flores" },
      { id: "3902", nombre: "Villa Leales" },
      { id: "3948", nombre: "Villa Mariano Moreno - El Colmenar" },
      { id: "3944", nombre: "Villa Nueva Italia" },
      { id: "3918", nombre: "Villa Quinteros" },
      { id: "3869", nombre: "Villa Recaste" },
      { id: "3870", nombre: "Villa Tercera" },
      { id: "3955", nombre: "Yerba Buena - Marcos Paz" },
      { id: "3847", nombre: "7 De Abril" }
    ]
  }
];
