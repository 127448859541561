import React, { Component } from "react";
import PropTypes from "prop-types";
import feathersClient from "../feathersClient";
import ContentLoader, { Facebook } from "react-content-loader";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Modal,
  Dropdown,
  Breadcrumb,
  List,
  Menu,
  Grid,
  GridColumn,
  Button,
  Icon,
  Message
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ProductCards from "../components/ProductCards";
import ImageGallery from "../components/ImageGallery";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";

const messages = defineMessages({
  priceFormat: {
    id: "app.views.productView.product.format.label",
    defaultMessage: "Formato"
  },
  amounts: {
    id: "app.views.productView.product.amount.label",
    defaultMessage: "Cantidad"
  },
  characteristics: {
    id: "app.views.productView.product.characteristics.header",
    defaultMessage: "Caracteristicas"
  },
  singleUnit: {
    id: "app.views.productView.product.units.singular",
    defaultMessage: "Unidad"
  },
  multipleUnits: {
    id: "app.views.productView.product.units.plural",
    defaultMessage: "Unidades"
  },
  paymentMethods: {
    id: "app.views.productView.product.payment.header",
    defaultMessage: "Formas de Pago"
  },
  shippingInformation: {
    id: "app.views.productView.product.shipping.header",
    defaultMessage: "Información de Envio"
  },
  buyButton: {
    id: "app.views.productView.product.purchase.button",
    defaultMessage: "Comprar"
  },
  orderButton: {
    id: "app.views.productView.product.order.button",
    defaultMessage: "Pedir"
  }
});
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";

const INITIAL_STATE = {
  product: {
    loading: false,
    error: null,
    value: [],
    selectedPriceFormatId: null,
    selectedAmount: null
  },
  method: ""
};
class ProductScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }

  loadProduct = async () => {
    const {
      match: { params },
      initialData: { company }
    } = this.props;
    this.setState({
      product: {
        ...this.state.product,
        loading: true,
        error: null
      }
    });

    try {
      const response = await feathersClient
        .service("api/products")
        .find({ query: { id: params.id, $limit: 1 } });
      //console.log(response.data);
      if (response.data.length <= 0) {
        throw new Error("PRODUCT NOT FOUND");
      }
      let product = response.data[0];
      let collections = null;

      if (product.CollectionId) {
        const collectionResponse = await feathersClient
          .service("api/collections")
          .get(product.CollectionId, { query: { $limited: true } });
        if (collectionResponse) {
          collections = collectionResponse;
          if (collections && !collections.properties.length) {
            collections.properties = [{ label: "Opciones", id: "_variant" }];
            let otherProducts = await feathersClient
              .service("api/products")
              .find({
                query: {
                  CollectionId: collections.id,
                  $select: ["id", "name"]
                }
              });
            collections.variants = collections.variants.map(aV => ({
              ...aV,
              _variant: (
                otherProducts.data.find(b => b.id == aV.id) || { name: aV.id }
              ).name
            }));
          }
        }
      }
      //console.log("Product", product);
      let sortedPrices =
        !product.prices || product.prices.length <= 0
          ? null
          : product.prices.sort((a, b) =>
              a.packAmount != b.packAmount
                ? a.packAmount - b.packAmount
                : a.value - b.value
            );
      product.prices = sortedPrices;
      product.collections = collections;
      let selectedPriceFormatId = !sortedPrices ? null : sortedPrices[0].id;
      let selectedAmount = 1;
      this.setState({
        product: {
          ...this.state.product,
          loading: false,
          error: null,
          value: product,
          selectedPriceFormatId,
          selectedAmount
        }
      });
    } catch (error) {
      this.setState({
        product: {
          ...this.state.product,
          loading: false,
          error
        }
      });
    }
  };

  addToCart = async () => {
    const {
      intl: { formatMessage },
      initialData: {
        company,
        categories: { data: categories }
      },
      location: { hash }
    } = this.props;
    let { settings } = company;
    let cartUUID = window.localStorage.getItem("cart-uuid");
    //console.log("cartUUID stored", cartUUID);
    let cartId = null;
    let { session } = this.props;
    try {
      if (!cartUUID) {
        let newOrder = await feathersClient.service("api/orders").create({
          UserId:
            session.authenticated && session.user ? session.user.id : undefined,
          status: "cart",
          CompanyId: company.id
        });
        cartUUID = newOrder.uuid;
        cartId = newOrder.id;
        window.localStorage.setItem("cart-uuid", cartUUID);
      }
      if (!cartId) {
        let order = await feathersClient.service("api/orders").find({
          query: {
            uuid: cartUUID,
            $limit: 1,
            $select: ["id"],
            status: "cart"
          }
        });
        if (order && order.data && order.data.length >= 1) {
          cartId = order.data[0].id;
        }
      }
      if (!cartId) {
        window.localStorage.removeItem("cart-uuid");
        this.addToCart();
        return;
      }
      // //console.log("this.state", this.state);
      let newEntry = await feathersClient.service("api/order-details").create({
        amount: this.state.product.selectedAmount,
        OrderId: cartId,
        ProductId: this.state.product.value.id,
        priceId: this.state.product.selectedPriceFormatId
      });
      this.props.history.push("/cart");
    } catch (error) {
      //console.log("addToCartError", error);
    }
  };

  componentDidMount() {
    this.loadProduct();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id != this.props.match.params.id) {
      this.loadProduct();
    }
  }

  render() {
    const {
      intl: { formatMessage },
      initialData: {
        company: { interface: uiConfiguration, settings },
        categories: { data: categories }
      },
      location: { hash }
    } = this.props;
    let stockRanges = settings.stockMapping;
    let orderAmountRange = settings.orderAmountRange || [1, 2, 3, 4, 5];
    let payment = this.props.initialData.company.payment;
    let shipmentOptions = settings.shipmentOptions || null;
    let disablePurchase = settings.disablePurchase || false;
    let disablePurchaseMessage = settings.disablePurchaseMessage || null;
    let disablePurchaseTitle = settings.disablePurchaseTitle || null;
    // console.log("shipmentOptions", shipmentOptions);
    let methods = !payment ? null : payment.methods;
    let discounts = !payment ? null : payment.discounts;
    let currentMethod = !this.state.method
      ? null
      : methods.find(a => a.ref === this.state.method);
    let instalmentOptions = !currentMethod
      ? null
      : currentMethod.instalments.map(aninstalment => {
          let aDisc = !discounts
            ? null
            : discounts.find(
                someDisc =>
                  (someDisc.ref == currentMethod.ref || !someDisc.ref) &&
                  someDisc.instalments.indexOf(aninstalment) != -1 &&
                  (!someDisc.ProcessorId ||
                    someDisc.ProcessorId == currentMethod.ProcessorId)
              );
          return {
            instalments: aninstalment,
            discount: !discounts ? null : aDisc,
            key: aninstalment,
            value: aninstalment,
            text: aDisc.hideInstalments
              ? aDisc.description
              : `${aninstalment} cuota${aninstalment > 1 ? "s" : ""} ${
                  !aDisc ? "" : `(${aDisc.description})`
                }`
          };
        });
    if (!Array.isArray(stockRanges) || stockRanges.length <= 0) {
      stockRanges = null;
    }

    const stockMapping = amount => {
      if (!stockRanges || stockRanges.length <= 0) return amount;
      let result = stockRanges.find(a => {
        //console.log("amount", amount, "a.min", a, "a.max", a.max);
        if (
          a.max != undefined &&
          a.min != undefined &&
          Number(amount) >= a.min &&
          Number(amount) <= a.max
        ) {
          return true;
        }
        return false;
      });
      if (!result) {
        result = stockRanges[stockRanges.length - 1];
      }
      return result.message;
    };

    const priceFormatter = new Intl.NumberFormat("es", {
      style: "currency",
      currency: "ARS",
      currencyDisplay: "symbol"
    });
    const { product } = this.state;
    const activeImage = !hash ? 0 : parseInt(hash.replace("#", ""));
    //console.log("props", this.props);
    const computeCategories = () => {
      let currentCategory = categories.find(
        aCategory => aCategory.id == product.value.CategoryId
      );
      if (!currentCategory) {
        return [{ name: "Categoria", id: product.value.CategoryId }];
      }
      let parentCategories = [];
      let lastCategory = { ...currentCategory };
      let stop = false;
      while (lastCategory.CategoryId != null && stop == false) {
        let parentCat = categories.find(
          aCat => aCat.id == lastCategory.CategoryId
        );
        if (!parentCat) {
          stop = true;
        } else {
          parentCategories = [parentCat, ...parentCategories];
          lastCategory = { ...parentCat };
        }
      }
      //console.log("categories", [...parentCategories, currentCategory]);
      return [...parentCategories, currentCategory];
    };
    let activePrice =
      !product.value || !product.value.prices
        ? []
        : product.value.prices.find(
            aP => aP.id == product.selectedPriceFormatId
          );

    let activeVariant = !product.value.collections
      ? null
      : product.value.collections.variants.find(
          a => a.id == this.props.match.params.id
        );

    const distanceToActiveVariant = aVariant => {
      return product.value.collections.properties.reduce((prev, aProp) => {
        let activeValue = activeVariant[aProp.id];
        activeValue =
          typeof activeValue == "object" ? activeValue.label : activeValue;
        let variantValue = aVariant[aProp.id];
        variantValue =
          typeof variantValue == "object" ? variantValue.label : variantValue;
        return prev + (activeValue != variantValue ? 1 : 0);
      }, 0);
    };
    return (
      <Container style={{ paddingTop: 10 }}>
        {!product.loading && !!product.value ? (
          <CategoryBreadcrumb
            uiConfiguration={uiConfiguration}
            categories={computeCategories()}
            product={product.value}
          />
        ) : (
          <ContentLoader height="10">
            <rect x="0" y="5" width="20" height="5" />
            <rect x="25" y="5" width="30" height="5" />
            <rect x="60" y="5" width="20" height="5" />
          </ContentLoader>
        )}
        <Grid doubling stackable style={{ marginTop: 10 }}>
          <Grid.Row>
            <Grid.Column width="8">
              {!product.loading && !!product.value ? (
                <ImageGallery
                  uiConfiguration={uiConfiguration}
                  images={product.value.images}
                  productId={product.value.id}
                  activeImage={activeImage}
                />
              ) : (
                <ContentLoader height="500">
                  <rect x="0" y="0" width="390" height="390" />
                  <rect x="0" y="400" width="100" height="100" />
                  <rect x="110" y="400" width="100" height="100" />
                  <rect x="220" y="400" width="100" height="100" />
                </ContentLoader>
              )}
            </Grid.Column>
            {!product.loading && !!product.value ? (
              <Grid.Column width="8">
                <Segment basic>
                  <Header size="huge">
                    <b>{product.value.name}</b>
                    <br />$
                    {!!product.selectedPriceFormatId
                      ? priceFormatter.format(
                          Number(
                            product.value.prices.find(
                              aPrice =>
                                aPrice.id == product.selectedPriceFormatId
                            ).value
                          )
                        )
                      : "PRECIO NO DISPONIBLE"}
                  </Header>
                  {/* <Button
                    basic
                    style={{
                      color: `${uiConfiguration.colors.primary}!important`
                    }}>
                    {formatMessage(messages.paymentMethods)}
                  </Button>
                  <Button
                    basic
                    style={{
                      color: `${uiConfiguration.colors.primary}!important`
                    }}>
                    {formatMessage(messages.shippingInformation)}
                  </Button> */}
                </Segment>

                {disablePurchase ? (
                  <Message>
                    {!disablePurchaseTitle ? null : (
                      <Message.Header>{disablePurchaseTitle}</Message.Header>
                    )}
                    <p>{disablePurchaseMessage}</p>
                  </Message>
                ) : (
                  <Segment basic>
                    {!product.value ||
                    !product.value.prices ||
                    (product.value.prices.length == 1 &&
                      product.value.prices[0].packAmount == 1)
                      ? null
                      : `${formatMessage(messages.priceFormat)}:`}
                    {!product.value ||
                    !product.value.prices ||
                    (product.value.prices.length == 1 &&
                      product.value.prices[0].packAmount == 1) ? null : (
                      <Dropdown
                        value={product.selectedPriceFormatId}
                        onChange={(e, { value }) =>
                          this.setState({
                            product: {
                              ...this.state.product,
                              selectedPriceFormatId: value,
                              selectedAmount: 1
                            }
                          })
                        }
                        selection
                        style={{
                          margin: 10,
                          marginRight: 0,
                          marginBottom: 0,
                          marginLeft: 10
                        }}
                        options={
                          !product.value || !product.value.prices
                            ? []
                            : product.value.prices.map(aPrice => ({
                                text: `${aPrice.packAmount} ${formatMessage(
                                  aPrice.packAmount > 1
                                    ? messages.multipleUnits
                                    : messages.singleUnit
                                )} x ${priceFormatter.format(
                                  Number(aPrice.value)
                                )}`,
                                value: aPrice.id
                              }))
                        }
                      />
                    )}
                    <br />
                    {formatMessage(messages.amounts)}:
                    <Dropdown
                      value={product.selectedAmount}
                      onChange={(e, { value }) =>
                        this.setState({
                          product: {
                            ...this.state.product,
                            selectedAmount: value
                          }
                        })
                      }
                      selection
                      style={{
                        margin: 10,
                        marginRight: 0,
                        marginBottom: 0,
                        marginLeft: 10
                      }}
                      options={
                        !activePrice
                          ? []
                          : orderAmountRange.map(aNumber => ({
                              value: aNumber,
                              text: `${aNumber *
                                activePrice.packAmount} ${formatMessage(
                                aNumber * activePrice.packAmount > 1
                                  ? messages.multipleUnits
                                  : messages.singleUnit
                              )}`
                            }))
                      }
                    />
                    <br />
                    {!product.value.collections
                      ? null
                      : product.value.collections.properties.map(
                          ({ id, label }, index) => (
                            <div style={{ paddingTop: 10 }}>
                              {label}:
                              <Menu
                                stackable
                                style={{ flexWrap: "wrap" }}
                                items={_.uniqBy(
                                  product.value.collections.variants
                                    .filter(aVariant => !!aVariant[id])
                                    .map(a =>
                                      typeof a[id] == "object"
                                        ? a[id]
                                        : { label: a[id] }
                                    ),
                                  a => a.label
                                ).map(aVariant => ({
                                  key: aVariant.label,
                                  name: aVariant.label,

                                  active:
                                    activeVariant[id] == aVariant.label ||
                                    activeVariant[id].label == aVariant.label,
                                  as: Link,
                                  to: `/product/${
                                    (
                                      product.value.collections.variants
                                        .map(aV => ({
                                          ...aV,
                                          distance: distanceToActiveVariant(aV)
                                        }))
                                        .sort((a, b) => a.distance - b.distance)
                                        .find(a => {
                                          // console.log("a", a);
                                          let c = a[id];
                                          if (!c) {
                                            return false;
                                          }
                                          if (typeof c == "object") {
                                            return c.label == aVariant.label;
                                          }
                                          return c == aVariant.label;
                                        }) || {}
                                    ).id
                                  }`,
                                  icon: !aVariant.tint
                                    ? null
                                    : {
                                        name: "circle",
                                        inverted:
                                          aVariant.tint == "white"
                                            ? true
                                            : false,
                                        color: aVariant.tint
                                      }
                                }))}
                                compact
                                secondary
                              />
                            </div>
                          )
                        )}
                    <br />
                    {!product.value.referenceImage ? null : (
                      <Modal trigger={<Button>Ver Referencias</Button>}>
                        <Modal.Header>Referencia</Modal.Header>
                        <Modal.Content image>
                          <Image
                            wrapped
                            fluid
                            src={product.value.referenceImage}
                          />
                        </Modal.Content>
                      </Modal>
                    )}
                    {!payment || !payment.methods ? null : (
                      <Modal
                        trigger={
                          <Button icon="credit card" content="Formas de pago" />
                        }
                      >
                        <Modal.Header>Formas de pago</Modal.Header>
                        <Modal.Content>
                          <Dropdown
                            fluid
                            selection
                            placeholder="Opciones"
                            options={methods.map(a => ({
                              key: a.ref,
                              value: a.ref,
                              text: a.name
                            }))}
                            onChange={(e, { value }) =>
                              this.setState({ method: value })
                            }
                          />
                          <List
                            bulleted
                            items={(instalmentOptions || []).map(a => ({
                              content: a.text
                            }))}
                          />
                        </Modal.Content>
                      </Modal>
                    )}
                    {!shipmentOptions || shipmentOptions.length <= 0 ? null : (
                      <Modal
                        trigger={
                          <Button icon="truck" content="Tarifas de envio" />
                        }
                      >
                        <Modal.Header>Tarifas de envio</Modal.Header>
                        <Modal.Content>
                          <List
                            bulleted
                            items={shipmentOptions.map(a => ({
                              content: a.name
                            }))}
                          />
                        </Modal.Content>
                      </Modal>
                    )}
                    <br />
                    <br />
                    <Grid>
                      <Grid.Column width="13">
                        <Button
                          color="blue"
                          fluid
                          size="large"
                          onClick={this.addToCart}
                          disabled={
                            !product.value.StockTransactions ||
                            !product.value.StockTransactions.amount ||
                            parseInt(product.value.StockTransactions.amount) < 1
                          }
                          style={{
                            backgroundColor: uiConfiguration.colors.primary,
                            color: uiConfiguration.colors.secondary
                          }}
                        >
                          {formatMessage(
                            settings && settings.orderOnly
                              ? messages.orderButton
                              : messages.buyButton
                          )}
                        </Button>
                      </Grid.Column>
                      <Grid.Column width="3">
                        <Button
                          basic
                          fluid
                          size="large"
                          onClick={this.addToCart}
                        >
                          <Icon
                            name="cart"
                            disabled={
                              !product.value.StockTransactions ||
                              !product.value.StockTransactions.amount ||
                              parseInt(product.value.StockTransactions.amount) <
                                1
                            }
                            style={{ color: uiConfiguration.colors.primary }}
                          />
                        </Button>
                      </Grid.Column>
                    </Grid>
                    <br />
                    STOCK{" "}
                    {stockMapping(
                      product.value.StockTransactions
                        ? product.value.StockTransactions.amount
                        : 0
                    )}
                    <br />
                    {product.value.code}
                  </Segment>
                )}

                <Segment basic>
                  {!product.value ||
                  !product.value.description ||
                  product.value.description.length <= 0 ? null : (
                    <Header>{formatMessage(messages.characteristics)}</Header>
                  )}
                  {product.value.description}
                </Segment>

                <Segment basic>
                  {!product.value.table ||
                  product.value.table.length <= 0 ? null : (
                    <table class="ui celled table">
                      {product.value.table[0].map((a, idx) => (
                        <tr>
                          <th>{a}</th>
                          {product.value.table
                            .slice(1)
                            .map((b, idxb) =>
                              b.length > idx ? <td>{b[idx]}</td> : null
                            )}
                        </tr>
                      ))}
                    </table>
                  )}
                </Segment>
                {/* <Segment basic>
                  {!product.value.table ||
                  product.value.table.length <= 0 ? null : (
                    <table class="ui celled table">
                      {product.value.table.map((a, idx) =>
                        idx == 0 ? (
                          <thead>
                            <tr>
                              {a.map(b => (
                                <th>{b}</th>
                              ))}
                            </tr>
                          </thead>
                        ) : (
                          <tr>
                            {a.map(b => (
                              <td>{b}</td>
                            ))}
                          </tr>
                        )
                      )}{" "}
                    </table>
                  )}
                </Segment> */}
              </Grid.Column>
            ) : (
              <Grid.Column width="8">
                <ContentLoader height="500">
                  <rect x="0" y="0" width="250" height="20" />
                  <rect x="0" y="40" width="300" height="20" />
                  <rect x="0" y="80" width="100" height="20" />
                  <rect x="0" y="120" width="100" height="20" />
                  <rect x="120" y="120" width="100" height="20" />
                  <rect x="0" y="250" width="200" height="40" />
                  <rect x="210" y="250" width="40" height="40" />
                  <rect x="0" y="320" width="150" height="20" />
                  <rect x="0" y="350" width="200" height="10" />
                  <rect x="0" y="370" width="250" height="10" />
                  <rect x="0" y="390" width="240" height="10" />
                </ContentLoader>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const CategoryBreadcrumb = ({ uiConfiguration, categories, product }) => {
  return (
    <Breadcrumb>
      <Breadcrumb.Section
        link
        as={Link}
        to="/"
        style={{ color: uiConfiguration.colors.primary }}
      >
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      {categories.map((aCategory, idx) => [
        <Breadcrumb.Section
          link
          key={`${idx}Category${aCategory.id}Section`}
          as={Link}
          to={`/category/${aCategory.id}`}
          style={{ color: uiConfiguration.colors.primary }}
        >
          {aCategory.name}
        </Breadcrumb.Section>,
        <Breadcrumb.Divider
          key={`${idx}Category${aCategory.id}Divider`}
          icon="right angle"
        />
      ])}

      <Breadcrumb.Section active>{product.name}</Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default injectIntl(ProductScreen);
