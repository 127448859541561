import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Breadcrumb,
  Menu,
  Grid
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ProductCards from "../components/ProductCards";
import { injectIntl, defineMessages } from "react-intl";
import CategoryCards from "../components/CategoryCards";

const messages = defineMessages({
  header: {
    id: "app.views.category.header",
    defaultMessage: "Categories"
  }
});
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";

class CategoriesScreen extends Component {
  render() {
    const {
      intl: { formatMessage },
      initialData: {
        company: { interface: uiConfiguration },
        categories: { data: categories }
      }
    } = this.props;
    return (
      <Container style={{ paddingTop: 10 }}>
        <Grid stackable>
          <Grid.Column>
            <CategoryBreadcrumb uiConfiguration={uiConfiguration} />
            <CategoryCards
              header={"Categorias"}
              showName={true}
              id={`categoriesList`}
              key={`categoriesList`}
              categories={categories.filter(
                aCategory => aCategory.CategoryId == null
              )}
              shouldScroll={false}
              loading={false}
            />
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const CategoryBreadcrumb = ({ uiConfiguration }) => {
  return (
    <Breadcrumb style={{ paddingLeft: 10 }}>
      <Breadcrumb.Section
        link
        as={Link}
        to="/"
        style={{ color: uiConfiguration.colors.primary }}>
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section active>Categories</Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default injectIntl(CategoriesScreen);
