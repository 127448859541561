import React, { Component } from "react";
import PropTypes from "prop-types";
import feathersClient from "../../feathersClient";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ContentLoader, { Facebook } from "react-content-loader";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { debounce } from "lodash";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Dropdown,
  Breadcrumb,
  Select,
  Form,
  Menu,
  Grid,
  GridColumn,
  Button,
  Tab,
  Icon
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { injectIntl, defineMessages } from "react-intl";
import t from "tcomb-form/lib";
import es from "tcomb-form/lib/i18n/es";
import templates from "tcomb-form-templates-semantic";
import moment from "moment";

import { DebounceInput } from "react-debounce-input";
import DetailForm from "./DetailForm";

t.form.Form.i18n = es;
t.form.Form.templates = templates;

const SelectTable = selectTableHOC(ReactTable);
const messages = defineMessages({
  priceFormat: {
    id: "app.views.productView.product.format.label",
    defaultMessage: "Formato"
  }
});
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";

const INITIAL_STATE = {
  data: [],
  brands: [],
  categories: [],
  selection: [],
  images: [],
  selectAll: false,
  selectType: "checkbox",
  loading: false,
  error: null,
  skip: 0,
  total: 0,
  pages: 1
};
class CRUDOrdersScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      selectType: props.select ? "radio" : "checkbox"
    };
  }

  toggleSelection = (key, shift, row) => {
    /*
      Implementation of how to manage the selection state is up to the developer.
      This implementation uses an array stored in the component state.
      Other implementations could use object keys, a Javascript Set, or Redux... etc.
    */
    let goodKey = parseInt(key.split("-")[1]);
    // start off with the existing state
    if (this.state.selectType === "radio") {
      let selection = [];
      if (selection.indexOf(goodKey) < 0) selection.push(goodKey);
      if (this.props.onSelect) {
        //console.log("onSelect");
        let position = selection.length > 0 ? selection[0] : null;
        let item = this.state.data.find(a => a.id == position);
        this.props.onSelect(item);
      }
      this.setState({ selection });
    } else {
      let selection = [...this.state.selection];
      const keyIndex = selection.indexOf(goodKey);
      // check to see if the key exists
      if (keyIndex >= 0) {
        // it does exist so we will remove it using destructing
        selection = [
          ...selection.slice(0, keyIndex),
          ...selection.slice(keyIndex + 1)
        ];
      } else {
        // it does not exist so add it
        selection.push(goodKey);
      }
      // update the state
      this.setState({ selection, images: [] });
    }
  };

  loadProduct = async ({ pageSize, page, sorted, filtered }) => {
    const {
      match: { params },
      initialData: { company }
    } = this.props;
    this.setState({
      loading: true,
      error: null,
      data: [],
      selection: []
    });
    // //console.log("page", page);
    // //console.log("skip", pageSize * page);
    // //console.log("sorted", sorted);
    // //console.log("filtered", filtered);
    const mapFilter = ({ id, value }) => {
      const iLikeFilter = () => ({ $iLike: `%%${value}%%` });
      const idFilter = () => ({ $iLike: `${value}%%` });
      const defaultFilter = () => value;
      switch (id) {
        case "name":
          return iLikeFilter();
          break;
        case "description":
          return iLikeFilter();
        default:
          return defaultFilter();
      }
    };

    let filters = filtered.reduce(
      (p, c) => ({ ...p, [c.id]: mapFilter(c) }),
      {}
    );
    try {
      const [response] = await Promise.all([
        feathersClient.service("api/orders").find({
          query: {
            CompanyId: company.id,
            $limit: pageSize,
            $skip: pageSize * page,

            $sort: sorted.reduce(
              (p, c) => ({ ...p, [c.id]: c.desc ? -1 : 1 }),
              {}
            ),
            ...filters
          }
        })
      ]);
      // //console.log(response.data);
      if (response.data.length <= 0) {
        throw new Error("PRODUCT NOT FOUND");
      }

      this.setState({
        loading: false,
        error: null,
        data: response.data,
        skip: response.skip,
        total: response.total,
        pages: Math.ceil(response.total / pageSize),
        images: []
      });
    } catch (error) {
      this.setState({
        ...INITIAL_STATE,
        brands: this.state.brands,
        categories: this.state.categories,
        error,
        selection: [],
        images: []
      });
    }
  };
  debouncedLoadProduct = debounce(this.loadProduct, 300, { leading: true });

  componentDidMount = async () => {
    const {
      initialData: { company }
    } = this.props;

    let categoryHiddenFilter =
      !company.settings || !company.settings.hideCategories
        ? {}
        : {
            id: {
              $nin: company.settings.hideCategories
            }
          };
    const [brands, categories] = await Promise.all([
      feathersClient
        .service("api/brands")
        .find({ query: { CompanyId: company.id, $limit: -1 } }),
      feathersClient.service("api/categories").find({
        query: {
          $select: ["name", "id"],
          CompanyId: company.id,
          $limit: -1,
          ...categoryHiddenFilter
        }
      })
    ]);
    this.setState({
      brands,
      categories
    });
  };

  toggleAll = () => {
    /*
      'toggleAll' is a tricky concept with any filterable table
      do you just select ALL the records that are in your data?
      OR
      do you only select ALL the records that are in the current filtered data?

      The latter makes more sense because 'selection' is a visual thing for the user.
      This is especially true if you are going to implement a set of external functions
      that act on the selected information (you would not want to DELETE the wrong thing!).

      So, to that end, access to the internals of ReactTable are required to get what is
      currently visible in the table (either on the current page or any other page).

      The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
      ReactTable and then get the internal state and the 'sortedData'.
      That can then be iterrated to get all the currently visible records and set
      the selection state.
    */
    //console.log("toggleAll");
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      //console.log("selecting");
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.selectTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (item._original) {
          selection.push(item._original.id);
        }
      });
    }
    this.setState({ selectAll, selection });
  };

  onSaveChanges = async e => {
    e.preventDefault();
    let { selection, images, data } = this.state;
    let formValue = this.form.getValue();

    let parsedForm = Object.keys(formValue).reduce(
      (p, c) => ({
        ...p,
        [c]: formValue[c] === null ? undefined : formValue[c]
      }),
      {}
    );
    //console.log("formValue", parsedForm);
    let previousValue =
      selection.length == 1
        ? data.find(aProduct => aProduct.id == selection[0])
        : undefined;
    parsedForm.published =
      selection.length == 1
        ? parsedForm.published
        : !parsedForm.published || parsedForm.published == "unchanged"
        ? undefined
        : parsedForm.published == "published";
    parsedForm.iconSrc =
      selection.length == 1 && images.length > 0
        ? images.map(a => a.serverId.replace(/"/g, ""))[0]
        : undefined;

    parsedForm.paymentMetadata = !previousValue
      ? undefined
      : !previousValue.paymentMetadata
      ? parsedForm.paymentMetadata
      : { ...previousValue.paymentMetadata, ...parsedForm.paymentMetadata };
    parsedForm.metadata = !previousValue
      ? undefined
      : !previousValue.metadata
      ? parsedForm.metadata
      : { ...previousValue.metadata, ...parsedForm.metadata };
    this.setState({ loading: true });
    await feathersClient
      .service("api/orders")
      .patch(
        selection.length == 1 ? parseInt(selection[0]) : null,
        { ...parsedForm },
        selection.length > 1
          ? { query: { id: { $in: [...selection.map(a => parseInt(a))] } } }
          : undefined
      );
    //console.log(this.selectTable);
    this.selectTable.wrappedInstance.fireFetchData();
  };

  handleInit = a => {
    this.pond._pond.setOptions({
      server: {
        process: {
          url: "/api/file?folder=categories",
          headers: {
            Authorization: window.localStorage.getItem("feathers-jwt")
          }
        }
      },
      acceptedFileTypes: ["image/*"],
      labelIdle:
        'Arrastra el nuevo icono para la categoria aquí o <span class="filepond--label-action"> seleccionar de mi computadora </span>',
      allowPaste: true,
      dropOnPage: true,
      timeout: 10000000
    });
  };

  render() {
    //TODO: Display OrderDetail and optionally allow to modify it
    const {
      intl: { formatMessage },
      initialData: {
        company: { interface: uiConfiguration, settings }
      },
      location: { hash }
    } = this.props;

    const possibleStatuses = [
      { key: "cart", text: "Carrito", value: "cart" },
      {
        key: "pendingPayment",
        text: "Pago Pendiente",
        value: "pendingPayment"
      },
      { key: "authorized", text: "Pago Autorizado", value: "authorized" },
      { key: "rejected", text: "Pago Rechazado", value: "rejected" },
      { key: "payed", text: "Pago Completado", value: "payed" },
      { key: "requested", text: "Pago Solicitado", value: "requested" },
      {
        key: "confirming",
        text: "Pendiente de Confirmación",
        value: "confirming"
      },
      { key: "confirmed", text: "Confirmado", value: "confirmed" },
      { key: "shipped", text: "Despachado", value: "shipped" },
      { key: "delivered", text: "Entregado (por envio)", value: "delivered" },
      { key: "picked-up", text: "Entregado (en local)", value: "picked-up" },
      { key: "completed", text: "Compra Finalizada", value: "completed" },
      { key: "cancelled", text: "Compra Cancelada", value: "cancelled" },
      { key: "returned", text: "Compra Devuelta", value: "returned" }
    ];

    const {
      data,
      brands,
      categories,
      loading,
      error,
      skip,
      total,
      pages,
      selection
    } = this.state;

    const MultiEditFormSchema = t.struct({
      status: t.enums(
        possibleStatuses.reduce((p, c) => ({ ...p, [c.value]: c.text }), {})
      )
    });

    const SingleEditFormSchema = t.struct({
      observations: t.maybe(t.String),
      paymentId: t.maybe(t.String),
      UserId: t.maybe(t.Number),
      status: t.enums(
        possibleStatuses.reduce((p, c) => ({ ...p, [c.value]: c.text }), {})
      ),
      paymentMetadata: t.maybe(
        t.struct({
          fullAmount: t.maybe(t.Number),
          finalAmount: t.maybe(t.Number)
        })
      ),
      metadata: t.maybe(
        t.struct({
          fullName: t.maybe(t.String),
          personalId: t.maybe(t.String),
          phoneNumber: t.maybe(t.String),
          mode: t.maybe(
            t.enums({
              "in-store": "Entrega en Local",
              "transport-with-price-structure":
                "Entrega con estructura de precios preacordada"
            })
          ),
          delivery: t.maybe(
            t.struct({
              address1: t.maybe(t.String),
              address2: t.maybe(t.String),
              city: t.maybe(t.String),
              postalCode: t.maybe(t.String),
              province: t.maybe(t.String),
              country: t.maybe(t.String)
            })
          )
        })
      )
    });

    const formOptions = {
      fields: {
        observations: {
          // you can use strings or JSX
          label: "Observaciones",
          type: "textarea"
        },
        description: { label: "Descripción" },
        paymentId: { label: "ID Pago" },
        paymentMetadata: {
          label: "Información del pago",
          help:
            "Solo modificar luego de haber actualizado manualmente en la interfaz del procesador de pagos.",
          fields: {
            fullAmount: {
              label: "Monto Subtotal (s/ descuentos)"
            },
            finalAmount: {
              label: "Monto Final (c/ descuentos)"
            }
          }
        },
        metadata: {
          fields: {
            fullName: { label: "Nombre Completo" },
            personalId: { label: "Documento de Identidad" },
            phoneNumber: { label: "Numero de Telefono" },
            mode: { label: "Forma de Entrega" },
            delivery: {
              label: "Dirección",
              fields: {
                address1: {
                  label: "Dirección (calle y numero)"
                },
                address2: {
                  label: "Dirección piso, timbre, etc)"
                },
                city: {
                  label: "Ciudad"
                },
                postalCode: {
                  label: "Codigo Postal"
                },
                province: {
                  label: "Provincia"
                },
                country: {
                  label: "Pais"
                }
              }
            }
          }
        },
        UserId: { label: "Usuario" }
      }
    };

    const extraProps = {
      selectAll: this.state.selectAll,
      isSelected: key => {
        /*
          Instead of passing our external selection state we provide an 'isSelected'
          callback and detect the selection state ourselves. This allows any implementation
          for selection (either an array, object keys, or even a Javascript Set object).
        */
        // //console.log("isSelected?", key);
        return this.state.selection.includes(key);
      },
      toggleAll: this.toggleAll,
      toggleSelection: this.toggleSelection,
      selectType: this.state.selectType
    };

    const DefaultTextFilter = ({ filter, onChange }) => (
      <DebounceInput
        minLength={1}
        debounceTimeout={1000}
        value={filter ? filter.value : ""}
        onChange={e => onChange(e.target.value)}
      />
    );

    const isOnSelect = this.props.onSelect && this.props.select;
    const WrapperClass = isOnSelect ? Segment : Container;
    let basicColumns = [
      {
        Header: "ID",
        id: "id",
        accessor: d => d.id,
        Filter: DefaultTextFilter
      },
      {
        Header: "Fecha de Creación",
        id: "createdAt",
        accessor: d => moment(d.createdAt).format("DD/MM/YY hh:mm"),
        Filter: () => null
      },
      {
        Header: "Ultimo cambio",
        id: "updatedAt",
        accessor: d => moment(d.updatedAt).format("DD/MM/YY hh:mm"),
        Filter: () => null
      },
      {
        Header: "Monto Final",
        id: "finalAmount",
        sortable: false,
        accessor: d =>
          (d.paymentMetadata && d.paymentMetadata.finalAmount) || "-",
        Filter: () => null
      },
      {
        Header: "ID Usuario",
        id: "UserId",
        accessor: d => d.UserId,
        Filter: DefaultTextFilter
      },
      {
        Header: "Codigo Pago",
        id: "paymentId",
        accessor: d => d.paymentId,
        Filter: DefaultTextFilter
      }
    ];
    if (!isOnSelect) {
      basicColumns = [...basicColumns];
    }

    return (
      <WrapperClass basic style={{ paddingTop: 10 }}>
        <Header>Administrar Pedidos</Header>
        <Segment basic>
          <SelectTable
            {...extraProps}
            ref={r => (this.selectTable = r)}
            columns={[
              ...basicColumns,
              {
                Header: "status",
                id: "status",
                style: { overflowY: "visible" },
                accessor: d =>
                  (
                    possibleStatuses.find(a => a.key == d.status) || {
                      text: d.status
                    }
                  ).text,
                Filter: ({ filter, onChange }) => {
                  return (
                    <Select
                      fluid
                      placeholder="Todos"
                      value={filter ? filter.value : undefined}
                      onChange={(e, { value }) => onChange(value)}
                      style={{ zIndex: 1000 }}
                      options={possibleStatuses}
                    />
                  );
                }
              }
            ]}
            keyField="id"
            manual
            data={
              data // Forces table not to paginate or sort automatically, so we can handle it server-side
            }
            getTheadFilterThProps={() => ({
              className: "ui fluid form",
              style: { overflow: "visible" }
            })}
            pages={pages}
            loading={
              loading // Display the total number of pages
            }
            onFetchData={
              this.debouncedLoadProduct // Display the loading overlay when we need it
            }
            defaultSorted={[{ id: "createdAt", desc: true }]}
            filterable
            defaultPageSize={isOnSelect ? 5 : 10}
            className="-striped -highlight"
          />
        </Segment>
        {selection.length <= 0 || loading || !!this.props.onSelect ? null : (
          <Tab
            panes={[
              {
                menuItem: "Pedido",
                render: () => (
                  <Segment basic>
                    <Header size="medium">
                      {selection.length == 1
                        ? `Editando Pedido #${
                            (
                              data.find(
                                anOrder => anOrder.id == selection[0]
                              ) || { name: "N/D" }
                            ).id
                          }`
                        : `Editando ${selection.length} elementos`}
                    </Header>
                    <Form>
                      <t.form.Form
                        value={
                          selection.length == 1
                            ? data.find(aProduct => aProduct.id == selection[0])
                            : undefined
                        }
                        ref={ref => (this.form = ref)}
                        type={
                          selection.length == 1
                            ? SingleEditFormSchema
                            : MultiEditFormSchema
                        }
                        options={formOptions}
                      />
                      <Segment basic>
                        <Form.Group>
                          <Button
                            content="Save"
                            onClick={this.onSaveChanges}
                            disabled={loading}
                          />
                        </Form.Group>
                      </Segment>
                    </Form>
                  </Segment>
                )
              },
              ...(selection.length != 1 ||
              !data.find(aProduct => aProduct.id == selection[0])
                ? []
                : [
                    {
                      menuItem: "Detalle",
                      render: () => (
                        <Segment basic>
                          <Header size="medium">Detalles</Header>
                          <DetailForm
                            initialData={this.props.initialData}
                            OrderId={selection[0]}
                            Order={data.find(
                              aProduct => aProduct.id == selection[0]
                            )}
                          />
                        </Segment>
                      )
                    }
                  ])
            ]}
          />
        )}
      </WrapperClass>
    );
  }
}

const CategoryBreadcrumb = ({ uiConfiguration, categories, product }) => {
  return (
    <Breadcrumb>
      <Breadcrumb.Section
        link
        as={Link}
        to="/"
        style={{ color: uiConfiguration.colors.primary }}
      >
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      {categories.map((aCategory, idx) => [
        <Breadcrumb.Section
          link
          key={`${idx}Category${aCategory.id}Section`}
          as={Link}
          to={`/category/${aCategory.id}`}
          style={{ color: uiConfiguration.colors.primary }}
        >
          {aCategory.name}
        </Breadcrumb.Section>,
        <Breadcrumb.Divider
          key={`${idx}Category${aCategory.id}Divider`}
          icon="right angle"
        />
      ])}

      <Breadcrumb.Section active>{product.name}</Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default injectIntl(CRUDOrdersScreen);
