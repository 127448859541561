import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import { Menu, Input, Container, Popup, Responsive } from "semantic-ui-react";
import { injectIntl, defineMessages } from "react-intl";
import { InitialDataContext } from "../../App";
import CategoriesMenu from "./CategoriesMenu";
import BrandsMenu from "./BrandsMenu";
import { format } from "util";
const messages = defineMessages({
  searchPlaceholder: {
    id: "app.navigation.search.placeholder",
    defaultMessage: "Buscar productos..."
  },
  logIn: {
    id: "app.navigation.session.log-in",
    defaultMessage: "Iniciar Sesión"
  },
  categories: {
    id: "app.navigation.categories.button",
    defaultMessage: "Categorias"
  },
  brands: {
    id: "app.navigation.brands.button",
    defaultMessage: "Marcas"
  },
  products: {
    id: "app.navigation.products.button",
    defaultMessage: "Hacer Pedidos"
  },
  logOut: {
    id: "app.navigation.session.log-out",
    defaultMessage: "Cerrar Sesión"
  }
});

class NavigationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: {
        open: false
      },
      mobile: {
        open: false
      }
    };
  }

  onMenuTriggerClick = (menuName, value = null) => event => {
    let oldState = this.state[menuName];
    if (!oldState) {
      return;
    }

    this.setState({
      [menuName]: {
        ...oldState,
        open: value === null ? !oldState.open : value
      }
    });
  };

  render() {
    const {
      company: { name: companyName, interface: uiConfiguration, settings },
      session: { loading, authenticated, logOut, user },
      categories: categoriesList,
      groups: groupsList,
      brands: brandsList,
      intl: { formatMessage }
    } = this.props;

    const { categories, mobile } = this.state;

    return <div>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Menu size="huge" color="blue" inverted style={{ margin: 0, backgroundColor: uiConfiguration.colors.primary, color: uiConfiguration.colors.secondary, borderRadius: 0 }}>
            <Container>
              {!!uiConfiguration.logos ? <Menu.Item as={Link} to="/" name={companyName} header>
                  <img src={uiConfiguration.logos.pictoric} style={uiConfiguration.logoWidth ? { width: uiConfiguration.logoWidth } : undefined} />
                </Menu.Item> : <Menu.Item as={Link} to="/" name={companyName} header />}

              <Menu.Item style={{ flex: 0.8 }}>
                {!!settings.disableSearch ? null : <Input icon={{ name: "search" }} onKeyPress={e => {
                      if (e.key === "Enter") {
                        this.props.history.push(`/search?q=${encodeURIComponent(e.target.value)}`);
                      }
                    }} placeholder={formatMessage(messages.searchPlaceholder)} />}
              </Menu.Item>
              <Menu.Menu position="right">
                {uiConfiguration.social && uiConfiguration.social.map(
                    aSocial =>
                      aSocial.img ? (
                        <Menu.Item
                          name={aSocial.name}
                          href={aSocial.href}
                          target="_blank"
                          as="a"
                        >
                          <img
                            src={aSocial.img}
                            style={
                              aSocial.width
                                ? { width: aSocial.width }
                                : undefined
                            }
                          />
                        </Menu.Item>
                      ) : (
                        <Menu.Item
                          name={aSocial.name}
                          icon={aSocial.icon}
                          href={aSocial.href}
                          target="_blank"
                          as="a"
                        />
                      )
                  )}
                <Menu.Item name={!user ? formatMessage(messages.logIn) : user.fullName} icon="user" as={Link} to="/sign-in" />
                {!user ? null : <Menu.Item name={formatMessage(messages.logOut)} icon="sign out" as={Link} onClick={() => {
                      logOut();
                      window.localStorage.removeItem("feathers-jwt");
                    }} to="/sign-in" />}

                <Menu.Item icon="cart" as={Link} to="/cart" />
              </Menu.Menu>
            </Container>
          </Menu>

          <Menu secondary style={{ margin: 0, backgroundColor: uiConfiguration.colors.primary, color: uiConfiguration.colors.secondary }} inverted size={uiConfiguration.size} color="grey">
            <Container>
              {!settings.advancedSearch ? null : <Menu.Item name={formatMessage(messages.products)} as={Link} to={`/advanced/cart`} />}
              {!!settings.disableCategoriesMenu ? null : <Popup hideOnScroll wide="very" flowing open={this.state.categories.open == "categories"} onOpen={this.onMenuTriggerClick("categories", "categories")} onClose={this.onMenuTriggerClick("categories", false)} position="bottom left" basic style={{ margin: 0, backgroundColor: "transparent", borderColor: "transparent", boxShadow: "none", width: "100%", maxWidth: "80%" }} trigger={<Menu.Item name={formatMessage(messages.categories)} size={uiConfiguration.size} />} on={["click"]}>
                  <Popup.Content basic style={{ margin: 0 }}>
                    <CategoriesMenu uiConfiguration={uiConfiguration} categories={categoriesList} primary groups={groupsList} onClick={this.onMenuTriggerClick("categories")} />
                  </Popup.Content>
                </Popup>}

              {!settings.showBrands ? null : <Popup hideOnScroll wide="very" flowing open={this.state.categories.open == "brands"} onOpen={this.onMenuTriggerClick("categories", "brands")} onClose={this.onMenuTriggerClick("categories", false)} position="bottom left" basic style={{ margin: 0, backgroundColor: "transparent", borderColor: "transparent", boxShadow: "none", width: "100%", maxWidth: "40%" }} trigger={<Menu.Item name={formatMessage(messages.brands)} />} on={["click"]}>
                  <Popup.Content basic style={{ margin: 0 }}>
                    <BrandsMenu uiConfiguration={uiConfiguration} brands={brandsList} onClick={this.onMenuTriggerClick("categories")} />
                  </Popup.Content>
                </Popup>}
              {!settings.extraCategoryMenus ? null : settings.extraCategoryMenus.map(
                    aMenu => (
                      <Popup
                        hideOnScroll
                        wide="very"
                        flowing
                        open={
                          this.state.categories.open ==
                          `${aMenu.id}#${aMenu.label}`
                        }
                        onOpen={this.onMenuTriggerClick(
                          "categories",
                          `${aMenu.id}#${aMenu.label}`
                        )}
                        onClose={this.onMenuTriggerClick(
                          "categories",
                          false
                        )}
                        position="bottom left"
                        basic
                        style={{
                          margin: 0,
                          backgroundColor: "transparent",
                          borderColor: "transparent",
                          boxShadow: "none",
                          width: "100%"
                        }}
                        trigger={
                          <Menu.Item
                            name={aMenu.label}
                            size={uiConfiguration.size}
                            as={Link}
                            to={`/category/${aMenu.id}`}
                          />
                        }
                        on={["click"]}
                      >
                        <Popup.Content basic style={{ margin: 0 }}>
                          <CategoriesMenu
                            uiConfiguration={uiConfiguration}
                            baseId={aMenu.id}
                            primary
                            categories={categoriesList}
                            groups={groupsList}
                            onClick={this.onMenuTriggerClick("categories")}
                          />
                        </Popup.Content>
                      </Popup>
                    )
                  )}
            </Container>
          </Menu>
        </Responsive>
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth} style={{ marginBottom: 62 }}>
          <Menu color="blue" size="small" inverted fixed="top" style={{ backgroundColor: uiConfiguration.colors.primary, color: uiConfiguration.colors.secondary }}>
            <Container>
              {!!uiConfiguration.logos ? <Menu.Item as={Link} to="/" name={companyName} header>
                  <img src={uiConfiguration.logos.pictoric} />
                </Menu.Item> : <Menu.Item as={Link} to="/" name={companyName} header />}
              {/* <Menu.Item>
                <Input
                  icon={{ name: "search", link: true }}
                  placeholder={formatMessage(messages.searchPlaceholder)}
                />
              </Menu.Item> */}

              <Menu.Menu position="right">
                <Menu.Item icon="bars" onClick={this.onMenuTriggerClick("mobile")} />
              </Menu.Menu>
            </Container>
          </Menu>
          {!mobile.open ? null : <Menu color="blue" size="small" inverted stackable style={{ marginTop: 62, backgroundColor: uiConfiguration.colors.primary, color: uiConfiguration.colors.secondary }}>
              <Menu.Item icon="cart" as={Link} to="/cart" content="Carrito" />
              {settings.disableCategoriesMenu ? null : <Menu.Item as={Link} to="/categories" content="Categorias" icon="shopping bag" />}
              {!settings.advancedSearch ? null : <Menu.Item name={formatMessage(messages.products)} as={Link} icon="shopping bag" to={`/advanced/cart`} />}
              {!!settings.disableSearch ? null : <Menu.Item>
                  <Input icon={{ name: "search" }} onKeyPress={e => {
                      if (e.key === "Enter") {
                        this.props.history.push(`/search?q=${encodeURIComponent(e.target.value)}`);
                      }
                    }} placeholder={formatMessage(messages.searchPlaceholder)} />
                </Menu.Item>}
            </Menu>}
        </Responsive>
      </div>;
  }
}

NavigationMenu.propTypes = {
  company: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired
};

export default injectIntl(withRouter(NavigationMenu));
