import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Responsive,
  Accordion,
  Message,
  Dropdown,
  Breadcrumb,
  List,
  Menu,
  Grid,
  GridColumn,
  Form,
  Button,
  Input
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ProductCards from "../components/ProductCards";
import ImageGallery from "../components/ImageGallery";
import feathersClient from "../feathersClient";
import { injectIntl, defineMessages } from "react-intl";

const messages = defineMessages({
  headerSignIn: {
    id: "app.views.session.signin.header",
    defaultMessage: "Iniciar Sesión"
  },
  headerSignUp: {
    id: "app.views.session.signup.header",
    defaultMessage: "Registrarse"
  },

  signUpSuccessHeader: {
    id: "app.views.session.signup.success.header",
    defaultMessage: "Exito!"
  },
  signUpSuccessContent: {
    id: "app.views.session.signup.success.content",
    defaultMessage: "Te enviamos un e-mail para verificar tu cuenta a"
  },
  breadcrumb: {
    id: "app.views.session.breadcrumb",
    defaultMessage: "Mi Cuenta"
  },
  email: {
    id: "app.views.session.email",
    defaultMessage: "E-mail"
  },
  fullName: {
    id: "app.views.session.fullName",
    defaultMessage: "Nombre Completo"
  },
  password: {
    id: "app.views.session.password",
    defaultMessage: "Contraseña"
  },
  passwordRepeat: {
    id: "app.views.session.passwordRepeat",
    defaultMessage: "Contraseña, de nuevo"
  },
  disabledSignUp: {
    id: "app.views.session.disabledSignUp",
    defaultMessage:
      "Para tener una cuenta tendrás que pedirle a un administrador"
  }
});

class SessionScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signIn: {
        emailValue: "",
        passwordValue: ""
      },
      signUp: {
        emailValue: "",
        passwordValue: "",
        passwordRepeatValue: "",
        fullNameValue: "",
        loading: false,
        error: null,
        user: null
      }
    };
  }

  onChangeGenerator = group => field => (e, { value }) => {
    this.setState({
      [group]: {
        ...this.state[group],
        [field]: value
      }
    });
  };

  onRegister = async () => {
    let {
      fullNameValue,
      emailValue,
      passwordValue,
      passwordRepeatValue
    } = this.state.signUp;
    let {
      initialData: {
        company: { id: CompanyId }
      }
    } = this.props;
    this.setState({
      signUp: {
        ...this.state.signUp,
        loading: true,
        error: null,
        user: null
      },
      signIn: {
        emailValue: "",
        passwordValue: ""
      }
    });
    try {
      let port =
        !window.location.port ||
        window.location.port == "" ||
        window.location.port == 0 ||
        window.location.port == 80 ||
        window.location.port == 443
          ? ""
          : `:${window.location.port}`;

      let newUser = await feathersClient.service("/api/users").create({
        email: emailValue,
        username: emailValue,
        password: passwordValue,
        fullName: fullNameValue,
        domain: `${window.location.protocol}//${
          window.location.hostname
        }${port}`,
        CompanyId: parseInt(CompanyId)
      });
      this.setState({
        signUp: {
          emailValue: "",
          passwordValue: "",
          passwordRepeatValue: "",
          fullNameValue: "",
          loading: false,
          error: null,
          user: newUser
        },
        signIn: {
          emailValue: "",
          passwordValue: ""
        }
      });
    } catch (error) {
      this.setState({
        signUp: {
          ...this.state.signUp,
          passwordValue: "",
          passwordRepeatValue: "",
          loading: false,
          error,
          user: null
        },
        signIn: {
          emailValue: "",
          passwordValue: ""
        }
      });
    }
  };

  async componentDidMount() {
    if (this.props.session.authenticated == true) {
      if (this.props.match.path.indexOf("account") == -1) {
        this.props.history.push("/account/dashboard");
      }
    }
  }

  componentDidUpdate(prevProps) {
    let prevSession = prevProps.session;
    let newSession = this.props.session;
    if (!prevSession.authenticated && newSession.authenticated == true) {
      //console.log(
      //   "path",
      //   this.props.match.path,
      //   this.props.match.path.indexOf("account") == -1
      // );
      if (this.props.match.path.indexOf("account") == -1) {
        if (this.props.initialData.company.settings.loginToAdvancedSearch) {
          this.props.history.push("/advanced/cart");
        } else {
          this.props.history.push("/account/dashboard");
        }
      }
      // this.props.history.push("/account/dashboard");
    }
  }

  render() {
    const { signIn, signUp } = this.state;

    const {
      intl: { formatMessage },
      initialData: {
        company: { interface: uiConfiguration, settings }
      },
      session: { loading, error, authenticate, user },
      match: { path }
    } = this.props;

    //console.log(this.props.session);
    //console.log(settings);
    return (
      <Container style={{ paddingTop: 10 }}>
        <Responsive
          as={Segment}
          basic
          minWidth={Responsive.onlyTablet.minWidth}
        >
          <Header size="large" textAlign="center">
            {formatMessage(messages.breadcrumb)}
          </Header>
          <Grid columns="2" padded>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Segment>
                  <Header>{formatMessage(messages.headerSignIn)}</Header>
                </Segment>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Segment>
                  <Header>{formatMessage(messages.headerSignUp)}</Header>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row divided>
              <Grid.Column textAlign="center">
                <Segment basic>
                  <SignInForm
                    formatMessage={formatMessage}
                    uiConfiguration={uiConfiguration}
                    onChange={this.onChangeGenerator("signIn")}
                    onSend={() =>
                      authenticate({
                        strategy: "local",
                        email: signIn.emailValue,
                        password: signIn.passwordValue
                      })
                    }
                    loading={loading}
                    error={error}
                    {...this.state.signIn}
                  />
                </Segment>
              </Grid.Column>
              <Grid.Column textAlign="center">
                <Segment basic>
                  <SignUpForm
                    formatMessage={formatMessage}
                    onChange={this.onChangeGenerator("signUp")}
                    loading={signUp.loading}
                    settings={settings}
                    onSend={this.onRegister}
                    error={signUp.error}
                    {...this.state.signUp}
                  />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Responsive>
        <Responsive
          as={Segment}
          basic
          maxWidth={Responsive.onlyMobile.maxWidth}
        >
          <Header>{formatMessage(messages.breadcrumb)}</Header>
          <Accordion styled fluid>
            <Menu.Item>
              <Accordion.Title
                active={path == "/sign-in/"}
                content={formatMessage(messages.headerSignIn)}
                index={0}
                onClick={() => this.props.history.push("/sign-in/")}
              />
              <Accordion.Content
                active={path == "/sign-in/"}
                content={
                  <SignInForm
                    formatMessage={formatMessage}
                    uiConfiguration={uiConfiguration}
                    onChange={this.onChangeGenerator("signIn")}
                    onSend={() =>
                      authenticate({
                        strategy: "local",
                        email: signIn.emailValue,
                        password: signIn.passwordValue
                      })
                    }
                    loading={loading}
                    error={error}
                    {...this.state.signIn}
                  />
                }
              />
            </Menu.Item>

            <Menu.Item>
              <Accordion.Title
                active={path == "/sign-up/"}
                content={formatMessage(messages.headerSignUp)}
                index={1}
                onClick={() => this.props.history.push("/sign-up/")}
              />
              <Accordion.Content
                active={path == "/sign-up/"}
                content={
                  <SignUpForm
                    formatMessage={formatMessage}
                    onChange={this.onChangeGenerator("signUp")}
                    loading={signUp.loading}
                    settings={settings}
                    onSend={this.onRegister}
                    error={signUp.error}
                    {...this.state.signUp}
                  />
                }
              />
            </Menu.Item>
          </Accordion>
        </Responsive>
      </Container>
    );
  }
}

const errorMessageDecoding = {
  NotAuthenticated: {
    "Invalid login": "Credenciales invalidas",
    "Could not find stored JWT and no authentication strategy was given": "VOID"
  },
  InvalidTokenError: {
    "Invalid token specified: Cannot read property 'replace' of undefined":
      "Necesitamos que inicies sesión de nuevo"
  }
};

const decode = error => {
  let section = errorMessageDecoding[error.name];
  if (!section) {
    return `Error desconocido (${error.name})`;
  }
  let message = section[error.message];
  if (!message && message != "VOID") {
    return `Error desconocido (${error.name}-${error.message})`;
  }
  if (message == "VOID") {
    return false;
  }
  return message;
};

const SignInForm = ({
  formatMessage,
  uiConfiguration,
  emailValue,
  passwordValue,
  onChange,
  onSend,
  loading,
  error
}) => {
  return (
    <Form loading={loading} error={!!error && decode(error)}>
      <Form.Field>
        <label>{formatMessage(messages.email)}</label>
        <Input
          placeholder="test@example.com"
          type="email"
          value={emailValue}
          onChange={onChange("emailValue")}
        />
      </Form.Field>
      <Form.Field>
        <label>{formatMessage(messages.password)}</label>
        <Input
          placeholder="*********"
          type="password"
          value={passwordValue}
          onChange={onChange("passwordValue")}
        />
      </Form.Field>
      <Button
        fluid
        color="blue"
        type="submit"
        disabled={loading || emailValue.length < 5 || passwordValue.length < 6}
        style={{ backgroundColor: uiConfiguration.colors.primary }}
        onClick={onSend}
      >
        {formatMessage(messages.headerSignIn)}
      </Button>
      <Message error header="Oops!" content={!error ? "" : decode(error)} />
    </Form>
  );
};
const SignUpForm = ({
  formatMessage,
  emailValue,
  passwordValue,
  passwordRepeatValue,
  fullNameValue,
  user,
  onSend,
  onChange,
  loading,
  error,
  settings
}) => {
  if (settings && settings.disableSignUp == true) {
    return (
      <Message color="blue">{formatMessage(messages.disabledSignUp)}</Message>
    );
  }
  let disabled =
    !!loading ||
    fullNameValue.length < 1 ||
    passwordValue.length < 6 ||
    passwordRepeatValue != passwordValue ||
    emailValue.length <= 4;
  return (
    <Form
      loading={loading}
      error={!!error && decode(error)}
      success={!!user && user.email && !!disabled}
    >
      <Form.Field>
        <label>{formatMessage(messages.fullName)}</label>
        <Input
          placeholder="John Appleseed"
          type="text"
          value={fullNameValue}
          onChange={onChange("fullNameValue")}
        />
      </Form.Field>
      <Form.Field>
        <label>{formatMessage(messages.email)}</label>
        <Input
          placeholder="test@example.com"
          type="email"
          value={emailValue}
          onChange={onChange("emailValue")}
        />
      </Form.Field>
      <Form.Field>
        <label>{formatMessage(messages.password)}</label>
        <Input
          placeholder="*********"
          type="password"
          value={passwordValue}
          onChange={onChange("passwordValue")}
        />
      </Form.Field>
      <Form.Field>
        <label>{formatMessage(messages.passwordRepeat)}</label>
        <Input
          placeholder="*********"
          type="password"
          value={passwordRepeatValue}
          onChange={onChange("passwordRepeatValue")}
        />
      </Form.Field>
      <Button
        color="green"
        fluid
        disabled={disabled}
        type="submit"
        onClick={onSend}
      >
        {formatMessage(messages.headerSignUp)}
      </Button>
      <Message error header="Oops!" content={!error ? "" : decode(error)} />
      <Message
        success
        header={formatMessage(messages.signUpSuccessHeader)}
        icon="mail"
        content={`${formatMessage(messages.signUpSuccessContent)} ${
          !user ? "" : user.email
        }`}
      />
    </Form>
  );
};

const CategoryBreadcrumb = props => {
  return (
    <Breadcrumb>
      <Breadcrumb.Section link as={Link} to="/">
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section active>
        {props.formatMessage(messages.breadcrumb)}
      </Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default injectIntl(SessionScreen);
