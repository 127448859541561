import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Image,
  Segment,
  Message,
  Header,
  Modal,
  Card,
  Dropdown,
  Breadcrumb,
  List,
  Menu,
  Grid,
  GridColumn,
  Button,
  Input,
  Responsive
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ProductCards from "../components/ProductCards";
import ImageGallery from "../components/ImageGallery";
import { injectIntl, defineMessages } from "react-intl";
import PurchasesListScreen from "./PurchasesListScreen";
import ProductsList from "../components/ProductsList";
import feathersClient from "../feathersClient";
const messages = defineMessages({
  header: {
    id: "app.views.purchaseDetail.header",
    defaultMessage: "Compra #"
  },
  requestedStatus: {
    id: "app.views.purchaseDetail.status.requested",
    defaultMessage: "Solicitado"
  },
  payedStatus: {
    id: "app.views.purchaseDetail.status.payed",
    defaultMessage: "Pagado"
  },
  pendingPaymentStatus: {
    id: "app.views.purchaseDetail.status.pendingPayment",
    defaultMessage: "Pago Pendiente"
  },
  authorizedStatus: {
    id: "app.views.purchaseDetail.status.authorized",
    defaultMessage: "Pago Autorizado"
  },
  rejectedStatus: {
    id: "app.views.purchaseDetail.status.rejected",
    defaultMessage: "Pago Rechazado"
  },
  anulledStatus: {
    id: "app.views.purchaseDetail.status.anulled",
    defaultMessage: "Pago Anulado"
  },
  confirmingStatus: {
    id: "app.views.purchaseDetail.status.confirming",
    defaultMessage: "Confirmando Pedido y verificando Stock"
  },
  confirmedStatus: {
    id: "app.views.purchaseDetail.status.confirmed",
    defaultMessage: "Pedido confirmado  y Stock verificado"
  },
  shippedStatus: {
    id: "app.views.purchaseDetail.status.shipped",
    defaultMessage: "Pedido despachado"
  },
  deliveredStatus: {
    id: "app.views.purchaseDetail.status.delivered",
    defaultMessage: "Pedido entregado"
  },
  "picked-upStatus": {
    id: "app.views.purchaseDetail.status.picked-up",
    defaultMessage: "Pedido retirado"
  },
  completedStatus: {
    id: "app.views.purchaseDetail.status.completed",
    defaultMessage: "Pedido completado"
  },
  cancelledStatus: {
    id: "app.views.purchaseDetail.status.cancelled",
    defaultMessage: "Pedido Cancelado"
  },
  returnedStatus: {
    id: "app.views.purchaseDetail.status.returned",
    defaultMessage: "Pedido Devuelto"
  }
});
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";
class PurchaseDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      data: []
    };
  }

  recreateCart = async () => {
    const {
      intl: { formatMessage },
      initialData: {
        company,
        categories: { data: categories }
      },
      location: { hash }
    } = this.props;
    let oldOrder = this.state.data;

    if (!oldOrder || oldOrder.length <= 0 || this.state.loading) {
      return;
    }
    let oldItems = this.state.data.items;
    if (!oldItems) {
      return;
    }

    oldOrder = oldOrder[0];
    //console.log("oldOrder", oldOrder);
    let { settings } = company;
    let cartUUID = window.localStorage.getItem("cart-uuid");
    //console.log("cartUUID stored", cartUUID);
    let cartId = null;
    let { session } = this.props;
    try {
      if (!cartUUID) {
        let newOrder = await feathersClient.service("api/orders").create({
          UserId:
            session.authenticated && session.user ? session.user.id : undefined,
          status: "cart",
          CompanyId: company.id
        });
        cartUUID = newOrder.uuid;
        cartId = newOrder.id;
        window.localStorage.setItem("cart-uuid", cartUUID);
      }
      if (!cartId) {
        let order = await feathersClient.service("api/orders").find({
          query: {
            uuid: cartUUID,
            $limit: 1,
            $select: ["id"],
            status: "cart"
          }
        });
        if (order && order.data && order.data.length >= 1) {
          cartId = order.data[0].id;
        }
      }
      if (!cartId) {
        window.localStorage.removeItem("cart-uuid");
        this.addToCart();
        return;
      }

      let newEntries = oldItems.map(a => ({
        amount: a.amount,
        OrderId: cartId,
        ProductId: a.ProductId,
        priceId: a.priceId
      }));
      // //console.log("this.state", this.state);
      const prices = await feathersClient.service("api/prices").find({
        query: {
          $limit: -1,
          id: { $in: [...new Set(newEntries.map(element => element.priceId))] }
        }
      });

      const products = await feathersClient.service("api/products").find({
        query: {
          $limit: -1,
          id: {
            $in: [...new Set(newEntries.map(element => element.ProductId))]
          }
        }
      });

      //console.log("newEntries", newEntries);
      //console.log("prices", prices);
      //console.log("prices", products);

      newEntries = newEntries.filter(
        a =>
          !!prices.some(b => b.id == a.priceId) &&
          !!products.some(b => b.id == a.ProductId)
      );

      newEntries = await Promise.all(
        newEntries.map(a =>
          feathersClient.service("api/order-details").create(a)
        )
      );

      // let newEntry = await feathersClient.service("api/order-details").create({
      //   amount: this.state.product.selectedAmount,
      //   OrderId: cartId,
      //   ProductId: this.state.product.value.id,
      //   priceId: this.state.product.selectedPriceFormatId
      // });
      if (!!this.props.initialData.company.settings.advancedSearch) {
        this.props.history.push("/advanced/cart");
      } else {
        this.props.history.push("/cart");
      }
    } catch (error) {
      //console.log("addToCartError", error);
    }
  };

  loadPurchase = async () => {
    let { session } = this.props;
    const {
      intl: { formatMessage },
      initialData: { company }
    } = this.props;
    //console.log(this.props);
    this.setState({
      loading: true,
      error: null,
      data: []
    });
    try {
      let data = await feathersClient.service("api/orders").find({
        query: {
          $limit: -1,
          UserId: session.user.id,
          CompanyId: company.id,
          id: this.props.match.params.id,
          status: { $nin: ["cart", "anonymousCart", "pendingPayment"] }
        }
      });
      let items = await feathersClient.service("api/order-details").find({
        query: {
          $limit: -1,
          OrderId: this.props.match.params.id,
          replaced: false
        }
      });
      if (
        items.some(
          a =>
            !a.record ||
            a.record.product == null ||
            a.record.category == null ||
            a.record.price == null ||
            a.record.priceList == null
        )
      ) {
        let productIds = [...new Set(items.map(a => a.ProductId))];
        let pricesIds = [...new Set(items.map(a => a.priceId))];
        let [products, prices] = await Promise.all([
          feathersClient.service("api/products").find({
            query: {
              $limit: -1,
              CompanyId: company.id,
              id: { $in: productIds }
            }
          }),
          feathersClient.service("api/prices").find({
            query: { $limit: -1, CompanyId: company.id, id: { $in: pricesIds } }
          })
        ]);
        items = items.map(a => ({
          ...a,
          Product: products.find(b => a.ProductId == b.id),
          Price: prices.find(b => (a.priceId = b.id)),
          fromRecord: false
        }));
      } else {
        items = items.map(a => ({
          ...a,
          Product: a.record.product,
          Price: a.record.price,
          fromRecord: true
        }));
      }

      data.items = items;
      // //console.log(data.items);
      this.setState({
        data,
        loading: false,
        error: null
      });
    } catch (error) {
      //console.log(error);
      this.setState({
        data: [],
        loading: false,
        error
      });
    }
  };

  componentDidMount() {
    this.loadPurchase();
  }

  render() {
    const {
      intl: { formatMessage },
      initialData: {
        company: { interface: uiConfiguration }
      }
    } = this.props;
    const { loading, data, error } = this.state;
    //console.log(data);
    let item = !data || data.length <= 0 ? null : data[0];
    let detailView = (
      <Container style={{ paddingTop: 10 }}>
        <CategoryBreadcrumb
          formatMessage={formatMessage}
          uiConfiguration={uiConfiguration}
          id={!item ? "" : item.id}
        />
        <Header size="large">
          {formatMessage(messages.header)}
          {!item ? "" : item.id}
        </Header>
        Estado: {!item ? "" : formatMessage(messages[`${item.status}Status`])}
        <Segment loading={loading}>
          {!loading && !error && data && data.items ? (
            <ProductsList items={data.items} />
          ) : (
            <Message>Cargando...</Message>
          )}
        </Segment>
        <Segment textAlign="right" basic>
          <Header size="medium" style={{ fontWeight: "none" }}>
            Total:{" "}
            <b>
              ${" "}
              {!data || data.length <= 0
                ? "Cargando..."
                : Number(`${data[0].paymentMetadata.finalAmount}`).toFixed(2)}
            </b>
          </Header>
        </Segment>
        <Segment textAlign="right" basic>
          <Button content="Recrear pedido" onClick={this.recreateCart} />
        </Segment>
      </Container>
    );

    return (
      <div>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <Modal
            closeOnDimmerClick={true}
            onClose={() =>
              this.props.history.push("/account/dashboard/purchases")
            }
            open={
              this.props.match.params.id !== undefined && this.props.isPhone
            }
          >
            <Modal.Content>{detailView}</Modal.Content>
          </Modal>
          <PurchasesListScreen
            {...this.props}
            initialData={this.props.initialData}
          />
        </Responsive>
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
          {detailView}
        </Responsive>
      </div>
    );
  }
}

const CategoryBreadcrumb = props => {
  return (
    <Breadcrumb>
      <Breadcrumb.Section
        link
        as={Link}
        to="/"
        style={{ color: props.uiConfiguration.colors.primary }}
      >
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section>Mi Cuenta</Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section
        link
        as={Link}
        to="/account/dashboard/purchases"
        style={{ color: props.uiConfiguration.colors.primary }}
      >
        Compras
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section active>Compra #{props.id}</Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default injectIntl(PurchaseDetailScreen);
