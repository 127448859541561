import React, { Component, useState, useEffect, useCallback } from "react";
import { Button, Checkbox, Form, Select, Input } from "semantic-ui-react";
import feathersClient from "../../feathersClient";
import { load } from "@feathersjs/client/dist/feathers";
function StockForm(props) {
  const {
    ProductId,
    initialData: { company }
  } = props;

  const [currentStockEntry, setCurrentStockEntry] = useState(null);
  const [latestStock, setLatestStock] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(
    () => {
      if (loading) {
        return;
      }
      setLoading(true);
      feathersClient
        .service("api/stock-transactions")
        .find({
          query: {
            CompanyId: company.id,
            ProductId,
            $limit: 1,
            $sort: {
              id: 1
            }
          }
        })
        .then(stock => {
          if (stock.data.length > 0) {
            setCurrentStockEntry(stock.data[0]);
            setLatestStock(stock.data[0].amount);
          } else {
            setCurrentStockEntry({
              amount: 0,
              ProductId,
              CompanyId: company.id
            });
            setLatestStock(null);
          }

          setLoading(false);
          setError(null);
        })
        .catch(error => {
          setLoading(false);
          setCurrentStockEntry(null);
          setError(error);
        });
    },
    [ProductId]
  );

  const updateEntry = useCallback(
    async () => {
      if (loading || !currentStockEntry || !ProductId) {
        return;
      }
      setLoading(true);
      try {
        if (!currentStockEntry.id) {
          let anEntry = await feathersClient
            .service("api/stock-transactions")
            .create(currentStockEntry);
          setCurrentStockEntry(anEntry);
          setLatestStock(anEntry.amount);
        } else {
          let anEntry = await feathersClient
            .service("api/stock-transactions")
            .patch(currentStockEntry.id, currentStockEntry);
          // console.log(anEntry);
          setCurrentStockEntry(anEntry);
          setLatestStock(anEntry.amount);
        }
        setLoading(false);
        setError(null);
      } catch (error) {
        setLatestStock(null);
        setLoading(false);
        setError(error);
        // console.log(error);
      }
    },
    [currentStockEntry, loading, ProductId]
  );

  return (
    <Form loading={loading} onSubmit={updateEntry}>
      <Form.Group>
        {!currentStockEntry ? null : (
          <Form.Field
            disabled={loading}
            id="form-input-control-stock"
            control={Input}
            label="Stock"
            type="number"
            min="1"
            value={currentStockEntry.amount}
            onChange={(e, { value }) =>
              setCurrentStockEntry(entry => {
                let nValue = parseInt(value);
                nValue = Number.isNaN(nValue) ? entry.amount : value;
                return {
                  ...entry,
                  amount: nValue
                };
              })
            }
            placeholder="0.00"
          />
        )}
        {!latestStock ? null : (
          <Form.Field
            transparent
            id="form-input-control-stock-latest"
            control={Input}
            label="Stock Activo"
            min="1"
            step="1"
            value={`${latestStock}`}
            placeholder="0.00"
            readOnly
          />
        )}
        {!currentStockEntry ? null : (
          <Form.Button
            content="Actualizar"
            label=" &nbsp;"
            positive
            disabled={
              loading ||
              !currentStockEntry.amount ||
              isNaN(currentStockEntry.amount) ||
              currentStockEntry.amount < 0
            }
          />
        )}
      </Form.Group>
    </Form>
  );
}

export default StockForm;
