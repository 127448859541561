import React, { Component } from "react";
import PropTypes from "prop-types";
import feathersClient from "../../feathersClient";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ContentLoader, { Facebook } from "react-content-loader";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { debounce } from "lodash";
import {
  Container,
  Image,
  Segment,
  Header,
  Message,
  Card,
  Dropdown,
  Breadcrumb,
  Select,
  Form,
  Input,
  Menu,
  Grid,
  GridColumn,
  Button,
  Icon
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { injectIntl, defineMessages } from "react-intl";
import t from "tcomb-form/lib";
import es from "tcomb-form/lib/i18n/es";
import templates from "tcomb-form-templates-semantic";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { DebounceInput } from "react-debounce-input";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
t.form.Form.i18n = es;
t.form.Form.templates = templates;

const imageTemplate = t.form.Form.templates.textbox.clone({
  // override just the input default implementation (labels, help, error will be preserved)
  renderInput: locals => {
    return <Image src={locals.value} />;
  }
});
const SelectTable = selectTableHOC(ReactTable);
const messages = defineMessages({
  priceFormat: {
    id: "app.views.productView.product.format.label",
    defaultMessage: "Formato"
  }
});
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";

const INITIAL_STATE = {
  data: [],
  lists: [],
  clients: [],
  selection: [],
  images: [],
  selectAll: false,
  selectType: "checkbox",
  loading: false,
  error: null,
  skip: 0,
  total: 0,
  pages: 1,
  newUserForm: {
    email: "",
    password: "",
    fullName: "",
    username: "",
    ClientId: null
  }
};
class CRUDUsersScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      selectType: props.select ? "radio" : "checkbox"
    };
  }

  toggleSelection = (key, shift, row) => {
    /*
      Implementation of how to manage the selection state is up to the developer.
      This implementation uses an array stored in the component state.
      Other implementations could use object keys, a Javascript Set, or Redux... etc.
    */
    let goodKey = parseInt(key.split("-")[1]);
    // start off with the existing state
    if (this.state.selectType === "radio") {
      let selection = [];
      if (selection.indexOf(goodKey) < 0) selection.push(goodKey);
      if (this.props.onSelect) {
        console.log("onSelect");
        let position = selection.length > 0 ? selection[0] : null;
        let item = this.state.data.find(a => a.id == position);
        this.props.onSelect(item);
      }
      this.setState({ selection });
    } else {
      let selection = [...this.state.selection];
      const keyIndex = selection.indexOf(goodKey);
      // check to see if the key exists
      if (keyIndex >= 0) {
        // it does exist so we will remove it using destructing
        selection = [
          ...selection.slice(0, keyIndex),
          ...selection.slice(keyIndex + 1)
        ];
      } else {
        // it does not exist so add it
        selection.push(goodKey);
      }
      // update the state
      this.setState({ selection, images: [] });
    }
  };

  onCreateUser = async () => {
    let { newUserForm } = this.state;
    let { email, password, fullName } = newUserForm;
    const {
      match: { params },
      initialData: { company }
    } = this.props;
    if (!email || !password || !fullName) {
      return;
    }
    // //console.log("activeSection", activeSection, "activeTable", activeTable);
    this.setState({
      loading: true,
      error: null
    });
    try {
      let result = await feathersClient.service("api/users").create({
        ...newUserForm,
        CompanyId: company.id
      });
      //console.log("result", result);
      this.setState(
        {
          selection: [result.id],
          newUserForm: {
            email: "",
            password: "",
            fullName: "",
            username: "",
            ClientId: null
          }
        },
        () => this.selectTable.wrappedInstance.fireFetchData()
      );
    } catch (error) {
      console.error("error", error);
      this.setState({
        loading: false,
        error
      });
    }
  };

  loadProduct = async ({ pageSize, page, sorted, filtered }) => {
    const {
      match: { params },
      initialData: { company }
    } = this.props;
    let { selection } = this.state;

    this.setState({
      loading: true,
      error: null,
      data: [],
      selection: []
    });
    // //console.log("page", page);
    // //console.log("skip", pageSize * page);
    // //console.log("sorted", sorted);
    // //console.log("filtered", filtered);
    const mapFilter = ({ id, value }) => {
      const iLikeFilter = () => ({ $iLike: `%%${value}%%` });
      const defaultFilter = () => value;
      switch (id) {
        case "fullName":
          return iLikeFilter();
        case "email":
          return iLikeFilter();
        case "username":
          return iLikeFilter();
          break;
        default:
          return defaultFilter();
      }
    };

    let filters = filtered.reduce(
      (p, c) => ({ ...p, [c.id]: mapFilter(c) }),
      {}
    );
    try {
      const [response] = await Promise.all([
        feathersClient.service("api/users").find({
          query: {
            CompanyId: company.id,
            $limit: pageSize,
            $skip: pageSize * page,
            $sort: sorted.reduce(
              (p, c) => ({ ...p, [c.id]: c.desc ? -1 : 1 }),
              {}
            ),
            ...filters
          }
        })
      ]);
      // console.log(response);
      if (response.data.length <= 0) {
        throw new Error("PRODUCT NOT FOUND");
      }
      selection = selection.every(value =>
        response.data.some(b => b.id == value)
      )
        ? selection
        : [];
      //console.log(selection);
      this.setState({
        loading: false,
        error: null,
        data: response.data.map(a => ({
          ...a,
          isAdmin: a.permissions.some(b => b == "sysadmin")
        })),
        skip: response.skip,
        total: response.total,
        pages: Math.ceil(response.total / pageSize),
        images: [],
        selection
      });
    } catch (error) {
      console.log("error CRUDUsers", error);
      this.setState({
        ...INITIAL_STATE,
        brands: this.state.brands,
        categories: this.state.categories,
        error,
        selection: [],
        images: []
      });
    }
  };
  debouncedLoadProduct = debounce(this.loadProduct, 300, { leading: true });

  componentDidMount = async () => {
    console.log("CDM");
    const {
      initialData: { company }
    } = this.props;
    this.setState({ loading: true });
    const [lists, clients] = await Promise.all([
      feathersClient.service("api/price-lists").find({
        query: { $select: ["name", "id"], CompanyId: company.id, $limit: -1 }
      }),
      feathersClient.service("api/clients").find({
        query: {
          $select: ["denomination", "id", "externalId"],
          CompanyId: company.id,
          $limit: -1
        }
      })
    ]);
    const MultiEditFormSchema = t.struct({
      isVerified: t.maybe(t.Boolean),
      priceLists: t.maybe(
        t.list(t.enums(lists.reduce((p, c) => ({ ...p, [c.id]: c.name }), {})))
      ),
      ClientId: t.maybe(
        t.enums(
          clients.reduce(
            (p, c) => ({
              ...p,
              [c.id]: `${c.denomination} (ID Sistar ${c.externalId})`
            }),
            {}
          )
        )
      )
    });

    const SingleEditFormSchema = t.struct({
      username: t.String,
      fullName: t.maybe(t.String),
      email: t.String,
      password: t.maybe(t.String),
      personalId: t.maybe(t.String),
      phoneNumber: t.maybe(t.String),
      isAdmin: t.Boolean,
      isVerified: t.Boolean,
      address: t.maybe(
        t.struct({
          street: t.String,
          streetContinuation: t.maybe(t.String),
          streetNumber: t.Number,
          city: t.String,
          postalCode: t.String,
          province: t.String,
          country: t.String
        })
      ),
      priceLists: t.maybe(
        t.list(t.enums(lists.reduce((p, c) => ({ ...p, [c.id]: c.name }), {})))
      ),
      ClientId: t.maybe(
        t.enums(
          clients.reduce(
            (p, c) => ({
              ...p,
              [c.id]: `${c.denomination} (ID Sistar ${c.externalId})`
            }),
            {}
          )
        )
      )
    });
    this.setState({
      lists,
      clients,
      MultiEditFormSchema,
      SingleEditFormSchema,
      loading: false
    });
  };

  toggleAll = () => {
    /*
      'toggleAll' is a tricky concept with any filterable table
      do you just select ALL the records that are in your data?
      OR
      do you only select ALL the records that are in the current filtered data?

      The latter makes more sense because 'selection' is a visual thing for the user.
      This is especially true if you are going to implement a set of external functions
      that act on the selected information (you would not want to DELETE the wrong thing!).

      So, to that end, access to the internals of ReactTable are required to get what is
      currently visible in the table (either on the current page or any other page).

      The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
      ReactTable and then get the internal state and the 'sortedData'.
      That can then be iterrated to get all the currently visible records and set
      the selection state.
    */

    //console.log("toggleAll");
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      //console.log("selecting");
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.selectTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (item._original) {
          selection.push(item._original.id);
        }
      });
    }

    this.setState({ selectAll, selection });
  };

  onSaveChanges = async e => {
    e.preventDefault();
    let { selection, images } = this.state;
    let formValue = this.form.getValue();

    let parsedForm = Object.keys(formValue).reduce(
      (p, c) => ({
        ...p,
        [c]: formValue[c] === null ? undefined : formValue[c]
      }),
      {}
    );
    if (parsedForm.isAdmin != undefined) {
      parsedForm.permissions = parsedForm.isAdmin
        ? ["sysadmin", "client"]
        : ["client"];
    }

    delete parsedForm.isAdmin;
    //console.log("formValue", parsedForm);
    // parsedForm.published =
    //   selection.length == 1
    //     ? parsedForm.published
    //     : !parsedForm.published || parsedForm.published == "unchanged"
    //     ? undefined
    //     : parsedForm.published == "published";
    // parsedForm.images =
    //   selection.length == 1
    //     ? [
    //         ...parsedForm.images,
    //         ...images.map(a => a.serverId.replace(/"/g, ""))
    //       ]
    //     : undefined;
    this.setState({ loading: true });
    await feathersClient
      .service("api/users")
      .patch(
        selection.length == 1 ? selection[0] : null,
        { ...parsedForm },
        selection.length > 1
          ? { query: { id: { $in: [...selection] } } }
          : undefined
      );
    //console.log(this.selectTable);
    if (this.selectTable && this.selectTable.wrappedInstance) {
      this.selectTable.wrappedInstance.fireFetchData();
    }
  };

  handleInit = a => {
    this.pond._pond.setOptions({
      server: {
        process: {
          url: "/api/file?folder=products",
          headers: {
            Authorization: window.localStorage.getItem("feathers-jwt")
          }
        }
      },
      acceptedFileTypes: ["image/*"],
      labelIdle:
        'Arrastra tus imagenes adjuntas aquí o <span class="filepond--label-action"> seleccionar de mi computadora </span>',
      allowPaste: true,
      dropOnPage: true,
      timeout: 10000000
    });
  };

  render() {
    const {
      intl: { formatMessage },
      initialData: {
        company: { interface: uiConfiguration }
      },
      location: { hash }
    } = this.props;

    const {
      data,
      lists,
      clients,
      loading,
      error,
      skip,
      total,
      pages,
      selection,
      MultiEditFormSchema,
      SingleEditFormSchema
    } = this.state;

    const formOptions = {
      fields: {
        fullName: {
          // you can use strings or JSX
          label: "Nombre"
        },
        personalId: {
          label: "DNI"
        },
        email: {
          label: "E-Mail"
        },
        password: {
          label: "Contraseña",
          type: "password"
        },
        phoneNumber: {
          label: "Telefono"
        },
        isVerified: {
          label: "E-Mail Verificado (cuenta activada)"
        },
        isAdmin: {
          label: "Es Administrador"
        },
        address: {
          label: "Dirección",
          fields: {
            street: {
              label: "Calle"
            },
            streetContinuation: {
              label: "Calle (detalles e.g. Piso, Depto, etc)"
            },
            streetNumber: {
              label: "Numero"
            },
            city: {
              label: "Ciudad"
            },
            postalCode: {
              label: "Codigo Postal"
            },
            province: {
              label: "Provincia / Estado"
            },
            country: {
              label: "País"
            }
          }
        },
        priceLists: {
          label: "Listas de precios",
          disableOrder: true
          // item: {
          //   template: imageTemplate
          // }
        }
      }
    };

    const extraProps = {
      selectAll: this.state.selectAll,
      isSelected: key => {
        /*
          Instead of passing our external selection state we provide an 'isSelected'
          callback and detect the selection state ourselves. This allows any implementation
          for selection (either an array, object keys, or even a Javascript Set object).
        */
        // //console.log("isSelected?", key);
        return this.state.selection.includes(key);
      },
      toggleAll: this.toggleAll,
      toggleSelection: this.toggleSelection,
      selectType: this.state.selectType
    };

    const DefaultTextFilter = ({ filter, onChange }) => (
      <DebounceInput
        minLength={1}
        debounceTimeout={1000}
        value={filter ? filter.value : ""}
        onChange={e => onChange(e.target.value)}
      />
    );

    const isOnSelect = this.props.onSelect && this.props.select;
    const WrapperClass = isOnSelect ? Segment : Container;
    let basicColumns = [
      {
        Header: "ID",
        id: "id",
        accessor: d => d.id,
        Filter: DefaultTextFilter
      },
      {
        Header: "Usuario",
        id: "username",
        accessor: d => d.username,
        Filter: DefaultTextFilter
      },
      {
        Header: "Email",
        id: "email",
        accessor: d => d.email,
        Filter: DefaultTextFilter
      },
      {
        Header: "DNI",
        id: "personalId",
        accessor: d => d.personalId,
        Filter: DefaultTextFilter
      }
    ];
    if (!isOnSelect) {
      basicColumns = [
        ...basicColumns,
        {
          Header: "Nombre",
          id: "fullName",
          accessor: d => d.fullName,
          Filter: DefaultTextFilter
        }
      ];
    }

    return (
      <WrapperClass
        basic={isOnSelect ? true : undefined}
        style={{ paddingTop: 10 }}
      >
        <Header>Administrar Usuarios</Header>
        <Segment basic>
          {!this.state.error ? null : (
            <Message content={this.state.error.message} negative />
          )}
          <Input
            placeholder="Email"
            value={this.state.newUserForm.email}
            onChange={(e, { value }) =>
              this.setState({
                newUserForm: {
                  ...this.state.newUserForm,
                  email: value,
                  username: value
                }
              })
            }
          />
          <Input
            placeholder="Nombre Completo"
            value={this.state.newUserForm.fullName}
            onChange={(e, { value }) =>
              this.setState({
                newUserForm: { ...this.state.newUserForm, fullName: value }
              })
            }
          />
          {/* <Dropdown key="clientPicker" selection placeholder="Cliente" value={this.state.newUserForm.ClientId} onChange={(e, {value}) => this.setState({
                newUserForm: {
                  ...this.state.newUserForm,
                  ClientId: value
                }})} options={clientOptions}/> */}
          <Input
            placeholder="Contraseña"
            value={this.state.newUserForm.password}
            type="password"
            onChange={(e, { value }) =>
              this.setState({
                newUserForm: { ...this.state.newUserForm, password: value }
              })
            }
          />

          <Button
            size="small"
            icon="plus"
            content="Crear Usuario"
            inverted
            positive
            onClick={this.onCreateUser}
            disabled={
              this.state.loading ||
              this.state.newUserForm.fullName.length < 3 ||
              this.state.newUserForm.password.length < 6 ||
              this.state.newUserForm.email.length < 3 ||
              this.state.newUserForm.email.indexOf("@") == -1
            }
          />
        </Segment>
        <Segment basic>
          <SelectTable
            {...extraProps}
            ref={r => (this.selectTable = r)}
            columns={basicColumns}
            keyField="id"
            key="selectUserTable"
            manual // Forces table not to paginate or sort automatically, so we can handle it server-side
            data={data}
            getTheadFilterThProps={() => ({
              className: "ui fluid form",
              style: {
                overflow: "visible"
              }
            })}
            pages={pages} // Display the total number of pages
            loading={loading} // Display the loading overlay when we need it
            onFetchData={this.debouncedLoadProduct}
            defaultSorted={[
              {
                id: "fullName",
                desc: false
              }
            ]}
            filterable
            defaultPageSize={isOnSelect ? 5 : 10}
            className="-striped -highlight"
          />
        </Segment>
        {selection.length <= 0 || loading || this.props.onSelect ? null : (
          <Segment basic>
            <Header size="medium">
              {selection.length == 1
                ? `Editando ${
                    (
                      data.find(aProduct => aProduct.id == selection[0]) || {
                        fullName: "N/D"
                      }
                    ).fullName
                  }`
                : `Editando ${selection.length} elementos`}
            </Header>
            <Form>
              <t.form.Form
                value={
                  selection.length == 1
                    ? data.find(aProduct => aProduct.id == selection[0])
                    : undefined
                }
                ref={ref => (this.form = ref)}
                type={
                  selection.length == 1
                    ? SingleEditFormSchema
                    : MultiEditFormSchema
                }
                options={formOptions}
              />
              <Segment basic>
                {true || selection.length != 1 ? null : (
                  <FilePond
                    ref={ref => {
                      this.pond = ref;
                    }}
                    allowMultiple={true}
                    maxFiles={5}
                    acceptedFileTypes={[]}
                    dropOnPage={true}
                    name="uri"
                    server="/api/file"
                    oninit={this.handleInit}
                    onprocessfile={e => {
                      // Set current file objects to this.state

                      this.setState({
                        images: this.pond.getFiles()
                      });
                    }}
                    onupdatefiles={fileItems => {
                      // Set current file objects to this.state

                      this.setState({
                        images: fileItems
                      });
                    }}
                  >
                    {/* {this.state.images.map(file => (
                        <File key={file.file} src={file.file} origin="local" />
                    ))} */}
                  </FilePond>
                )}
                <Form.Group>
                  <Button
                    content="Save"
                    onClick={this.onSaveChanges}
                    disabled={
                      this.state.images.length > 0 &&
                      this.state.images.reduce((p, a) => {
                        return p || !a.serverId;
                      }, false)
                    }
                  />
                </Form.Group>
              </Segment>
            </Form>
          </Segment>
        )}
      </WrapperClass>
    );
  }
}

const CategoryBreadcrumb = ({ uiConfiguration, categories, product }) => {
  return (
    <Breadcrumb>
      <Breadcrumb.Section
        link
        as={Link}
        to="/"
        style={{ color: uiConfiguration.colors.primary }}
      >
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      {categories.map((aCategory, idx) => [
        <Breadcrumb.Section
          link
          key={`${idx}Category${aCategory.id}Section`}
          as={Link}
          to={`/category/${aCategory.id}`}
          style={{ color: uiConfiguration.colors.primary }}
        >
          {aCategory.name}
        </Breadcrumb.Section>,
        <Breadcrumb.Divider
          key={`${idx}Category${aCategory.id}Divider`}
          icon="right angle"
        />
      ])}

      <Breadcrumb.Section active>{product.name}</Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default injectIntl(CRUDUsersScreen);
