import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Dropdown,
  Breadcrumb,
  List,
  Menu,
  Pagination,
  Message,
  Grid,
  GridColumn,
  Button,
  Input
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ProductCards from "../components/ProductCards";
import ImageGallery from "../components/ImageGallery";
import feathersClient from "../feathersClient";
import { injectIntl, defineMessages } from "react-intl";

const messages = defineMessages({
  header: {
    id: "app.views.purchasesList.header",
    defaultMessage: "Compras"
  },
  requestedStatus: {
    id: "app.views.purchasesList.status.requested",
    defaultMessage: "Solicitado"
  },
  payedStatus: {
    id: "app.views.purchasesList.status.payed",
    defaultMessage: "Pagado"
  },
  pendingPaymentStatus: {
    id: "app.views.purchasesList.status.pendingPayment",
    defaultMessage: "Pago Pendiente"
  },
  authorizedStatus: {
    id: "app.views.purchasesList.status.authorized",
    defaultMessage: "Pago Autorizado"
  },
  rejectedStatus: {
    id: "app.views.purchasesList.status.rejected",
    defaultMessage: "Pago Rechazado"
  },
  anulledStatus: {
    id: "app.views.purchasesList.status.anulled",
    defaultMessage: "Pago Anulado"
  },
  confirmingStatus: {
    id: "app.views.purchasesList.status.confirming",
    defaultMessage: "Confirmando Pedido y verificando Stock"
  },
  confirmedStatus: {
    id: "app.views.purchasesList.status.confirmed",
    defaultMessage: "Pedido confirmado  y Stock verificado"
  },
  shippedStatus: {
    id: "app.views.purchasesList.status.shipped",
    defaultMessage: "Pedido despachado"
  },
  deliveredStatus: {
    id: "app.views.purchasesList.status.delivered",
    defaultMessage: "Pedido entregado"
  },
  "picked-upStatus": {
    id: "app.views.purchasesList.status.picked-up",
    defaultMessage: "Pedido retirado"
  },
  completedStatus: {
    id: "app.views.purchasesList.status.completed",
    defaultMessage: "Pedido completado"
  },
  cancelledStatus: {
    id: "app.views.purchasesList.status.cancelled",
    defaultMessage: "Pedido Cancelado"
  },
  returnedStatus: {
    id: "app.views.purchasesList.status.returned",
    defaultMessage: "Pedido Devuelto"
  }
});
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";
class PurchasesListScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      data: []
    };
  }

  loadPurchases = async () => {
    let { session } = this.props;
    const {
      intl: { formatMessage },
      initialData: { company }
    } = this.props;
    //console.log(this.props);
    this.setState({
      loading: true,
      error: null,
      data: []
    });
    try {
      let data = await feathersClient.service("api/orders").find({
        query: {
          $limit: -1,
          UserId: session.user.id,
          $sort: {
            createdAt: -1
          },
          CompanyId: company.id,
          status: { $nin: ["cart", "anonymousCart", "pendingPayment"] }
        }
      });
      //console.log(data);
      this.setState({
        data,
        loading: false,
        error: null
      });
    } catch (error) {
      //console.log(error);
      this.setState({
        data: [],
        loading: false,
        error
      });
    }
  };

  componentDidMount() {
    this.loadPurchases();
  }

  render() {
    const {
      intl: { formatMessage },
      initialData: {
        company: { interface: uiConfiguration }
      }
    } = this.props;
    const { data, loading, error } = this.state;
    return (
      <Container style={{ paddingTop: 10 }}>
        <CategoryBreadcrumb
          formatMessage={formatMessage}
          uiConfiguration={uiConfiguration}
        />
        <Header size="large">{formatMessage(messages.header)}</Header>
        <Segment loading={loading}>
          <List divided verticalAlign="middle" size="huge">
            {!loading ? null : <Message>Cargando...</Message>}
            {data.length <= 0 && !loading && !error ? (
              <Message>No tienes pedidos!</Message>
            ) : (
              ""
            )}
            {data.map(anOrder => (
              <List.Item key={anOrder.id}>
                <List.Content />
                <Image size="tiny" src={placeholderProductImg} />

                <List.Content>
                  <List.Header>Compra #{anOrder.id}</List.Header>
                  Estado: {formatMessage(messages[`${anOrder.status}Status`])}
                  <br />
                  {moment(
                    anOrder.paymentMetadata.txndatetime,
                    "YYYY:MM:DD-HH:mm:ss"
                  ).format("DD/MM/YYYY HH:mm")}
                </List.Content>
                <List.Content floated="right" style={{ marginTop: 30 }}>
                  <List.Header>
                    $ {anOrder.paymentMetadata.finalAmount}
                  </List.Header>
                  <Button
                    basic
                    size="small"
                    content="Detalle"
                    as={Link}
                    to={`/account/dashboard/purchases/${anOrder.id}`}
                    style={{
                      padding: 5,
                      marginTop: 10,
                      marginBottom: 10,
                      color: uiConfiguration.colors.primary
                    }}
                  />
                </List.Content>
                <List.Content floated="right" style={{ marginTop: 15 }} />
              </List.Item>
            ))}
          </List>
        </Segment>
        {/* <Pagination defaultActivePage={5} totalPages={10} /> */}
      </Container>
    );
  }
}

const CategoryBreadcrumb = props => {
  return (
    <Breadcrumb>
      <Breadcrumb.Section
        link
        as={Link}
        to="/"
        style={{ color: props.uiConfiguration.colors.primary }}
      >
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section>Mi Cuenta</Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section active>
        {props.formatMessage(messages.header)}
      </Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default injectIntl(PurchasesListScreen);
