import React, { Component } from "react";
import PropTypes from "prop-types";
import feathersClient from "../feathersClient";
import queryString from "query-string";
import {
  Container,
  Image,
  Segment,
  Header,
  Label,
  Card,
  Breadcrumb,
  Menu,
  Grid,
  Button,
  Icon
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ProductCards from "../components/ProductCards";
import { injectIntl, defineMessages } from "react-intl";

const messages = defineMessages({
  sortBy: {
    id: "app.views.category.sortBy.header",
    defaultMessage: "Sort By"
  },
  priceSort: {
    id: "app.views.category.sortBy.price",
    defaultMessage: "Price"
  },
  nameSort: {
    id: "app.views.category.sortBy.name",
    defaultMessage: "Name"
  },
  subcategories: {
    id: "app.views.category.subcategories.header",
    defaultMessage: "Subcategories"
  },
  categories: {
    id: "app.views.category.categories.header",
    defaultMessage: "Categories"
  },
  header: {
    id: "app.views.category.header",
    defaultMessage: "A Category"
  },
  headerSearch: {
    id: "app.views.category.headerSearch",
    defaultMessage: "Search Results for "
  }
});
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";

const SORT_OPTIONS = {
  NAME_DESC: "NAME_DESC",
  NAME_ASC: "NAME_ASC",
  PRICE_DESC: "PRICE_DESC",
  PRICE_ASC: "PRICE_ASC"
};

const INITIAL_STATE = {
  products: {
    loading: false,
    error: null,
    data: [],
    limit: 25,
    total: 0,
    sort: SORT_OPTIONS.NAME_ASC,
    filter: null
  }
};
class CategoryScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }

  loadProducts = async () => {
    const { sort, filter, limit } = this.state.products;
    console.log("LOG: CategoryScreen -> loadProducts -> limit", limit);
    const {
      match: { params },
      initialData: {
        company,
        categories: { data: categories }
      },
      searchView
    } = this.props;
    this.setState({
      products: {
        ...this.state.products,
        loading: true,
        error: null
      }
    });
    let sortConfiguration;
    switch (sort) {
      case SORT_OPTIONS.NAME_ASC:
        sortConfiguration = { name: -1 };
        break;
      case SORT_OPTIONS.NAME_DESC:
        sortConfiguration = { name: 1 };
        break;
      case SORT_OPTIONS.PRICE_ASC:
        sortConfiguration = { price: 1, name: -1 };
        break;
      case SORT_OPTIONS.PRICE_DESC:
        sortConfiguration = { price: -1, name: -1 };
        break;
      default:
        sortConfiguration = { name: -1 };
    }

    let CategoriesIds = categories
      .filter(aCategory => {
        let parentCategories = [];
        let lastCategory = { ...aCategory };
        let stop = false;
        while (lastCategory.CategoryId != null && stop == false) {
          let parentCat = categories.find(
            aCat => aCat.id == lastCategory.CategoryId
          );
          if (!parentCat) {
            stop = true;
            return false;
          } else {
            if (parentCat.id == params.id) {
              stop = true;
              return true;
            }
            parentCategories = [parentCat, ...parentCategories];
            lastCategory = { ...parentCat };
          }
          return false;
        }
      })
      .map(aCat => aCat.id);

    let currentCategory = categories.find(
      aCategory => aCategory.id == params.id
    );

    let validFilters = !params.id
      ? []
      : [
          "brand",
          "extraField1",
          "extraField2",
          "extraField3",
          "extraField4",
          "extraField5",
          "extraField6",
          "arrayField1"
        ].filter(aField => currentCategory[aField] != null);
    let filters = queryString.parse(this.props.location.search).filter;
    if (!!filters) {
      filters = JSON.parse(filters);
      filters = filters.reduce((p, c, i) => {
        if (c == null) {
          return p;
        }
        if (validFilters[i].indexOf("array") != -1) {
          return {
            ...p,
            [validFilters[i]]: { $contains: Array.isArray(c) ? c : [c] }
          };
        }
        return { ...p, [validFilters[i]]: c };
      }, {});
    } else {
      filters = {};
    }

    let query = {
      $sort: sortConfiguration,
      $select: ["name", "id", "images", "CollectionId"],
      CategoryId: params.id
        ? { $in: [Number(params.id), ...CategoriesIds] }
        : undefined,
      CompanyId: company.id,
      $limit: limit,
      published: true,
      ...filters
    };

    if (searchView) {
      let searchQuery = queryString.parse(this.props.location.search).q;
      query.name = { $iLike: `%${searchQuery}%` };
    }
    //console.log(query);
    try {
      const response = await feathersClient
        .service("api/products")
        .find({ query });
      console.log(
        "LOG: CategoryScreen -> loadProducts -> response.total",
        response.total
      );

      let collectionsIds = [
        ...new Set(response.data.map(a => a.CollectionId).filter(a => !!a))
      ];
      let baseLength = collectionsIds.length;

      //const collections = await feathersClient.service("api/collections").find([query])
      //console.log(response);
      let finalData = response.data.filter(a => {
        console.log({ a });
        if (!a.CollectionId || baseLength == 0) {
          console.log(1);
          return true;
        }
        let keep = collectionsIds.some(b => b == a.CollectionId);
        console.log({ keep });

        if (keep) {
          collectionsIds = collectionsIds.filter(b => b != a.CollectionId);
        }
        return keep;
      });
      console.log({ data: response.data, collectionsIds, finalData });
      this.setState({
        products: {
          ...this.state.products,
          loading: false,
          error: null,
          data: finalData,
          total: response.total
        }
      });
    } catch (error) {
      this.setState({
        products: {
          ...this.state.products,
          loading: false,
          error
        }
      });
    }
  };

  setNameSort = () => {
    let currentSort = this.state.products.sort;
    this.setState(
      {
        products: {
          ...this.state.products,
          sort:
            currentSort == SORT_OPTIONS.NAME_ASC
              ? SORT_OPTIONS.NAME_DESC
              : SORT_OPTIONS.NAME_ASC
        }
      },
      () => {
        this.loadProducts();
      }
    );
  };

  setPriceSort = () => {
    let currentSort = this.state.products.sort;
    this.setState(
      {
        products: {
          ...this.state.products,
          sort:
            currentSort == SORT_OPTIONS.PRICE_ASC
              ? SORT_OPTIONS.PRICE_DESC
              : SORT_OPTIONS.PRICE_ASC
        }
      },
      () => {
        this.loadProducts();
      }
    );
  };

  componentDidMount() {
    this.loadProducts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id != this.props.match.params.id) {
      this.setState(
        {
          ...INITIAL_STATE
        },
        () => {
          this.loadProducts();
        }
      );
    } else if (
      queryString.parse(prevProps.location.search).q !=
      queryString.parse(this.props.location.search).q
    ) {
      this.setState(
        {
          ...INITIAL_STATE
        },
        () => {
          this.loadProducts();
        }
      );
    } else if (
      queryString.parse(prevProps.location.search).filter !=
      queryString.parse(this.props.location.search).filter
    ) {
      this.setState(
        {
          ...INITIAL_STATE
        },
        () => {
          this.loadProducts();
        }
      );
    }
  }
  render() {
    const {
      intl: { formatMessage },
      initialData: {
        company: { interface: uiConfiguration, settings },
        categories: { data: categories },
        groups
      },
      match: { params }
    } = this.props;

    const { products } = this.state;

    const setArrayValueAndReturnItself = (array, pos, value) => {
      let copy = [...array];
      copy[pos] = value;
      return copy;
    };
    let subcategories = categories.filter(
      aCategory => aCategory.CategoryId == params.id
    );

    let currentCategory = categories.find(
      aCategory => aCategory.id == params.id
    );
    let parentCategory = !currentCategory
      ? null
      : categories.find(
          aCategory => aCategory.id == currentCategory.CategoryId
        );
    let sisCategories = !parentCategory
      ? []
      : categories.filter(
          aCategory => aCategory.CategoryId == parentCategory.id
        );
    let headerName = this.props.searchView
      ? `${formatMessage(messages.headerSearch)} "${
          queryString.parse(this.props.location.search).q
        }"`
      : currentCategory.name;

    let validFilters = !params.id
      ? []
      : [
          "brand",
          "extraField1",
          "extraField2",
          "extraField3",
          "extraField4",
          "extraField5",
          "extraField6",
          "arrayField1"
        ].filter(aField => currentCategory[aField] != null);
    let filters = queryString.parse(this.props.location.search).filter;
    if (!!filters) {
      filters = JSON.parse(filters);
    } else {
      filters = [];
    }
    return (
      <Container style={{ paddingTop: 10 }}>
        <Grid stackable doubling>
          <Grid.Column width="2" only="tablet computer">
            <Menu vertical text style={{ paddingTop: 100 }}>
              <Menu.Item header>{"Ordenar Por"}</Menu.Item>
              <Menu.Item
                name={"Nombre"}
                style={{
                  fontWeight:
                    products.sort == SORT_OPTIONS.NAME_DESC ||
                    products.sort == SORT_OPTIONS.NAME_ASC
                      ? "bold"
                      : undefined
                }}
                icon={
                  products.sort == SORT_OPTIONS.NAME_DESC
                    ? "sort alphabet up"
                    : products.sort == SORT_OPTIONS.NAME_ASC
                    ? "sort alphabet down"
                    : "font"
                }
                active={
                  products.sort == SORT_OPTIONS.NAME_DESC ||
                  products.sort == SORT_OPTIONS.NAME_ASC
                }
                onClick={this.setNameSort}
              />
              <Menu.Item
                name={"Precio"}
                style={{
                  fontWeight:
                    products.sort == SORT_OPTIONS.PRICE_DESC ||
                    products.sort == SORT_OPTIONS.PRICE_ASC
                      ? "bold"
                      : undefined
                }}
                active={
                  products.sort == SORT_OPTIONS.PRICE_DESC ||
                  products.sort == SORT_OPTIONS.PRICE_ASC
                }
                icon={
                  products.sort == SORT_OPTIONS.PRICE_DESC
                    ? "caret up"
                    : products.sort == SORT_OPTIONS.PRICE_ASC
                    ? "caret down"
                    : "money"
                }
                onClick={this.setPriceSort}
              />
              {subcategories.length <= 0 ? null : (
                <Menu.Item header>
                  {this.props.searchView ? "Categorias" : "Subcategorias"}
                </Menu.Item>
              )}
              {subcategories.map(aCategory => (
                <Menu.Item
                  name={aCategory.name}
                  as={Link}
                  to={`/${this.props.searchView ? "search" : "category"}/${
                    aCategory.id
                  }${
                    !this.props.searchView
                      ? ""
                      : `?q=${queryString.parse(this.props.location.search).q}`
                  }`}
                />
              ))}
              {sisCategories.length <= 0 ? null : (
                <Menu.Item header>{parentCategory.name}</Menu.Item>
              )}
              {sisCategories.map(aCategory => (
                <Menu.Item
                  name={aCategory.name}
                  as={Link}
                  active={aCategory.id == currentCategory.id}
                  style={{
                    fontWeight:
                      aCategory.id == currentCategory.id ? "bold" : undefined
                  }}
                  to={`/${this.props.searchView ? "search" : "category"}/${
                    aCategory.id
                  }${
                    !this.props.searchView
                      ? ""
                      : `?q=${queryString.parse(this.props.location.search).q}`
                  }`}
                />
              ))}
              {validFilters.map((aFilter, idx) => {
                let options = groups.filter(a => a.CategoryId == params.id);
                filters.forEach((aFilter, idx2) => {
                  if (!aFilter || idx2 == idx) {
                    return;
                  }
                  options = options.filter(
                    anOpt => anOpt[validFilters[idx2]] == aFilter
                  );
                });
                options = [
                  ...new Set(
                    options.map(a => a[aFilter]).filter(a => a != null)
                  )
                ];
                if (options.length <= 0) {
                  return null;
                }
                return [
                  <Menu.Item
                    header
                    name={currentCategory[aFilter]}
                    keu={aFilter}
                  />,
                  options.map(anOption => {
                    let newFilter = queryString.parse(
                      this.props.location.search
                    ).filter;
                    if (!newFilter) {
                      newFilter = [];
                    } else {
                      newFilter = JSON.parse(newFilter);
                    }
                    newFilter[idx] = anOption;

                    if (newFilter[idx] == filters[idx]) {
                      newFilter[idx] = null;
                    }
                    return (
                      <Menu.Item
                        name={anOption}
                        keu={anOption}
                        style={{
                          fontWeight:
                            filters[idx] == anOption ? "bold" : undefined
                        }}
                        active={filters[idx] == anOption}
                        icon={filters[idx] == anOption ? "delete" : undefined}
                        as={Link}
                        to={`/${
                          this.props.searchView ? "search" : "category"
                        }/${params.id}${`?filter=${JSON.stringify(
                          newFilter
                        )}`}`}
                      />
                    );
                  })
                ];
              })}
            </Menu>
          </Grid.Column>
          <Grid.Column width="14">
            {this.props.searchView ? null : (
              <CategoryBreadcrumb
                uiConfiguration={uiConfiguration}
                categories={categories}
                currentCategory={currentCategory}
              />
            )}
            <Segment basic>
              {filters.map((aFilter, idx) =>
                !aFilter ? null : (
                  <Label
                    tag
                    as={Link}
                    to={`/category/${params.id}?filter=${JSON.stringify(
                      setArrayValueAndReturnItself(filters, idx, null)
                    )}`}
                  >
                    {aFilter}
                    <Icon name="delete" />
                  </Label>
                )
              )}
            </Segment>
            <ProductCards
              header={headerName}
              showName
              showWarning
              itemsPerRow={settings.itemsPerRow || 4}
              products={products.data.map(aProduct => {
                let anImage =
                  aProduct.images.length > 0
                    ? aProduct.images[0]
                    : placeholderProductImg;

                let thumbnailVersion = anImage;
                if (
                  anImage != placeholderProductImg &&
                  !uiConfiguration.disableThumbnails
                ) {
                  thumbnailVersion = `${anImage}`.split(".");
                  let ext = thumbnailVersion.pop();

                  thumbnailVersion = anImage.replace(
                    `.${ext}`,
                    `-thumbnail-512-512.${ext}`
                  );
                }
                return {
                  ...aProduct,
                  thumbnail: thumbnailVersion
                };
              })}
              shouldScroll={false}
              loading={products.loading}
              defaultColor={
                !!uiConfiguration.colors
                  ? uiConfiguration.colors.primary
                  : undefined
              }
            />
            {!products.loading &&
            products.total > products.data.length &&
            products.data.length > 0 ? (
              <Button
                onClick={() => {
                  this.setState(
                    {
                      products: {
                        ...this.state.products,
                        limit: this.state.products.limit + 15
                      }
                    },
                    () => {
                      this.loadProducts();
                    }
                  );
                }}
                content="Ver más"
              />
            ) : null}
          </Grid.Column>
          <Grid.Column only="mobile">
            <Menu text stackable>
              <Menu.Item header>{"Ordenar Por"}</Menu.Item>
              <Menu.Item
                name={"Nombre"}
                icon={
                  products.sort == SORT_OPTIONS.NAME_DESC
                    ? "sort alphabet up"
                    : products.sort == SORT_OPTIONS.NAME_ASC
                    ? "sort alphabet down"
                    : "font"
                }
                active={
                  products.sort == SORT_OPTIONS.NAME_DESC ||
                  products.sort == SORT_OPTIONS.NAME_ASC
                }
                onClick={this.setNameSort}
              />
              <Menu.Item
                name={"Precio"}
                active={
                  products.sort == SORT_OPTIONS.PRICE_DESC ||
                  products.sort == SORT_OPTIONS.PRICE_ASC
                }
                icon={
                  products.sort == SORT_OPTIONS.PRICE_DESC
                    ? "caret up"
                    : products.sort == SORT_OPTIONS.PRICE_ASC
                    ? "caret down"
                    : "money"
                }
                onClick={this.setPriceSort}
              />
              {subcategories.length <= 0 ? null : (
                <Menu.Item header>
                  {this.props.searchView ? "Categorias" : "Subcategorias"}
                </Menu.Item>
              )}
              {subcategories.map(aCategory => (
                <Menu.Item
                  name={aCategory.name}
                  as={Link}
                  to={`/${this.props.searchView ? "search" : "category"}/${
                    aCategory.id
                  }${
                    !this.props.searchView
                      ? ""
                      : `?q=${queryString.parse(this.props.location.search).q}`
                  }`}
                />
              ))}
            </Menu>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const CategoryBreadcrumb = ({
  uiConfiguration,
  currentCategory,
  categories
}) => {
  let parentCategories = [];
  let lastCategory = { ...currentCategory };
  let stop = false;
  while (lastCategory.CategoryId != null && stop == false) {
    let parentCat = categories.find(aCat => aCat.id == lastCategory.CategoryId);
    if (!parentCat) {
      stop = true;
    } else {
      parentCategories = [parentCat, ...parentCategories];
      lastCategory = { ...parentCat };
    }
  }
  return (
    <Breadcrumb style={{ paddingLeft: 10 }}>
      <Breadcrumb.Section
        link
        as={Link}
        to="/"
        style={{ color: uiConfiguration.colors.primary }}
      >
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section
        link
        as={Link}
        to="/categories"
        style={{ color: uiConfiguration.colors.primary }}
      >
        Categorias
      </Breadcrumb.Section>
      {parentCategories
        .map(aParent => [
          <Breadcrumb.Divider
            icon="right angle"
            key={`${aParent.id}parentIdDivider`}
          />,
          <Breadcrumb.Section
            link
            key={`${aParent.id}parentIdSection`}
            as={Link}
            to={`/category/${aParent.id}`}
            style={{ color: uiConfiguration.colors.primary }}
          >
            {aParent.name}
          </Breadcrumb.Section>
        ])
        .reduce((p, c) => [...p, ...c], [])}

      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section active>{currentCategory.name}</Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default injectIntl(CategoryScreen);
//test
