import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { injectIntl, defineMessages } from "react-intl";
import feathersClient from "../feathersClient";
import {
  Grid,
  Header,
  Container,
  Segment,
  Form,
  Button,
  Loader
} from "semantic-ui-react";
import ShoppingCartScreen from "./ShoppingCartScreen";
import _ from "lodash";
const messages = defineMessages({
  header: {
    id: "app.views.advancedsearch.header",
    defaultMessage: "Busqueda Avanzada"
  },
  name: {
    id: "app.views.advancedsearch.fields.name",
    defaultMessage: "Nombre"
  },
  description: {
    id: "app.views.advancedsearch.fields.description",
    defaultMessage: "Descripción"
  },
  brand: {
    id: "app.views.advancedsearch.fields.brand",
    defaultMessage: "Marca"
  },
  category: {
    id: "app.views.advancedsearch.categoryNames.category",
    defaultMessage: "Categoria"
  },
  subcategory: {
    id: "app.views.advancedsearch.categoryNames.subcategory",
    defaultMessage: "Subcategoria"
  },
  segment: {
    id: "app.views.advancedsearch.categoryNames.segment",
    defaultMessage: "Segmento"
  },
  subsegment: {
    id: "app.views.advancedsearch.categoryNames.subsegment",
    defaultMessage: "Subsegmento"
  }
});

// function useColumns(settings) {
//   const [columns, setColumns] = useState([{}])
// }

function useArrayState(initialValue = []) {
  const [arrayState, setState] = useState(initialValue);
  function setStateForPosition(state, position) {
    let newArray = [...arrayState];
    newArray[position] = state;
    setState(newArray);
  }

  return [arrayState, setStateForPosition];
}

function useBrands(CompanyId) {
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    feathersClient
      .service("api/brands")
      .find({ query: { CompanyId, $limit: -1 } })
      .then(a => {
        // //console.log("response", a);
        setBrands(
          a.map(b => ({
            key: b.brand,
            value: b.brand,
            text: b.brand
          }))
        );
      })
      .catch(e => console.log("errors", e));
  }, []);

  return brands;
}

function AdvancedSearchScreen(props) {
  const {
    intl: { formatMessage },
    initialData: {
      company: { settings, id },
      categories: { data: categories }
    },
    location
  } = props;
  const categoriesPlaceholderNames = [
    formatMessage(messages.category),
    formatMessage(messages.subcategory),
    formatMessage(messages.segment),
    formatMessage(messages.subsegment)
  ];
  let stockRanges = settings.stockRanges;
  if (!Array.isArray(stockRanges) || stockRanges.length <= 0) {
    stockRanges = null;
  }
  const topCategories = categories.filter(aCat => aCat.CategoryId == null);
  const [categoriesValues, setCategoryValue] = useArrayState([""]);
  const [reloadProperty, setReloadProperty] = useState(Math.random());
  const brands = useBrands(id);
  // const brands = [];
  // //console.log("brands", brands);
  const [nameFilterValue, setNameFilterValue] = useState("");
  const [descriptionFilterValue, setDescriptionFilterValue] = useState("");
  const [brandFilterValue, setBrandFilterValue] = useState("");
  const [paginator, setPaginator] = useState({ page: 0, limit: 25 });
  const [response, setResponse] = useState({
    loading: false,
    data: [],
    total: 0,
    limit: 25,
    skip: 0
  });

  const [cartLoading, setCartLoading] = useState(false);
  //console.log(response);
  const advancedSearchFields = !settings.extraSearchFields
    ? []
    : settings.extraSearchFields.map(a => {
        let [get, set] = useState("");
        return { label: a, get, set: _.debounce(set, 500) };
      });

  const buildQuery = () => {
    const lastValue = _.findLast(categoriesValues, n => {
      return n != "" && !!n;
    });
    const query = {
      $limit: paginator.limit,
      name:
        nameFilterValue.length > 0
          ? { $iLike: `%${nameFilterValue}%` }
          : undefined,
      brand: brandFilterValue || undefined,
      $noStockFiltering: true,
      CompanyId: id,
      $noMerge: true,

      CategoryId:
        lastValue != "" && lastValue != undefined
          ? {
              $in: [
                lastValue,
                ...categories
                  .filter(a => a.CategoryId == lastValue)
                  .map(a => a.id)
              ]
            }
          : undefined,
      description:
        descriptionFilterValue.length > 0
          ? { $iLike: `%${descriptionFilterValue}%` }
          : undefined
    };
    advancedSearchFields.forEach(({ get: value }, idx) => {
      if (!!value && value != "") {
        query[`extraField${idx + 1}`] = { $iLike: `%${value}%` };
      }
    });
    console.log("query", query);
    return query;
  };

  useEffect(
    () => {
      const query = { ...buildQuery(), $skip: 0 };
      //console.log("query", query);
      setPaginator({ page: 0, limit: paginator.limit });
      setResponse({ loading: true, data: [], total: 0, limit: 0, skip: 0 });
      feathersClient
        .service("api/products")
        .find({
          query
        })
        .then(response => {
          setResponse({ ...response, loading: false });
        });
    },
    [
      nameFilterValue,
      brandFilterValue,
      descriptionFilterValue,
      categoriesValues,
      ...advancedSearchFields.map(a => a.get)
    ]
  );

  const addToCart = async price => {
    setCartLoading(true);
    let cartUUID = window.localStorage.getItem("cart-uuid");
    //console.log("cartUUID stored", cartUUID);
    let cartId = null;
    let { session } = props;
    try {
      if (!cartUUID) {
        let newOrder = await feathersClient.service("api/orders").create({
          UserId:
            session.authenticated && session.user ? session.user.id : undefined,
          status: "cart",
          CompanyId: id
        });
        cartUUID = newOrder.uuid;
        cartId = newOrder.id;
        window.localStorage.setItem("cart-uuid", cartUUID);
      }
      if (!cartId) {
        let order = await feathersClient.service("api/orders").find({
          query: {
            uuid: cartUUID,
            $limit: 1,
            $select: ["id"],
            status: "cart"
          }
        });
        if (order && order.data && order.data.length >= 1) {
          cartId = order.data[0].id;
        }
      }
      if (!cartId) {
        window.localStorage.removeItem("cart-uuid");
        addToCart(price);
        return;
      }
      // //console.log("this.state", this.state);
      let newEntry = await feathersClient.service("api/order-details").create({
        amount: 1,
        OrderId: cartId,
        ProductId: price.ProductId,
        priceId: price.id
      });
      setReloadProperty(Math.random());
      setCartLoading(false);
      // this.props.history.push("/cart");
    } catch (error) {
      setCartLoading(false);
      //console.log("addToCartError", error);
    }
  };

  useEffect(
    () => {
      const query = { ...buildQuery(), $skip: 0 };
      //console.log("query", query);
      setPaginator({ page: 0, limit: paginator.limit });
      setResponse({ loading: true, data: [], total: 0, limit: 0, skip: 0 });
      feathersClient
        .service("api/products")
        .find({
          query
        })
        .then(response => {
          setResponse({ ...response, loading: false });
        });
    },
    [
      nameFilterValue,
      brandFilterValue,
      descriptionFilterValue,
      categoriesValues,
      ...advancedSearchFields.map(a => a.get)
    ]
  );

  useEffect(
    () => {
      const query = {
        ...buildQuery(),
        $skip: paginator.page * paginator.limit
      };
      // //console.log("query", query, "page", page);
      setResponse({ loading: true, data: [], total: 0, limit: 0, skip: 0 });
      feathersClient
        .service("api/products")
        .find({
          query
        })
        .then(response => {
          setResponse({ ...response, loading: false });
        });
    },
    [paginator]
  );

  // //console.log("categoriesValues", categoriesValues);
  let subfilters = categoriesValues.map((aCatValue, index) => {
    if (aCatValue != "") {
      let subValues = categories.filter(aCat => aCat.CategoryId == aCatValue);
      if (subValues.length > 0) {
        if (
          !subValues.find(a => a.id == categoriesValues[index + 1]) &&
          categoriesValues[index + 1] != ""
        ) {
          setCategoryValue("", index + 1);
        }
        return (
          <Form.Select
            key={index}
            clearable
            inline
            placeholder={
              categoriesPlaceholderNames.length >= index + 1
                ? `${categoriesPlaceholderNames[index + 1]} (${
                    subValues.length
                  })`
                : ""
            }
            value={categoriesValues[index + 1]}
            onChange={(e, c) => setCategoryValue(c.value, index + 1)}
            options={subValues.map(a => ({
              key: a.id,
              value: a.id,
              text: a.name
            }))}
          />
        );
      }
    } else {
      if (
        categoriesValues[index + 1] != "" &&
        categoriesValues[index + 1] != undefined
      ) {
        setCategoryValue("", index + 1);
      }
    }
  });

  const stockMapping = amount => {
    if (!stockRanges || stockRanges.length <= 0) return amount;
    let result = stockRanges.find(a => {
      // //console.log("amount", amount, "a.min", a, "a.max", a.max);
      if (
        a.max != undefined &&
        a.min != undefined &&
        Number(amount) >= a.min &&
        Number(amount) <= a.max
      ) {
        return true;
      }
      return false;
    });
    if (!result) {
      result = stockRanges[stockRanges.length - 1];
    }
    return result.message;
  };

  const debouncedSetNameFilterValue = _.debounce(
    (e, c) => setNameFilterValue(c.value),
    500
  );

  const debouncedSetDescriptionFilterValue = _.debounce(
    (e, c) => setDescriptionFilterValue(c.value),
    500
  );

  return (
    <Container fluid style={{ marginTop: 30 }} key="advancedSearchComponent">
      <Grid columns={2} padded>
        <Grid.Column width="11" style={{overflowY: "scroll", maxHeight:"calc(100vh - 170px)"}}>
        <Header size="huge" style={{ marginLeft: 10 }}>
        {formatMessage(messages.header)}
      </Header>
          <Segment>
            <Form>
              <Form.Group inline>
                <Form.Input
                  placeholder={formatMessage(messages.name)}
                  key="nameField"
                  onChange={debouncedSetNameFilterValue}
                  width={6}
                />
                <Form.Input
                  placeholder={formatMessage(messages.description)}
                  key="descriptionField"
                  onChange={debouncedSetDescriptionFilterValue}
                  width={6}
                />
                <Form.Select
                  search
                  key="brandsField"
                  lazyLoad
                  clearable
                  value={brandFilterValue}
                  onChange={(e, c) => setBrandFilterValue(c.value)}
                  placeholder={formatMessage(messages.brand)}
                  disabled={brands.length <= 0}
                  loading={brands.length <= 0}
                  options={brands}
                />
              </Form.Group>
              <Form.Group inline>
              {advancedSearchFields.map((aSearchField, idx) => {
                  return (
                    <Form.Input
                      placeholder={aSearchField.label}
                      key={`${idx}${aSearchField}`}
                      onChange={(e, c) => aSearchField.set(c.value)}
                    />
                  );
                })}
                <Form.Select
                  placeholder={`${categoriesPlaceholderNames[0]} (${
                    topCategories.length
                  })`}
                  clearable
                  value={categoriesValues[0]}
                  onChange={(e, c) => setCategoryValue(c.value, 0)}
                  options={topCategories.map(a => ({
                    key: a.id,
                    value: a.id,
                    text: a.name
                  }))}
                />
                {subfilters}
              </Form.Group>
            </Form>
          </Segment>
          {!!response.loading ? <Loader active={true} /> : null}
          <ReactTable
            page={paginator.page}
            loading={response.loading}
            pageSize={Number(response.limit)}
            pages={Math.ceil(response.total / response.limit)}
            onPageChange={page => {
              setPaginator({ page, limit: paginator.limit });
            }}
            onPageSizeChange={pageSize => {
              setPaginator({ page: 0, limit: pageSize });
            }}
            manual
            filterable={false}
            sortable={false}
            className="-striped -highlight"
            data={response.data}
            columns={[
              {
                Header: "",
                id: "prices",
                accessor: d => d.prices,
                Cell: ({ value, row }) => {
                  return (
                    <div>
                      <Button
                        fluid
                        onClick={() => addToCart(value)}
                        color="blue">
                        ${Number(value.value).toFixed(2)} x{" "}
                        {value.packAmount}
                      </Button>
                    </div>
                  );
                }
              },
              {
                Header: "Nombre",
                id: "name",
                minWidth: 300,
                accessor: d => d.name
              },
              {
                Header: "Descripción",
                id: "description",
                minWidth: 150,
                accessor: d => d.description
              },
              {
                Header: "",
                id: "stock",
                width: 60,
                accessor: d => `${stockMapping(d.StockTransactions.amount).slice(0,4)}.`
              },
              {
                Header: "Marca",
                id: "brand",
                accessor: d => d.brand
              },
              ...settings.extraSearchFields.map((a, idx) => ({
                Header: a,
                id: `extraField${idx + 1}`,
                accessor: d => d[`extraField${idx + 1}`]
              }))
            ]}
          />
        </Grid.Column>
        <Grid.Column width="5">
          <Header>Carrito</Header>
          <Segment loading={cartLoading}>
            <ShoppingCartScreen
              {...props}
              height={"40vh"}
              simple
              noHeader={true}
              reload={reloadProperty}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    </Container>
  );
}

export default injectIntl(AdvancedSearchScreen);
