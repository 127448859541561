import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Dropdown,
  Form,
  Accordion,
  Icon,
  Breadcrumb,
  Menu,
  Grid,
  GridColumn,
  Button,
  Message,
  Responsive
} from "semantic-ui-react";
import Provinces from "../data/provinces";
import { Link } from "react-router-dom";
import ProductCards from "../components/ProductCards";
import ImageGallery from "../components/ImageGallery";
import { injectIntl, defineMessages } from "react-intl";
import _ from "lodash";
import ProductsList from "../components/ProductsList";
import countries from "../data/countries";
import cities from "../data/cities";
import feathersClient from "../feathersClient";
const messages = defineMessages({
  amounts: {
    id: "app.views.checkout.product.units.label",
    defaultMessage: "Cantidad"
  },
  characteristics: {
    id: "app.views.checkout.product.characteristics.header",
    defaultMessage: "Caracteristicas"
  },
  singleUnit: {
    id: "app.views.checkout.product.units.singular",
    defaultMessage: "Unidad"
  },
  multipleUnits: {
    id: "app.views.checkout.product.units.plural",
    defaultMessage: "Unidades"
  },
  paymentMethods: {
    id: "app.views.checkout.product.payment.header",
    defaultMessage: "Formas de Pago"
  },
  shippingInformation: {
    id: "app.views.checkout.product.shipping.header",
    defaultMessage: "Información de Envio"
  },
  payButton: {
    id: "app.views.checkout.product.purchase.button",
    defaultMessage: "Pagar"
  },
  orderButton: {
    id: "app.views.checkout.product.order.button",
    defaultMessage: "Pedir"
  }
});
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";
class CheckoutScreen extends Component {
  constructor(props) {
    super(props);
    let client = props.session.user.client;
    let billing = !!client
      ? {
          fullName: client.denomination,
          personalId: client.accountInfo.CUIT,
          phoneNumber: client.phone
        }
      : {
          fullName: props.session.user.fullName,
          personalId: props.session.user.personalId,
          phoneNumber: props.session.user.phoneNumber
        };
    this.state = {
      mode: "in-store",
      billing,
      disabledBillingEditting: !!client ? true : false,
      loading: false,
      error: null,
      cart: null,
      cartItems: [],
      products: [],
      prices: [],
      priceLists: [],
      categories: [],
      paymentForm: null,
      method: null,
      instalments: null,
      delivery: {
        country: "Argentina",
        city: "",
        province: "",
        address1: "",
        address2: "",
        postalCode: ""
      }
    };
  }

  placeOrder = async () => {
    const {
      intl: { formatMessage },
      initialData: { company }
    } = this.props;
    const { cart, loading } = this.state;
    if (!cart || !cart.id || !!loading) {
      //console.log("placeOrder empty");
      return;
    }
    this.setState({
      loading: true,
      error: null
    });
    try {
      let updatedOrder = await feathersClient
        .service("api/store-order")
        .get(cart.id);
      this.props.history.push(
        `/account/dashboard/purchases/${updatedOrder.id}`
      );
    } catch (error) {
      //console.log("placeOrder error", error);
      this.setState({ error });
    }
  };

  loadCart = async () => {
    const {
      intl: { formatMessage },
      initialData: { company }
    } = this.props;
    let { settings } = company;
    let cartUUID = window.localStorage.getItem("cart-uuid");
    let { session } = this.props;
    let cart = null;

    try {
      if (!cartUUID) {
        if (!session.authenticated || !session.user) {
          return;
        }
        this.setState({
          loading: true,
          error: null
        });
        let response = await feathersClient.service("api/orders").find({
          query: {
            UserId: session.user.id,
            status: { $in: ["cart", "pendingPayment"] },
            $limit: 1,
            CompanyId: company.id,
            $sort: {
              id: -1
            }
          }
        });
        if (response && response.data && response.data.length >= 1) {
          cart = response.data[0];
        } else {
          this.setState({
            loading: false,
            error: null
          });
          return;
        }
      }
      if (!cart) {
        this.setState({
          loading: true,
          error: null
        });
        let response = await feathersClient.service("api/orders").find({
          query: {
            uuid: cartUUID,
            status: { $in: ["cart", "pendingPayment", "confirming"] }
          }
        });
        if (response && response.data && response.data.length >= 1) {
          cart = response.data[0];
        } else {
          this.setState({
            loading: false,
            error: null
          });
          return;
        }
      }
      this.setState({
        loading: true,
        error: null
      });
      if (!cart.UserId) {
        cart = await feathersClient
          .service("api/orders")
          .patch(cart.id, { UserId: session.user.id });
      }
      let details = await feathersClient
        .service("api/order-details")
        .find({ query: { $limit: -1, OrderId: cart.id } });
      let productIds = [...new Set(details.map(a => a.ProductId))];
      let pricesIds = [...new Set(details.map(a => a.priceId))];
      let [products, prices] = await Promise.all([
        feathersClient.service("api/products").find({
          query: {
            $limit: -1,
            CompanyId: company.id,
            id: { $in: productIds }
          }
        }),
        feathersClient.service("api/prices").find({
          query: { $limit: -1, CompanyId: company.id, id: { $in: pricesIds } }
        })
      ]);
      //console.log("products", products);
      details = details.map(a => ({
        ...a,
        Product: products.find(b => b.id == a.ProductId),
        Price: prices.find(b => b.id == a.priceId)
      }));
      let paymentForm =
        (!!settings && !!settings.orderOnly) ||
        (!!company.payment.methods && company.payment.methods.length > 0)
          ? null
          : await feathersClient.service("api/payment-link").get(cart.id);
      this.setState(
        {
          loading: false,
          error: null,
          cart,
          cartItems: details,
          products,
          prices,
          paymentForm
        },
        () => this.updateRemoteBilling()
      );
    } catch (error) {
      this.setState({
        error,
        loading: false
      });
    }
  };

  handleChange = field => (e, { value }) => this.setState({ [field]: value });
  handleChangeDelivery = field => (e, { value }) =>
    this.setState(
      {
        delivery: {
          ...this.state.delivery,
          province: field == "country" ? "" : this.state.delivery.province,
          city:
            field == "province" || field == "country"
              ? ""
              : this.state.delivery.city,
          [field]: value
        }
      },
      this.updateRemoteBilling
    );
  onChangeBilling = field => (e, { value }) => {
    this.setState({ billing: { ...this.state.billing, [field]: value } }, () =>
      this.updateRemoteBilling()
    );
  };

  updateRemoteBilling = _.debounce(
    async () => {
      if (!this.state.cart.id) {
        return;
      }
      try {
        let { billing, delivery, mode } = this.state;
        this.setState({ loading: true });
        let patchedOrder = await feathersClient
          .service("api/orders")
          .patch(this.state.cart.id, {
            observations: `
          Modo de Entrega: ${
            mode == "in-store"
              ? "Retira por sucursal"
              : mode == "transport-to-client-address"
              ? "Entrega en dirección prepactada"
              : "Entrega en domicilio"
          }
          ${mode != "transport-with-price-structure" ? "" : "Dirección:"}
          ${
            mode != "transport-with-price-structure"
              ? ""
              : `${delivery.address1} ${delivery.address2},
          ${delivery.city} (${delivery.postalCode}),
          ${delivery.province}, ${delivery.country}`
          }
          `,
            metadata: {
              ...(this.state.cart.metadata || {}),
              ...billing,
              delivery,
              mode
            }
          });
        console.log("LOG: patchedOrder", patchedOrder);

        this.setState({ cart: patchedOrder });
        this.reloadPaymentLink();
      } catch (error) {
        console.log("LOG: error", error);
        this.setState({ error, loading: false });
      }
    },
    600,
    { trailing: true, leading: false }
  );

  componentDidMount() {
    this.loadCart();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      initialData: {
        company: { payment }
      }
    } = this.props;

    let methods = !payment ? null : payment.methods;
    // let discounts = !payment ? null : payment.discounts;
    if (prevState.method != this.state.method) {
      let currentMethod = !this.state.method
        ? null
        : methods.find(a => a.ref === this.state.method);
      if (!currentMethod.instalments || currentMethod.instalments.length <= 0) {
        //RELOAD PAYMENT STUFF
        this.reloadPaymentLink();
      }
    } else if (prevState.instalments != this.state.instalments) {
      // RELOAD PAYMENT STUFF
      this.reloadPaymentLink();
    } else if (prevState.mode != this.state.mode) {
      this.updateRemoteBilling();
    }
  }

  reloadPaymentLink = async () => {
    if (!this.state.cart || !this.state.cart.id) {
      return;
    }
    let method = this.state.method;
    let instalments = this.state.instalments;
    let mode = this.state.mode;
    let delivery = this.state.delivery;
    if (!method || !instalments) {
      this.setState({
        paymentForm: null
      });
      return;
    }
    try {
      this.setState({ loading: true });
      let {
        formData: paymentForm,
        delivery: { updatedDetails }
      } = await feathersClient
        .service("api/payment-link")
        .patch(this.state.cart.id, { method, instalments, mode, delivery });
      console.log("ud", updatedDetails);
      this.setState({
        loading: false,
        paymentForm,
        cartItems: !updatedDetails ? this.state.cartItems : updatedDetails
      });
    } catch (error) {
      console.log(error);
      this.setState({
        loading: false,
        error
      });
    }
  };

  render() {
    const {
      intl: { formatMessage },
      match: { path },
      session,
      initialData: {
        company: { interface: uiConfiguration, settings, payment }
      }
    } = this.props;

    let methods = !payment ? null : payment.methods;
    let discounts = !payment ? null : payment.discounts;
    let currentMethod = !this.state.method
      ? null
      : methods.find(a => a.ref === this.state.method);
    let instalmentOptions = !currentMethod
      ? null
      : currentMethod.instalments.map(aninstalment => {
          let aDisc = !discounts
            ? null
            : discounts.find(
                someDisc =>
                  (someDisc.ref == currentMethod.ref || !someDisc.ref) &&
                  someDisc.instalments.indexOf(aninstalment) != -1 &&
                  (!someDisc.ProcessorId ||
                    someDisc.ProcessorId == currentMethod.ProcessorId)
              );
          return {
            instalments: aninstalment,
            discount: !discounts ? null : aDisc,
            key: aninstalment,
            value: aninstalment,
            text: aDisc.hideInstalments
              ? aDisc.description
              : `${aninstalment} cuota${aninstalment > 1 ? "s" : ""} ${
                  !aDisc ? "" : `(${aDisc.description})`
                }`
          };
        });
    let validModes = (settings || {}).transportOptions || ["in-store"];
    console.log(session);
    if (
      !session ||
      !session.user ||
      !session.user.client ||
      !session.user.client.accountInfo ||
      session.user.client.accountInfo.FormaEnvio != 1
    ) {
      validModes = validModes.filter(a => a != "transport-to-client-address");
    }
    const {
      mode,
      billing,
      cartItems,
      paymentForm,
      loading,
      disabledBillingEditting
    } = this.state;
    if (
      cartItems.some(a => {
        //console.log(a);
        return (!a.Product || !a.Price) && !a.record.delivery;
      }) ||
      this.state.error
    ) {
      return (
        <Container style={{ paddingTop: 10 }}>
          <Segment basic>
            {!!this.state.error ? (
              <Message>
                Hubo un error: {JSON.stringify(this.state.error)}
              </Message>
            ) : (
              <Message>
                Algunos productos o precios han cambiado, por favor editá tu
                carrito para corregirlo
              </Message>
            )}

            <Button content="Ir a Carrito" fluid as={Link} to={"/cart"} />
          </Segment>
        </Container>
      );
    }
    let Summary = (
      <Segment color="green" inverted>
        <div
          style={{
            paddingRight: "10px",
            marginBottom: "20px"
          }}
        >
          <Header size="tiny" inverted>
            Productos
          </Header>
          <ProductsList
            size="tiny"
            inverted
            noImage
            items={cartItems.filter(a => a.Price && a.Product)}
          />
          Subtotal: $
          {(this.state.paymentForm && this.state.paymentForm.fullAmount) ||
            cartItems
              .filter(a => a.Price && a.Product)
              .reduce((p, c) => p + c.Price.value * c.amount, 0)
              .toFixed(2)}
          <br />
          {this.state.paymentForm && this.state.paymentForm.finalAmount
            ? `Total: $${this.state.paymentForm.finalAmount}`
            : ""}
          <Header size="tiny" inverted>
            Entrega
          </Header>
          <p>
            {/* Envio por correo de 3 a 4 dias a: <br /> Dean Funes 3586, Mar del
            Plata, Argentina */}
            {this.state.mode == "transport-with-price-structure"
              ? "Entrega a domicilio"
              : this.state.mode == "transport-to-client-address"
              ? "Transporte a dirección en sistema"
              : "Entrega en sucursal"}
          </p>
          <Header size="tiny" inverted>
            Facturación
          </Header>
          <p>
            {billing.fullName} <br />
            Documento {billing.personalId} <br />
            Telefono {billing.phoneNumber} <br />
            {this.props.session.user.email}
          </p>
        </div>
        {!settings.orderOnly ? null : (
          <Button
            icon="buy"
            fluid
            inverted
            loading={loading}
            type="submit"
            disabled={loading}
            onClick={this.placeOrder}
          >
            {formatMessage(messages.orderButton)}
          </Button>
        )}
        <Form>
          {!payment.methods ? null : (
            <Form.Select
              placeholder="Forma de Pago"
              options={payment.methods.map(a => ({
                key: a.ref,
                value: a.ref,
                text: a.name
              }))}
              value={this.state.method}
              onChange={(e, { value }) =>
                this.setState({ method: value, instalments: null })
              }
            />
          )}
          {!instalmentOptions ? null : (
            <Form.Select
              placeholder="Cuotas"
              options={instalmentOptions}
              value={this.state.instalments}
              onChange={(e, { value }) => this.setState({ instalments: value })}
            />
          )}
        </Form>
        <br />
        {!paymentForm && !settings.orderOnly ? (
          <Button
            icon="payment"
            loading={true}
            fluid
            inverted
            type="submit"
            disabled={true}
          >
            Cargando...
          </Button>
        ) : (
          <Form
            action={paymentForm.action || undefined}
            method={paymentForm.method || undefined}
            disabled={
              !!this.state.loading ||
              !this.state.cart ||
              !!this.state.error ||
              (this.state.mode == "transport-with-price-structure" &&
                (this.state.delivery.address1.length < 3 ||
                  this.state.delivery.city.length < 1 ||
                  this.state.delivery.province.length < 1 ||
                  this.state.delivery.postalCode.length < 1 ||
                  this.state.delivery.country.length < 1)) ||
              (!disabledBillingEditting &&
                (!this.state.cart.metadata ||
                  !this.state.cart.metadata.personalId ||
                  this.state.cart.metadata.personalId.length < 4 ||
                  this.state.cart.metadata.personalId > 9 ||
                  !this.state.cart.metadata.fullName ||
                  this.state.cart.metadata.fullName.length < 1 ||
                  !this.state.cart.metadata.phoneNumber ||
                  this.state.cart.metadata.phoneNumber.length < 4))
            }
          >
            {paymentForm.fields.map(a => (
              <input key={a.id} type="hidden" name={a.id} value={a.value} />
            ))}

            <Button
              icon="payment"
              loading={loading}
              fluid
              inverted
              as={paymentForm.href ? "a" : undefined}
              href={paymentForm.href || undefined}
              type={paymentForm.href ? undefined : "submit"}
              disabled={
                (!currentMethod && methods != null) ||
                (!this.state.instalments &&
                  !!instalmentOptions &&
                  instalmentOptions.length > 0) ||
                !!this.state.loading ||
                !this.state.cart ||
                !!this.state.error ||
                (this.state.mode == "transport-with-price-structure" &&
                  (this.state.delivery.address1.length < 3 ||
                    this.state.delivery.city.length < 1 ||
                    this.state.delivery.province.length < 1 ||
                    this.state.delivery.postalCode.length < 1 ||
                    this.state.delivery.country.length < 1)) ||
                (!disabledBillingEditting &&
                  (!this.state.cart.metadata ||
                    !this.state.cart.metadata.personalId ||
                    !this.state.cart.metadata.fullName ||
                    !this.state.cart.metadata.phoneNumber))
              }
            >
              {formatMessage(messages.payButton)}
            </Button>
            {/* <Button
              icon="payment"
              fluid
              inverted
              content={"Pagar"}
              as="input"
              type="submit"
              value="Pagar"
            /> */}
          </Form>
        )}
      </Segment>
    );

    //console.log("props", this.props);

    if (path == "/checkout/confirm") {
      return (
        <Container style={{ paddingTop: 10 }}>
          <Header>
            <Header.Subheader>
              <Link to="/checkout/">
                <Icon name="triangle left" fitted />
                {" Modificar el pedido"}
              </Link>
            </Header.Subheader>
            Resumen
          </Header>
          {Summary}
        </Container>
      );
    } else {
      return (
        <Container style={{ paddingTop: 10 }}>
          <CategoryBreadcrumb uiConfiguration={uiConfiguration} />
          <Grid doubling stackable style={{ marginTop: 10 }}>
            <Grid.Column width="10">
              <Header>Checkout</Header>
              <Accordion fluid styled>
                <Accordion.Title active={true} index={0}>
                  <Icon name="dropdown" />
                  Información de Envio/Entrega
                </Accordion.Title>
                <Accordion.Content active={true}>
                  <Form>
                    <Form.Group inline>
                      {validModes.indexOf("transport-with-price-structure") ===
                      -1 ? null : (
                        <Form.Radio
                          label="Entrega a domicilio"
                          value="delivery"
                          inline
                          onClick={() =>
                            this.setState({
                              mode: "transport-with-price-structure"
                            })
                          }
                          checked={
                            this.state.mode == "transport-with-price-structure"
                          }
                        />
                      )}
                      {validModes.indexOf("in-store") === -1 ? null : (
                        <Form.Radio
                          inline
                          label="Retiro por sucursal"
                          value="pickup"
                          onClick={() => this.setState({ mode: "in-store" })}
                          checked={this.state.mode == "in-store"}
                        />
                      )}
                      {validModes.indexOf("transport-to-client-address") ===
                      -1 ? null : (
                        <Form.Radio
                          inline
                          label="Entrega por transporte a dirección preestablecida"
                          value="transport-to-client-address"
                          onClick={() =>
                            this.setState({
                              mode: "transport-to-client-address"
                            })
                          }
                          checked={
                            this.state.mode == "transport-to-client-address"
                          }
                        />
                      )}
                    </Form.Group>
                    {this.state.mode !=
                    "transport-with-price-structure" ? null : (
                      <Form.Group inline>
                        <Form.Input
                          placeholder="Calle y numero"
                          width="10"
                          required
                          value={this.state.delivery.address1}
                          onChange={this.handleChangeDelivery("address1")}
                        />
                      </Form.Group>
                    )}
                    {this.state.mode !=
                    "transport-with-price-structure" ? null : (
                      <Form.Group inline>
                        <Form.Input
                          inline
                          required
                          placeholder="Piso, Depto, etc..."
                          width="10"
                          value={this.state.delivery.address2}
                          onChange={this.handleChangeDelivery("address2")}
                        />
                      </Form.Group>
                    )}
                    {this.state.mode !=
                    "transport-with-price-structure" ? null : (
                      <Form.Group inline unstackable>
                        <Form.Select
                          search
                          required
                          options={(
                            cities.find(
                              a => a.nombre == this.state.delivery.province
                            ) || { ciudades: [] }
                          ).ciudades.map(a => ({
                            key: a.id,
                            text: a.nombre,
                            value: a.nombre
                          }))}
                          value={this.state.delivery.city}
                          onChange={this.handleChangeDelivery("city")}
                          placeholder="Ciudad"
                          width="5"
                        />
                        <Form.Input
                          fluid
                          placeholder="CP"
                          required
                          width="5"
                          value={this.state.delivery.postalCode}
                          onChange={this.handleChangeDelivery("postalCode")}
                        />
                      </Form.Group>
                    )}
                    {this.state.mode !=
                    "transport-with-price-structure" ? null : (
                      <Form.Group inline>
                        <Form.Select
                          search
                          options={(!settings.validProvinces
                            ? cities
                            : cities.filter(a =>
                                settings.validProvinces.some(b => b == a.id)
                              )
                          ).map(aProv => ({
                            text: aProv.nombre,
                            key: aProv.id,
                            value: aProv.nombre
                          }))}
                          value={this.state.delivery.province}
                          onChange={this.handleChangeDelivery("province")}
                          placeholder="Provincia"
                        />
                        <Form.Select
                          options={(!settings.validCountries
                            ? countries
                            : settings.validCountries
                          ).map((a, i) => ({
                            key: i,
                            text: a,
                            value: a
                          }))}
                          defaultValue={"Argentina"}
                          value={this.state.delivery.country}
                          placeholder="País"
                        />
                      </Form.Group>
                    )}
                  </Form>
                </Accordion.Content>
                <Accordion.Title active={false} index={0}>
                  <Icon name="dropdown" />
                  Información de Facturación
                </Accordion.Title>
                <Accordion.Content active={true}>
                  <Form>
                    <Form.Group inline>
                      <Form.Input
                        fluid
                        disabled={disabledBillingEditting}
                        required
                        placeholder="Nombre Completo"
                        value={billing.fullName}
                        onChange={this.onChangeBilling("fullName")}
                        width="6"
                      />
                      <Form.Input
                        fluid
                        disabled={disabledBillingEditting}
                        required
                        placeholder="Documento"
                        onChange={this.onChangeBilling("personalId")}
                        width="4"
                        value={billing.personalId}
                      />
                    </Form.Group>
                    <Form.Group inline>
                      <Form.Input
                        fluid
                        placeholder="Telefono"
                        required
                        disabled={disabledBillingEditting}
                        onChange={this.onChangeBilling("phoneNumber")}
                        width="10"
                        value={billing.phoneNumber}
                      />
                    </Form.Group>
                    <label style={{ color: "red" }}>
                      Todos los campos son requeridos, y debe elegir una forma
                      de pago para continuar
                    </label>
                  </Form>
                </Accordion.Content>
              </Accordion>
              <Responsive as={Segment} basic {...Responsive.onlyMobile}>
                <Button
                  floated="right"
                  color="blue"
                  as={Link}
                  to="/checkout/confirm"
                >
                  Ver Resumen de Compra
                </Button>
              </Responsive>
            </Grid.Column>

            <Grid.Column width="6" only="computer tablet">
              {Summary}
            </Grid.Column>
          </Grid>
        </Container>
      );
    }
  }
}

const CategoryBreadcrumb = ({ uiConfiguration }) => {
  return (
    <Breadcrumb>
      <Breadcrumb.Section
        link
        as={Link}
        to="/"
        style={{ color: uiConfiguration.colors.primary }}
      >
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section
        link
        as={Link}
        to="/cart"
        style={{ color: uiConfiguration.colors.primary }}
      >
        Cart
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section active>Checkout</Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default injectIntl(CheckoutScreen);
