import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Menu,
  Input,
  Container,
  Grid,
  Segment,
  Icon,
  Image,
  Modal,
  Header
} from "semantic-ui-react";
import { injectIntl, defineMessages } from "react-intl";
const messages = defineMessages({
  searchPlaceholder: {
    id: "app.navigation.search.placeholder",
    defaultMessage: "Buscar productos..."
  },
  logIn: {
    id: "app.navigation.session.log-in",
    defaultMessage: "Iniciar Sesión"
  },
  categories: {
    id: "app.navigation.categories.button",
    defaultMessage: "Categorias"
  }
});

class FooterMenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      initialData,
      intl: { formatMessage }
    } = this.props;
    let footer = initialData.company.interface.footer || [];
    let footerColor = initialData.company.interface.footerColor || "lightgrey";
    let fab = initialData.company.interface.fab || [];
    if (window.location.pathname.indexOf("admin") != -1) {
      return null;
    }
    return (
      <Container
        fluid
        textAlign="center"
        style={{
          paddingTop: 50,
          paddingBottom: 50,
          backgroundColor: footerColor
        }}
      >
        {fab.map((aFab, idx) => (
          <a
            key={aFab.href}
            href={aFab.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              class="fab"
              style={{
                right: 50 + 70 * idx,
                backgroundColor: aFab.color || "transparent"
              }}
            >
              {" "}
              <Image src={aFab.src} fluid />{" "}
            </div>
          </a>
        ))}
        {footer.map(aFooter => {
          if (aFooter.length == 1 && !!aFooter[0].html) {
            return (
              <div dangerouslySetInnerHTML={{ __html: aFooter[0].html }} />
            );
          }
          return (
            <div>
              <Menu
                compact
                secondary
                style={{ lineHeight: "0px", minHeight: "20px" }}
              >
                {aFooter.map(anItem => {
                  if (anItem.modal) {
                    return (
                      <Modal
                        closeIcon
                        trigger={
                          <Menu.Item
                            key={anItem.value}
                            fitted
                            style={{ lineHeight: "5px" }}
                          >
                            {!anItem.icon ? null : (
                              <Icon
                                style={{ height: "5px" }}
                                name={anItem.icon}
                              />
                            )}
                            <b>{anItem.label}</b>
                            {!anItem.label ? null : ": "}
                            {anItem.value}
                          </Menu.Item>
                        }
                      >
                        <Header
                          icon={anItem.icon || null}
                          content={anItem.value}
                        />
                        <Modal.Content>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: anItem.modalHtml
                            }}
                          />
                        </Modal.Content>
                      </Modal>
                    );
                  }
                  return (
                    <Menu.Item
                      key={anItem.value}
                      fitted
                      style={{ lineHeight: "5px" }}
                      href={anItem.href}
                      target={anItem.target}
                    >
                      {!anItem.icon ? null : (
                        <Icon style={{ height: "5px" }} name={anItem.icon} />
                      )}
                      <b>{anItem.label}</b>
                      {!anItem.label ? null : ": "}
                      {anItem.value}
                    </Menu.Item>
                  );
                })}
              </Menu>
            </div>
          );
        })}
      </Container>
    );
  }
}

export default injectIntl(FooterMenu);
