import React, { Component, useState, useEffect, useCallback } from "react";
import { Button, Checkbox, Form, Select, Input } from "semantic-ui-react";
import feathersClient from "../../feathersClient";
import { load } from "@feathersjs/client/dist/feathers";
function PriceForm(props) {
  const {
    ProductId,
    initialData: { company }
  } = props;

  const [priceLists, setPriceLists] = useState([]);
  const [currentListId, setCurrentListId] = useState(null);
  const [currentPriceEntry, setCurrentPriceEntry] = useState(null);
  const [latestPrice, setLatestPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(
    () => {
      feathersClient
        .service("api/price-lists")
        .find({
          query: {
            CompanyId: company.id,
            $limit: -1,
            $sort: {
              id: 1
            }
          }
        })
        .then(lists => {
          setPriceLists(
            lists.map(aList => ({
              key: aList.id,
              text: `#${aList.id} - ${aList.name}`,
              value: aList.id
            }))
          );
          setLoading(false);
          setError(null);
        })
        .catch(error => {
          setPriceLists([]);
          setLoading(false);
          setError(error);
        });
    },
    [ProductId]
  );

  useEffect(
    () => {
      if (!currentListId || loading) {
        return;
      }
      setLoading(true);
      feathersClient
        .service("api/prices")
        .find({
          query: {
            priceListId: currentListId,
            ProductId,
            $limit: 1
          }
        })
        .then(prices => {
          if (prices.data.length > 0) {
            setCurrentPriceEntry(prices.data[0]);
            setLatestPrice(prices.data[0].value);
          } else {
            setCurrentPriceEntry({
              value: "",
              packAmount: 1,
              ProductId,
              priceListId: currentListId,
              CompanyId: company.id,
              validFrom: new Date(),
              validUntil: new Date(
                new Date().setFullYear(new Date().getFullYear() + 150)
              )
            });
            setLatestPrice(null);
          }

          setLoading(false);
          setError(null);
        })
        .catch(error => {
          setLoading(false);
          setCurrentPriceEntry(null);
          setError(error);
        });
    },
    [currentListId]
  );

  const updateEntry = useCallback(
    async () => {
      if (loading || !currentListId || !currentPriceEntry || !ProductId) {
        return;
      }
      setLoading(true);
      try {
        if (!currentPriceEntry.id) {
          let anEntry = await feathersClient
            .service("api/prices")
            .create(currentPriceEntry);
          setCurrentPriceEntry(anEntry);
          setLatestPrice(anEntry.value);
        } else {
          let anEntry = await feathersClient
            .service("api/prices")
            .patch(currentPriceEntry.id, currentPriceEntry);
          setCurrentPriceEntry(anEntry);
          setLatestPrice(anEntry.value);
        }
        setLoading(false);
        setError(null);
      } catch (error) {
        setLatestPrice(null);
        setLoading(false);
        setError(error);
      }
    },
    [currentPriceEntry, currentListId, loading, ProductId]
  );

  return (
    <Form loading={loading} onSubmit={updateEntry}>
      <Form.Group>
        <Form.Field
          control={Select}
          options={priceLists}
          label={{
            children: "Lista De Precios",
            htmlFor: "form-select-control-price-lists"
          }}
          placeholder="Lista De Precios"
          disabled={loading}
          search
          searchInput={{ id: "form-select-control-price-lists" }}
          onChange={(e, { value }) => {
            setLatestPrice(null);
            setCurrentListId(value);
          }}
        />{" "}
        {!currentPriceEntry ? null : (
          <Form.Field
            disabled={loading}
            id="form-input-control-price"
            control={Input}
            label="Precio"
            type="number"
            min="1"
            value={currentPriceEntry.value}
            onChange={(e, { value }) =>
              setCurrentPriceEntry(entry => {
                let nValue = parseFloat(value);
                nValue = Number.isNaN(nValue) ? entry.value : value;
                return {
                  ...entry,
                  value: nValue
                };
              })
            }
            placeholder="0.00"
          />
        )}
        {!latestPrice ? null : (
          <Form.Field
            transparent
            id="form-input-control-price-latest"
            control={Input}
            label="Precio Activo"
            min="1"
            value={`$${latestPrice}`}
            placeholder="0.00"
            readOnly
          />
        )}
        {!currentPriceEntry ? null : (
          <Form.Button
            content="Actualizar"
            label=" &nbsp;"
            positive
            disabled={
              loading ||
              !currentPriceEntry.value ||
              isNaN(currentPriceEntry.value) ||
              currentPriceEntry.value <= 0
            }
          />
        )}
      </Form.Group>
    </Form>
  );
}

export default PriceForm;
