import React, { Component } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Responsive,
  Button
} from "semantic-ui-react";

const buttonStyles = {
  position: "absolute",
  top: "40%",
  zIndex: 10
};

// class HorizontalScrollAware extends Component {
//   state = {
//     scrollLeft: 0
//   };

//   setScroll = (scrollLeft = 0) => {
//     this.setState({
//       scrollLeft
//     });
//   };

//   render() {
//     let Child = React.Children.only(this.props.children);

//     window.onload = () => {
//       window.setTimeout(() => {
//         (document.getElementById(`trackId`) || {}).onscroll = function(ev) {
//           this.setScroll(
//             Math.abs(document.getElementById(`trackId`).scrollLeft)
//           );
//         };
//       }, 1000);
//     };

//     return (
//       <Child trackId={`trackId`} scroll={{ left: this.state.scrollLeft }} />
//     );
//   }
// }

class PromoCards extends Component {
  state = {
    scrollLeft: 0,
    page: 2,
    endOfScroll: false
  };

  setScroll = (scrollLeft = 0, endOfScroll = false) => {
    this.setState({
      scrollLeft,
      endOfScroll
    });
  };

  componentDidMount() {
    this.setupListening();
  }

  setupListening() {
    let element = document.getElementById(`scrollComponent${this.props.id}`);
    if (element) {
      this.setScroll(
        element.scrollLeft,
        element.offsetWidth + element.scrollLeft === element.scrollWidth
      );
      let updateFunc = () => {
        let otherElement = document.getElementById(
          `scrollComponent${this.props.id}`
        );
        // console.dir(otherElement);
        if (otherElement) {
          this.setScroll(
            otherElement.scrollLeft,
            element.offsetWidth + element.scrollLeft === element.scrollWidth
          );
        }
      };
      window.setInterval(updateFunc, 300);
      element.onscroll = debounce(updateFunc, 300, { leading: true });
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.loading != this.props.loading) {
      this.setupListening();
    }
  }

  scroll = (reverse = false) => () => {
    let modifier = reverse ? -1 : 1;
    let selectString = `[id="scrollComponent${this.props.id}"]`;
    let selection = window.document.querySelector(selectString);
    let rect = selection.getBoundingClientRect();
    let width = rect.width;
    //console.log(selectString);
    selection.scrollBy({
      left: modifier * width,
      behavior: "smooth"
    });
  };

  debouncedScroll = this.scroll;

  render() {
    let {
      header,
      promos,
      loading,
      shouldScroll,
      showName,
      itemsPerRow
    } = this.props;

    let { scrollLeft, endOfScroll } = this.state;

    return (
      <Responsive as={Segment} basic>
        {showName && <Header>{header}</Header>}

        <div style={{ position: "relative" }} className="sectionProducts">
          {scrollLeft <= 0 ? null : (
            <Button
              icon="arrow left"
              circular
              size="massive"
              className="sectionArrow"
              onClick={this.scroll(true)}
              style={{ ...buttonStyles, left: -40 }}
            />
          )}
          <Responsive
            as={Card.Group}
            doubling
            itemsPerRow={itemsPerRow || 2}
            id={`scrollComponent${this.props.id}`}
            className="scrolling-wrapper scrolling-no-bars"
            style={
              shouldScroll === false
                ? undefined
                : { display: "flex", flexWrap: "nowrap", overflowX: "auto" }
            }
          >
            {!!loading
              ? null
              : promos.map((aPromo, i) => (
                  <Card
                    as={
                      aPromo.link && !aPromo.linksExternally
                        ? Link
                        : !aPromo.link
                        ? undefined
                        : "a"
                    }
                    id={`scrollComponentCard${this.props.id}Position${i}`}
                    to={
                      !aPromo.linksExternally && aPromo.link
                        ? aPromo.link
                        : undefined
                    }
                    href={
                      !!aPromo.linksExternally && aPromo.link
                        ? aPromo.link
                        : undefined
                    }
                    style={{ flex: "0 0 auto" }}
                    color={aPromo.color || undefined}
                    image={aPromo.imageSrc}
                    style={
                      this.props.defaultColor && !aPromo.color
                        ? {
                            flex: "0 0 auto",
                            "box-shadow": `0 0 0 1px #d4d4d5,0 2px 0 0 ${
                              this.props.defaultColor
                            },0 1px 3px 0 #d4d4d5`
                          }
                        : { flex: "0 0 auto" }
                    }
                    header={
                      showName ? (
                        <Header>
                          <Header.Subheader>
                            {aPromo.displayName}
                          </Header.Subheader>
                        </Header>
                      ) : null
                    }
                    key={`${aPromo.id}${header}${i}`}
                  />
                ))}
          </Responsive>
          {endOfScroll ? null : (
            <Button
              icon="arrow right"
              circular
              size="massive"
              className="sectionArrow"
              onClick={this.scroll()}
              style={{ ...buttonStyles, right: -40 }}
            />
          )}
        </div>
      </Responsive>
    );
  }
}

export default props => <PromoCards {...props} />;
