import React, { Component } from "react";
import PropTypes from "prop-types";
import feathersClient from "../../feathersClient";
import ReactTable from "react-table";
import "react-table/react-table.css";
import ContentLoader, { Facebook } from "react-content-loader";
import selectTableHOC from "react-table/lib/hoc/selectTable";
import { debounce } from "lodash";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Dropdown,
  Tab,
  Breadcrumb,
  Select,
  Checkbox,
  Form,
  Menu,
  Grid,
  GridColumn,
  Button,
  Icon,
  MenuItem,
  Message
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { injectIntl, defineMessages } from "react-intl";
import t from "tcomb-form/lib";
import es from "tcomb-form/lib/i18n/es";
import templates from "tcomb-form-templates-semantic";
import { FilePond, File, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { DebounceInput } from "react-debounce-input";
import PriceForm from "./PriceForm";
import StockForm from "./StockForm";
import CollectionForm from "./CollectionForm";
import moment from "moment";
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
t.form.Form.i18n = es;
t.form.Form.templates = templates;

const imageTemplate = t.form.Form.templates.textbox.clone({
  // override just the input default implementation (labels, help, error will be preserved)
  renderInput: locals => {
    return (
      <div>
        <Image src={locals.value} />
        <Checkbox
          label="Es Imagen de referencia"
          checked={locals.value.indexOf("?isReference=true") != -1}
          onChange={() =>
            locals.onChange(
              locals.value.indexOf("?isReference=true") != -1
                ? locals.value.replace("?isReference=true", "")
                : `${locals.value}?isReference=true`
            )
          }
        />
      </div>
    );
  }
});
const SelectTable = selectTableHOC(ReactTable);
const messages = defineMessages({
  priceFormat: {
    id: "app.views.productView.product.format.label",
    defaultMessage: "Formato"
  }
});
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";

const INITIAL_STATE = {
  data: [],
  brands: [],
  categories: [],
  CategoryId: null,
  selection: [],
  images: [],
  selectAll: false,
  selectType: "checkbox",
  loading: false,
  error: null,
  skip: 0,
  total: 0,
  pages: 1
};
class CRUDProductScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      selectType: props.select ? "radio" : "checkbox"
    };
  }

  toggleSelection = (key, shift, row) => {
    /*
      Implementation of how to manage the selection state is up to the developer.
      This implementation uses an array stored in the component state.
      Other implementations could use object keys, a Javascript Set, or Redux... etc.
    */
    let goodKey = parseInt(key.split("-")[1]);
    // start off with the existing state
    if (this.state.selectType === "radio") {
      let selection = [];
      if (selection.indexOf(goodKey) < 0) selection.push(goodKey);
      if (this.props.onSelect) {
        //console.log("onSelect");
        let position = selection.length > 0 ? selection[0] : null;
        let item = this.state.data.find(a => a.id == position);
        this.props.onSelect(item);
      }
      this.setState({ selection });
    } else {
      let selection = [...this.state.selection];
      const keyIndex = selection.indexOf(goodKey);
      // check to see if the key exists
      if (keyIndex >= 0) {
        // it does exist so we will remove it using destructing
        selection = [
          ...selection.slice(0, keyIndex),
          ...selection.slice(keyIndex + 1)
        ];
      } else {
        // it does not exist so add it
        selection.push(goodKey);
      }
      // update the state
      this.setState({ selection, images: [] });
    }
  };

  loadProduct = async ({ pageSize, page, sorted, filtered }) => {
    const {
      match: { params },
      initialData: { company }
    } = this.props;
    this.setState({
      loading: true,
      error: null,
      data: [],
      selection: []
    });
    // //console.log("page", page);
    // //console.log("skip", pageSize * page);
    // //console.log("sorted", sorted);
    // //console.log("filtered", filtered);
    const mapFilter = ({ id, value }) => {
      const iLikeFilter = () => ({ $iLike: `%%${value}%%` });
      const defaultFilter = () => value;
      const countFilter = () => (!value ? undefined : value);
      switch (id) {
        case "name":
          return iLikeFilter();
          break;
        case "description":
          return iLikeFilter();
        case "code":
          return iLikeFilter();
          break;
        case "images":
          return countFilter();
          break;
        default:
          return defaultFilter();
      }
    };

    let filters = filtered.reduce(
      (p, c) => ({ ...p, [c.id]: mapFilter(c) }),
      {}
    );
    let categoryHiddenFilter =
      !company.settings || !company.settings.hideCategories
        ? {}
        : {
            CategoryId: {
              $nin: company.settings.hideCategories
            }
          };
    try {
      const [response] = await Promise.all([
        feathersClient.service("api/products").find({
          query: {
            CompanyId: company.id,
            $limit: pageSize,
            $skip: pageSize * page,
            $noListFiltering: true,
            $noStockFiltering: true,
            $sort: sorted.reduce(
              (p, c) => ({ ...p, [c.id]: c.desc ? -1 : 1 }),
              {}
            ),
            ...filters,
            ...categoryHiddenFilter
          }
        })
      ]);
      // //console.log(response.data);
      if (response.data.length <= 0) {
        throw new Error("PRODUCT NOT FOUND");
      }

      this.setState({
        loading: false,
        error: null,
        data: response.data,
        skip: response.skip,
        total: response.total,
        pages: Math.ceil(response.total / pageSize),
        images: []
      });
    } catch (error) {
      this.setState({
        ...INITIAL_STATE,
        brands: this.state.brands,
        categories: this.state.categories,
        error,
        selection: [],
        images: []
      });
    }
  };
  debouncedLoadProduct = debounce(this.loadProduct, 300, { leading: true });

  componentDidMount = async () => {
    const {
      initialData: { company }
    } = this.props;
    let categoryHiddenFilter =
      !company.settings || !company.settings.hideCategories
        ? {}
        : {
            id: {
              $nin: company.settings.hideCategories
            }
          };
    const [brands, categories] = await Promise.all([
      feathersClient
        .service("api/brands")
        .find({ query: { CompanyId: company.id, $limit: -1 } }),
      feathersClient.service("api/categories").find({
        query: {
          $select: ["name", "id"],
          CompanyId: company.id,
          $limit: -1,
          ...categoryHiddenFilter
        }
      })
    ]);
    this.setState({
      brands,
      categories
    });
  };

  toggleAll = () => {
    /*
      'toggleAll' is a tricky concept with any filterable table
      do you just select ALL the records that are in your data?
      OR
      do you only select ALL the records that are in the current filtered data?

      The latter makes more sense because 'selection' is a visual thing for the user.
      This is especially true if you are going to implement a set of external functions
      that act on the selected information (you would not want to DELETE the wrong thing!).

      So, to that end, access to the internals of ReactTable are required to get what is
      currently visible in the table (either on the current page or any other page).

      The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
      ReactTable and then get the internal state and the 'sortedData'.
      That can then be iterrated to get all the currently visible records and set
      the selection state.
    */

    //console.log("toggleAll");
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      //console.log("selecting");
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.selectTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (item._original) {
          selection.push(item._original.id);
        }
      });
    }

    this.setState({ selectAll, selection });
  };

  onSaveChanges = async e => {
    e.preventDefault();
    let { selection, images } = this.state;
    let formValue = this.form.getValue();

    let parsedForm = Object.keys(formValue).reduce(
      (p, c) => ({
        ...p,
        [c]: formValue[c] === null ? undefined : formValue[c]
      }),
      {}
    );
    //console.log("formValue", parsedForm);
    parsedForm.published =
      selection.length == 1
        ? parsedForm.published
        : !parsedForm.published || parsedForm.published == "unchanged"
        ? undefined
        : parsedForm.published == "published";
    parsedForm.images =
      selection.length == 1
        ? [
            ...parsedForm.images,
            ...images.map(a => a.serverId.replace(/"/g, ""))
          ]
        : undefined;
    parsedForm.referenceImage = !parsedForm.images
      ? undefined
      : parsedForm.images.find(a => a.indexOf("?isReference=true") != -1) ||
        null;
    parsedForm.images = !parsedForm.images
      ? undefined
      : parsedForm.images.map(a =>
          a == parsedForm.referenceImage
            ? a
            : a.replace("?isReference=true", "")
        );
    this.setState({ loading: true });
    await feathersClient
      .service("api/products")
      .patch(
        selection.length == 1 ? selection[0] : null,
        { ...parsedForm },
        selection.length > 1
          ? { query: { id: { $in: [...selection] } } }
          : undefined
      );
    //console.log(this.selectTable);
    this.selectTable.wrappedInstance.fireFetchData();
  };

  handleInit = a => {
    this.pond._pond.setOptions({
      server: {
        process: {
          url: "/api/file?folder=products",
          headers: {
            Authorization: window.localStorage.getItem("feathers-jwt")
          }
        }
      },
      acceptedFileTypes: ["image/*"],
      labelIdle:
        'Arrastra tus imagenes adjuntas aquí o <span class="filepond--label-action"> seleccionar de mi computadora </span>',
      allowPaste: true,
      dropOnPage: true,
      timeout: 10000000
    });
  };

  onCreateProduct = async () => {
    const {
      match: { params },
      initialData: { company }
    } = this.props;
    let { CategoryId } = this.state;
    if (!CategoryId) {
      return;
    }

    //console.log("activeSection", activeSection, "activeTable", activeTable);
    this.setState({
      loading: true,
      error: null
    });
    try {
      let result = await feathersClient.service("api/products").create({
        name: `Producto creada a las ${moment().format("DD/MM/YYYY HH:MM")}`,
        CategoryId,
        CompanyId: company.id
      });
      //console.log("result", result);
      this.setState(
        {
          selection: [result.id]
        },
        () => this.selectTable.wrappedInstance.fireFetchData()
      );
    } catch (error) {
      console.error("error", error);
      this.setState({
        loading: false,
        error
      });
    }
  };

  render() {
    const {
      intl: { formatMessage },
      initialData: {
        company: { interface: uiConfiguration, settings }
      },
      location: { hash }
    } = this.props;

    const {
      data,
      brands,
      categories,
      loading,
      error,
      skip,
      total,
      pages,
      selection
    } = this.state;
    const canCreateProducts =
      settings.canCreate && settings.canCreate.indexOf("products") != -1;

    const MultiEditFormSchema = t.struct({
      name: t.maybe(t.String),
      description: t.maybe(t.String),
      code: t.maybe(t.String),
      brand: t.maybe(
        t.enums.of([...new Set(brands.map(aBrand => aBrand.brand))].join(" "))
      ),
      CategoryId: t.maybe(
        t.enums(categories.reduce((p, c) => ({ ...p, [c.id]: c.name }), {}))
      ),
      published: t.maybe(
        t.enums({
          unchanged: "No Cambiar",
          published: "Sí",
          unpublished: "No"
        })
      ),
      table: t.maybe(t.list(t.list(t.String)))
    });

    const currProd =
      (selection.length == 1 &&
        data.find(aProduct => aProduct.id == selection[0])) ||
      null;

    const SingleEditFormSchema = t.struct({
      name: t.String,
      description: t.maybe(t.String),
      code: t.maybe(t.String),
      brand:
        !!currProd && (!currProd.brand || currProd.brand.length <= 0)
          ? t.maybe(t.String)
          : t.maybe(
              t.enums.of(
                [...new Set(brands.map(aBrand => aBrand.brand))].join(" ")
              )
            ),
      CategoryId: t.enums(
        categories.reduce((p, c) => ({ ...p, [c.id]: c.name }), {})
      ),
      published: t.Boolean,
      table: t.list(t.list(t.String)),
      images: t.list(t.String)
    });

    const formOptions = {
      fields: {
        name: {
          // you can use strings or JSX
          label: "Nombre"
        },
        description: {
          label: "Descripción"
        },
        code: {
          label: "Codigo"
        },
        brand: {
          label: "Marca"
        },
        published: {
          label: "Publicado"
        },
        CategoryId: {
          label: "Categoria"
        },
        table: {
          label: "Tabla de datos",
          item: {
            label: "Fila",
            item: {}
          }
        },
        images: {
          disableAdd: true,
          label: "Imagenes",
          item: {
            template: imageTemplate
          }
        }
      }
    };

    const extraProps = {
      selectAll: this.state.selectAll,
      isSelected: key => {
        /*
          Instead of passing our external selection state we provide an 'isSelected'
          callback and detect the selection state ourselves. This allows any implementation
          for selection (either an array, object keys, or even a Javascript Set object).
        */
        // //console.log("isSelected?", key);
        return this.state.selection.includes(key);
      },
      toggleAll: this.toggleAll,
      toggleSelection: this.toggleSelection,
      selectType: this.state.selectType
    };

    const isOnSelect = this.props.onSelect && this.props.select;
    const WrapperClass = isOnSelect ? Segment : Container;

    const DefaultTextFilter = ({ filter, onChange }) => (
      <DebounceInput
        minLength={2}
        debounceTimeout={1000}
        value={filter ? filter.value : ""}
        onChange={e => onChange(e.target.value)}
      />
    );

    let basicColumns = [
      {
        Header: "Nombre",
        id: "name",
        accessor: d => d.name,
        Filter: DefaultTextFilter
      }
    ];
    if (!isOnSelect) {
      basicColumns = [
        ...basicColumns,
        {
          Header: "Descripción",
          id: "description",
          accessor: d => d.description,
          Filter: DefaultTextFilter
        }
      ];
    }
    let canCreatePrices = settings.canCreate;
    canCreatePrices =
      !!canCreatePrices && canCreatePrices.some(a => a == "prices");
    let canCreateStock = settings.canCreate;
    canCreateStock = !!canCreateStock && canCreateStock.some(a => a == "stock");
    let canCreateCollections = settings.canCreate;
    canCreateCollections =
      !!canCreateCollections &&
      canCreateCollections.some(a => a == "collections");
    return (
      <WrapperClass basic style={{ paddingTop: 10 }}>
        <Header>
          Administrar Productos{" "}
          <Header.Subheader>
            {!canCreateProducts ? null : (
              <Dropdown
                selection
                placeholder="Rubro"
                value={this.state.CategoryId}
                onChange={(e, { value }) =>
                  this.setState({ CategoryId: value })
                }
                options={categories.map(a => ({
                  key: a.id,
                  text: a.name,
                  value: a.id
                }))}
              />
            )}
            {!canCreateProducts ? null : (
              <Button
                size="small"
                icon="plus"
                content="Crear Producto"
                inverted
                positive
                onClick={this.onCreateProduct}
                disabled={loading || !this.state.CategoryId}
              />
            )}
          </Header.Subheader>
        </Header>
        <Segment basic>
          <SelectTable
            {...extraProps}
            ref={r => (this.selectTable = r)}
            columns={[
              ...basicColumns,
              {
                Header: "Marca",
                id: "brand",
                accessor: d => d.brand,
                Filter: ({ filter, onChange }) => {
                  return (
                    <Select
                      fluid
                      search
                      placeholder="Todas"
                      value={filter ? filter.value : undefined}
                      onChange={(e, { value }) => onChange(value)}
                      options={[
                        { key: "allBrands", value: undefined, text: "Todas" },
                        ...[...new Set(brands.map(aBrand => aBrand.brand))].map(
                          aBrand => ({
                            key: aBrand,
                            value: aBrand,
                            text: aBrand
                          })
                        )
                      ]}
                    />
                  );
                }
              },
              {
                Header: "Categoria",
                id: "CategoryId",
                accessor: d =>
                  (
                    categories.find(aCat => aCat.id == d.CategoryId) || {
                      name: "N/D"
                    }
                  ).name,
                Filter: ({ filter, onChange }) => {
                  return (
                    <Select
                      fluid
                      search
                      placeholder="Todas"
                      value={filter ? filter.value : undefined}
                      onChange={(e, { value }) => onChange(value)}
                      options={[
                        {
                          key: "allCategories",
                          value: undefined,
                          text: "Todas"
                        },
                        ...categories.map(aCategory => ({
                          key: aCategory.id,
                          value: aCategory.id,
                          text: aCategory.name
                        }))
                      ]}
                    />
                  );
                }
              },
              {
                Header: "Codigo",
                id: "code",
                accessor: d => d.code,
                Filter: DefaultTextFilter
              },
              {
                Header: "Publicado",
                id: "published",
                style: { overflowY: "visible" },
                accessor: d => (d.published ? "Sí" : "No"),
                Filter: ({ filter, onChange }) => {
                  return (
                    <Select
                      fluid
                      placeholder="Todos"
                      value={filter ? filter.value : undefined}
                      onChange={(e, { value }) => onChange(value)}
                      style={{ zIndex: 1000 }}
                      options={[
                        { key: "all", text: "Todos", value: undefined },
                        { key: "published", text: "Publicados", value: true },
                        {
                          key: "unpublished",
                          text: "Sin Publicar",
                          value: false
                        }
                      ]}
                    />
                  );
                }
              },
              {
                Header: "Imagenes",
                id: "images",
                accessor: d => d.images.length,
                style: { overflowY: "visible" },
                Filter: ({ filter, onChange }) => {
                  return (
                    <Select
                      fluid
                      placeholder="Todos"
                      value={filter ? filter.value : undefined}
                      onChange={(e, { value }) => onChange(value)}
                      style={{ zIndex: 1000 }}
                      options={[
                        { key: "all", text: "Todos", value: undefined },
                        { key: "noimg", text: "Sin Imagenes", value: [] },
                        {
                          key: "someimg",
                          text: "Con Imagenes",
                          value: { $ne: [] }
                        }
                      ]}
                    />
                  );
                }
              },
              {
                Header: "Campos Extra",
                id: "extraFields",
                accessor: d =>
                  [
                    d.extraField1,
                    d.extraField2,
                    d.extraField3,
                    d.extraField4,
                    d.extraField5,
                    d.arrayField1
                  ]
                    .filter(eField => !!eField && eField.length)
                    .map(eField =>
                      Array.isArray(eField) ? `[${eField.join(",")}]` : eField
                    )
                    .join(", "),
                style: { overflowY: "visible" },
                Filter: () => null
              }
            ]}
            keyField="id"
            manual
            data={
              data // Forces table not to paginate or sort automatically, so we can handle it server-side
            }
            getTheadFilterThProps={() => ({
              className: "ui fluid form",
              style: { overflow: "visible" }
            })}
            pages={pages}
            loading={
              loading // Display the total number of pages
            }
            onFetchData={
              this.debouncedLoadProduct // Display the loading overlay when we need it
            }
            defaultSorted={[
              { id: "name", desc: false },
              { id: "code", desc: false }
            ]}
            filterable
            defaultPageSize={isOnSelect ? 5 : 10}
            className="-striped -highlight"
          />
        </Segment>
        {selection.length <= 0 || loading || this.props.onSelect ? null : (
          <Tab
            panes={[
              {
                menuItem: "Producto",
                render: () => {
                  return (
                    <Segment basic>
                      <Header size="medium">
                        {selection.length == 1
                          ? `Editando ${
                              (
                                data.find(
                                  aProduct => aProduct.id == selection[0]
                                ) || { name: "N/D" }
                              ).name
                            }`
                          : `Editando ${selection.length} elementos`}
                      </Header>

                      <Form>
                        <t.form.Form
                          value={
                            selection.length == 1
                              ? data.find(
                                  aProduct => aProduct.id == selection[0]
                                )
                              : undefined
                          }
                          ref={ref => (this.form = ref)}
                          type={
                            selection.length == 1
                              ? SingleEditFormSchema
                              : MultiEditFormSchema
                          }
                          options={formOptions}
                        />
                        <Segment basic>
                          {selection.length != 1 ? null : (
                            <FilePond
                              ref={ref => {
                                this.pond = ref;
                              }}
                              allowMultiple={true}
                              maxFiles={5}
                              acceptedFileTypes={[]}
                              dropOnPage={true}
                              name="uri"
                              server="/api/file"
                              oninit={this.handleInit}
                              onprocessfile={e => {
                                // Set current file objects to this.state

                                this.setState(
                                  {
                                    images: this.pond.getFiles()
                                  },
                                  () => {
                                    this.handleInit();
                                  }
                                );
                              }}
                              onupdatefiles={fileItems => {
                                // Set current file objects to this.state

                                this.setState(
                                  {
                                    images: fileItems
                                  },
                                  () => {
                                    this.handleInit();
                                  }
                                );
                              }}
                            >
                              {/* {this.state.images.map(file => (
                        <File key={file.file} src={file.file} origin="local" />
                    ))} */}
                            </FilePond>
                          )}
                          <Form.Group>
                            <Button
                              content="Save"
                              onClick={this.onSaveChanges}
                              disabled={
                                this.state.images.length > 0 &&
                                this.state.images.reduce((p, a) => {
                                  return p || !a.serverId;
                                }, false)
                              }
                            />
                          </Form.Group>
                        </Segment>
                      </Form>
                    </Segment>
                  );
                }
              },
              {
                menuItem: "Precio",
                render: () => {
                  if (!canCreatePrices) {
                    return (
                      <Segment>
                        <Message>
                          Sus precios se administran desde otro sistema
                        </Message>
                      </Segment>
                    );
                  }
                  if (!selection.length > 1) {
                    return (
                      <Segment>
                        <Message>
                          No puedes cambiar el precio de mas de un producto a la
                          vez
                        </Message>
                      </Segment>
                    );
                  }
                  return (
                    <Segment basic>
                      <Header size="medium">
                        {selection.length == 1
                          ? `Editando Precio de ${
                              (
                                data.find(
                                  aProduct => aProduct.id == selection[0]
                                ) || { name: "N/D" }
                              ).name
                            }`
                          : `Editando Precio de ${selection.length} elementos`}
                      </Header>
                      <PriceForm
                        initialData={this.props.initialData}
                        ProductId={selection[0]}
                      />
                    </Segment>
                  );
                }
              },
              {
                menuItem: "Stock",
                render: () => {
                  if (!canCreateStock) {
                    return (
                      <Segment>
                        <Message>
                          Su stock se administra desde otro sistema
                        </Message>
                      </Segment>
                    );
                  }
                  if (!selection.length > 1) {
                    return (
                      <Segment>
                        <Message>
                          No puedes cambiar el stock de mas de un producto a la
                          vez
                        </Message>
                      </Segment>
                    );
                  }
                  return (
                    <Segment basic>
                      <Header size="medium">
                        {selection.length == 1
                          ? `Editando Stock de ${
                              (
                                data.find(
                                  aProduct => aProduct.id == selection[0]
                                ) || { name: "N/D" }
                              ).name
                            }`
                          : `Editando Stock de ${selection.length} elementos`}
                      </Header>
                      <StockForm
                        initialData={this.props.initialData}
                        ProductId={selection[0]}
                      />
                    </Segment>
                  );
                }
              },
              {
                menuItem: "Colección",
                render: () => {
                  if (!canCreateCollections) {
                    return (
                      <Segment>
                        <Message>
                          Sus colecciones se administran desde otro sistema
                        </Message>
                      </Segment>
                    );
                  }
                  if (!selection.length > 1) {
                    return (
                      <Segment>
                        <Message>
                          No puedes cambiar la colección de mas de un producto
                          con una multiselección
                        </Message>
                      </Segment>
                    );
                  }
                  return (
                    <Segment basic>
                      <Header size="medium">
                        {selection.length == 1
                          ? `Editando colección de ${
                              (
                                data.find(
                                  aProduct => aProduct.id == selection[0]
                                ) || { name: "N/D" }
                              ).name
                            }`
                          : `Editando colección de ${
                              selection.length
                            } elementos`}
                      </Header>
                      <CollectionForm
                        initialData={this.props.initialData}
                        ProductId={selection[0]}
                        Product={data.find(
                          aProduct => aProduct.id == selection[0]
                        )}
                        mainProps={this.props}
                        CollectionId={
                          (
                            data.find(
                              aProduct => aProduct.id == selection[0]
                            ) || { CollectionId: null }
                          ).CollectionId
                        }
                      />
                    </Segment>
                  );
                }
              }
            ]}
          />
        )}
      </WrapperClass>
    );
  }
}

const CategoryBreadcrumb = ({ uiConfiguration, categories, product }) => {
  return (
    <Breadcrumb>
      <Breadcrumb.Section
        link
        as={Link}
        to="/"
        style={{ color: uiConfiguration.colors.primary }}
      >
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      {categories.map((aCategory, idx) => [
        <Breadcrumb.Section
          link
          key={`${idx}Category${aCategory.id}Section`}
          as={Link}
          to={`/category/${aCategory.id}`}
          style={{ color: uiConfiguration.colors.primary }}
        >
          {aCategory.name}
        </Breadcrumb.Section>,
        <Breadcrumb.Divider
          key={`${idx}Category${aCategory.id}Divider`}
          icon="right angle"
        />
      ])}

      <Breadcrumb.Section active>{product.name}</Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default injectIntl(CRUDProductScreen);
