import React, { Component } from "react";
import { Image, List } from "semantic-ui-react";
import { injectIntl, defineMessages } from "react-intl";
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";

const ProductsList = props => {
  const {
    intl: { formatMessage },
    items
  } = props;
  return (
    <List
      divided
      verticalAlign="middle"
      size={props.size || "huge"}
      inverted={props.inverted || false}>
      {items.map(anItem => (
        <List.Item key={anItem.id}>
          {!!props.noImage? null : <Image
            size="tiny"
            src={
              anItem && anItem.Product.images.length > 0
                ? anItem.Product.images[0]
                : placeholderProductImg
            }
            style={{ maxWidth: 30 }}
          />}
          <List.Content>
            <List.Header>
              Producto {anItem.Product.name} ({parseInt(anItem.amount)}x
              {anItem.Price.packAmount}u)
            </List.Header>
          </List.Content>
          <List.Content floated="right" style={{ marginTop: 30 }}>
            <List.Header>$ {(anItem.Price.value * anItem.amount).toFixed(2)}</List.Header>
          </List.Content>
        </List.Item>
      ))}
    </List>
  );
};

export default injectIntl(ProductsList);
