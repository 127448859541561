import React, {
  Component,
  useState,
  useEffect,
  useCallback,
  useRef
} from "react";
import {
  Button,
  Checkbox,
  Header,
  Form,
  Select,
  Image,
  Input,
  Popup
} from "semantic-ui-react";
import CRUDProductsScreen from "./CRUDProductsScreen";
import feathersClient from "../../feathersClient";
import { load } from "@feathersjs/client/dist/feathers";
import t from "tcomb-form/lib";
import es from "tcomb-form/lib/i18n/es";
import templates from "tcomb-form-templates-semantic";

t.form.Form.i18n = es;
t.form.Form.templates = templates;

function CollectionForm(props) {
  const {
    ProductId,
    Product,
    CollectionId,
    initialData: { company }
  } = props;

  const [currentCollectionEntry, setCurrentCollectionEntry] = useState({
    properties: [],
    variants: [],
    CompanyId: company.id,
    name: "",
    picture: ""
  });
  const [latestCollection, setLatestCollection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const propertiesFormRef = useRef();
  const variantsFormRef = useRef();
  useEffect(
    () => {
      if (loading || !CollectionId) {
        setCurrentCollectionEntry({
          properties: [],
          variants: [{ id: Product }],
          CompanyId: company.id
        });
        return;
      }
      setLoading(true);
      feathersClient
        .service("api/collections")
        .find({
          query: {
            CompanyId: company.id,
            id: CollectionId,
            $limit: 1,
            $sort: {
              id: 1
            }
          }
        })
        .then(async collection => {
          if (collection.data.length > 0) {
            let value = collection.data[0];
            let products = await feathersClient.service("api/products").find({
              query: {
                $limit: -1,
                CompanyId: company.id,
                $noListFiltering: true,
                $noStockFiltering: true,
                id: { $in: value.variants.map(a => a.id) }
              }
            });
            value.variants = value.variants.map(a => ({
              ...a,
              id: products.find(b => b.id == a.id)
            }));
            setCurrentCollectionEntry(value);
            setLatestCollection(value);
          } else {
            setCurrentCollectionEntry({
              properties: [],
              variants: [],
              CompanyId: company.id,
              name: "",
              picture: ""
            });
            setLatestCollection(null);
          }

          setLoading(false);
          setError(null);
        })
        .catch(error => {
          setLoading(false);
          setCurrentCollectionEntry(null);
          setError(error);
        });
    },
    [ProductId, CollectionId]
  );

  const updateEntry = useCallback(
    async () => {
      if (loading || !currentCollectionEntry || !ProductId) {
        return;
      }
      let fixedCollection = currentCollectionEntry;
      fixedCollection.variants = fixedCollection.variants.map(a => {
        console.log(a);
        return {
          ...a,
          id: typeof a.id == "object" ? a.id.id : JSON.parse(a.id).id
        };
      });
      if (fixedCollection.variants.length > 0) {
        let firstItem = await feathersClient
          .service("api/products")
          .get(fixedCollection.variants[0].id);
        if (!!firstItem) {
          if (!fixedCollection.name || fixedCollection.name == "") {
            fixedCollection.name = firstItem.name;
          }
          fixedCollection.picture =
            firstItem.images.length > 0 ? firstItem.images[0] : "";
        }
      }

      setLoading(true);
      try {
        let anEntry;
        if (!currentCollectionEntry.id) {
          anEntry = await feathersClient
            .service("api/collections")
            .create(fixedCollection);

          let products = await feathersClient.service("api/products").find({
            query: {
              $limit: -1,
              CompanyId: company.id,
              $noListFiltering: true,
              $noStockFiltering: true,
              id: { $in: anEntry.variants.map(a => a.id) }
            }
          });
          anEntry.variants = anEntry.variants.map(a => ({
            ...a,
            id: products.find(b => b.id == a.id)
          }));
          setCurrentCollectionEntry(anEntry);
          setLatestCollection(anEntry);
          //REFRESH FROM TOP?
        } else {
          anEntry = await feathersClient
            .service("api/collections")
            .patch(currentCollectionEntry.id, fixedCollection);

          let products = await feathersClient.service("api/products").find({
            query: {
              $limit: -1,
              CompanyId: company.id,
              $noListFiltering: true,
              $noStockFiltering: true,
              id: { $in: anEntry.variants.map(a => a.id) }
            }
          });
          anEntry.variants = anEntry.variants.map(a => ({
            ...a,
            id: products.find(b => b.id == a.id)
          }));
          // console.log(anEntry);
          setCurrentCollectionEntry(anEntry);
          setLatestCollection(anEntry);
        }

        await feathersClient.service("api/products").patch(
          null,
          { CollectionId: anEntry.id },
          {
            query: {
              id: {
                $in: anEntry.variants.map(a => a.id.id)
              }
            }
          }
        );
        setLoading(false);
        setError(null);
      } catch (error) {
        setLatestCollection(null);
        setLoading(false);
        setError(error);
        // console.log(error);
      }
    },
    [currentCollectionEntry, loading, ProductId, CollectionId]
  );

  const validatedJSON = t.subtype(t.String, jsonString => {
    return true;
  });

  const Property = t.struct(
    {
      id: t.String,
      label: t.String
    },
    "PropertiesBase"
  );
  console.log(currentCollectionEntry);

  const VariantValue = t.struct(
    {
      label: t.maybe(t.String),
      tint: t.maybe(
        t.enums({
          white: "Blanco",
          orange: "Naranja",
          red: "Rojo",
          yellow: "Amarillo",
          olive: "Oliva",
          green: "Verde",
          teal: "Aguamarina",
          blue: "Azul",
          violet: "Violeta",
          purple: "Purpura",
          pink: "Rosa",
          brown: "Marrón",
          grey: "Gris",
          black: "Negro"
        })
      )
    },
    "VariantValueBase"
  );

  const productTemplate = t.form.Form.templates.textbox.clone({
    // override just the input default implementation (labels, help, error will be preserved)
    renderInput: locals => {
      //console.log(locals);

      try {
        let {
          id,
          name,
          code,
          displayName,
          imageSrc,
          iconSrc,
          images
        } = locals.value;
        return (
          <div style={{ verticalAlign: "center" }}>
            <Popup
              size="large"
              flowing
              trigger={
                <Button
                  secondary
                  color="grey"
                  content={
                    !!id && !!name
                      ? `Producto #${id}: ${name} (Codigo ${code})`
                      : "Seleccionar"
                  }
                  fluid
                />
              }
              content={
                <CRUDProductsScreen
                  {...props.mainProps}
                  select
                  onSelect={product => {
                    // this.setActiveItem(null)();
                    locals.onChange(product);
                  }}
                />
              }
              on="click"
              // open={this.state.activeItem == id}
              // onClose={this.setActiveItem(null)}
              // onOpen={this.setActiveItem(id)}
            />
          </div>
        );
      } catch (error) {
        console.log(error);
        return <div>error</div>;
      }
    }
  });

  const Variant = t.struct(
    {
      id: t.String,
      ...(currentCollectionEntry.properties || []).reduce(
        (p, c) => ({ ...p, [c.id]: VariantValue }),
        {}
      )
    },
    "VariantsBase"
  );

  const Properties = t.list(Property);
  const Variants = t.list(Variant);

  const Collection = t.struct(
    {
      properties: Properties,
      variants: Variants
    },
    "CollectionBase"
  );

  const collectionPropertiesOptions = {
    item: {
      fields: {
        id: {
          label: "Identificador"
        },
        label: {
          label: "Texto a Mostrar"
        }
      }
    }
  };

  const collectionVariantsOptions = {
    item: {
      fields: {
        id: {
          template: productTemplate,
          label: "Producto",
          transformer: {
            format: value => {
              return typeof value == "object"
                ? value
                : value
                ? JSON.parse(value)
                : {};
            },
            parse: str => {
              return JSON.stringify(str);
            }
          }
        },
        ...(currentCollectionEntry.properties || []).reduce(
          (p, c) => ({
            ...p,
            [c.id]: {
              fields: { label: { label: "Valor" }, tint: { label: "Color" } }
            }
          }),
          {}
        )
      }
    }
  };

  const onChangeProperties = () => {
    if (propertiesFormRef.current) {
      let value = propertiesFormRef.current.getValue();
      console.log(value);
      if (!!value) {
        setCurrentCollectionEntry(v => ({ ...v, properties: value }));
      }
    }
  };

  const onChangeVariants = () => {
    if (propertiesFormRef.current) {
      let value = variantsFormRef.current.getValue();
      console.log(value);
      if (!!value) {
        setCurrentCollectionEntry(v => ({ ...v, variants: value }));
      }
    }
  };

  return (
    <Form loading={loading}>
      <Header as="h4">Nombre</Header>
      <Input
        value={currentCollectionEntry.name}
        onChange={(e, { value }) =>
          setCurrentCollectionEntry(v => ({ ...v, name: value }))
        }
      />
      {currentCollectionEntry.picture && (
        <Image src={currentCollectionEntry.picture} />
      )}
      <Header as="h4">Propiedades</Header>
      <t.form.Form
        key="collectionPropertiesForm"
        value={currentCollectionEntry.properties}
        type={Properties}
        ref={propertiesFormRef}
        options={collectionPropertiesOptions}
        onChange={onChangeProperties}
      />
      <Header as="h4">Productos Variantes</Header>
      <t.form.Form
        key="collectionVariantsForm"
        value={currentCollectionEntry.variants}
        type={Variants}
        ref={variantsFormRef}
        options={collectionVariantsOptions}
        onChange={onChangeVariants}
      />
      <Form.Button
        content="Actualizar (Apretarlo tambien actualiza la imagen usada)"
        label=" &nbsp;"
        positive
        onClick={updateEntry}
        disabled={loading}
      />
    </Form>
  );
}

export default CollectionForm;
