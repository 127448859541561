import React, { Component, useState, useEffect } from "react";
import { debounce } from "lodash";
import {
  Segment,
  Header,
  Container,
  Select,
  Button,
  Loader,
  Input
} from "semantic-ui-react";
import feathersClient from "../../feathersClient";
import {
  Query,
  Builder,
  BasicConfig,
  Utils as QbUtils
} from "react-awesome-query-builder";
import "react-awesome-query-builder/css/styles.scss";
import { setOptions } from "react-filepond";
const InitialConfig = BasicConfig;

const BASELINE_CONFIG = {
  ...InitialConfig,
  fields: {
    finalAmount: {
      label: "Monto Final (c/ desc tarjetas)",
      type: "number",
      fieldSettings: {
        min: 0,
        step: 0.01
      },
      valueSources: ["value"],
      preferWidgets: ["number"]
    },
    fullAmount: {
      label: "Monto Total (s/ desc tarjetas)",
      type: "number",
      fieldSettings: {
        min: 0,
        step: 0.01
      },
      valueSources: ["value"],
      preferWidgets: ["number"]
    }
  }
};
const queryValue = { id: QbUtils.uuid(), type: "group" };

function BuilderView(props) {
  return (
    <div className="query-builder-container" style={{ padding: "10px" }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );
}

function CRUDShippingScreen(props) {
  const [config, setConfig] = useState(BASELINE_CONFIG);
  const [shipmentOptions, setShipmentOptions] = useState(
    props.initialData.company.settings.shipmentOptions || []
  );
  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState([]);
  const [activeOption, setActiveShipmentOption] = useState(null);
  const [tree, setTree] = useState(QbUtils.loadTree(queryValue, config));
  const [loading, setLoading] = useState(false);
  const company = props.initialData.company;
  const activeOptionInstance = !activeOption
    ? null
    : shipmentOptions.find(a => a.id == activeOption);
  useEffect(() => {
    loadOptions();
    loadProducts();
  }, []);

  useEffect(
    () => {
      if (!!activeOptionInstance && !!activeOptionInstance.logic) {
        console.log(activeOptionInstance);
        setTree(QbUtils.loadFromJsonLogic(activeOptionInstance.logic, config));
      }
    },
    [activeOption]
  );

  useEffect(
    () => {
      saveOptions(shipmentOptions);
    },
    [shipmentOptions]
  );

  const loadOptions = async () => {
    setLoading(true);
    let updatedCompany = await feathersClient
      .service("api/companies")
      .get(company.id);
    console.log(updatedCompany);
    setShipmentOptions(updatedCompany.settings.shipmentOptions);
    setLoading(false);
  };

  const loadProducts = async () => {
    setLoading(true);
    let products = await feathersClient.service("api/products").find({
      query: {
        $limit: -1,
        CompanyId: company.id,
        CategoryId: {
          $in: company.settings.deliveryCategories || []
        },
        $noListFiltering: true,
        $noStockFiltering: true
      }
    });
    console.log(products);
    setProducts(products);
    loadPrices(products.map(a => a.id));
  };

  const loadPrices = async productIds => {
    setLoading(true);
    let prices = await feathersClient.service("api/prices").find({
      query: {
        $limit: -1,
        CompanyId: company.id,
        ProductId: {
          $in: productIds
        }
      }
    });
    console.log(prices);
    setPrices(prices);
    setLoading(false);
  };

  const saveOptions = async newOptions => {
    setLoading(true);
    await feathersClient.service("api/companies").patch(company.id, {
      settings: { ...company.settings, shipmentOptions: newOptions }
    });
    setLoading(false);
  };

  const onChange = (immutableTree, config) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    setConfig(config);
    setTree(immutableTree);

    const jsonLogic = QbUtils.jsonLogicFormat(immutableTree, config);
    console.log(jsonLogic);
    setShipmentOptions(opt => {
      let copy = opt.map(a =>
        a.id != activeOption ? a : { ...a, logic: jsonLogic.logic }
      );
      return copy;
    });
    // `jsonTree` can be saved to backend, and later loaded to `queryValue`
  };

  return (
    <Container style={{ paddingTop: 10 }}>
      <Header>Administrar Precios de Envios</Header>
      <Segment basic>
        <Select
          placeholder={"Opción de envio"}
          options={shipmentOptions.map(a => ({
            key: a.id,
            value: a.id,
            text: a.name
          }))}
          value={activeOption}
          onChange={(e, { value }) => setActiveShipmentOption(value)}
        />
        {"   "}
        {"   "}
        <Button
          positive
          icon="plus"
          content="Crear nueva opción"
          disabled={loading}
          onClick={() => {
            let option = {
              id: QbUtils.uuid(),
              name: "Opción",
              ProductId: null,
              PriceId: null,
              logic: null
            };
            option.name = `${option.name} #${option.id}`;
            setShipmentOptions(options => [...options, option]);
            let qv = { id: option.id, type: "group" };
            setTree(QbUtils.loadTree(qv, config));
            setActiveShipmentOption(option.id);
          }}
        />
        {!activeOption ? null : (
          <Button
            negative
            icon="plus"
            content="Eliminar Opción"
            disabled={loading}
            onClick={() => {
              setShipmentOptions(options =>
                [...options].filter(a => a.id != activeOption)
              );
              setActiveShipmentOption(null);
            }}
          />
        )}

        {!activeOption ? null : !loading ? (
          <span>Todos los cambios están guardados</span>
        ) : (
          <Loader active inline />
        )}
        <br />
        <br />
        {!activeOptionInstance ? null : (
          <Input
            label="Nombre"
            value={activeOptionInstance.name}
            onChange={(e, { value }) => {
              setShipmentOptions(opt => {
                setLoading(true);
                let copy = opt.map(a =>
                  a.id != activeOption ? a : { ...a, name: value }
                );
                return copy;
              });
            }}
          />
        )}
        <br />
        <br />
        {!activeOptionInstance ? null : (
          <Select
            placeholder={"Producto de envio"}
            options={products.map(a => ({
              key: a.id,
              value: a.id,
              text: a.name
            }))}
            value={activeOptionInstance.ProductId}
            onChange={(e, { value }) => {
              setShipmentOptions(opt => {
                setLoading(true);
                let copy = opt.map(a =>
                  a.id != activeOption
                    ? a
                    : { ...a, ProductId: value, PriceId: null }
                );
                return copy;
              });
            }}
          />
        )}
        {"   "}
        {"   "}
        {!activeOptionInstance || !activeOptionInstance.ProductId ? null : (
          <Select
            label="Precio"
            placeholder={"Precio de envio"}
            options={prices
              .filter(a => a.ProductId == activeOptionInstance.ProductId)
              .map(a => ({
                key: a.id,
                value: a.id,
                text: `$${a.value} - cantidad ${a.packAmount} - Lista externa ${
                  a.externalListId
                }`
              }))}
            value={activeOptionInstance.PriceId}
            onChange={(e, { value }) => {
              setShipmentOptions(opt => {
                setLoading(true);
                let copy = opt.map(a =>
                  a.id != activeOption ? a : { ...a, PriceId: value }
                );
                return copy;
              });
            }}
          />
        )}
        {!activeOption ? null : (
          <Query
            {...BASELINE_CONFIG}
            value={tree}
            onChange={onChange}
            renderBuilder={BuilderView}
          />
        )}
      </Segment>
    </Container>
  );
}

export default CRUDShippingScreen;
