import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Menu, Input, Container, Grid } from "semantic-ui-react";
import { injectIntl, defineMessages } from "react-intl";
const messages = defineMessages({
  searchPlaceholder: {
    id: "app.navigation.search.placeholder",
    defaultMessage: "Buscar productos..."
  },
  logIn: {
    id: "app.navigation.session.log-in",
    defaultMessage: "Iniciar Sesión"
  },
  categories: {
    id: "app.navigation.categories.button",
    defaultMessage: "Categorias"
  }
});

class BrandsMenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      brands,
      uiConfiguration,
      onClick,
      intl: { formatMessage }
    } = this.props;
    let brandsData = brands.data;

    return (
      <Grid style={{ marginTop: -8 }}>
        <Grid.Column width={8} style={{ backgroundColor: "black" }}>
          <Menu vertical secondary inverted>
            {brandsData.map(aBrand => (
              <Menu.Item
                as={Link}
                to={`/search?q=${aBrand.brand}`}
                name={aBrand.brand}
                key={aBrand.brand}
                onClick={onClick}
              />
            ))}
          </Menu>
        </Grid.Column>
        <Grid.Column width={8} />
      </Grid>
    );
  }
}

export default injectIntl(BrandsMenu);
