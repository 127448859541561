import React, { Component } from "react";
import "./App.css";
import { Container } from "semantic-ui-react";
import { IntlProvider, addLocaleData, injectIntl } from "react-intl";
import esLocaleData from "react-intl/locale-data/es";
import translations from "./i18n/locales";
import Router from "./Router";
import smoothscroll from "smoothscroll-polyfill";
import authenticate, { logOut } from "./authenticate";
import feathersClient from "./feathersClient";
import _ from "lodash";
export const InitialDataContext = React.createContext({
  company: { name: "Sistel Prueba", interface: {} }
});

export const SessionDataContext = React.createContext({
  user: null,
  authenticated: false,
  loading: false,
  error: null,
  authenticate: authenticate(feathersClient),
  logOut: logOut(feathersClient)
});
// kick off the polyfill!
smoothscroll.polyfill();
addLocaleData(esLocaleData);

const helperCreateSubscriptions = (
  service,
  handler,
  events = ["created", "updated", "patched", "removed"]
) => {
  let removers = [];
  let subscriptions = events.reduce((partial, anEvent) => {
    let handlerFunc = handler(anEvent);
    removers.push(() => service.removeListener(anEvent, handlerFunc));
    return { ...partial, [anEvent]: service.on(anEvent, handlerFunc) };
  }, {});
  return {
    subscriptions,
    remover: () => removers.forEach(element => element())
  };
};
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      authenticated: false,
      loading: false,
      error: null
    };
    this.listener = null;
  }

  onUserChange = event => user => {
    //console.log(event);
    if (event == "patched" || "updated") {
      // //console.log(user);
      if (
        this.state.user &&
        this.state.authenticated &&
        user.id == this.state.user.id
      ) {
        // console.log(
        //   _.omit(this.state.user, [
        //     "createdAt",
        //     "updatedAt",
        //     "verifyChanges",
        //     "verifyToken",
        //     "verifyExpires",
        //     "resetToken",
        //     "resetExpires"
        //   ])
        // );
        // //console.log(
        //   _.omit(user, [
        //     "createdAt",
        //     "updatedAt",
        //     "verifyChanges",
        //     "verifyToken",
        //     "verifyExpires",
        //     "resetToken",
        //     "resetExpires"
        //   ])
        // );
        if (
          !_.isEqual(
            _.omit(this.state.user, [
              "createdAt",
              "updatedAt",
              "verifyChanges",
              "verifyToken",
              "verifyExpires",
              "resetToken",
              "resetExpires"
            ]),
            _.omit(user, [
              "createdAt",
              "updatedAt",
              "verifyChanges",
              "verifyToken",
              "verifyExpires",
              "resetToken",
              "resetExpires"
            ])
          )
        )
          this.authenticate(undefined, true);
        return;
      }
    } else {
      this.props.authenticate();
    }
  };

  _authenticate = options => {
    return authenticate(feathersClient)(options);
  };

  _logOut = () => {
    return logOut(feathersClient)();
  };

  logOut = async () => {
    this.setState({
      loading: true,
      error: null,
      user: null,
      authenticated: false
    });
    if (!!this.listener) {
      this.listener.remover();
      this.listener = null;
    }
    try {
      await this._logOut();
    } catch (error) {
      //console.log("error.logout", error);
    }
    this.setState({
      loading: false,
      error: null,
      user: null,
      authenticated: null
    });
    window.localStorage.removeItem("feathers-jwt");
  };

  authenticate = async (options, seamless = false) => {
    if (seamless) {
      this.setState({
        loading: true,
        error: null
      });
    }

    try {
      let user = await this._authenticate(options);
      this.setState({ user, loading: false, error: null, authenticated: true });
      this.listener = helperCreateSubscriptions(
        feathersClient.service("api/users"),
        this.onUserChange,
        ["updated", "patched", "removed"]
      );
      return user;
    } catch (error) {
      this.setState({
        error,
        loading: false,
        authenticated: false,
        user: null
      });
      if (!!this.listener) {
        this.listener.remover();
        this.listener = null;
      }
      return error;
    }
  };

  componentDidMount() {
    window.document.title = this.props.initialData.company.name;

    let currentJWT = window.localStorage.getItem("feathers-jwt");
    if (!!currentJWT && currentJWT.length > 0) {
      this.authenticate();
    }
  }

  componentWillUnmount() {
    if (!!this.listener) {
      this.listener.remover();
      this.listener = null;
    }
  }

  render() {
    // //console.log(JSON.stringify(this.props.initialData));
    const locale = window.location.search.replace("?locale=", "") || "es";
    const messages = translations[locale];
    return (
      <InitialDataContext.Provider value={this.props.initialData}>
        <SessionDataContext.Provider
          value={{
            ...this.state,
            authenticate: options => this.authenticate(options),
            logOut: () => this.logOut()
          }}
        >
          <IntlProvider locale={locale} key={locale} messages={messages}>
            <div>
              <Router />
            </div>
          </IntlProvider>
        </SessionDataContext.Provider>
      </InitialDataContext.Provider>
    );
  }
}

export default App;
