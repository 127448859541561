import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Image,
  Segment,
  Header,
  Card,
  Dropdown,
  Breadcrumb,
  List,
  Menu,
  Message,
  Grid,
  GridColumn,
  Button,
  Input
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ProductCards from "../components/ProductCards";
import ImageGallery from "../components/ImageGallery";
import { injectIntl, defineMessages } from "react-intl";
import feathersClient from "../feathersClient";
import _ from "lodash";
const messages = defineMessages({
  shoppingCart: {
    id: "app.views.cart.header",
    defaultMessage: "Carrito de Compras"
  },
  buyButton: {
    id: "app.views.cart.buy",
    defaultMessage: "Comprar"
  },
  continueButton: {
    id: "app.views.cart.continue",
    defaultMessage: "Seguir Comprando"
  },
  orderButton: {
    id: "app.views.cart.order",
    defaultMessage: "Revisar Pedido"
  }
});
const placeholderProductImg =
  "https://react.semantic-ui.com/images/wireframe/white-image.png";
class ShoppingCartScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      cart: null,
      changingCart: false,
      cartItems: [],
      products: [],
      prices: [],
      priceLists: [],
      categories: []
    };
  }

  loadCart = async () => {
    const {
      intl: { formatMessage },
      initialData: { company }
    } = this.props;
    let { settings } = company;
    let cartUUID = window.localStorage.getItem("cart-uuid");
    let { session } = this.props;
    let cart = null;
    //console.log("start");
    try {
      if (!cartUUID) {
        if (!session.authenticated || !session.user) {
          //console.log("end1");
          return;
        }
        this.setState({
          loading: true,
          error: null
        });
        //console.log("start2");
        let response = await feathersClient.service("api/orders").find({
          query: {
            UserId: session.user.id,
            status: "cart",
            $limit: 1,
            CompanyId: company.id,
            $sort: {
              id: -1
            }
          }
        });
        //console.log("orders", response);
        if (response && response.data && response.data.length >= 1) {
          cart = response.data[0];
        } else {
          this.setState({
            loading: false,
            changingCart: false,
            error: null
          });
          return;
        }
      }
      if (!cart) {
        this.setState({
          loading: true,
          error: null
        });
        //console.log("start3");
        let response = await feathersClient.service("api/orders").find({
          query: { uuid: cartUUID, status: { $in: ["cart", "confirming"] } }
        });
        //console.log("start4", response);
        if (response && response.data && response.data.length >= 1) {
          cart = response.data[0];
        } else {
          let newOrder = await feathersClient.service("api/orders").create({
            UserId:
              session.authenticated && session.user
                ? session.user.id
                : undefined,
            status: "cart",
            CompanyId: company.id
          });
          cartUUID = newOrder.uuid;
          window.localStorage.setItem("cart-uuid", cartUUID);
          this.loadCart();
          // this.setState({
          //   loading: false,
          //   error: null
          // });
          return;
        }
      }
      this.setState({
        loading: true,
        error: null
      });
      let stockSummary = await feathersClient
        .service("api/order-stock-summary")
        .get(cart.id);
      //console.log("stockSummary", stockSummary);
      //console.log("start5");
      let details = await feathersClient.service("api/order-details").find({
        query: { $limit: -1, OrderId: cart.id, $sort: { createdAt: 1 } }
      });
      //console.log("start6");
      let productIds = [...new Set(details.map(a => a.ProductId))];
      let pricesIds = [...new Set(details.map(a => a.priceId))];
      //console.log("start7");
      await feathersClient.service("api/products").find({
        query: {
          $limit: -1,
          CompanyId: company.id,
          id: { $in: productIds }
        }
      });
      //console.log("start8");
      let [products, prices] = await Promise.all([
        feathersClient.service("api/products").find({
          query: {
            $limit: -1,
            CompanyId: company.id,
            id: { $in: productIds }
          }
        }),
        feathersClient.service("api/prices").find({
          query: { $limit: -1, CompanyId: company.id, id: { $in: pricesIds } }
        })
      ]);
      //console.log("start8");
      //console.log("products", products);
      details = details.map(a => ({
        ...a,
        Product: products.find(b => b.id == a.ProductId),
        Price: prices.find(b => b.id == a.priceId)
      }));
      this.setState({
        loading: false,
        changingCart: true,
        error: null,
        cart,
        cartItems: details,
        products,
        prices
      });
    } catch (error) {
      //console.log("error", error);
      this.setState({
        error,
        loading: false,
        changingCart: false
      });
    }
  };

  componentDidMount() {
    this.loadCart();
  }

  componentDidUpdate(prev) {
    if (prev.reload != this.props.reload) {
      this.loadCart();
    }
  }

  _changeAmount = async (id, value) => {
    try {
      this.setState({ loading: true, error: null });
      await feathersClient
        .service("api/order-details")
        .patch(id, { amount: value });
      this.loadCart();
    } catch (error) {
      this.setState({ loading: false, error, changingCart: false });
    }
  };

  _debouncedChangeAmount = _.debounce(this._changeAmount, 1000);

  changeAmount = id => async (e, { value }) => {
    this.setState({ changingCart: true });
    this._debouncedChangeAmount(id, value);
  };

  removeDetail = id => async () => {
    try {
      this.setState({ loading: true, error: null });
      await feathersClient.service("api/order-details").remove(id);
      this.loadCart();
    } catch (error) {
      this.setState({ loading: false, error });
    }
  };

  render() {
    const {
      simple,
      height,
      intl: { formatMessage },
      initialData: {
        company: { interface: uiConfiguration, settings }
      },
      reloadProperty
    } = this.props;
    let basicCheckout = uiConfiguration.basicCheckout || false;
    const { cart, cartItems, loading, error } = this.state;

    return (
      <Container style={{ paddingTop: 10 }}>
        {this.props.noHeader ? null : (
          <CategoryBreadcrumb
            formatMessage={formatMessage}
            uiConfiguration={uiConfiguration}
          />
        )}
        {this.props.noHeader ? null : (
          <Header size="large">{formatMessage(messages.shoppingCart)}</Header>
        )}
        <Segment basic={simple || basicCheckout} loading={loading}>
          <List
            divided
            verticalAlign="middle"
            size={simple ? "tiny" : "huge"}
            style={!height ? undefined : { height, overflowY: "scroll" }}
          >
            {!loading ? null : (
              <List.Item>
                {" "}
                <List.Content>
                  <List.Header>Cargando carrito de compras...</List.Header>
                </List.Content>
              </List.Item>
            )}
            {!!loading || cartItems.length > 0 ? null : (
              <List.Item>
                {" "}
                <List.Content>
                  <List.Header>No hay productos en el carrito.</List.Header>
                </List.Content>
              </List.Item>
            )}
            {(cartItems || []).map((anItem, idx) => {
              if (!anItem.Price && !!anItem.Product) {
                return (
                  <List.Item key={idx}>
                    {" "}
                    <List.Content>
                      <List.Header>
                        Producto {anItem.Product.name}{" "}
                        <span>ESTE PRECIO NO ESTÁ DISPONIBLE</span>
                      </List.Header>
                    </List.Content>
                    <List.Content floated="right" style={{ marginTop: 15 }}>
                      <Button
                        basic
                        color="red"
                        size="huge"
                        icon="trash"
                        onClick={this.removeDetail(anItem.id)}
                      />
                    </List.Content>
                  </List.Item>
                );
              } else if (!anItem.Product) {
                return (
                  <List.Item key={idx}>
                    {" "}
                    <List.Content>
                      <List.Header>Producto NO DISPONIBLE</List.Header>
                    </List.Content>
                    <List.Content floated="left" style={{ marginTop: 15 }}>
                      <Button
                        basic
                        color="red"
                        size="tiny"
                        icon="trash"
                        onClick={this.removeDetail(anItem.id)}
                      />
                    </List.Content>
                  </List.Item>
                );
              } else if (
                !anItem.Product.StockTransactions ||
                !anItem.Product.StockTransactions.amount ||
                parseInt(anItem.Product.StockTransactions.amount) < 1
              ) {
                return (
                  <List.Item key={idx}>
                    {" "}
                    <List.Content>
                      <List.Header>
                        Producto {anItem.Product.name}{" "}
                        <Header.Subheader>
                          (ESTE PRODUCTO NO TIENE STOCK)
                        </Header.Subheader>
                      </List.Header>
                    </List.Content>
                    <List.Content floated="right" style={{ marginTop: 15 }}>
                      <Button
                        basic
                        color="red"
                        size="tiny"
                        icon="trash"
                        onClick={this.removeDetail(anItem.id)}
                      />
                    </List.Content>
                  </List.Item>
                );
              }
              return (
                <List.Item key={idx}>
                  {simple ? null : (
                    <Image
                      size="tiny"
                      style={{ width: "auto", height: "100px" }}
                      src={
                        anItem.Product &&
                        anItem.Product.images &&
                        anItem.Product.images.length > 0
                          ? anItem.Product.images[0]
                          : placeholderProductImg
                      }
                    />
                  )}
                  <List.Content>
                    {simple ? (
                      anItem.Product.name
                    ) : (
                      <List.Header style={{ fontWeight: "normal" }}>
                        {anItem.Product.name}
                      </List.Header>
                    )}
                  </List.Content>
                  <List.Content floated="right" style={{ marginTop: 30 }}>
                    <List.Header>
                      ${" "}
                      {(anItem.amount * anItem.Price.value)
                        .toFixed(2)
                        .replace(".", ",")}
                    </List.Header>
                  </List.Content>
                  <List.Content floated="left" style={{ marginTop: 15 }}>
                    <Button
                      basic
                      color="red"
                      size={simple ? "tiny" : "huge"}
                      icon="trash"
                      onClick={this.removeDetail(anItem.id)}
                    />
                  </List.Content>
                  <List.Content floated="left" style={{ marginTop: 15 }}>
                    <Input
                      type="number"
                      min={1}
                      max={6}
                      size={simple ? "tiny" : undefined}
                      step={1}
                      defaultValue={parseInt(anItem.amount)}
                      onChange={this.changeAmount(anItem.id)}
                    />
                    {"     "}x{anItem.Price.packAmount}u
                  </List.Content>
                </List.Item>
              );
            })}
          </List>
        </Segment>
        <Segment textAlign="right" basic>
          <Header size="medium" style={{ fontWeight: "none" }}>
            {this.state.loading ? "" : "Total:"}{" "}
            <b>
              {this.state.loading ? "" : "$"}{" "}
              {this.state.loading
                ? ""
                : cartItems
                    .filter(a => a.Price && a.Product)
                    .reduce((p, c) => p + c.Price.value * c.amount, 0)
                    .toFixed(2)
                    .replace(".", ",")}
            </b>
          </Header>
          <Button
            size="large"
            inverted
            color="grey"
            as={Link}
            to="/"
            style={{ color: "grey" }}
          >
            {formatMessage(messages.continueButton)}
          </Button>
          <Button
            size="large"
            color="blue"
            as={Link}
            to="/checkout"
            disabled={
              this.state.loading ||
              cartItems.some(
                a =>
                  !a.Product ||
                  !a.Price ||
                  !a.Product.StockTransactions ||
                  !a.Product.StockTransactions.amount ||
                  parseInt(a.Product.StockTransactions.amount) < 1
              ) ||
              cartItems.length <= 0
            }
            style={{ backgroundColor: uiConfiguration.colors.primary }}
          >
            {cartItems.some(
              a =>
                !a.Product ||
                !a.Price ||
                !a.Product.StockTransactions ||
                !a.Product.StockTransactions.amount ||
                parseInt(a.Product.StockTransactions.amount) < 1
            )
              ? "Eliminá los productos invalidos para continuar"
              : cartItems.length <= 0
              ? "No hay productos"
              : formatMessage(
                  !!settings && !!settings.orderOnly
                    ? messages.orderButton
                    : messages.buyButton
                )}
          </Button>
        </Segment>
      </Container>
    );
  }
}

const CategoryBreadcrumb = props => {
  return (
    <Breadcrumb>
      <Breadcrumb.Section
        link
        as={Link}
        to="/"
        style={{ color: props.uiConfiguration.colors.primary }}
      >
        Home
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right angle" />
      <Breadcrumb.Section active>
        {props.formatMessage(messages.shoppingCart)}
      </Breadcrumb.Section>
    </Breadcrumb>
  );
};

export default injectIntl(ShoppingCartScreen);
